import { faMedium } from "@fortawesome/free-brands-svg-icons";
import {
    faChalkboardTeacher,
    faCircle,
    faCoffee,
    faCookieBite,
    faCouch,
    faExchange,
    faFastForward,
    faGavel,
    faGlassMartini,
    faInfoCircle,
    faMapMarkerQuestion,
    faMapSigns,
    faMoneyCheckEditAlt,
    faPaw,
    faPlug,
    faRandom,
    faRedo,
    faShoppingBag,
    faSuitcase,
    faTachometerFastest,
    faTicketAlt,
    faTv,
    faUndoAlt,
    faUserCheck,
    faUtensils,
    faWifi,
    IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import _ from "lodash";
import {
    IncludedServiceData,
    IncludedServiceMainType,
    IncludedServiceSubType,
} from "WebApiClient";
import Tooltip from "@mui/material/Tooltip";
import { SabreIconsEmoji } from "components/Fare/IncludedServiceIcons/SabreIcons";
interface IncludedServiceIconProps {
    data: any;
    incSerFull?: any;
    toShow?: boolean;
    checkSabreOther?: any;
}
var warningCheck = false;
export const incServices: React.FC<IncludedServiceIconProps> = (props) => {
    var mainGroup: any = props.data;
    const uniqueServices = _.uniqBy(mainGroup.services, "hint");

    const resultObject = { ...mainGroup, services: uniqueServices };

    function checkSabreServiceIcon() {
        if (props.data.type == 0) {
            return SabreIconsEmoji.Baggage.icon;
        }
        if (props.data.type == 1) {
            return SabreIconsEmoji.Seat.icon;
        }
        if (props.data.type == 2) {
            return SabreIconsEmoji.M.icon;
        }
        if (props.data.type == 3) {
            return SabreIconsEmoji.MileageAccural.icon;
        }
        if (props.data.type == 5) {
            return SabreIconsEmoji.InFlightEntertainment.icon;
        }
        if (props.data.type == 6) {
            return SabreIconsEmoji.Change.icon;
        }
        return (
            <FontAwesomeIcon
                icon={GetIcon(mainGroup)}
                style={{ marginRight: "4px" }}
            />
        );
    }
    //label
    function checkSabreServiceHint() {
        if (props.data.type == 0) {
            return SabreIconsEmoji.Baggage.hint;
        }
        if (props.data.type == 1) {
            return SabreIconsEmoji.Seat.hint;
        }
        if (props.data.type == 2) {
            return SabreIconsEmoji.M.hint;
        }
        if (props.data.type == 3) {
            return SabreIconsEmoji.MileageAccural.hint;
        }
        if (props.data.type == 5) {
            return SabreIconsEmoji.InFlightEntertainment.hint;
        }
        if (props.data.type == 6) {
            return SabreIconsEmoji.Change.hint;
        }
        return "";
    }

    const warningCheckTrue = (hint?:string) =>{
        warningCheck = true
        return null
    }

    const warningCheckFalse = (hint:string) =>{
        warningCheck = false
        return hint
    }
    return (
        <React.Fragment>
      
                <span
                    style={{
                        width: "20px",
                        position: "relative",
                        margin: "1px",
                    }}
                >
                    <Tooltip
                        title={
                            <React.Fragment>
                                {mainGroup.services!.map(
                                    (sub: any, subIndex: any) => (
                                        <span key={"mainSer_" + subIndex}>
                                            <li>{sub.hint}</li>
                                        </span>
                                    )
                                )}
                            </React.Fragment>
                        }
                    >
                        <span>
                        {resultObject.services!.map((sub: any, Index: any) => (
                            <>
                                {
                                Index == 0 && <span style={{ marginRight: "4px" }}>
                                {typeof sub.hint === "string" &&
                                sub.hint.includes("WARNING:") && props?.toShow
                                    ?  null
                                    :  checkSabreServiceIcon()}
                                    </span>
                                }
                            </>
                            
                        ))}
                        </span>
                    </Tooltip>
                    {props.toShow &&
                        resultObject.services!.map((sub: any, Index: any) => (
                            <span style={{ marginRight: "4px" }}>
                                {typeof sub.hint === "string" &&
                                sub.hint.includes("WARNING:")
                                    ?  null
                                    :  warningCheckFalse(sub.hint)}
                                {Index + 1 !== resultObject.services.length ? (
                                    <span>,</span>
                                ) : (
                                    <> </>
                                )}
                            </span>
                        ))}
                </span>
            
        </React.Fragment>
    );
};

function GetIcon(data: IncludedServiceData): IconDefinition {
    let iconClass: IconDefinition = faInfoCircle;
    switch (data.subType) {
        case IncludedServiceSubType.FreeBaggageAllowance:
            iconClass = faSuitcase;
            break;
        case IncludedServiceSubType.CarryOnBaggage:
            iconClass = faShoppingBag;
            break;
        case IncludedServiceSubType.Lunch:
            iconClass = faUtensils;
            break;
        case IncludedServiceSubType.Snack:
            iconClass = faCookieBite;
            break;
        case IncludedServiceSubType.SnacksAndBeverage:
            iconClass = faUtensils;
            break;
        case IncludedServiceSubType.NonAlcoholicDrink:
            iconClass = faCoffee;
            break;
        case IncludedServiceSubType.FreeAlcoholicDrink:
            iconClass = faGlassMartini;
            break;
        // INFLIGHT ENTERTAINMENT
        case IncludedServiceSubType.PersonalEntertainment:
            iconClass = faTv;
            break;
        case IncludedServiceSubType.InternetAccess:
            iconClass = faWifi;
            break;
        case IncludedServiceSubType.InSeatpower:
            iconClass = faPlug;

            break;
        case IncludedServiceSubType.Pet:
            iconClass = faPaw;

            break;
        case IncludedServiceSubType.Security:
            iconClass = faGavel;

            break;
        case IncludedServiceSubType.PriorityBoarding:
            iconClass = faFastForward;

            break;
        case IncludedServiceSubType.Ticket:
            iconClass = faTicketAlt;

            break;
        case IncludedServiceSubType.CheckIn:
            iconClass = faChalkboardTeacher;

            break;
        case IncludedServiceSubType.ReBooking:
            iconClass = faRedo;
            break;
        case IncludedServiceSubType.RefundAllowed:
            iconClass = faMoneyCheckEditAlt;
            break;
        case IncludedServiceSubType.PriorityCheckIn:
            iconClass = faUserCheck;
            break;
        case IncludedServiceSubType.FastTrack:
            iconClass = faTachometerFastest;
            break;
        case IncludedServiceSubType.ChangeAfterDeparture:
            iconClass = faUndoAlt;
            break;
        case IncludedServiceSubType.ChangeBeforeDeparture:
            iconClass = faUndoAlt;
            break;
        case IncludedServiceSubType.ChangeInCaseOfNoShow:
            iconClass = faMapMarkerQuestion;
            break;
        case IncludedServiceSubType.OpenReturnDate:
            iconClass = faExchange;
            break;
        case IncludedServiceSubType.Stopover:
            iconClass = faMapSigns;
            break;
        default:
            switch (data.type) {
                case IncludedServiceMainType.Baggage:
                    iconClass = faSuitcase;
                    break;
                case IncludedServiceMainType.Meal:
                    iconClass = faUtensils;
                    break;
                case IncludedServiceMainType.InFlightEntertainment:
                    iconClass = faTv;
                    break;
                case IncludedServiceMainType.Miles:
                    iconClass = faMedium;
                    break;
                case IncludedServiceMainType.Transfer:
                    iconClass = faRandom;

                    break;
                case IncludedServiceMainType.Seat:
                    iconClass = faCouch;

                    break;
                case IncludedServiceMainType.Other:
                    iconClass = faCircle;
                    break;
            }
    }
    return iconClass;
}
export default incServices;
