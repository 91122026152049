import { faBug, faSync } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { BugReportModal } from "components/Shared/BugReportModal";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useAvailableFaresClient } from "hooks/useHttpClient";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { AvailableFareModel, FareIdentifier, GetRuleTextApiResponse, RuleTextResponseDataView } from "WebApiClient";


interface FareRuleModalProps {
    IsOpen: boolean;
    Toggle: () => void;
    Identifier: FareIdentifier
    SelectedConnections: number[];
    SetSelectedConnection: (legindex: number, connectionIndex: number) => void
    Fare: AvailableFareModel
}



const FareRuleModal: React.FC<FareRuleModalProps> = props => {
    const dispatch = useDispatch();
    const client = useAvailableFaresClient();
    const [bugReportModalOpen, setBugReportOpen] = useState(false);
    const ToggleBugReportModal = () => { setBugReportOpen(!bugReportModalOpen) };
    const [fetching, setFetching] = useState(true);
    const [error, setError] = useState(false);
    const [response, setResponse] = useState<GetRuleTextApiResponse | undefined>(undefined)
    const responseMetaData = response?.responseMetaData;
    const result = response?.result;

    useEffect(() => {
        if (props.IsOpen) {
            Fetch(props.SelectedConnections);

        }
    }, [props.IsOpen, props.SelectedConnections]);

    function OnRefresh() {
        Fetch(props.SelectedConnections);
    }
    function Fetch(selectedConnections: number[]) {
        setFetching(true);
        setResponse(undefined);
        setError(false);
        client.getFareRuleInformationText({ fareIdentifier: props.Identifier, selectedLegConnections: selectedConnections }).then((response) => {
            dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
            setResponse(response);
            if (response.result) {
                setError(false);
            }
            else { setError(true) };

        }).catch(() => {
            setError(true);
        }).finally(() => {
            setFetching(false);
        })

    }
    return (
        <Modal size="xl" centered={true} scrollable={true} isOpen={props.IsOpen} toggle={props.Toggle}>
            <ModalHeader toggle={props.Toggle}>Flight Rules</ModalHeader>
            <ModalBody>
                {props.IsOpen &&
                    <div className="row">
                        {fetching ?
                            <div className="col-12 mb-2">
                                <WaitingCard />
                            </div> :
                            <Fragment>

                                {error &&
                                    <Fragment>
                                        <div className="col-12">
                                            <ErrorDisplay data={response?.responseMetaData} />
                                        </div>

                                    </Fragment>

                                }
                                {result &&
                                    <Fragment>
                                        <div className="col-12 mb-2">
                                            <FareRuleDisplay data={result} />

                                        </div>
                                    </Fragment>
                                }
                            </Fragment>

                        }
                    </div>
                }

            </ModalBody>
            <ModalFooter>
                {responseMetaData &&
                    <button type="button" className="btn btn-secondary" onClick={ToggleBugReportModal}><FontAwesomeIcon icon={faBug} /> Bug Report</button>
                }
                {!fetching &&
                    <button type="button" className="btn btn-secondary" onClick={OnRefresh}><FontAwesomeIcon icon={faSync} /> Refresh</button>
                }
                <Button color="primary" onClick={props.Toggle}>close</Button>
            </ModalFooter>
            <BugReportModal Toggle={ToggleBugReportModal} IsOpen={bugReportModalOpen} MetaData={responseMetaData}></BugReportModal>

        </Modal>
    );
};


const FareRuleDisplay: React.FC<{ data: RuleTextResponseDataView }> = props => {
    return <div className="card">
        <div className="card-body">
            <div className="row">
                <div className="col-12">
                    <Table responsive striped bordered>
                        <tbody>
                            {props.data.rules.map((chapter, chapterIndex) =>
                                <tr key={chapterIndex}>
                                    <td className="font-weight-bold">{chapter.ruleHeader}</td>
                                    <td>{chapter.ruleLines.map((line, lineIndex) =>
                                        <pre style={{ marginBottom: "0px" }} key={"ruleLines"+lineIndex}>{line}</pre>
                                    )}</td>
                                </tr>
                            )
                            }
                        </tbody>
                    </Table>
                </div>


            </div>
        </div>
    </div>


}


export default FareRuleModal;