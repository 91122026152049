import { BookingItemModel, BookingMangerFilterRequestData, BookingStatus, ENotificationActionRequiredEnumModel } from "WebApiClient"



export const DefaultBookingManagerFilter: BookingMangerFilterRequestData = {
    freeField: "",
    shoppingCartID: "",
    bookingItemID: "",
    recordLocator: "",
    mainTravellerLastName: "",
    origin: "",
    destination: "",
    branch: "",
    branchGroup: "",
    responsibleAgent: "",
    firstDepartureDate: undefined,
    firstDepartureDateTo: undefined,
    distributionChannel: "",
    statusCode: "",
    transactionID: "",
    gds: "",
    pcc: "",
    bookingDateFrom: undefined,
    bookingDateTo: undefined,
    bookingStatus: BookingStatus.All,
    ticketTimeLimitFrom: undefined,
    ticketTimeLimitTo: undefined,
    ticketingDateFrom: undefined,
    ticketingDateTo: undefined,
    lastArrvialDate: undefined,
    lastArrvialDateTo: undefined,
    airlineCodes: "",
    externalReference: "",
    web: "",
    itemsOffset: 0,
    maxItems: 20,
    sortModifiers: [],
    notificationActionRequired: ENotificationActionRequiredEnumModel.None
}


export interface ShoppingCartModel {
    CartID: string;
    Bookings: BookingItemModel[];
}


export interface BookingManagerVisibilityOptions {
    Open: boolean;
    Ordered: boolean;
    Canceled: boolean;
    Booked: boolean;
    Departed: boolean;
    Imported: boolean;
}





