import { GetRemarkLabel } from "components/Shared/RemarkEnumDisplay";
import React from "react";
import { EnhancedRemarkModel } from "WebApiClient";

export const PnrEnhancedRemarksCard: React.FC<{ data: EnhancedRemarkModel[] }> = ({ data }) => {


    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Enhanced Remarks <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <div className="">
                    <table className="table table-sm table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th className="font-weight-bold">Remark</th>
                                <th className="font-weight-bold">Type</th>
                                <th className="font-weight-bold">Code</th>
                                <th className="font-weight-bold">Segments</th>
                                <th className="font-weight-bold">Passengers</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((remark, index) =>
                                <RemarkItem data={remark} key={"rmrk_"+index} />
                            )}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );


}

export const RemarkItem: React.FC<{ data: EnhancedRemarkModel }> = ({ data }) => {
    const { passengerSelection, remark, segmentSelection, remarkType, typeCode } = data;

    return (
        <tr>
            <td>{remark}</td>
            <td>{GetRemarkLabel(remarkType)}</td>
            <td>{typeCode}</td>
            <td>{passengerSelection.join(',')}</td>
            <td>{segmentSelection.join(',')}</td>

        </tr>
    );


}


