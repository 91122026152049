import React, { useEffect, useState } from "react";
import { AvailableFareConnectionModel } from "WebApiClient";
import FareLegHeader from "./Fare.Leg.Header";
import _ from "lodash";

interface LegProps {
  Data: any;
  ShowFlightTimes: boolean;
  SelectedConnection: number;
  GetSelectedConnectionIndexByLegIndex?: any;
  LegIndex: number;
  SelectConnection: (legIndex: number, connectionIndex: number) => void;
  ShowLegPlatingCarrier: boolean;
  ApplicableConnections?: number[];
  Fare: any;
  ToggleFlightTimes: () => void;
  isActivate?: boolean;
  CityName?: any;
}

const FareLeg: React.FC<LegProps> = (props) => {
  const con: AvailableFareConnectionModel =
    props.Data.connections![props.SelectedConnection];

  useEffect(() => {
    if (props.isActivate) {
      // sortByTime(props.Data.connections);
    }
  }, []);

  function sortByTime(connection: any) {
    let arr: any = [];
    let con: any = _.map(connection, "connectionHeader");
    _.map(con, (itr: any, index: number) => {
      var days = itr.legTravelTime.daysStayOver * 1440;
      let hours: any = itr.legTravelTime.hours * 60;
      let totalMinutes = days + hours + itr.legTravelTime.minutes;
      let obj = {
        index: index,
        totalMinutes: totalMinutes,
        itr: itr,
      };
      arr.push(obj);
    });
    let minValue: any = _.minBy(arr, "totalMinutes");
    props.SelectConnection(props.LegIndex, minValue.index);
  }

  props.Data.connections.sort((a: any, b: any) => {
    const legTravelTimeA = a.connectionHeader
      ? a.connectionHeader.legTravelTime.hours * 60 +
        a.connectionHeader.legTravelTime.minutes
      : Infinity;
    const legTravelTimeB = b.connectionHeader
      ? b.connectionHeader.legTravelTime.hours * 60 +
        b.connectionHeader.legTravelTime.minutes
      : Infinity;
    return legTravelTimeA - legTravelTimeB;
  });


  //----------- no need -----------
  // const sendPageHeight = () => {
  //   const height = document.body.scrollHeight;
  //   window.parent.postMessage(["setHeight", height + "px"], "*");
  // };

  // useEffect(() => {
  //   sendPageHeight();
  // }, []);

  return (
    <React.Fragment>
      <FareLegHeader
        key={"fareHeader" + props.LegIndex}
        Data={con?.connectionHeader!}
        timeSegmentDiff={con?.segments}
        LegIndex={props.LegIndex}
        PlatingCarrierCode={props.Data.platingCarrier!.code!}
        PlatingCarrierName={props.Data.platingCarrier!.hint!}
        ShowLegPlatingCarrier={props.ShowLegPlatingCarrier}
        ToggleFlightTimes={props.ToggleFlightTimes}
        IsExpanded={props.ShowFlightTimes}
        CityName={props.CityName}
      />
    </React.Fragment>
  );
};

export default FareLeg;
