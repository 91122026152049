import { faAngleDoubleDown, faAngleDoubleUp, faPlaneArrival, faPlaneDeparture } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CalculationResultDisplay } from "components/Fare/CalculationDebugModal";
import { PnrSegmentsCard } from "components/PNR/Pnr.SegmentsCard";
import { AirlineLogo } from "components/Shared/AirlineLogo";
import { TabbedContent, TabbedContentProps, TabProps } from "components/Shared/TabbedContent";
import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { BookingManagementLeg, CalculationResultModel } from "WebApiClient";
import { BookingSegmentIdentifier } from "../types/BookingDetailsTypes";
interface BookingVersionItemDetailsBookedFlightProps {
    Data: BookingManagementLeg[]
    OnCancelSegment?: (segments: BookingSegmentIdentifier[]) => void;
    OnAddSegment?: () => void;
}

const BookedFlightSegmentsCard: React.FC<BookingVersionItemDetailsBookedFlightProps> = props => {
    const enableSelection = (props.OnCancelSegment !== undefined)
    const [selectedSegments, setSelectedSegments] = useState<BookingSegmentIdentifier[]>([]);
    // const [selectedSegments, setSelectedSegments] = useState<number[]>([]);
    const hasSelectedElements = selectedSegments.length > 0;

    function OnToggleSelection(selection: BookingSegmentIdentifier) {
        const elemAt = selectedSegments.findIndex(e => e.LegIndex === selection.LegIndex && e.SegmentIndex === selection.SegmentIndex);
        let contains = elemAt !== -1;
        let arrCopy = [...selectedSegments];
        if (contains) {
            arrCopy.splice(elemAt, 1);
        }
        else {
            arrCopy.push(selection);
        }
        setSelectedSegments(arrCopy);
    }

    function OnCancel() {
        if (props.OnCancelSegment) {
            props.OnCancelSegment(selectedSegments)
        }
    }
    function GetTabs(): TabProps[] {
        let result: TabProps[] = [];

        result = props.Data.map((leg, index) => {
            const maxSegmIndex = leg.segments.length - 1;
            const tabTitle = leg.segments.length > 0 ? `${leg.segments[0].departure.iata} - ${leg.segments[maxSegmIndex].arrival.iata}` : "Unkown";
            const firstDep = leg.segments.length > 0 ? `${leg.segments[0].departure.iata}` : "Unkown";
            const lastArr = leg.segments.length > 0 ? `${leg.segments[maxSegmIndex].arrival.iata}` : "Unkown";
            const tab: TabProps = {
                Component: <LegItem data={leg} index={index} title={tabTitle} />,
                Title: <span><FontAwesomeIcon icon={faPlaneDeparture} /> {firstDep} - <FontAwesomeIcon icon={faPlaneArrival} /> {lastArr} <span style={{ fontSize: "0.7em" }} className="align-top badge badge-pill badge-dark">{leg.segments.length} segments</span></span>,
                AmountIndicator: undefined
            }
            return tab;
        })
        return result;
    }

    const DetailsTabContent: TabbedContentProps = {
        EnableJump: true,
        Tabs: GetTabs()
    }

    return (
        <div className="card">
            <div className="card-header card-header-primary">Flight</div>
            <div className="card-body">
                <div className="row">
                    {props.Data.length > 0 ?
                        <div className="col-12">
                            <TabbedContent {...DetailsTabContent} />
                        </div>
                        : <div className="col-12">
                            No data available
                        </div>
                    }
                </div>

            </div>
            {enableSelection &&
                <div className="card-footer">
                    {props.OnCancelSegment &&
                        <button disabled={!hasSelectedElements} className="btn btn-primary float-end" onClick={OnCancel}>Cancel selection</button>
                    }
                </div>
            }
        </div>
    );
}

export default BookedFlightSegmentsCard;

const LegItem: React.FC<{ data: BookingManagementLeg, index: number, title: string }> = ({ data, index, title }) => {
    const { cabinClass, carrier, pointOfTurnaround, segments, appliedDiscounts, calculationResults } = data;

    return (

        <div className="card">
            <div className="card-body">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 mb-2 text-black ">
                            <h5 className="card-title"> {title} <span className="float-end"><AirlineLogo IsAlliance={false} PlatingCarrier={carrier.code} PlatingCarrierName={carrier.hint} /></span></h5>
                        </div>
                        <div className="col-12 text-black">
                            <p>Carrier: {carrier.hint} ({carrier.code})</p>
                            {cabinClass.length > 0 &&
                                <p>Cabin class: {cabinClass}</p>
                            }
                            {pointOfTurnaround.length > 0 &&
                                <p>Point of turnaround: {pointOfTurnaround}</p>
                            }
                        </div>
                        {calculationResults.length > 0 &&
                            <div className="col-12 mb-2">
                                <LegCalculationsItem calculations={calculationResults} />
                            </div>
                        }
                        <div className="col-12">
                            <PnrSegmentsCard data={segments} />
                        </div>
                    </div>

                </div>

            </div>
        </div>

    );


}

const LegCalculationsItem: React.FC<{ calculations: CalculationResultModel[] }> = ({ calculations }) => {
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    return <div className="card">
        <div className="card-header card-header-primary clickme" onClick={toggle}>
            Calculations <span className="badge badge-primary badge-pill align-top">{calculations.length} </span><span className="float-end"><FontAwesomeIcon icon={open ? faAngleDoubleDown : faAngleDoubleUp} /></span>
        </div>
        <Collapse isOpen={open}>
            <div className="card-body card-body-secondary">
                <div className="row">


                    {calculations.map((calc, i) =>
                        <div key={"calc"+i} className="col-12 mb-2">
                            <CalculationResultDisplay data={calc} index={i} />
                            {/* <CalculationItem calculation={calc} index={i+1}/> */}
                        </div>
                    )}
                </div>
            </div>
        </Collapse>
    </div>

}

// const CalculationItem: React.FC<{ calculation: CalculationResultModel,index:number }> = ({ calculation,index }) => {
//     const {calculationDebugPaxInfo,calculationInput,calculationItems} = calculation;
//     const [open, setOpen] = useState(false);
//     const toggle = () => setOpen(!open);
//     return <div className="card">
//         <div className="card-header card-header-primary clickme" onClick={toggle}>
//             #{index}
//         </div>
//         <Collapse isOpen={open}>
//             <div className="card-body">
//                 {calculationItems.map((calc,i)=>
//                 <div key={i}>
//                     {calc.}
//                 </div>
//                 )}
//             </div>
//         </Collapse>
//     </div>

// }
