import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { BookingMangerFilterRequestData } from "WebApiClient";
import {
  BookingActionRequiredSelect,
  BookingStatusSelect,
} from "./BookingManager.Filter";

interface BookingManagerQuickFilterProps {
  Filter: BookingMangerFilterRequestData;
  OnSubmit: (filter: BookingMangerFilterRequestData) => void;
}
enum FilterSource {
  PassengerLastName = "PassengerLastName",
  Filekey = "Filekey",
}
const BookingManagerQuickFilter: React.FC<BookingManagerQuickFilterProps> = (
  props
) => {
  const filter = props.Filter;

  const [inputValState, setInputValState] = useState("");

  const [source, setSource] = useState<string>(FilterSource.PassengerLastName);

  function OnApply() {
    let newfilter: BookingMangerFilterRequestData = {
      ...filter,
      itemsOffset: 0,
    };
    switch (source) {
      case FilterSource.Filekey:
        newfilter = { ...newfilter, recordLocator: inputValState };
        break;
      case FilterSource.PassengerLastName:
        newfilter = { ...newfilter, mainTravellerLastName: inputValState };
        break;
    }
    // newfilter = { ...newfilter, freeField: inputValState };
    props.OnSubmit(newfilter);
  }

  useEffect(() => {
    const regex_A_N = /^(?=.*[0-9])(?=.*[a-zA-Z]).+$/;
    const regex_A = /^[a-zA-Z]+$/;

    if (regex_A.test(inputValState)) {
      setSource("PassengerLastName");
    }
    if (regex_A_N.test(inputValState)) {
      setSource("Filekey");
    }
  }, [inputValState?.length]);

  return (
    <div className="row">
      <div className="col-xl-4 col-md-6 mb-1">
        <div
          className="input-group "
          onKeyDown={(e) => {
            if (e.which === 13) OnApply();
          }}
        >
          <input
            placeholder="Quick search"
            autoFocus={true}
            value={inputValState}
            onChange={(e) => {
              setInputValState(e.target.value);
            }}
            className="form-control"
          />

          <select
            value={source}
            onChange={(e) => {
              setSource(e.target.value);
            }}
            className="form-select w-auto"
          >
            <option value={FilterSource.PassengerLastName}>Last name</option>
            <option value={FilterSource.Filekey}>Filekey</option>
          </select>
          <div className="input-group-append">
            <button
              className="btn btn-outline btnStyle"
              onClick={OnApply}
              type="button"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-md-6 mb-1">
        <div className="input-group">
          <span className="input-group-text">Status</span>

          <BookingStatusSelect
            value={props.Filter.bookingStatus}
            OnChange={(e) => {
              props.OnSubmit({ ...props.Filter, bookingStatus: e });
            }}
          />
        </div>
      </div>
      <div className="col-xl-4 col-md-6 mb-1">
        <div className="input-group">
          <span className="input-group-text">Notifications</span>

          <BookingActionRequiredSelect
            value={props.Filter.notificationActionRequired}
            OnChange={(e) => {
              props.OnSubmit({
                ...props.Filter,
                notificationActionRequired: e,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingManagerQuickFilter;

{
  /* <div className="row mt-2">

<div className="col-auto">


  <span><b className="font-weight-bold">Booking Status: </b> <BookingStatusSelect value={Data.bookingStatus} OnChange={(e) => { UpdateFilter({ ...Data, bookingStatus: e }) }} /></span>
</div>
<div className="col-auto">
  <span><b className="font-weight-bold">Action required: </b> <BookingActionRequiredSelect value={Data.notificationActionRequired} OnChange={(e) => { UpdateFilter({ ...Data, notificationActionRequired: e }) }} /></span>

</div>

</div> */
}
