import { ISegmentState } from "components/FareSearch/types/FareSearchTypes";
import DateInput from "components/Shared/DateInput";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { useAvailableFaresClient } from "hooks/useHttpClient";
import { t } from "i18next";

interface DepartureDateProps {
  SegmentIndex: number;
  Segment: ISegmentState;
  PreviousSegment: ISegmentState | undefined;
  NextSegment: ISegmentState | undefined;
  UpdateSegment: (index: number, segment: ISegmentState) => void;
  btnRef: any;
  reference?: any;
  tabIndex?: number;
  setdepartureCollector?: any;
  setScrollingDown?: any;
  isDateInputInOrder?: boolean;
  segmentArr: ISegmentState[];
  scrollingDown?: boolean;
}

const DepartureDate: React.FC<DepartureDateProps> = (props) => {
  const isAdmin = useSelector((state: State) => state.Session.isAdmin);
  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );
  const airlineData = useSelector((state: any) => state.airlineSession.value);
  let settingArr = otherSettings.split("\n");
  let smartCalender = (settingArr[2] !== undefined && settingArr[2].length <= 15)
    ? "showSmartCalender=None/n[]/nfalse/nfalse"
    : settingArr[2];
  let calender = smartCalender.split("/n");
  let showSmartCalender = calender[0];
  // let airline = JSON.parse(calender[1]);
  let flightDate = JSON.parse(calender[2]);
  let allotment = JSON.parse(calender[3]);

  function maxDate(): Date {
    return props.NextSegment?.DateInput !== undefined
      ? props.NextSegment?.DateInput
      : moment().add(15, "years").toDate();
  }
  function minDate(): Date {
    return props.PreviousSegment?.DateInput !== undefined
      ? props.PreviousSegment?.DateInput
      : moment().toDate();
  }

  function OnChangeNew(value: Date | undefined) {
    let newSegment: ISegmentState = { ...props.Segment, DateInput: value };
    props.UpdateSegment(props?.SegmentIndex, newSegment);
    // props.btnRef.current.focus()
  }

  function SelectNext() { }

  const bookedStyle3 = {
    border: "2px solid blue",
    backgroundColor: "#90EE90",
  };

  const [booked1, setBooked1] = React.useState(flightDate);
  const [booked2, setBooked2] = React.useState(allotment);
  const [origin, setOrigin] = useState("");
  const [destination, setDestination] = useState("");
  const [bookedDays1, setBookedDays1] = useState();
  const [bookedDays2, setBookedDays2] = useState();
  const [bookedDays3, setBookedDays3] = useState();
  const [isFind, setIsFind] = useState(false);
  const bookedStyle2 = { border: "2px solid blue" };
  const [Hdates, setHdates] = useState();
  const AvailableFare = useAvailableFaresClient();

  const bookedStyle1 = {
    border: "#90EE90",
    backgroundColor: "#90EE90",
  };

  if (origin !== props.Segment.DepartureInput.toUpperCase()) {
    setOrigin(props.Segment.DepartureInput.toUpperCase());
    setIsFind(true);
  }

  if (destination !== props.Segment.ArrivalInput.toUpperCase()) {
    setDestination(props.Segment.ArrivalInput.toUpperCase());
    setIsFind(true);
  }

  const getDates = () => {
    var newOrigin = "";
    var newDestination = "";

    if (origin.includes("[")) {
      newOrigin = origin.slice(1, 4);
    } else if (origin.length >= 3) {
      newOrigin = origin.slice(0, 3);
    } else {
      newOrigin = origin;
    }

    if (destination.includes("[")) {
      newDestination = destination.slice(1, 4);
    } else if (destination.length >= 3) {
      newDestination = destination.slice(0, 3);
    } else {
      newDestination = destination;
    }

    let payloadA = {
      origin: newOrigin,
      destination: newDestination,
      airline: airlineData[0]?.value,
      dataSource: "F",
    };

    let payloadB = {
      origin: newOrigin,
      destination: newDestination,
      airline: airlineData[0]?.value,
      dataSource: "A",
    };
    if (showSmartCalender !== "showSmartCalender=None" && flightDate) {
      AvailableFare.getPossibleDepartureDates(payloadA)
        .then((response: any) => {
          flightDates(response.result.possibleDepartureDates);
        })
        .catch((err: any) => {
          console.log(err);
        })
        .finally(() => { });
    }

    // if (allotment) {
    //     AvailableFare.getPossibleDepartureDates(payloadB).then((response: any) => {
    //         AllotmentDates(response.result.possibleDepartureDates);
    //     }).catch((err: any) => {
    //         console.log(err);
    //     }).finally(() => {
    //     });
    // }
  };

  useEffect(() => {
    getDates();
  }, [booked1, airlineData?.length]);

  const flightDates = (dates: any) => {
    let dateArr: any = [];
    dates.map((date: any) => {
      let year = date.slice(0, 4);
      let month = date.slice(4, 6);
      let day = date.slice(6, 8);
      new Date(year + "/" + month + "/" + day);
      dateArr.push(new Date(year + "/" + month + "/" + day));
      return dateArr;
    });
    setHdates(dateArr);
    setBookedDays1(dateArr);
    setIsFind(false);
  };

  const AllotmentDates = (dates: any) => {
    let dateArr: any = [];
    dates.map((date: any) => {
      let year = date.slice(0, 4);
      let month = date.slice(4, 6);
      let day = date.slice(6, 8);
      new Date(year + "/" + month + "/" + day);
      dateArr.push(new Date(year + "/" + month + "/" + day));
      return dateArr;
    });
    setBookedDays2(dateArr);
    setIsFind(false);
  };

  return (
    <div>
      {showSmartCalender !== "showSmartCalender=None" &&
        (showSmartCalender == "showSmartCalender=All" || isAdmin) &&
        (booked1 || booked2) &&
        airlineData.length ? (
        <div
          onFocus={isFind ? getDates : undefined}
          onClick={isFind ? getDates : undefined}
        >
          <DateInput
            Value={props?.Segment?.DateInput}
            OnDateChange={OnChangeNew}
            InputID={`segment${props.SegmentIndex}_date`}
            SelectNextInput={SelectNext}
            MaxDate={maxDate()}
            MinDate={minDate()}
            InputGroup={true}
            IsDob={false}
            booked1={booked1}
            booked2={booked2}
            bookedDays1={bookedDays1}
            bookedDays2={bookedDays2}
            bookedDays3={bookedDays3}
            bookedStyle1={bookedStyle1}
            bookedStyle2={bookedStyle2}
            bookedStyle3={bookedStyle3}
            btnRef={props.btnRef}
            reference={props.reference}
            tabIndex={props.tabIndex}
            PlaceHolder={t("LandingPage.SearchForm.Date")}
            setdepartureCollector={props.setdepartureCollector}
            autoFocus={true}
            setScrollingDown={props.setScrollingDown}
            isDateInputInOrder={props.isDateInputInOrder}
            segmentArr={props.segmentArr}
            scrollingDown={props.scrollingDown}
          />
        </div>
      ) : (
        <div>
          <DateInput
            Value={props?.Segment?.DateInput}
            OnDateChange={OnChangeNew}
            InputID={`segment${props.SegmentIndex}_date`}
            SelectNextInput={SelectNext}
            MaxDate={maxDate()}
            MinDate={minDate()}
            InputGroup={true}
            IsDob={false}
            booked1={booked1}
            booked2={booked2}
            bookedDays1={bookedDays1}
            bookedDays2={bookedDays2}
            bookedDays3={bookedDays3}
            bookedStyle1={bookedStyle1}
            bookedStyle2={bookedStyle2}
            bookedStyle3={bookedStyle3}
            btnRef={props.btnRef}
            reference={props.reference}
            tabIndex={props.tabIndex}
            PlaceHolder={t("LandingPage.SearchForm.Date")}
            setdepartureCollector={props.setdepartureCollector}
            autoFocus={true}
            setScrollingDown={props.setScrollingDown}
            isDateInputInOrder={props.isDateInputInOrder}
            segmentArr={props.segmentArr}
            scrollingDown={props.scrollingDown}
          />
        </div>
      )}
    </div>
  );
};

export default DepartureDate;
