import i18next from "i18next";
import i18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enTranslation from "./Lang/en.json";
import deTranslation from "./Lang/de.json";
import hiTranslation from "./Lang/hi.json";
import arTranslation from "./Lang/ar.json";
import chTranslation from "./Lang/ch.json";

const resources = {
  en: { translation: enTranslation },
  de: { translation: deTranslation },
  hi: { translation: hiTranslation },
  ar: { translation: arTranslation },
  cn: { translation: chTranslation },
};

i18next.use(i18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init(
    {
      resources,
      lng: "en",
      fallbackLng: "en",
      debug: true,
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: ["cookie", "localStorage", "navigator", "htmlTag"],
        caches: ["cookie"],
      },
      react: {
        useSuspense: false,
      },
    },
    (err, t) => {
      if (err) {
        console.error(err);
      } else {
        const dir = i18next.language === "ar" ? "rtl" : "ltr";
        document.documentElement.setAttribute("dir", dir);
      }
    }
  );

export default i18next;
