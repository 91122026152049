import { AssignablePassengerOption } from "components/AncillarySelection/AncillarySelection";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import Fare from "components/Fare/Fare";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { AvailableFareModel } from "WebApiClient";
import { useTranslation } from "react-i18next";
export interface FareBookingOptions {
  Fare: AvailableFareModel;
  SelectedConnections: number[];
  OnSelectConnection: (legIndex: number, connectionIndex: number) => void;
  Icon: JSX.Element;
  Passengers: AssignablePassengerOption[];
  AddedServices: ServiceAddObj[];
}

const FareBookingSelectedFlight: React.FC<FareBookingOptions> = (props) => {
  const { t } = useTranslation();
  const EnableCalcDebugger = useSelector(
    (state: State) => state.Session.FrontendSettings.enableCalculationDeugger
  );

  const sendPageHeight = () => {
    const height = document.body.scrollHeight;
    console.log("selected--------------", height);
    window.parent.postMessage(["setHeight", height + "px"], "*");
  };

  useEffect(() => {
    sendPageHeight();
  }, []);
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12">
          <div className="card mt-4 fareCardInnerContainer">
            <div className="card-header card-header-primary clearfix">
              <span className="float-start">
                {props.Icon} {t("FareBooking.Check&BookPage.SelectConnection")}{" "}
              </span>
            </div>
            <div className="card-body card-body-primary">
              <div className="fare__selector_container mt-1">
                <div className="fare__selector">
                  <React.Fragment>
                    <Fare
                      key={"fare" + props.Fare.fareIndex}
                      FareData={props.Fare}
                      ShowAllLegsActive={true}
                      ShowAllFlightTimesActive={true}
                      ShowAllCalculationActive={false}
                      IsFareBooking={true}
                      isActivate={false}
                      SelectedConnections={props.SelectedConnections}
                      SelectConnection={props.OnSelectConnection}
                      BookPassengers={props.Passengers}
                      ShowCalcDebugger={EnableCalcDebugger}
                      AddedServices={props.AddedServices}
                      showSelected={true}
                    />
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FareBookingSelectedFlight;
