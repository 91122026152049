import React, { useEffect } from "react";
import { IFareSearchInputState } from "components/FareSearch/types/FareSearchTypes";
import { InvariantDateDisplay } from "components/Shared/DateDisplay";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import {
  AgentFrontendFareSearchOptions,
  AvailableFaresPassengerInput,
  CustomerProfileData,
  CustomerProfilePassenger,
  CustomerProfilePassengerGroup,
  CustomerProfilePassengers,
} from "WebApiClient";
import { PassengersInput } from "./Passengers.AvailFaresPassengers.Modal";

interface PassengersProps {
  Input: IFareSearchInputState;
  IsModifySearch: boolean;
  IsAvailableFares: boolean;
  UpdateState: (state: IFareSearchInputState) => void;
  Configuration: AgentFrontendFareSearchOptions;
  setInput?: any;
}

const Passengers: React.FC<PassengersProps> = (props) => {
  const BodyClass: string = props.IsModifySearch ? "" : "";
  const ContainerClass: string = props.IsModifySearch ? "" : " h-100";
  const cabinclasses = props.Input.CabinClasses;
  const passengers = props.Input.AvilableFaresPassengers;
  const countPassengers =
    (passengers.adultPassengers?.passengers?.length ?? 0) +
    (passengers.childPassengers?.passengers?.length ?? 0) +
    (passengers.infantPassengers?.passengers?.length ?? 0);
  const customerProfileData: CustomerProfileData | undefined = useSelector(
    (state: State) => state.Session.FrontendSettings.customerProfileData?.data
  );

  function UpdateAvailFaresPassengers(
    passengers: AvailableFaresPassengerInput
  ) {
    const newState: IFareSearchInputState = {
      ...props.Input,
      AvilableFaresPassengers: passengers,
    };
    props.UpdateState(newState);
  }

  useEffect(() => {
    if (customerProfileData?.passengers !== undefined) {
      props.setInput((prev: any) => ({
        ...prev,
        AvilableFaresPassengers: {
          ...prev.AvilableFaresPassengers,
          adultPassengers: {
            ...prev.AvilableFaresPassengers.adultPassengers,
            passengers:
              customerProfileData?.passengers?.adults == null
                ? []
                : customerProfileData?.passengers?.adults?.passengers,
          },
          childPassengers: {
            ...prev.AvilableFaresPassengers.childPassengers,
            passengers:
              customerProfileData?.passengers?.children == null
                ? []
                : customerProfileData?.passengers?.children.passengers,
          },
          infantPassengers: {
            ...prev.AvilableFaresPassengers.infantPassengers,
            passengers:
              customerProfileData?.passengers?.infants == null
                ? []
                : customerProfileData?.passengers?.infants?.passengers,
          },
        },
      }));
    }
  }, []);


  return (
    <React.Fragment>
      <div className="">
        <div className={`${ContainerClass}`}>
          <div className={`${BodyClass}`}>
            <div className="d-flex flex-wrap">
              <div className="w-100">
                {customerProfileData?.passengers ? (
                  <CustomerProfilePassengersDisplay
                    passengers={customerProfileData.passengers}
                  />
                ) : (
                  <PassengersInput
                    Configuration={props.Configuration}
                    PassengersAmount={countPassengers}
                    Passengers={props.Input.AvilableFaresPassengers}
                    Segments={props.Input!.Segments}
                    UpdateAvailFaresPassengers={UpdateAvailFaresPassengers}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const CustomerProfilePassengersDisplay: React.FC<{
  passengers: CustomerProfilePassengers;
}> = (props) => {
  const { adults, children, infants } = props.passengers;

  return (
    <div className="row">
      {adults && (
        <div className="col-12 mb-2">
          <CustomerProfilePassengersGroupDisplay
            passengergroup={adults}
            label="Adults"
          />
        </div>
      )}
      {children && (
        <div className="col-12 mb-2">
          <CustomerProfilePassengersGroupDisplay
            passengergroup={children}
            label="Children"
          />
        </div>
      )}
      {infants && (
        <div className="col-12 mb-2">
          <CustomerProfilePassengersGroupDisplay
            passengergroup={infants}
            label="Infants"
          />
        </div>
      )}
    </div>
  );
};

const CustomerProfilePassengersGroupDisplay: React.FC<{
  passengergroup: CustomerProfilePassengerGroup;
  label: string;
}> = (props) => {
  const { passengerType, passengers } = props.passengergroup;

  return (
    <div className="row">
      <div className="col-12 mb-2">
        <h5>
          {props.label} ({passengerType})
        </h5>
      </div>
      <div className="col-12">
        <div className="row">
          {passengers.map((e, i) => (
            <div key={"passengers_" + i} className="col-12 mb-2">
              <CustomerProfilePassengersGroupPassengerDisplay
                passenger={e}
                index={i + 1}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const CustomerProfilePassengersGroupPassengerDisplay: React.FC<{
  passenger: CustomerProfilePassenger;
  index: number;
}> = (props) => {
  const {
    title,
    middleName,
    dateOfBirth,
    firstName,
    lastName,
    frequentFlyerCards,
  } = props.passenger;

  const airline = (airline: any) => {
    return airline.map((itr: any, index: any) => {
      return <span key={"air" + index}> {itr}</span>;
    });
  };

  return (
    <div className="row">
      <div className="col">
        #{props.index} {title} {firstName} {middleName} {lastName}{" "}
        {dateOfBirth && (
          <span>
            | DOB <InvariantDateDisplay date={dateOfBirth} />
          </span>
        )}
        {frequentFlyerCards?.length ? (
          <>
            {frequentFlyerCards?.map((itr: any, i: any) => {
              return (
                <div key={"ffc" + i}>
                  <br />
                  <br />
                  <h5>Frequent Flyer Card {i + 1}</h5>
                  <strong>
                    Program No.{itr?.programNumber}, Program Type:{" "}
                    {itr?.programType},{" "}
                  </strong>{" "}
                  <br />
                  <strong>
                    Holder Name: {itr?.holderTitle} {itr?.holderFirstName}{" "}
                    {itr?.holderLastName}
                  </strong>
                  <p>Airlines: {airline(itr?.carrierCodes)}</p>
                </div>
              );
            })}
          </>
        ) : (
          ""
        )}
      </div>
      <hr />
    </div>
  );
};

export default Passengers;
