import * as React from "react";
import {
  GetHandleProps,
  GetTrackProps,
  Handles,
  Rail,
  Slider,
  SliderItem,
  Tracks,
} from "react-compound-slider";

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
interface IHandleProps {
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
}

const Handle: React.FC<IHandleProps> = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
}) => (
  <div
    role="slider"
    aria-valuemin={min}
    aria-valuemax={max}
    aria-valuenow={value}
    style={{
      left: `${percent}%`,
      position: "absolute",
      marginLeft: "-11px",
      marginTop: "-6px",
      zIndex: 2,
      width: 24,
      height: 24,
      cursor: "pointer",
      borderRadius: "50%",
      boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#2196f3",
    }}
    {...getHandleProps(id)}
  />
);

// *******************************************************
// TRACK COMPONENT
// *******************************************************
interface ITrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
}

const Track: React.FC<ITrackProps> = ({ source, target, getTrackProps }) => (
  <div
    style={{
      position: "absolute",
      height: 5,
      zIndex: 1,
      backgroundColor: "#2196f3",
      borderRadius: 7,
      cursor: "pointer",
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`,
      top:"4px"
    }}
    {...getTrackProps()}
  />
);

// *******************************************************
// TICK COMPONENT
// *******************************************************

const sliderStyle: React.CSSProperties = {
  position: "relative",
  // width: "90%",
};

const railStyle: React.CSSProperties = {
  position: "absolute",
  width: "100%",
  height: 5,
  borderRadius: 7,
  cursor: "pointer",
  backgroundColor: "rgb(155,155,155)",
  top:"4px"
};

const domain: number[] = [0, 24];

interface TimeRangePickerProps {
  Update: (values: number[]) => void;
  values: number[];
  Label: string;
}

interface TimeRangePickerState {
  Dragging: boolean;
  values: number[];
}
export class TimeRangePicker extends React.Component<
  TimeRangePickerProps,
  TimeRangePickerState
> {
  constructor(props: TimeRangePickerProps) {
    super(props);
    this.state = { values: props.values, Dragging: false };
  }

  onChange = (newValues: readonly number[]) => {
    this.props.Update([...newValues]);
    this.setState({ values: [...newValues] });
  };
  onUpdate = (values: readonly number[]) => {
    this.setState({ values: [...values] });
  };

  GetDisplay() {
    const minVal = this.state.Dragging
      ? this.state.values[0]
      : this.props.values[0];
    const maxVal = this.state.Dragging
      ? this.state.values[1]
      : this.props.values[1];
    return (
      <span className="float-end ">
        {GetTimeDisplay_Basis100(minVal)} - {GetTimeDisplay_Basis100(maxVal)}
      </span>
    );
  }

  public render() {
    return (
      <div className="mb-5">
        <div className="ml-0 mb-2 d-flex justify-content-between text-dark">
          <span className="eclips"> {this.props.Label}</span> {this.GetDisplay()}
        </div>
        <Slider
          mode={1}
          step={0.25}
          domain={domain}
          rootStyle={sliderStyle}
          onChange={this.onChange}
          onSlideStart={() => this.setState({ Dragging: true })}
          onSlideEnd={() => this.setState({ Dragging: false })}
          values={this.state.Dragging ? this.state.values : this.props.values}
          onUpdate={this.onUpdate}
        >
          <Rail>
            {({ getRailProps }) => (
               <div className='filterSliderColor' style={railStyle} {...getRailProps()} />
            )}
          </Rail>
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <Handle
                    key={"handleI_" + handle.id}
                    handle={handle}
                    domain={domain}
                    getHandleProps={getHandleProps}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks left={false} right={false}>
            {({ tracks, getTrackProps }) => (
              <div className="slider-tracks">
                {tracks.map(({ id, source, target }) => (
                  <Track
                    key={"tsckI" + id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
        </Slider>
      </div>
    );
  }
}

function GetTimeDisplay_Basis100(num: number) {
  const hoursVal = Math.floor(num);
  const hours: string =
    hoursVal > 9 ? hoursVal.toString() : "0" + hoursVal.toString();
  const minutestemp = (((num % 1) * 100) / 100) * 60;
  const minutes = minutestemp === 0 ? "00" : minutestemp.toString();
  return `${hours}:${minutes}h`;
}