import { ICabinClassState } from "components/FareSearch/types/FareSearchTypes";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { useTranslation } from "react-i18next";
import { b2cSettingsText } from "WebApiClient";

interface CabinClassesProps {
  CabinClasses: ICabinClassState;
  UpdateCabinClasses: (cabinClasses: ICabinClassState) => void;
  ClassName?: string;
  disabled: boolean;
  segmentIndex: any;
  reduxCabinClass?: ICabinClassState;
}

const CabinClassInput: React.FC<CabinClassesProps> = (props) => {
  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=On\nshowSmartCalender=None/n[]/nfalse/nfalse\nPreselectAllCabins"
        ? 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#232323"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None'
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#232323"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None'
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );
  const sessionInfo = useSelector((state: State) => state.Session);
  const defaultCabinClasses: b2cSettingsText = (() => {
    try {
      const parsedSettings = JSON.parse(
        sessionInfo?.FrontendSettings?.b2cSettingsText || "{}"
      );

      if (
        !parsedSettings.CabinClass ||
        Object.keys(parsedSettings.CabinClass).length === 0
      ) {
        parsedSettings.CabinClass = {
          Economy: {
            Checked: true,
            preSelect: true,
          },
          Premium: {
            Checked: true,
            preSelect: true,    
          },
          Business: {
            Checked: true,
            preSelect: true,
          },
          First: {
            Checked: true,
            preSelect: true,
          },
        };
      }

      return parsedSettings;
    } catch {
      return {
        HotelTab: "None",
        AirPortSearchType: "All",
        ShowLanguageDecimalTabB2C: "English",
        ShowExtraAncillariesTabB2C: "On",
        ShowDateInput: "Left",
        ShowDefaultFilter: "Off",
        CabinClass: {
          Economy: {
            Checked: true,
            preSelect: true,
          },
          Premium: {
            Checked: true,
            preSelect: true,
          },
          Business: {
            Checked: true,
            preSelect: true,
          },
          First: {
            Checked: true,
            preSelect: true,
          },
        },
      };
    }
  })();

  const { t } = useTranslation();
  useEffect(() => {
    let newCbin = { ...props.CabinClasses };
    let newState: ICabinClassState = { ...newCbin };
    newState.Economy =
      props?.reduxCabinClass?.Economy != null
        ? props?.reduxCabinClass.Economy
        : defaultCabinClasses?.CabinClass?.Economy.preSelect;
    newState.PremiumEconomy =
      props?.reduxCabinClass?.PremiumEconomy != null
        ? props?.reduxCabinClass.PremiumEconomy
        : defaultCabinClasses?.CabinClass?.Premium.preSelect;
    newState.Business =
      props?.reduxCabinClass?.Business != null
        ? props?.reduxCabinClass?.Business
        : defaultCabinClasses?.CabinClass?.Business.preSelect;
    newState.First =
      props?.reduxCabinClass?.First != null
        ? props?.reduxCabinClass?.First
        : defaultCabinClasses?.CabinClass?.First.preSelect;
    props.UpdateCabinClasses(newState);
  }, []);

  const cabinClasses = { ...props.CabinClasses };

  function OnToggleEconomy() {
    if (!props.disabled) {
      let newState: ICabinClassState = { ...cabinClasses };
      newState.Economy = !newState.Economy;
      props.UpdateCabinClasses(newState);
    }
  }

  function OnTogglePremiumEconomy() {
    if (!props.disabled) {
      let newState: ICabinClassState = { ...cabinClasses };
      newState.PremiumEconomy = !newState.PremiumEconomy;
      props.UpdateCabinClasses(newState);
    }
  }

  function OnToggleBusiness() {
    if (!props.disabled) {
      let newState: ICabinClassState = { ...cabinClasses };
      newState.Business = !newState.Business;
      props.UpdateCabinClasses(newState);
    }
  }

  function OnToggleFirst() {
    if (!props.disabled) {
      let newState: ICabinClassState = { ...cabinClasses };
      newState.First = !newState.First;
      props.UpdateCabinClasses(newState);
    }
  }

  const returnTabId = (segId: any, InputTab: any): any => {
    switch (segId) {
      case 1:
        if (InputTab == 1) return 5;
        if (InputTab == 2) return 6;
        if (InputTab == 3) return 7;
        if (InputTab == 4) return 8;
        break;
      case 2:
        if (InputTab == 1) return 8;
        if (InputTab == 2) return 9;
        if (InputTab == 3) return 10;
        if (InputTab == 4) return 11;
        break;
      case 3:
        if (InputTab == 1) return 11;
        if (InputTab == 2) return 12;
        if (InputTab == 3) return 13;
        if (InputTab == 4) return 14;
        break;
      default:
        return;
    }
  };

  return (
    <React.Fragment>
      <div className={`d-flex ${props.ClassName} `}>
        <div
          className=" d-flex flex-grow-0 px-0 innerCabinContainer"
          role="group"
        >
          {defaultCabinClasses?.CabinClass?.Economy.Checked && (
            <div
              style={{
                background: "white",
                borderRadius: "6px",
                margin: "1px",
              }}
            >
              <input
                disabled={props.disabled}
                type="checkbox"
                onChange={OnToggleEconomy}
                checked={cabinClasses?.Economy}
                className="btn-check"
                tabIndex={returnTabId(props.segmentIndex, 1)}
              />
              <label
                onClick={(e) => {
                  e.stopPropagation();
                  OnToggleEconomy();
                }}
                className="btn btn-outline-primary"
              >
                {t("LandingPage.SearchForm.Economy")}
              </label>
            </div>
          )}
          {defaultCabinClasses?.CabinClass?.Premium.Checked && (
            <div
              style={{
                background: "white",
                borderRadius: "6px",
                margin: "1px",
              }}
            >
              <input
                disabled={props.disabled}
                type="checkbox"
                onChange={OnTogglePremiumEconomy}
                checked={cabinClasses.PremiumEconomy}
                className="btn-check"
                tabIndex={returnTabId(props.segmentIndex, 2)}
              />
              <label
                onClick={(e) => {
                  e.stopPropagation();
                  OnTogglePremiumEconomy();
                }}
                className="btn btn-outline-primary"
              >
                {t("LandingPage.SearchForm.Premium")}
              </label>
            </div>
          )}

          {defaultCabinClasses?.CabinClass?.Business.Checked && (
            <div
              style={{
                background: "white",
                borderRadius: "6px",
                margin: "1px",
              }}
            >
              <input
                disabled={props.disabled}
                type="checkbox"
                onChange={OnToggleBusiness}
                checked={cabinClasses.Business}
                className="btn-check"
                tabIndex={returnTabId(props.segmentIndex, 3)}
              />
              <label
                onClick={(e) => {
                  e.stopPropagation();
                  OnToggleBusiness();
                }}
                className="btn btn-outline-primary"
              >
                {t("LandingPage.SearchForm.Business")}
              </label>
            </div>
          )}

          {defaultCabinClasses?.CabinClass?.First.Checked && (
            <div
              style={{
                background: "white",
                borderRadius: "6px",
                margin: "1px",
              }}
            >
              <input
                disabled={props.disabled}
                type="checkbox"
                onChange={OnToggleFirst}
                checked={cabinClasses.First}
                className="btn-check"
                tabIndex={returnTabId(props.segmentIndex, 4)}
              />
              <label
                onClick={(e) => {
                  e.stopPropagation();
                  OnToggleFirst();
                }}
                className="btn btn-outline-primary"
              >
                {t("LandingPage.SearchForm.First")}
              </label>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CabinClassInput;
