import { faChair, faQuestion, faStar, faStarExclamation, faStickyNote, faText, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PriceDisplay, SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { useLocalizedPrice } from "hooks/useLocalization";
import React from "react";
import { CalculationAdditionals, CalculationRow_Complete, CalculationRow_DisplaySettings } from "WebApiClient";
import { ServiceAddObj } from "./AncillariesModal";

interface FareCalculationRowProps {
    DisplaySettings: CalculationRow_DisplaySettings
    Data: CalculationRow_Complete
    Currency: string;
}
export const FareCalculationRow: React.FC<FareCalculationRowProps> = props => {


    const values = props.Data.rawValues!;
    const displaySettings = props.DisplaySettings

    const agencyServiceFee = useLocalizedPrice(values.gross_AgencyServiceFee_PerPerson, props.Currency, undefined);
    const consoServiceFee = useLocalizedPrice(values.net_ConsolidatorServiceFee_PerPerson, props.Currency, undefined);

    return (<tr>

        <td>
            <span>{`${props.Data.passengerAmount.toString()} ${props.Data.passengerType}(s)`}</span>
        </td>
        <td className="text-right" title={`${displaySettings.show_Gross_AgencyServiceFee ? "Agency Service fee: " + agencyServiceFee + " p.P" : ""}`}>
            <PriceDisplay price={values.gross_PerPerson} currency={props.Currency} />
        </td>

        {displaySettings.showNet &&
            <td className="text-right d-none d-md-table-cell" title={`${displaySettings.show_Net_ConsolidatorServiceFee ? "Conso Service fee: " + consoServiceFee + " p.P" : ""}`}>
                <PriceDisplay price={values.net_PerPerson} currency={props.Currency} />
            </td>
        }
        {displaySettings.showNetNet &&
            <td className="text-right d-none d-md-table-cell"> <PriceDisplay price={values.netNet_PerPerson} currency={props.Currency} /></td>
        }
        {displaySettings.showQSurcharge &&
            <td className="text-right d-none d-md-table-cell"><PriceDisplay price={values.qSurcharge_PerPerson} currency={props.Currency} /></td>
        }

        <td className="text-right"><PriceDisplay price={values.taxes_PerPerson} currency={props.Currency} /></td>
        <td className="text-right"><PriceDisplay price={values.total} currency={props.Currency} /></td>
    </tr>
    );
};

export const AncillaryCalcRow: React.FC<{ DisplaySettings: CalculationRow_DisplaySettings, Service: ServiceAddObj; Currency: string; }> = props => {
    const displaySettings = props.DisplaySettings;
    return (<tr>

        <td>
            <span><FontAwesomeIcon icon={faStar} /> {props.Service.Service.bookingCode}</span>
        </td>
        <td className="text-right" >

        </td>

        {displaySettings.showNet &&
            <td className="text-right d-none d-md-table-cell">

            </td>
        }
        {displaySettings.showNetNet &&
            <td className="text-right d-none d-md-table-cell">

            </td>
        }
        {displaySettings.showQSurcharge &&
            <td className="text-right d-none d-md-table-cell"></td>
        }

        <td className="text-right"></td>
        <td className="text-right"><SharedPriceDisplay price={props.Service.Price.equivalentPrice} /></td>
    </tr>
    );
};


export const FareCalculationAdditionalsRow: React.FC<{
    DisplaySettings: CalculationRow_DisplaySettings
    Data: CalculationAdditionals
    Currency: string;
}> = props => {



    const displaySettings = props.DisplaySettings
    const { eqvivalentTotalPrice, text, type, code, amount } = props.Data;

    function GetIcon(): IconDefinition {
        let result: IconDefinition = faQuestion;
        switch (type) {
            case "SSR":
                result = faStarExclamation;
                break;
            case "OSI":
                result = faStickyNote;
                break;
            case "SK":
                break;
            case "SEAT":
                result = faChair;
                break;
            case "REMARK":
                result = faText;
                break;
            case "SERVICE":
                result = faStar;
                break;
        }
        return result;
    }

    return (<tr>

        <td title={text.length > 0 ? text : undefined}>
            <span><FontAwesomeIcon icon={GetIcon()} /> {amount}x {code.length > 0 ? code : text} <span className="fw-bold">({type})</span></span>
        </td>
        <td className="text-right">

        </td>

        {displaySettings.showNet &&
            <td className="text-right d-none d-md-table-cell">

            </td>
        }
        {displaySettings.showNetNet &&
            <td className="text-right d-none d-md-table-cell"></td>
        }
        {displaySettings.showQSurcharge &&
            <td className="text-right d-none d-md-table-cell"></td>
        }

        <td className="text-right"></td>
        <td className="text-right"><PriceDisplay price={eqvivalentTotalPrice} currency={props.Currency} /></td>
    </tr>
    );
};


