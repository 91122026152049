import { faElementor } from "@fortawesome/free-brands-svg-icons";
import {
  faBan,
  faChair,
  faDownload,
  faExclamation,
  faInfoSquare,
  faMailForward,
  faRotateExclamation,
  faStar,
  faTicket,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useBookingClient } from "hooks/useHttpClient";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { AgentPlusBookingManagerFeature, BookingItemModel } from "WebApiClient";
// import xmlJs from "xml-js";
// import BookingMangerManageElementsModal from './BookingManager.ManageElementsModal';
// import BookingManagerManageNotificationModal from './BookingManager.ManageNotificationsModal';
// import BookingMangerManageServicesModal from './BookingManager.ManageServicesModal';
// import BookingMangerTableRowConfirmCancelModal from "./BookingManager.Table.Row.Actions.ConfirmCancel";
// import BookingMangerTableRowConfirmCancelTicketModal from './BookingManager.Table.Row.Actions.ConfirmCancelTicket';
// import BookingMangerTableRowReshopModal from './BookingManager.Table.Row.Actions.ReshopModal';
// import ConfirmTicketModal from "./ConfirmTicket";
// import BookingMangerSeatmapModal from './SeatmapModal';
// import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { useDispatch, useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
// import { BookingDetailsApiResponse } from "WebApiClient";

interface BookingMangerTableRowActionsProps {
  Booking: BookingItemModel;
  OnUpdateBooking: (data: BookingItemModel) => void;
  NotificationsModalOpen: boolean;
  ToggleNotificationModal: () => void;
  ToggleDetailsModal: () => void;
  OnSync: () => void;
  Label?: string;
  IsFetching: boolean;
  seatmapModalOpen?: boolean;
  setMapToggle?: () => void;
  serviceModalOpen?: boolean;
  serviceToggle?: () => void;
  cancelModalOpen?: boolean;
  cancelTicketModal?: () => void;
  cancelModal?: () => void;
  ElementsModal?: () => void;
  ticketModal?: () => void;
  ConfirmEmailSend?: () => void;
  EmailSendModalOpen?: boolean;
}
const BookingMangerTableRowActions: React.FC<
  BookingMangerTableRowActionsProps
> = (props) => {
  // const [cancelModalOpen, setcancelModalOpen] = useState(false);
  // const [cancelTicketModalOpen, setcancelTicketModalOpen] = useState(false);
  // const [ticketModalOpen, setTicketModalOpen] = useState(false);
  // const [reshopModalOpen, setReshopModalOpen] = useState(false);
  // const [modifyModalOpen, setModifyModalOpen] = useState(false);
  // const [seatmapModalOpen, setSeatmapModalOpen] = useState(false);
  // const [serviceModalOpen, setServiceModalOpen] = useState(false);
  // const [elementsModalOpen, setElementsModalOpen] = useState(false);
  const [ActionsDropDownOpen, setActionsDropDownOpen] = useState(false);
  // const dispatch = useDispatch();
  const data = props.Booking.latestVersion!;
  const actions = data.allowedActions;
  // const bookingIdentifier = data.bookingIdentifier!;
  // const cartid = bookingIdentifier.cartId!;
  // const bookingid = bookingIdentifier.bookingId!;
  const bookingClient = useBookingClient();
  const [isConfirmEmail, setisConfirmEmail] = useState<boolean>(false);

  var otherSettings: string = useSelector((state: State) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#232323"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None';
    }
    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });

  // function ToggleCancelModal() {
  //   setcancelModalOpen(!cancelModalOpen);
  // }

  // function ToggleCancelTicketModal() {
  //   setcancelTicketModalOpen(!cancelTicketModalOpen);
  // }
  // function ToggleTicketModal() {
  //   setTicketModalOpen(!ticketModalOpen);
  // }
  // function ToggleReshopModal() {
  //   setReshopModalOpen(!reshopModalOpen);
  // }
  // function ToggleModifyModal() {
  //   setModifyModalOpen(!modifyModalOpen);
  // }
  // function ToggleServiceModal() {
  //   setServiceModalOpen(!serviceModalOpen);
  // }
  // function ToggleElementsModal() {
  //   setElementsModalOpen(!elementsModalOpen);
  // }

  function DownloadBackOffice() {
    let downloadingrowState = { ...props.Booking };
    // downloadingrowState.IsDownloadingBackofficeFile = true;
    // props.UpdateBookingState(downloadingrowState);
    bookingClient
      .downloadBackOfficeFile(data.bookingIdentifier!)
      .then((e) => {
        const fileName = `${data.generalInfo!.filekey}_bo.xml`;
        const a = document.createElement("a");
        a.href = URL.createObjectURL(e!.data);
        a.download = fileName;
        a.click();
        a.remove();
        toast.success("Backoffice file saved to disk.");
      })
      .catch(() => {
        toast.error("An error occured while downloading Backoffice file.");
      })
      .finally(() => {
        let downloadedRowState = { ...props.Booking };
        // downloadedRowState.IsDownloadingBackofficeFile = false;
        // props.UpdateBookingState(downloadedRowState);
      });
  }

  // useEffect(() => {
  //   const xmlData = "<xml><name>John Doe</name><age>30</age></xml>";
  //   const jsonData: any = xmlJs.xml2json(xmlData);
  //   const items = jsonData["xml"]?.name + " - " + jsonData["xml"]?.age;
  //   console.log(JSON.parse(jsonData), items);
  // }, []);

  let settingArr = otherSettings.split("\n");
  const fieldContainer =
    settingArr[7] != undefined ? settingArr[7].split("#") : [];

  useEffect(() => {
    try {
      // const isBranch = JSON.parse( settingArr[7] !== undefined ? settingArr[7] : "");
      const isConfirm = fieldContainer.includes("CONFIRMATIONMAIL");
      setisConfirmEmail(isConfirm);
    } catch (error) {
      console.error("Error parsing JSON:", error); 
      setisConfirmEmail(false); // Handle parsing error
    }
  }, [fieldContainer]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={ActionsDropDownOpen}
        toggle={() => setActionsDropDownOpen(!ActionsDropDownOpen)}
      >
        <DropdownToggle
          className="text-white"
          color="primary"
          size="sm"
          caret
          disabled={props.IsFetching}
        >
          {props.Label && <span>{props.Label}</span>}
        </DropdownToggle>
        <DropdownMenu>
          {actions.includes(AgentPlusBookingManagerFeature.RefreshPnr) &&
          props.OnSync ? (
            <DropdownItem onClick={props.OnSync}>
              <FontAwesomeIcon icon={faRotateExclamation} /> Refresh PNR
            </DropdownItem>
          ) : null}
          <DropdownItem color="primary" onClick={props.ToggleDetailsModal}>
            <FontAwesomeIcon icon={faInfoSquare} /> Booking details
          </DropdownItem>
          {actions.includes(AgentPlusBookingManagerFeature.CancelBooking) &&
            props.Booking.latestVersion.generalInfo.bookingStatus !== 16 && (
              <DropdownItem onClick={props.cancelModal}>
                <FontAwesomeIcon icon={faBan} style={{ color: "red" }} /> Cancel
                booking
              </DropdownItem>
            )}
          {actions.includes(AgentPlusBookingManagerFeature.VoidCancelTicket) &&
            props.Booking.latestVersion.generalInfo.bookingStatus !== 16 && (
              <DropdownItem onClick={props.cancelTicketModal}>
                <FontAwesomeIcon icon={faBan} style={{ color: "red" }} />{" "}
                Void/Cancel ticket
              </DropdownItem>
            )}
          {actions.includes(AgentPlusBookingManagerFeature.ManageServices) &&
            props.Booking.latestVersion.generalInfo.bookingStatus !== 16 && (
              <DropdownItem onClick={props.serviceToggle}>
                <FontAwesomeIcon icon={faStar} /> Manage services
              </DropdownItem>
            )}
          {actions.includes(AgentPlusBookingManagerFeature.ManageElements) &&
            props.Booking.latestVersion.generalInfo.bookingStatus !== 16 && (
              <DropdownItem onClick={props.ElementsModal}>
                <FontAwesomeIcon icon={faElementor} />
                &nbsp;&nbsp;Manage elements
              </DropdownItem>
            )}
          {actions.includes(AgentPlusBookingManagerFeature.Seatmap) &&
            props.Booking.latestVersion.generalInfo.bookingStatus !== 16 && (
              <DropdownItem onClick={props.setMapToggle}>
                <FontAwesomeIcon icon={faChair} />
                &nbsp;&nbsp;Seatmap
              </DropdownItem>
            )}
          <DropdownItem onClick={props.ToggleNotificationModal}>
            &nbsp;
            <FontAwesomeIcon icon={faExclamation} />
            &nbsp;&nbsp;Notifications
          </DropdownItem>
          {/* {CanReshop &&
            <DropdownItem onClick={() => setReshopModalOpen(true)}><FontAwesomeIcon icon={faBan} /> Reshop</DropdownItem>
          } */}
          {actions.includes(AgentPlusBookingManagerFeature.TicketIssue) &&
            props.Booking.latestVersion.generalInfo.bookingStatus !== 16 && (
              <DropdownItem onClick={props.ticketModal}>
                <FontAwesomeIcon icon={faTicket} /> Ticket/EMD
              </DropdownItem>
            )}
          {/* {CanModify &&
            <DropdownItem onClick={() => setModifyModalOpen(true)}><FontAwesomeIcon icon={faTicket} /> Modify</DropdownItem>
          } */}
          {actions.includes(
            AgentPlusBookingManagerFeature.DownloadBackOfficeFile
          ) && (
            <DropdownItem onClick={DownloadBackOffice}>
              <FontAwesomeIcon icon={faDownload} /> Download BO file
            </DropdownItem>
          )}
          {isConfirmEmail && (
            <DropdownItem onClick={props.ConfirmEmailSend}>
              <FontAwesomeIcon icon={faMailForward} /> Send confirmation
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
      {/* <BookingMangerTableRowConfirmCancelModal Booking={props.Booking} OnUpdate={props.OnUpdateBooking} Toggle={ToggleCancelModal} IsOpen={cancelModalOpen} />
      <BookingMangerTableRowConfirmCancelTicketModal Booking={props.Booking} OnUpdate={props.OnUpdateBooking} Toggle={ToggleCancelTicketModal} IsOpen={cancelTicketModalOpen} />
      <ConfirmTicketModal Booking={props.Booking} OnUpdate={props.OnUpdateBooking} Toggle={ToggleTicketModal} IsOpen={ticketModalOpen} />
      <BookingMangerTableRowReshopModal Booking={props.Booking} Toggle={ToggleReshopModal} IsOpen={reshopModalOpen} />
      <BookingMangerManageServicesModal Booking={props.Booking} Toggle={ToggleServiceModal} IsOpen={serviceModalOpen} /> */}
      {/* <BookingMangerSeatmapModal autoRefresh={props.autoRefresh} Booking={props.Booking} Toggle={props.setMapToggle} IsOpen={props.seatmapModalOpen} /> */}
      {/* <BookingMangerManageElementsModal Booking={props.Booking} Toggle={ToggleElementsModal} IsOpen={elementsModalOpen} /> */}
      {/* <BookingManagerManageNotificationModal Booking={props.Booking} OnUpdate={props.OnUpdateBooking} Toggle={props.ToggleNotificationModal} IsOpen={props.NotificationsModalOpen} /> */}
    </React.Fragment>
  );
};

export default BookingMangerTableRowActions;