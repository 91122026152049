import { AgentApiURL } from "Constants";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Modal, ModalBody, Progress } from "reactstrap";
import { State } from "rootExports/rootReducer";
import { WaitScreenProgress } from "../types/SessionTypes";

const CustomerWaitScreen: React.FC<AllProps> = (props) => {

  
  //termsCondition redux
  const sessionInfoB2c = useSelector((state: State) => state.Session);
  const showBackModalColor = JSON.parse(
      sessionInfoB2c?.FrontendSettings?.b2cSettingsText
    );

  return (
    <React.Fragment>
      {props.WaitScreenID && (
        <Modal
          centered={true}
          isOpen={props.ShowWaitScreen}
          zIndex={99999999}
          size={"lg"}
          backdropClassName={showBackModalColor?.ShowBackgroundModalColor=="On"? "loaderColorHalf":"loaderColorZero"}
        >
          <ModalBody>
            <div className="row">
              <div className="col-12">
                <img
                  src={`${AgentApiURL}/publicservices/images/waitscreen/${props.WaitScreenID}`}
                  className="img-fluid"
                  alt="CustomerWaitscreen"
                  width="100%"
                />
              </div>
              {/* <div className="col-12 mb-2">
                <Progress animated value={props.Info.Percentage}>
                  {props.Info.Label}
                </Progress>
              </div> */}
            </div>
          </ModalBody>
          {/* <ModalFooter></ModalFooter> */}
        </Modal>
      )}
      {/* <div className="modal show mx-auto" id="waitScreenModal" role="dialog" style={style}>
                        <div className="modal-dialog modal-xl modal-dialog-centered justify-content-center" role="document">
                            <div className="modal-content">
                            </div>
                        </div>
                    </div> */}
    </React.Fragment>
  );
};

type AllProps = PropsFromState & DispatchProps;

interface PropsFromState {
  WaitScreenID: string | undefined;
  ShowWaitScreen: boolean;
  Info: WaitScreenProgress;
}

const mapStateToProps = (state: State) => ({
  WaitScreenID: state.Session.FrontendSettings
    ? state.Session.FrontendSettings.waitScreenID
    : undefined,
  ShowWaitScreen: state.Session.ShowCustomerWaitScreen,
  Info: state.Session.WaitscreenProgress,
});

interface DispatchProps {}
const mapDispatchToProps = () => ({});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerWaitScreen);
