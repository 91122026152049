import { createSlice } from '@reduxjs/toolkit'

const initialState = { value: {} }
export const segmentSlice = createSlice({
    name: 'segment',
    initialState,
    reducers: {
        segment_Reducer(state, action) {
            console.log("action---------------",action.payload);
            
            state.value = action.payload
        },
    },
})

export const { segment_Reducer } = segmentSlice.actions