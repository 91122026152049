import React, { useEffect, useState } from "react";
import pay from "Styles/images/pay.png";
import paypal from "Styles/images/paypal.png";
import discover from "Styles/images/discover.png";
import mc from "Styles/images/mc.png";
import dc from "Styles/images/dc.png";
import amc from "Styles/images/amc.png";
import visa from "Styles/images/visa.png";
import tripAdviser from "Styles/images/tripAdviser.png";
import google from "Styles/images/google.png";
import FareSearch from "components/FareSearch/component/FareSearch";
import {
  faBed,
  faChevronRight,
  faPlaneUp,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Modal, ModalBody } from "reactstrap";
import SignIn from "components/AuthLoginReg/SignIn";
import SignUp from "components/AuthLoginReg/SignUp";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { AgentApiURL } from "Constants";

interface LandingPageInterface {
  OnReset: any;
  IsModifySearch: any;
  OnSearchSubmitted: any;
  LastSubmitted: any;
  submitResult: any;
  GoToResults: any;
  setHeightHolder?: any;
  setActiveTabHF?: any;
  activeTabHF?: any;
  language: string;
  isB2cAirline: boolean;
}

const LandingPage: React.FC<LandingPageInterface> = (props) => {
  const toggleTab = (tab: any) => {
    if (props.activeTabHF !== tab) {
      props.setActiveTabHF(tab);
    }
  };

  //modal Login Reg
  const [modal, setModal] = useState(false);
  const [LoginToReg, setLoginToReg] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleLR = () => setLoginToReg(!LoginToReg);
  const sessionInfo = useSelector((state: State) => state.Session);
  const { t } = useTranslation();

  // form hide open when scroll
  const [scrollingDown, setScrollingDown] = useState(true);

  const hotelCheck = (() => {
    try {
      return JSON.parse(
        sessionInfo?.FrontendSettings?.b2cSettingsText ||
        '{"HotelTab":"All","AirPortSearchType":"All"}'
      );
    } catch {
      return { HotelTab: "All", AirPortSearchType: "All" };
    }
  })();

  const sendPageHeight = () => {
    const height = document.body.scrollHeight;
    window.parent.postMessage(["setHeight", height + "px"], "*");
  };

  useEffect(() => {
    sendPageHeight();
  }, []);


  //termsCondition redux
  const sessionInfoB2c = useSelector((state: State) => state.Session);
  const showBackModalColor = JSON.parse(
    sessionInfoB2c?.FrontendSettings?.b2cSettingsText
  );

  return (
    <div className=" ">
      <div className="bgImg p-3 text-white text-center">
        <div className="d-flex justify-content-between pb-3">
          <div className="d-flex">
            <img
              src={
                sessionInfo.isLoggedIn &&
                  sessionInfo.FrontendSettings.logoID !== ""
                  ? `${AgentApiURL}/publicservices/images/logo/${sessionInfo.FrontendSettings.logoID}`
                  : "images/logo.png"
              }
              height="35"
              width="78"
              className="img-fluid mb-2 companyLogo"
              alt="company logo"
            ></img>
          </div>
        </div>
        <div className="d-flex flex-column-reverse flex-lg-column">
          <h1 className="mx-3 my-5">{t("LandingPage.header.head")}</h1>
          <div className="px-lg-5 searchCard">
            <div>
              <Nav tabs className="searchCard">
                <div className="d-flex cardTop">
                  <NavItem>
                    <NavLink
                      className={props.activeTabHF === "1" ? "active" : ""}
                      onClick={() => toggleTab("1")}
                    >
                      <FontAwesomeIcon icon={faPlaneUp} />{" "}
                      {t("LandingPage.SearchForm.Airline")}
                    </NavLink>
                  </NavItem>
                  {hotelCheck.HotelTab !== "None" && (
                    <NavItem>
                      <NavLink
                        className={props.activeTabHF === "2" ? "active" : ""}
                        onClick={() => toggleTab("2")}
                      >
                        <FontAwesomeIcon icon={faBed} />{" "}
                        {t("LandingPage.SearchForm.Hotels")}
                      </NavLink>
                    </NavItem>
                  )}
                </div>
              </Nav>
              <TabContent activeTab={props.activeTabHF}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12" md="12" lg="12">
                      <FareSearch
                        OnReset={props.OnReset}
                        IsModifySearch={props.IsModifySearch}
                        OnSearchSubmitted={props.OnSearchSubmitted}
                        LastSubmitted={props.LastSubmitted}
                        submitResult={props.submitResult}
                        GoToResults={props.GoToResults}
                        boxRef={props.setHeightHolder}
                        language={props.language}
                        isNext={true}
                        setScrollingDown={setScrollingDown}
                        isB2cAirline={props.isB2cAirline}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="6">
                      <Card body>
                        <CardTitle>Special Title Treatment</CardTitle>
                        <CardText>
                          With supporting text below as a natural lead-in to
                          additional content.
                        </CardText>
                        <Button>Go somewhere</Button>
                      </Card>
                    </Col>
                    <Col sm="6">
                      <Card body>
                        <CardTitle>Special Title Treatment</CardTitle>
                        <CardText>
                          With supporting text below as a natural lead-in to
                          additional content.
                        </CardText>
                        <Button>Go somewhere</Button>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="d-flex justify-content-between py-3 ft flex-wrap">
          <div className="py-2">
            <strong className="ps-3">
              {" "}
              {t("LandingPage.Footer.ContactUs")}
            </strong>
            <ul className="">
              <li> {t("LandingPage.Footer.CustomerSupport")} </li>
              <li>{t("LandingPage.Footer.ServiceGuarantee")}</li>
              <li>{t("LandingPage.Footer.WebsiteFeedback")}</li>
            </ul>
          </div>
          <div className="py-2">
            <strong className="ps-3">{t("LandingPage.Footer.About")}</strong>
            <ul className="">
              <li>{t("LandingPage.Footer.AboutHitchHiker")}</li>
              <li>{t("LandingPage.Footer.News")}</li>
              <li>{t("LandingPage.Footer.Careers")}</li>
              <li>{t("LandingPage.Footer.TermsConditions")}</li>
              <li>{t("LandingPage.Footer.PrivacyStatement")}</li>
              <li>{t("LandingPage.Footer.PersonalInfo")}</li>
              <li>{t("LandingPage.Footer.Group")}</li>
            </ul>
          </div>
          <div className="py-2">
            <strong className="ps-3">{t("LandingPage.Footer.Services")}</strong>
            <ul className="">
              <li>{t("LandingPage.Footer.Investor")}</li>
              <li>{t("LandingPage.Footer.Rewards")}</li>
              <li>{t("LandingPage.Footer.Affiliate")}</li>
              <li>{t("LandingPage.Footer.ListMyHotel")}</li>
              <li>{t("LandingPage.Footer.AllHotels")}</li>
              <li>{t("LandingPage.Footer.BecomeSupplier")}</li>
              <li>{t("LandingPage.Footer.Security")}</li>
            </ul>
          </div>
          <div className="py-2">
            <strong>{t("LandingPage.Footer.PaymentMethods")}</strong>
            <div className="d-flex flex-wrap gap-2 w-75">
              <img src={visa} alt="visa" />
              <img src={mc} alt="masterCard" />
              <img src={amc} alt="american card" />
              <img src={paypal} alt="paypal" />
              <img src={dc} alt="dc" />
              <img src={discover} alt="discover" />
              <img src={pay} alt="pay" />
            </div>
            <div className="mt-3">
              <strong className="pt-3">
                {t("LandingPage.Footer.OurPartners")}
              </strong>
              <div className="d-flex gap-2 mt-2">
                <img src={tripAdviser} alt="" />
                <img src={google} alt="" />
              </div>
            </div>
          </div>
        </div>
      </footer>


      <div style={{ position: "fixed", top: "20vh", right: "0px" }}>
        <div className="btn-group btn-group-vertical">
          {props.GoToResults && (
            <button
              className="btn btn-primary btn-lg mb-2 py-4 PrevBtn"
              onClick={props.GoToResults}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          )}
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} backdropClassName={showBackModalColor?.ShowBackgroundModalColor == "On" ? "loaderColorHalf" : "loaderColorZero"}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          {LoginToReg ? (
            <SignUp setLoginToReg={toggleLR} />
          ) : (
            <SignIn setLoginToReg={toggleLR} />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LandingPage;
