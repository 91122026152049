import { createSlice } from '@reduxjs/toolkit'

const initialState = { value: false }
export const loginBoolSlice = createSlice({
    name: 'loginBool',
    initialState,
    reducers: {
        loginBool_Reducer(state, action) {
            state.value = action.payload
        },
    },
})

export const { loginBool_Reducer } = loginBoolSlice.actions