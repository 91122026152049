import { AssignablePassengerOption } from "components/AncillarySelection/AncillarySelection";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { LocalizationConverter } from "components/Shared/Helpers/LocalizationConverter";
import { currencyFormatter } from "components/Shared/Helpers/CurrencyConverter";
import _ from "lodash";


interface FarePriceOverviewProps {
    FarePrice: number;
    Currency: string;
    Services: ServiceAddObj[];
    AssignablePassengers: AssignablePassengerOption[];
    TotalTax?: number;
    FullSegments?: any;
    cardViewPayment: boolean;
    fareData?: any
}
export function GetTotalFarePrice(
    FarePrice: number,
    Services: ServiceAddObj[]
): number {
    const allServices = Services.flat();
    const prices = allServices
        .filter((e) => e !== undefined && e.Price.equivalentPrice !== undefined)
        .map((e) => e.Price.equivalentPrice!.value);
    const total = prices.reduce(function (a, b) {
        return a + b;
    }, FarePrice);
    return total;
}
export function GetTotalFarePrice1(
    FarePrice: number,
    Services: ServiceAddObj[],
    TotalPassengers: number
): number {
    const allServices = Services.flat();
    const prices = allServices
        .filter((e) => e !== undefined && e.Price.equivalentPrice !== undefined)
        .map((e) => e.Price.equivalentPrice!.value);
    const total = prices.reduce(function (a, b) {
        return a + b;
    }, FarePrice);
    return total / TotalPassengers;
}

export const FarePriceOverviewCard: React.FC<FarePriceOverviewProps> = ({
    Currency,
    FarePrice,
    Services,
    AssignablePassengers,
    TotalTax,
    FullSegments,
    cardViewPayment,
    fareData
}) => {
    const { t, i18n } = useTranslation();
    const totalPriceDisplay = (
        <SharedPriceDisplay
            price={{
                currencyCode: Currency,
                isIATA: false,
                isIATASpecified: false,
                value: GetTotalFarePrice(FarePrice, Services),
                valueSpecified: true,
                tax: TotalTax,
            }}
        />
    );

    const combined = _.map(AssignablePassengers, passenger => {
        const services = _.filter(Services, service => service.PassengerIndex === passenger.Index);
        const rawValues = _.find(fareData, o => o.passengerType === passenger.PassengerType)?.rawValues;
        const extraServiceTotal = _.sumBy(services, service => service?.Price?.equivalentPrice?.value!);

        return {
            ...passenger,
            Services: services,
            grossPerPerson: rawValues?.gross_PerPerson,
            taxesPerPerson: rawValues?.taxes_PerPerson,
            extraServiceTotalPerPerson: extraServiceTotal
        };
    });
    const totalGrossPerPerson = _.sumBy(combined, 'grossPerPerson');
    const totalTaxesPerPerson = _.sumBy(combined, 'taxesPerPerson');
    const totalExtraService = _.sumBy(Services, service => service?.Price?.equivalentPrice?.value!);

    return (
        <div className="mt-4">
            <div className="row">
                {
                    cardViewPayment ?
                        <>
                            {
                                combined?.map((singlePerson: any, personIndex: number) => (

                                    <div className="col-md-12">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <span style={{ fontWeight: '700' }}>{singlePerson?.Index + 1}. {singlePerson?.Label}</span>
                                            </div>
                                            <div>
                                                <span style={{ fontWeight: '700' }}>{LocalizationConverter(singlePerson?.grossPerPerson + singlePerson?.taxesPerPerson + singlePerson?.extraServiceTotalPerPerson, i18n.language)}{currencyFormatter(Currency)}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div style={{ paddingLeft: '8px' }}>
                                                <span>{t(
                                                    "FareBooking.Check&BookPage.FarePriceTbl.Base"
                                                )}</span>
                                            </div>
                                            <div>
                                                <span>{LocalizationConverter(singlePerson?.grossPerPerson, i18n.language)}{currencyFormatter(Currency)}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div style={{ paddingLeft: '8px' }}>
                                                <span>{t(
                                                    "FareBooking.Check&BookPage.FarePriceTbl.Tax"
                                                )}</span>
                                            </div>
                                            <div>
                                                <span>{LocalizationConverter(singlePerson?.taxesPerPerson, i18n.language)}{currencyFormatter(Currency)}</span>
                                            </div>
                                        </div>
                                        {
                                            singlePerson?.Services?.map((singleService: any, indexService: number) => (
                                                <>
                                                    <div className="d-flex justify-content-between">
                                                        <div style={{ paddingLeft: '8px' }}>
                                                            <span>{singleService?.Service?.text}</span>
                                                        </div>
                                                        <div>
                                                            <span>{LocalizationConverter(singleService?.Price?.equivalentPrice?.value, i18n.language)}{currencyFormatter(Currency)}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            ))
                                        }
                                        <br />
                                    </div>

                                ))
                            }
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between" style={{ fontWeight: '700' }}>
                                    <div style={{ fontSize: '20px' }}>
                                        <span>{t(
                                            "FareBooking.Check&BookPage.FarePriceTbl.Total"
                                        )}</span>
                                    </div>
                                    <div style={{ fontSize: '20px' }}>
                                        <span>{LocalizationConverter(GetTotalFarePrice(FarePrice, Services), i18n.language)}{currencyFormatter(Currency)}</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div style={{ paddingLeft: '8px' }}>
                                        <span>{t(
                                            "FareBooking.Check&BookPage.FarePriceTbl.Price"
                                        )}</span>
                                    </div>
                                    <div>
                                        <span>{LocalizationConverter(totalGrossPerPerson, i18n.language)}{currencyFormatter(Currency)}</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div style={{ paddingLeft: '8px' }}>
                                        <span>{t(
                                            "FareBooking.Check&BookPage.FarePriceTbl.Taxes"
                                        )}</span>
                                    </div>
                                    <div>
                                        <span>{LocalizationConverter(totalTaxesPerPerson, i18n.language)}{currencyFormatter(Currency)}</span>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div style={{ paddingLeft: '8px' }}>
                                        <span>{t(
                                            "FareBooking.Check&BookPage.FarePriceTbl.ExtraServices"
                                        )}</span>
                                    </div>
                                    <div>
                                        <span>{LocalizationConverter(totalExtraService, i18n.language)}{currencyFormatter(Currency)}</span>
                                    </div>
                                </div>
                                <br />
                            </div>
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h3>{t(
                                            "FareBooking.Check&BookPage.FarePriceTbl.TotalAmount"
                                        )}</h3>
                                    </div>
                                    <div>
                                        <span style={{ fontWeight: '700', fontSize: '24px' }}>{LocalizationConverter(GetTotalFarePrice(FarePrice, Services), i18n.language)}{currencyFormatter(Currency)}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className="col-12 mb-2">
                            <div className="card">
                                <table className="table table-sm table-bordered table-responsive-xl">
                                    <thead className="thead-light">
                                        <tr>
                                            <th></th>
                                            <th>
                                                {t(
                                                    "FareBooking.Check&BookPage.FarePriceTbl.Price"
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    "FareBooking.Check&BookPage.FarePriceTbl.TotalTax"
                                                )}
                                            </th>
                                            <th>
                                                {t(
                                                    "FareBooking.Check&BookPage.FarePriceTbl.Passenger"
                                                )}
                                            </th>
                                            <th>
                                                {" "}
                                                {t(
                                                    "FareBooking.Check&BookPage.FarePriceTbl.Segments"
                                                )}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {t(
                                                    "FareBooking.Check&BookPage.FarePriceTbl.BaseFare"
                                                )}
                                            </td>
                                            <td>
                                                <SharedPriceDisplay
                                                    price={{
                                                        currencyCode: Currency,
                                                        isIATA: false,
                                                        isIATASpecified: false,
                                                        value: FarePrice,
                                                        valueSpecified: true,
                                                        tax: TotalTax,
                                                    }}
                                                />
                                            </td>
                                            <td>{LocalizationConverter(TotalTax, i18n.language)}{currencyFormatter(Currency)}</td>
                                            <td>{
                                                AssignablePassengers?.map((BookP: any, BookIndex: any) => (
                                                    <span>
                                                        {BookP.Label}{(AssignablePassengers.length != BookIndex + 1) && <span style={{ marginRight: '3px' }}>,</span>}
                                                    </span>
                                                ))
                                            }</td>
                                            <td>
                                                <div style={{ display: 'flex' }}>
                                                    {
                                                        FullSegments?.map((singleSeg: any, sindex: any) => (
                                                            <span
                                                                style={{ marginRight: FullSegments?.length != sindex + 1 ? '3px' : '0px' }}
                                                                key={"baseSegment" + sindex}
                                                            >
                                                                {singleSeg?.departure}-{singleSeg?.arrival} {(FullSegments?.length != sindex + 1 && FullSegments?.length != 1) && <span>{" || "}</span>}
                                                                <br />
                                                            </span>
                                                        ))
                                                    }
                                                </div>
                                            </td>
                                        </tr>

                                        {Services.map((e, serviceIndex) => (
                                            <tr key={"Services1" + serviceIndex}>
                                                {e && (
                                                    <Fragment>
                                                        <td>{e.Service.text}</td>
                                                        <td>
                                                            <SharedPriceDisplay
                                                                price={
                                                                    e.Price
                                                                        .equivalentPrice
                                                                }
                                                            />
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            {
                                                                AssignablePassengers[
                                                                    e.PassengerIndex
                                                                ].Label
                                                            }
                                                        </td>
                                                        <td>
                                                            <div style={{ display: 'flex' }}>
                                                                {e.Segments.map(
                                                                    (s, sIndex) => (
                                                                        <span
                                                                            style={{ marginRight: e.Segments?.length != sIndex + 1 ? '3px' : '0px' }}
                                                                            key={
                                                                                "Services2" +
                                                                                sIndex
                                                                            }
                                                                        >
                                                                            {s?.departure}-{s?.arrival} {(e.Segments?.length != sIndex + 1) && <span>{" || "}</span>}

                                                                            <br />
                                                                        </span>
                                                                    )
                                                                )}
                                                            </div>
                                                        </td>
                                                    </Fragment>
                                                )}
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className="font-weight-bold">
                                                {t(
                                                    "FareBooking.Check&BookPage.FarePriceTbl.Total"
                                                )}
                                            </td>
                                            <td>{totalPriceDisplay}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                }
            </div>
        </div>
    );
};
