interface referenceInterFace {
  tabIndex: number;
  reference: any;
}
export default function nextElementFocus(props: referenceInterFace) {

  switch (props.tabIndex) {
    case 1:
      props?.reference?.InputDate?.current.focus();
      break;
    case 2:
      props?.reference?.airlineRef?.current.focus();
      break;
    case 3:
      props?.reference?.airlineRef?.current.focus();
      break;
    default:
      return;
  }
}
