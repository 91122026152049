import { faBarcode, faEllipsisH, faMinusCircle, faPlane, faPlaneArrival, faPlaneDeparture, faPlusCircle, faSearch, faText, faUser, faUserFriends } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { BookingMangerPassengersTableCell } from "components/Shared/Helpers/BookingManagement/BookingPassengerHelpers";
import { BookingMangerSegmentsTableCell } from "components/Shared/Helpers/BookingManagement/BookingSegmentsHelpers";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { AddElementsToBookingRequestModel, Airline, BaseApiResponse, BookingIdentifier, BookingItemElementsManagementModel, BookingItemElementsSSROption, PnrResponsePassengerModel, PnrResponseSegmentModel, PnrResponseSSRModel, RemoveElementsFromBookingRequestModel, SSRRequestDataModel } from "WebApiClient";

// import { BookingManagerSingleBookingState } from "../types/BookingManagerTypes";



export const BookingMangerManageSSRElements: React.FC<{ model: BookingItemElementsManagementModel, OnRefresh: () => void, BookingIdentifier: BookingIdentifier }> = props => {
    const { bookingSSRElements, passengers, ssrOptions, segments, airlineOptions } = props.model;
    const [ssrAddQueue, setSSRAddQueue] = useState<SSRRequestDataModel[]>([])
    const [deleteQueue, setDeleteQueue] = useState<number[]>([])
    const defaultSSRAdd: SSRRequestDataModel = { carrierCode: "", code: "", freeText: "", passengerSelection: [], segments: [] }
    const [ssr, setSSRAdd] = useState<SSRRequestDataModel>(defaultSSRAdd);
    const bookClient = useBookingClient();
    const [fetching, setFetching] = useState(false);
    const [response, setResponse] = useState<BaseApiResponse | undefined>(undefined);
    const [errorOccured, setErrorOccured] = useState(false);
    const dispatch = useDispatch();
    function OnRefresh() {
        setSSRAddQueue([]);
        setResponse(undefined);
        props.OnRefresh();
    }
    function OnSubmitAdd() {
        const request: AddElementsToBookingRequestModel = { bookingIndentifier: props.BookingIdentifier, osi: undefined, remarks: undefined, services: undefined, ssrServices: ssrAddQueue, passengerAPIS: undefined }
        setFetching(true);
        bookClient.addElements(request)
            .then(response => {
                setResponse(response);
                dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
                if (response.responseMetaData.errorOccured) {
                    setErrorOccured(true)
                }
                else {
                    setErrorOccured(false);
                }

            })
            .catch(() => {
                setErrorOccured(true);
            })
            .finally(() => {
                setFetching(false);
            })


    }
    function ToggleDeleteIndex(index: number) {
        let arrC = [...deleteQueue];
        const iOf = arrC.indexOf(index);
        if (iOf === -1) {
            arrC.push(index);
        }
        else {
            arrC.splice(iOf, 1);
        }
        setDeleteQueue(arrC);
    }
    function OnConfirmDelete() {
        const deletessrs: SSRRequestDataModel[] = [];
        deleteQueue.forEach(index => {
            const allElemAt = allSSR[index];
            const addElem: SSRRequestDataModel = { carrierCode: "", code: allElemAt.code, freeText: allElemAt.text, passengerSelection: allElemAt.passengerNumbers, segments: allElemAt.segmentNumbers }
            deletessrs.push(addElem)
        })
        const request: RemoveElementsFromBookingRequestModel = { bookingIndentifier: props.BookingIdentifier, osi: undefined, remarks: undefined, services: undefined, ssrServices: deletessrs }
        setFetching(true);
        bookClient.removeElements(request)
            .then(response => {
                setResponse(response);
                dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
                if (response.responseMetaData.errorOccured) {
                    setErrorOccured(true)
                }
                else {
                    setErrorOccured(false);
                }

            })
            .catch(() => {
                setErrorOccured(true);
            })
            .finally(() => {
                setFetching(false);
            })
    }

    function OnUpdateQueueItem(data: SSRRequestDataModel | undefined, index: number) {
        if (data) {
            let arrC = [...ssrAddQueue];
            arrC[index] = data;
            setSSRAddQueue(arrC);
        }
        else {
            let arrC = [...ssrAddQueue];
            arrC.splice(index, 1);
            setSSRAddQueue(arrC);
        }
    }
    function OnAddQueueItem() {
        let arrC = [...ssrAddQueue];
        arrC.push(ssr);
        setSSRAddQueue(arrC);
        setSSRAdd(defaultSSRAdd);
    }

    function SubmitButtonDisabled(): boolean {
        let result = false;
        if (InvalidElements() || ssrAddQueue.length === 0 || fetching) {
            result = true;
        }
        return result;
    }
    function InvalidElements(): boolean {
        let result = false;
        if (ssrAddQueue.filter(e => e.code.length === 0).length > 0) {
            result = true;
        }
        return result;
    }

    function GetAllSSR(): PnrResponseSSRModel[] {
        let result: PnrResponseSSRModel[] = [];
        result = result.concat(bookingSSRElements);
        passengers.forEach(p => {
            result = result.concat(p.specialServiceRequests)
        })
        return result;
    }
    const allSSR = GetAllSSR();

    return <div className="row">
        {fetching &&
            <div className="col-12">
                <WaitingCard />
            </div>
        }

        {!fetching &&
            <Fragment>
                {errorOccured &&
                    <div className="col-12 mb-2">
                        <ErrorDisplay data={response?.responseMetaData} />
                    </div>
                }
                {(response && !errorOccured) ?
                    <div className="col-12 mb-2">
                        <Alert color="success">
                            <h4 className="alert-heading">SSR elements successfully modified.</h4>
                            <button className="btn btn-primary" onClick={OnRefresh}>Refresh</button>
                        </Alert>
                    </div> :
                    <Fragment>
                        <div className="col-12 mb-2">
                            <div className="card card-primary">
                                <div className="card-header card-header-primary">Add SSR elements</div>
                                <div className="card-body card-body-secondary">
                                    <div className="row">

                                        <SSRAddComponent options={ssrOptions} airlineOptions={airlineOptions} allPassengers={passengers} allSegments={segments} value={ssr} OnUpdate={(update) => { setSSRAdd(update) }} OnAdd={OnAddQueueItem} />
                                        {ssrAddQueue.length > 0 &&
                                            <Fragment>

                                                <div className="col-12">
                                                    <div className="content-divider-dark"></div>
                                                </div>
                                                <div className="col-12">
                                                    <p>{ssrAddQueue.length} SSR will be added to booking</p>
                                                </div>
                                                {ssrAddQueue.map((item, index) =>
                                                    <SSRAddComponent key={"ssrA_"+index} airlineOptions={airlineOptions} options={ssrOptions} allPassengers={passengers} allSegments={segments} value={item} OnUpdate={(update) => { OnUpdateQueueItem(update, index) }} OnRemove={() => { OnUpdateQueueItem(undefined, index) }} />

                                                )}
                                            </Fragment>

                                        }


                                        <div className="col-12 mb-2">
                                            <div className="d-grid">

                                                <button disabled={SubmitButtonDisabled()} onClick={OnSubmitAdd} className="btn btn-success text-white">Confirm and add SSR elements to booking</button>
                                            </div>
                                            {InvalidElements() &&
                                                <Alert color="danger">
                                                    Invalid elements. Please correct your input to continue.
                                                </Alert>

                                            }
                                        </div>
                                    </div>
                                </div>



                            </div>



                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header card-header-primary">
                                    SSR Elements in Booking
                                </div>
                                <div className="card-body card-body-secondary">
                                    <div className="row">
                                        <div className="col-12">
                                            <button disabled={deleteQueue.length === 0} className="btn btn-primary" onClick={OnConfirmDelete}>Remove selection from booking</button>
                                        </div>
                                        <div className="col-12">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Code</th>
                                                        <th>Text</th>
                                                        <th>Chargeable</th>
                                                        <th>Status</th>
                                                        <th>Segments</th>
                                                        <th>Passengers</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {allSSR.map((e, i) =>
                                                        <tr key={i}>
                                                            <td><input type="checkbox" checked={deleteQueue.includes(i)} onChange={() => { ToggleDeleteIndex(i) }}></input></td>
                                                            <td>{e.code}</td>
                                                            <td>{e.text}</td>
                                                            <td>{e.chargeable ? "Yes" : "No"}</td>
                                                            <td>{e.status}</td>
                                                            <BookingMangerSegmentsTableCell numbers={e.segmentNumbers} segments={segments} />
                                                            <BookingMangerPassengersTableCell numbers={e.passengerNumbers} pnrPassengers={passengers} />

                                                        </tr>
                                                    )}


                                                </tbody>

                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </Fragment>
                }

            </Fragment>
        }

    </div>

}

const SSRAddComponent: React.FC<{ value: SSRRequestDataModel, allPassengers: PnrResponsePassengerModel[], airlineOptions: Airline[], allSegments: PnrResponseSegmentModel[], options: BookingItemElementsSSROption[], OnUpdate: (update: SSRRequestDataModel) => void, OnRemove?: () => void, OnAdd?: () => void }> = props => {
    const { OnUpdate, options, value, OnAdd, OnRemove, airlineOptions } = props;
    const { carrierCode, code, freeText, passengerSelection, segments } = value;
    const [showSegmentsSelection, setShowSegmentsSelection] = useState(false);
    const [showPassengerSelection, setShowPassengerSelection] = useState(false);
    const [showExtended, setShowExtended] = useState(false);

    function TogglePassengerSelection(id: number) {
        const indexOf = passengerSelection.indexOf(id);
        let arrC = [...passengerSelection];
        if (indexOf === -1) {
            arrC.push(id);
        }
        else {
            arrC.splice(indexOf, 1);
        }
        OnUpdate({ ...value, passengerSelection: arrC });
    }
    function ToggleSegmentSelection(id: number) {
        const indexOf = segments.indexOf(id);
        let arrC = [...segments];
        if (indexOf === -1) {
            arrC.push(id);
        }
        else {
            arrC.splice(indexOf, 1);
        }
        OnUpdate({ ...value, segments: arrC });
    }

    return <div className="col-12 mb-2 p-1">
        <div className="card">
            <div className="card-body card-body-primary">
                <div className="row">
                    <div className="col-5 mb-2">
                        <div className="input-group custmCss">
                            <span className="input-group-text " title="Code">
                                <FontAwesomeIcon icon={faBarcode} />
                            </span>
                            <select value={code} onChange={e => { OnUpdate({ ...value, code: e.target.value }) }} className={`form-select ${code.length > 0 ? "is-valid" : "is-invalid"} custmCss`}>
                                <option value={""}>Select code...</option>
                                {options.map((o, i) =>
                                    <option key={i} value={o.code}>{o.code} - {o.label}</option>
                                )}
                            </select>
                        </div>


                    </div>
                    <div className="col-5 mb-2">
                        <div className="input-group custmCss">
                            <span className="input-group-text" title="Free text">
                                <FontAwesomeIcon icon={faText} />
                            </span>
                            <input value={freeText} type="text" className="form-control custmCss" onChange={(e) => { OnUpdate({ ...value, freeText: e.target.value }) }}></input>
                        </div>
                    </div>


                    <div className="col-2 mb-2">
                        {OnAdd &&
                            <div className="d-grid">

                                <button onClick={OnAdd} className="btn btn-primary"><FontAwesomeIcon icon={faPlusCircle} /> add</button>
                            </div>
                        }
                        {OnRemove &&
                            <div className="d-grid">

                                <button onClick={OnRemove} className="btn btn-danger"><FontAwesomeIcon icon={faMinusCircle} /> remove</button>
                            </div>
                        }

                    </div>
                    <div className="col-12 mb-2">
                        <div className="d-grid">

                            <button className="btn btn-sm btn-secondary" onClick={() => { setShowExtended(!showExtended) }}><FontAwesomeIcon icon={faSearch} /> {showExtended ? "Hide" : "Show"} detailed options for element</button>
                        </div>
                    </div>
                    {showExtended &&
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body row">

                                    <div className="col-4 mb-2">
                                        <div className="input-group custmCss">
                                            <span className="input-group-text" title="Airline">
                                                <FontAwesomeIcon icon={faPlane} />
                                            </span>


                                            <select value={carrierCode} onChange={e => { OnUpdate({ ...value, carrierCode: e.target.value }) }} className={`form-select custmCss`}>
                                                <option value={""}>Select airline...</option>
                                                {airlineOptions.map((o, i) =>
                                                    <option key={i} value={o.code}>{o.code} - {o.hint}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-4 mb-2">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-group custmCss">
                                                    <span className="input-group-text" title="Passengers">
                                                        <FontAwesomeIcon icon={faUserFriends} />
                                                    </span>
                                                    <input readOnly value={passengerSelection.length === 0 ? `All passengers (default)` : `${passengerSelection.length} / ${props.allPassengers.length}`} type="text" className="form-control custmCss" ></input>
                                                </div>
                                            </div>

                                            <div className="col-12">
                                                <p>Select only...</p>
                                                {props.allPassengers.map((pOption, poI) =>
                                                    <div key={poI} onClick={() => { TogglePassengerSelection(pOption.passengerNumber) }} className="form-check ">
                                                        <input onChange={() => { }} checked={passengerSelection.includes(pOption.passengerNumber)} type="checkbox" className="form-check-input custmCss" />
                                                        <label className="form-check-label custmCss" ><FontAwesomeIcon icon={faUser} /> {pOption.name}, {pOption.firstName}</label>
                                                    </div>

                                                )}
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-4 mb-2">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-group custmCss">
                                                    <span className="input-group-text" title="Segments">
                                                        <FontAwesomeIcon icon={faPlaneDeparture} />
                                                    </span>


                                                    <input readOnly value={segments.length === 0 ? `All segments (default)` : `${segments.length} / ${props.allSegments.length}`} type="text" className="form-control custmCss"></input>
                                                </div>
                                            </div>


                                            <div className="col-12">
                                                <p>Select only...</p>
                                                {props.allSegments.map((pOption, poI) =>
                                                    <div key={poI} onClick={() => { ToggleSegmentSelection(pOption.segmentNumber) }} className="form-check">
                                                        <input checked={segments.includes(pOption.segmentNumber)} onChange={() => { }} type="checkbox" className="form-check-input" />
                                                        <label className="form-check-label" ><FontAwesomeIcon icon={faPlaneDeparture} /> {pOption.departure.iata} <FontAwesomeIcon icon={faEllipsisH} /> <FontAwesomeIcon icon={faPlaneArrival} /> {pOption.arrival.iata}</label>
                                                    </div>

                                                )}
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    }


                </div>
            </div>
        </div>

    </div>
}


