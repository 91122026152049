import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faInfo,
} from "@fortawesome/pro-light-svg-icons";
import { faAngleDoubleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import React from "react";
import { Button, Collapse } from "reactstrap";
import { FareBookingInputPassengerModel } from "WebApiClient";
import { BookPassengerValidatioOtherTabResult } from "./FareBooking.Validate.Passenger.Other";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
export interface IBookingPassengerGeneralTabProps {
  Passenger: FareBookingInputPassengerModel;
  OnUpdatePassenger: (data: FareBookingInputPassengerModel) => void;
  Index: number;
  Valid: BookPassengerValidatioOtherTabResult;
  IsOpen: boolean;
  Toggle: () => void;
  Next: () => void;
}

const BookingPassengerOtherTab: React.FC<IBookingPassengerGeneralTabProps> = (
  props
) => {
  const { t } = useTranslation();
  const passenger = props.Passenger;
  const IsValid = props.Valid;
  function ToggleAssistanceRequired() {
    let data = { ...passenger };
    data.assistanceRequired = !data.assistanceRequired;
    props.OnUpdatePassenger(data);
  }

  // function OnPasswordUpdate(value: string) {
  //     let data = { ...passenger };
  //     data.password = value;
  //     props.OnUpdatePassenger(props.Index, data);
  // }

  // function GetPasswordClassName(): string {
  //     let result = "";
  //     if (props.Valid.PasswordRequired) {
  //         result = props.Valid.Password ? "is-valid" : "is-invalid";
  //     }
  //     return result;
  // }
  //showAssistance redux 
  const sessionInfo = useSelector((state: any) => state.Session);
  const showAssistance = JSON.parse(
    sessionInfo?.FrontendSettings?.b2cSettingsText
  );
  return (
    <>
      {
        showAssistance?.showAssistance == "On" &&
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body pAssistance">
                <div className="row align-items-center mb-2">
                  <label className="col-12 col-md-4 col-lg-3 col-xl-2 align-middle widthAssistanceR">
                    {t("FareBooking.PassengerPage.Assistance")}
                  </label>
                  <div className="col-12 col-md-6 col-lg-3 align-middle">
                    <div
                      style={{
                        background: "white",
                        borderRadius: "6px",
                        margin: "1px",
                      }}
                    >
                      <input
                        // disabled={props.disabled}
                        type="checkbox"
                        onChange={() => ToggleAssistanceRequired()} // Corrected function name
                        checked={passenger.assistanceRequired}
                        className="btn-check"
                      />
                      <label
                        onClick={() => ToggleAssistanceRequired()} // Corrected function name
                        className="btn btn-outline-primary"
                      >
                        {t("FareBooking.PassengerPage.Required")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>

  );
};

export default BookingPassengerOtherTab;
