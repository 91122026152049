import { faMedium } from "@fortawesome/free-brands-svg-icons";
import {
    faCheckCircle,
    faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import {
    faChalkboardTeacher,
    faCircle,
    faCoffee,
    faCookieBite,
    faCouch,
    faExchange,
    faFastForward,
    faGavel,
    faGlassMartini,
    faInfoCircle,
    faMapMarkerQuestion,
    faMapSigns,
    faMoneyCheckEditAlt,
    faPaw,
    faPlug,
    faRandom,
    faRedo,
    faShoppingBag,
    faSuitcase,
    faTachometerFastest,
    faTicketAlt,
    faTv,
    faUndoAlt,
    faUserCheck,
    faUtensils,
    faWifi,
    IconDefinition,
    faCar,
} from "@fortawesome/pro-solid-svg-icons";
import _ from "lodash";
import { IncludedServiceMainType, IncludedServiceSubType } from "WebApiClient";
import { faLuggageCart } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useRef, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import Tooltip from "@mui/material/Tooltip";
import { FareHeaderView } from "WebApiClient";
import IncServices from "components/Shared/IncServices";
import { SabreIconsEmoji } from "./IncludedServiceIcons/SabreIcons";
interface IncludedServicesProps {
    readonly Data: FareHeaderView;
    readonly Fareindex: number;
    readonly BaggageType: string;
    FareTypeInfo: any;
    mealCode: string;
}

const FareHeaderIncludedServices: React.FC<IncludedServicesProps> = (props) => {
    // console.log(props.Data.cabinClassCombined);
    const combinedString = props?.Data?.cabinClassCombined?.join("");


    function checkBaggageTypeLength(baggageType: string) {
        // Extracting the numeric part using a regular expression
        var numericPart = baggageType.match(/\d+/);
        // Check if numericPart exists and return its length, otherwise return 0
        return numericPart ? numericPart[0].length : 0;
    }

    function extractNumbers(str: string) {
        // Use a regular expression to match all digits in the string
        const result = str.match(/\d+/);
        // If there's a match, return it, otherwise return an empty string
        return result ? result[0] : '';
    }

    //later check 
    function checkSabreOther() {
        if (
            props.FareTypeInfo.originalFareType == "GDS" ||
            props.FareTypeInfo.salesPreferenceDisplayName == "GDS"
        ) {
            return true;
        }
        if (
            props.FareTypeInfo.originalFareType == "Sabre" ||
            props.FareTypeInfo.salesPreferenceDisplayName == "Sabre"
        ) {
            return true;
        }
        if (
            props.FareTypeInfo.originalFareType == "1S" ||
            props.FareTypeInfo.salesPreferenceDisplayName == "1S"
        ) {
            return true;
        }
        return true;
    }

    function checkBaggageType() {
        if (props.BaggageType == "01P") {
            return (
                <span style={{ fontSize: '22px' }}>
                    <Tooltip
                        title={
                            <>
                                <span>{props.BaggageType}</span>
                            </>
                        }
                    >
                        <span>
                            <span className={checkBaggageTypeLength(props?.BaggageType) == 1 ? "baggageCodeInner1" : "baggageCodeInner"}>

                            </span>
                            {/* <span>{SabreIconsEmoji["01P"].icon}</span> */}
                            <img style={{margin:"2px 2px 0px"}} src={SabreIconsEmoji["01P"].icon} width="15px" height="18px" alt="hitchhiker" />
                        </span>
                    </Tooltip>
                </span>
            );
        }
        if (props.BaggageType == "02P") {
            return (
                <span style={{ fontSize: '22px' }}>
                    <Tooltip
                        title={
                            <>
                                <span>{props.BaggageType}</span>
                            </>
                        }
                    >
                        <span>
                            <span className={checkBaggageTypeLength(props?.BaggageType) == 1 ? "baggageCodeInner1" : "baggageCodeInner"}>
                                {
                                    extractNumbers(props.BaggageType)
                                }
                            </span>
                            {/* <span>{SabreIconsEmoji["01P"].icon}</span> */}
                            <img style={{margin:"2px 2px 0px"}} src={SabreIconsEmoji["01P"].icon} width="15px" height="18px" alt="hitchhiker" />
                        </span>
                    </Tooltip>
                </span>
            );
        }
        if (props.BaggageType == "25K") {
            return (
                <span style={{ fontSize: '22px' }}>
                    <Tooltip
                        title={
                            <>
                                <span>{props.BaggageType}</span>
                            </>
                        }
                    >
                        <span>
                            <span className={checkBaggageTypeLength(props?.BaggageType) == 1 ? "baggageCodeInner1" : "baggageCodeInner"}>
                                {extractNumbers(props.BaggageType)}
                            </span>
                            {/* <span>{SabreIconsEmoji["01P"].icon}</span> */}
                            <img style={{margin:"2px 2px 0px"}} src={SabreIconsEmoji["01P"].icon} width="15px" height="18px" alt="hitchhiker" />
                        </span>
                    </Tooltip>
                </span>
            );
        }
        if (props.BaggageType == "") {
            return (
                <span style={{ fontSize: '22px' }}>
                    <Tooltip
                        title={
                            <>
                                <span>This fare has free baggage allowance for at least one connection</span>
                            </>
                        }
                    >
                        <span>
                            {/* <span>{SabreIconsEmoji["01P"].icon}</span> */}
                            <img style={{marginTop:"2px"}} src={SabreIconsEmoji["01P"].icon} width="15px" height="18px" alt="hitchhiker" />
                        </span>
                    </Tooltip>
                </span>
            );
        }
        return (
            <span style={{ fontSize: '22px' }}>
                <Tooltip
                    title={
                        <>
                            <span>{props.BaggageType}</span>
                        </>
                    }
                >
                    <span>
                        <span className={checkBaggageTypeLength(props?.BaggageType) == 1 ? "baggageCodeInner1" : "baggageCodeInner"}>
                            {props.BaggageType == "1P" ? "" :
                                props.BaggageType == "0K" ? "" :
                                    props.BaggageType == "01P" ? "" :
                                        props.BaggageType == "1PC" ? "" :
                                            extractNumbers(props.BaggageType)
                            }
                        </span>
                        {/* <span>{SabreIconsEmoji["01P"].icon}</span> */}
                        <img style={{margin:"2px 2px 0px"}} src={SabreIconsEmoji["01P"].icon} width="15px" height="18px" alt="hitchhiker" />
                    </span>
                </Tooltip>
            </span>
        );
    }

    //mealcode make array
    const mealCodeArray = _.split(props.mealCode, ",");
    // console.log("mealCodeArray  - ", mealCodeArray);

    return (
        <React.Fragment>
            <div
                className="ancillaries align-bottom min-w-100px d-flex align-items-end"
                style={{
                    width: "118px",
                    position: 'relative',
                    flexWrap:'wrap'
                }}
            >
                    <>
                        {props.BaggageType === "" ? (
                            props.Data.fareHasFreeBaggage == false ? (
                                <FreeBaggeIndicator HasFreeBaggage={props.Data.fareHasFreeBaggage} />
                            ) : (
                                checkBaggageType()
                            )
                        ) : (
                            checkBaggageType()
                        )}
                    </>
                {/* {props.Data.includedServices!.length > 0 && <span> | </span>} */}
                {props.Data.includedServices!.map((mainGroup, mainIndex) => (
                    <IncServices
                        key={"IncServices" + mainIndex}
                        data={mainGroup}
                        toShow={false}
                    />
                ))}
                <>
                    {mealCodeArray?.map((iconD: any, iMeal: number) => (
                        <span key={iMeal}>
                            <Tooltip
                                title={
                                    <>
                                        <span>
                                            {
                                                SabreIconsEmoji[
                                                    iconD
                                                ]?.hint
                                            }
                                        </span>
                                    </>
                                }
                            >
                                <span>
                                    {SabreIconsEmoji[iconD]?.icon}
                                </span>
                            </Tooltip>
                        </span>
                    ))}
                </>
            </div>
        </React.Fragment>
    );

    function GetIcon(data: any): any {
        let iconClass: any = faInfoCircle;
        switch (data.subType) {
            case IncludedServiceSubType.FreeBaggageAllowance:
                iconClass = faSuitcase;
                break;
            case IncludedServiceSubType.CarryOnBaggage:
                iconClass = faShoppingBag;
                break;
            case IncludedServiceSubType.Lunch:
                iconClass = faUtensils;
                break;
            case IncludedServiceSubType.Snack:
                iconClass = faCookieBite;
                break;
            case IncludedServiceSubType.SnacksAndBeverage:
                iconClass = faUtensils;
                break;
            case IncludedServiceSubType.NonAlcoholicDrink:
                iconClass = faCoffee;
                break;
            case IncludedServiceSubType.FreeAlcoholicDrink:
                iconClass = faGlassMartini;
                break;
            // INFLIGHT ENTERTAINMENT
            case IncludedServiceSubType.PersonalEntertainment:
                iconClass = faTv;
                break;
            case IncludedServiceSubType.InternetAccess:
                iconClass = faWifi;
                break;
            case IncludedServiceSubType.InSeatpower:
                iconClass = faPlug;
                break;
            case IncludedServiceSubType.Pet:
                iconClass = faPaw;
                break;
            case IncludedServiceSubType.Security:
                iconClass = faGavel;
                break;
            case IncludedServiceSubType.PriorityBoarding:
                iconClass = faFastForward;
                break;
            case IncludedServiceSubType.Ticket:
                iconClass = faTicketAlt;
                break;
            case IncludedServiceSubType.CheckIn:
                iconClass = faChalkboardTeacher;
                break;
            case IncludedServiceSubType.ReBooking:
                iconClass = faRedo;
                break;
            case IncludedServiceSubType.RefundAllowed:
                iconClass = faMoneyCheckEditAlt;
                break;
            case IncludedServiceSubType.PriorityCheckIn:
                iconClass = faUserCheck;
                break;
            case IncludedServiceSubType.FastTrack:
                iconClass = faTachometerFastest;
                break;
            case IncludedServiceSubType.ChangeAfterDeparture:
                iconClass = faUndoAlt;
                break;
            case IncludedServiceSubType.ChangeBeforeDeparture:
                iconClass = faUndoAlt;
                break;
            case IncludedServiceSubType.ChangeInCaseOfNoShow:
                iconClass = faMapMarkerQuestion;
                break;
            case IncludedServiceSubType.OpenReturnDate:
                iconClass = faExchange;
                break;
            case IncludedServiceSubType.Stopover:
                iconClass = faMapSigns;
                break;
            default:
                switch (data.type) {
                    case IncludedServiceMainType.Baggage:
                        iconClass = faSuitcase;
                        break;
                    case IncludedServiceMainType.Meal:
                        iconClass = faUtensils;
                        break;
                    case IncludedServiceMainType.InFlightEntertainment:
                        iconClass = faTv;
                        break;
                    case IncludedServiceMainType.Miles:
                        iconClass = faMedium;
                        break;
                    case IncludedServiceMainType.Transfer:
                        iconClass = faRandom;
                        break;
                    case IncludedServiceMainType.Seat:
                        iconClass = faCouch;
                        break;
                    case IncludedServiceMainType.Other:
                        iconClass = faCircle;
                        break;
                }
        }
        return iconClass;
    }
};

export default FareHeaderIncludedServices;
interface FreeBaggeIndicatorProps {
    HasFreeBaggage: boolean;
}

const FreeBaggeIndicator: React.FC<FreeBaggeIndicatorProps> = ({
    HasFreeBaggage,
}) => {
    const title = HasFreeBaggage
        ? "This fare has free baggage allowance for at least one connection"
        : "This fare has no free baggage allowance.";
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);
    const elemRef = useRef<HTMLSpanElement>(null);

    return (
        <React.Fragment>
            <Popover target={elemRef} isOpen={open} toggle={toggle}>
                <PopoverBody>{title}</PopoverBody>
            </Popover>
            <span
                className="fa-layers fa-fw mb-1"
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                onTouchStart={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
                onTouchEnd={() => setOpen(false)}
                ref={elemRef}
            >
                <FontAwesomeIcon
                    style={{ opacity: HasFreeBaggage ? 1 : 0.5 }}
                    title={title}
                    icon={faLuggageCart}
                />
                <FontAwesomeIcon
                    style={
                        {
                            "--fa-secondary-opacity": "0.7",
                            "--fa-primary-color": "black",
                            color: `${HasFreeBaggage ? "green" : "red"}`,
                        } as CSSProperties
                    }
                    icon={
                        HasFreeBaggage ? faCheckCircle : faExclamationTriangle
                    }
                    // color={HasFreeBaggage ? "green" : "red"}
                    transform="shrink-7 down-6 right-5"
                />
            </span>
        </React.Fragment>
    );
};