export const LocalizationConverter = (price: any, language: string) => {
    const Price = Number(price);
    let languageNumber = "";
    if (language === "de") {
        // For German localization, format using toLocaleString
        languageNumber = Price.toLocaleString("de-DE");

        // Check if there's no decimal part
        if (!languageNumber.includes(",")) {
            languageNumber += ",00"; // Add ",00" suffix
        } else if (languageNumber.match(/,\d$/)) {
            languageNumber += "0"; // Add trailing zero
        }
    } else {
        // For other languages, format with 2 decimal places using toFixed
        languageNumber = Price.toFixed(2);
    }
    return languageNumber; // Convert back to string
};
