import {
  AirportType,
  ICabinClassState,
  IFareSearchInputState,
  ISegmentState,
} from "components/FareSearch/types/FareSearchTypes";
import React, { useState, useEffect, useRef } from "react";
import CabinClassInput from "../Passengers/Passengers.CabinClasses";
import AirportInput from "./FlightDetails.Segment.Airport";
import SegmentButtons from "./FlightDetails.Segment.Buttons";
import DepartureDate from "./FlightDetails.Segment.DepartureDate";
import {
  ApiCredentialsOptions,
  PublicServicesClient,
  b2cSettingsText,
} from "WebApiClient";
import { dropdownItem } from "components/FareSearch/types/FareSearchTypes";
import { AgentApiURL } from "Constants";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
interface OwnProps {
  Input: IFareSearchInputState;
  SegmentIndex: number;
  Segment: ISegmentState;
  NextSegment?: ISegmentState;
  PreviousSegment?: ISegmentState;
  RemoveSegment: (index: number) => void;
  AddReturnSegment: (index: number) => void;
  UpdateSegment: (index: number, segment: ISegmentState) => void;
  UpdateState: (state: IFareSearchInputState) => void;
  OnSubmit: () => void;
  isValidSearch: boolean;
  cabinContainer?: boolean;
  setValidationTrigger?: any;
  ValidationTrigger?: any;
  setScrollingDown?: any;
  isDateInputInOrder?: boolean;
  fullSegment?: any;
  language: string;
  scrollingDown?: boolean;
  isFareListHeader?: boolean;
  isB2cAirline: boolean;
}
var checkDateSuperior: any = true;
const Segment: React.FC<OwnProps> = (props) => {

  const index = props.SegmentIndex;
  const segment = props.Segment;

  const UpdateSegment = props.UpdateSegment;
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [isInvalidDay, setIsInvalidDay] = useState<boolean>(false);
  const [isInvalidMonth, setIsInvalidMonth] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [itemsDept, setItemsDept] = useState<dropdownItem[]>([]);
  const [itemsAriv, setItemsAriv] = useState<dropdownItem[]>([]);

  function OnUpdateCabinClass(cabinClasses: ICabinClassState) {
    let newSegment = { ...segment };
    newSegment.CabinClasses = cabinClasses;
    props.UpdateSegment(index, newSegment);
  }

  function OnSelectNextElemAirport(type: AirportType) {
    let id = "";
    if (type === AirportType.Departure) {
      id = `segment${props.SegmentIndex}_airport1`;
    } else {
      // id = `segment${props.SegmentIndex}_date`;
    }
    // (document.getElementById(id) as HTMLInputElement).select();
  }

  const [flightDetailsCollector, setflightDetailsCollector] = useState({
    arrivalL: undefined,
    departureL: undefined,
    DayL: undefined,
    MonthL: undefined,
  });

  function segmentShrotHand(flightDetailsCollector: any) {
    let fileDetailsSegment = { ...segment };
    let currentMonth = new Date().getMonth() + 1;

    let currentDate = new Date().getDate();
    var Year;

    if (
      flightDetailsCollector?.MonthL > 12 ||
      flightDetailsCollector?.DayL > 31 ||
      flightDetailsCollector?.DayL < 1 ||
      flightDetailsCollector?.MonthL < 1
    ) {
      setIsInvalid(true);
      if (
        flightDetailsCollector?.DayL > 31 ||
        flightDetailsCollector?.DayL < 1
      ) {
        setIsInvalidDay(true);
        setIsInvalidMonth(false);
      }
      if (
        flightDetailsCollector?.MonthL > 12 ||
        flightDetailsCollector?.MonthL < 1
      ) {
        setIsInvalidMonth(true);
        setIsInvalidDay(false);
      }
    } else {
      setIsInvalid(false);
    }

    //aiport api callling
    if (flightDetailsCollector.departureL.length > 2) {
      setIsLoading(true);
      const client: PublicServicesClient = new PublicServicesClient(
        new ApiCredentialsOptions(AgentApiURL, "")
      );

      client
        .getAirportsByPrefix(
          flightDetailsCollector.departureL,
          props.language,
          checkAirportType
        )
        .then((result) => {
          if (result.length > 0) {
            const list: dropdownItem[] = [];
            result.forEach((e) => {
              const text = `[${e.iata}] - ${e.cityName} - ${e.name}`;
              list.push({
                value: e.iata!,
                text: text,
                image: e.countryCode!,
              });
            });
            setItemsDept(list);
          } else {
            setItemsDept([]);
          }
        })
        .catch(() => {
          setItemsDept([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (flightDetailsCollector.arrivalL.length > 2) {
      setIsLoading(true);
      const client: PublicServicesClient = new PublicServicesClient(
        new ApiCredentialsOptions(AgentApiURL, "")
      );
      client
        .getAirportsByPrefix(
          flightDetailsCollector.arrivalL,
          props.language,
          checkAirportType
        )
        .then((result) => {
          if (result.length > 0) {
            const list: dropdownItem[] = [];
            result.forEach((e) => {
              const text = `[${e.iata}] - ${e.cityName} - ${e.name}`;
              list.push({
                value: e.iata!,
                text: text,
                image: e.countryCode!,
              });
            });
            setItemsAriv(list);
          } else {
            setItemsAriv([]);
          }
        })
        .catch(() => {
          setItemsAriv([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (
      flightDetailsCollector?.MonthL < currentMonth ||
      (flightDetailsCollector?.MonthL === currentMonth &&
        flightDetailsCollector?.DayL < currentDate)
    ) {
      Year = new Date().getFullYear() + 1;
    } else {
      Year = new Date().getFullYear();
    }

    var myDate = new Date();
    if (isInvalid == true) {
      fileDetailsSegment.DepartureInput = itemsDept.length
        ? itemsDept[0].text
        : flightDetailsCollector.departureL;
      fileDetailsSegment.ArrivalInput = itemsAriv.length
        ? itemsAriv[0].text
        : flightDetailsCollector.arrivalL;
      fileDetailsSegment.DateInput = undefined;
      props.UpdateSegment(index, fileDetailsSegment);
    } else {
      myDate = new Date(
        `${Year}-${flightDetailsCollector?.MonthL}-${flightDetailsCollector?.DayL}`
      );
      fileDetailsSegment.DepartureInput = itemsDept.length
        ? itemsDept[0].text
        : flightDetailsCollector.departureL;
      fileDetailsSegment.ArrivalInput = itemsAriv.length
        ? itemsAriv[0].text
        : flightDetailsCollector.arrivalL;
      fileDetailsSegment.DateInput = myDate;
      props.UpdateSegment(index, fileDetailsSegment);
    }
  }

  useEffect(() => {
    if (
      flightDetailsCollector.arrivalL != undefined &&
      flightDetailsCollector.departureL != undefined &&
      flightDetailsCollector.MonthL != undefined &&
      flightDetailsCollector.DayL != undefined
    ) {
      segmentShrotHand(flightDetailsCollector);
      // searchRef.current?.focus()
      document.getElementById(`addFlight`)?.focus();
    }
  }, [flightDetailsCollector, isInvalid, itemsAriv.length, itemsDept.length]);

  const departureRef = React.useRef<HTMLInputElement | any>();
  const arrivalRef = React.useRef<HTMLInputElement | any>();
  const inputeDateRef = React.useRef<HTMLInputElement | any>();
  const searchRef = React.useRef<HTMLInputElement | any>();

  const returnTabId = (segId: any, InputTab: any): any => {
    if (showDateInputSide?.ShowDateInput == "Right") {
      switch (segId) {
        case 0:
          if (InputTab == 1) {
            return 1;
          }
          if (InputTab == 2) return 2;
          if (InputTab == 3) return 3;
          break;
        case 1:
          if (InputTab == 1) return 4;
          if (InputTab == 2) return 5;
          if (InputTab == 3) return 6;
          break;
        case 2:
          if (InputTab == 1) return 7;
          if (InputTab == 2) return 8;
          if (InputTab == 3) return 9;
          break;
        case 3:
          if (InputTab == 1) return 10;
          if (InputTab == 2) return 11;
          if (InputTab == 3) return 12;
          break;
        case 4:
          if (InputTab == 1) return 13;
          if (InputTab == 2) return 14;
          if (InputTab == 3) return 15;
          break;
        default:
          return;
      }
    } else {
      switch (segId) {
        case 0:
          if (InputTab == 1) {
            return 1;
          }
          if (InputTab == 2) return 2;
          if (InputTab == 3) return 3;
          break;
        case 1:
          if (InputTab == 1) return 4;
          if (InputTab == 2) return 5;
          if (InputTab == 3) return 6;
          break;
        case 2:
          if (InputTab == 1) return 7;
          if (InputTab == 2) return 8;
          if (InputTab == 3) return 9;
          break;
        case 3:
          if (InputTab == 1) return 10;
          if (InputTab == 2) return 11;
          if (InputTab == 3) return 12;
          break;
        case 4:
          if (InputTab == 1) return 13;
          if (InputTab == 2) return 14;
          if (InputTab == 3) return 15;
          break;
        default:
          return;
      }
    }
  };
  const sessionInfo = useSelector((state: State) => state.Session);
  const showDateInputSide: b2cSettingsText = (() => {
    try {
      return JSON.parse(
        sessionInfo?.FrontendSettings?.b2cSettingsText ||
        '{"HotelTab":"None","AirPortSearchType":"All","ShowLanguageDecimalTabB2C":"English","ShowExtraAncillariesTabB2C":"On","ShowDateInput":"Left","ShowDefaultFilter":"Off"}'
      );
    } catch {
      return {
        HotelTab: "All",
        AirPortSearchType: "All",
        ShowLanguageDecimalTabB2C: "English",
        ShowExtraAncillariesTabB2C: "On",
        ShowDateInput: "Left",
        ShowDefaultFilter: "Off",
      };
    }
  })();
  const checkAirportType: boolean = showDateInputSide?.AirPortSearchType == "All" ? true : false;
  if (props.SegmentIndex != 0) {
    const prev = props.fullSegment[props.SegmentIndex - 1].DateInput;
    const current = props.fullSegment[props.SegmentIndex].DateInput;
    const prevDate = new Date(prev);
    const currentDate = new Date(current);
    checkDateSuperior = currentDate >= prevDate;
  }

  return (
    <React.Fragment>
      {showDateInputSide?.ShowDateInput == "Right" ? (
        <div className="row container__Flight">
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
            <AirportInput
              SegmentIndex={index}
              AirportType={AirportType.Departure}
              Segment={segment}
              UpdateSegment={UpdateSegment}
              OnSelectNextElem={OnSelectNextElemAirport}
              departureCollector={flightDetailsCollector.departureL}
              setdepartureCollector={setflightDetailsCollector}
              isInValidMonth={isInvalid}
              reference={departureRef}
              tabIndex={returnTabId(props.SegmentIndex, 1)}
              setValidationTrigger={props.setValidationTrigger}
              ValidationTrigger={props.ValidationTrigger}
              setScrollingDown={props.setScrollingDown}
              language={props.language}
              isFareListHeader={props.isFareListHeader}
              isB2cAirline={props.isB2cAirline}
            />
            {isInvalidDay && isInvalid && (
              <span style={{ color: "red", fontSize: "14px" }}>
                Please select valid Day. <strong>"DepArvDDMM"</strong>
              </span>
            )}
            {isInvalidMonth && isInvalid && (
              <span style={{ color: "red", fontSize: "14px" }}>
                Please select valid Month. <strong>"DepArvDDMM"</strong>
              </span>
            )}
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
            <AirportInput
              SegmentIndex={index}
              AirportType={AirportType.Arrival}
              Segment={segment}
              UpdateSegment={UpdateSegment}
              OnSelectNextElem={OnSelectNextElemAirport}
              arrivalCollector={flightDetailsCollector.arrivalL}
              setdepartureCollector={setflightDetailsCollector}
              isInValidMonth={isInvalid}
              reference={arrivalRef}
              tabIndex={returnTabId(props.SegmentIndex, 2)}
              language={props.language}
              setScrollingDown={props.setScrollingDown}
              isFareListHeader={props.isFareListHeader}
              isB2cAirline={props.isB2cAirline}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
            <div className="d-flex" style={{ position: 'relative' }}>
              <div
                style={{
                  width: props.Input.Segments.length > 1 ? "90%" : "100%",
                }}
              >
                <DepartureDate
                  SegmentIndex={index}
                  Segment={segment}
                  PreviousSegment={props.PreviousSegment}
                  NextSegment={props.NextSegment}
                  UpdateSegment={UpdateSegment}
                  btnRef={searchRef}
                  reference={inputeDateRef}
                  tabIndex={returnTabId(props.SegmentIndex, 3)}
                  setdepartureCollector={setflightDetailsCollector}
                  setScrollingDown={props.setScrollingDown}
                  isDateInputInOrder={
                    props.SegmentIndex != 0 ? checkDateSuperior : true
                  }
                  segmentArr={props.Input.Segments}
                  scrollingDown={props.scrollingDown}
                />
              </div>
              {props.Input.Segments.length > 1 && (
                <div className="removeBtn">
                  <SegmentButtons
                    SegmentIndex={index}
                    Segment={segment}
                    NextSegment={props.NextSegment}
                    RemoveSegment={props.RemoveSegment}
                    AddReturnSegment={props.AddReturnSegment}
                  />
                </div>
              )}
            </div>
          </div>
          {props.Input.CabinClassPerLeg && (
            <CabinClassInput
              CabinClasses={segment.CabinClasses}
              UpdateCabinClasses={OnUpdateCabinClass}
              ClassName="col-12 mb-2"
              disabled={false}
              segmentIndex={props.Input.Segments.length}
            />
          )}
        </div>
      ) : (
        <div className="row container__Flight">
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-1 dateFlightField">
            <DepartureDate
              SegmentIndex={index}
              Segment={segment}
              PreviousSegment={props.PreviousSegment}
              NextSegment={props.NextSegment}
              UpdateSegment={UpdateSegment}
              btnRef={searchRef}
              reference={inputeDateRef}
              tabIndex={returnTabId(props.SegmentIndex, 1)}
              setdepartureCollector={setflightDetailsCollector}
              setScrollingDown={props.setScrollingDown}
              isDateInputInOrder={
                props.SegmentIndex != 0 ? checkDateSuperior : true
              }
              segmentArr={props.Input.Segments}
              scrollingDown={props.scrollingDown}
            />
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-1 departureFlightField">
            <AirportInput
              SegmentIndex={index}
              AirportType={AirportType.Departure}
              Segment={segment}
              UpdateSegment={UpdateSegment}
              OnSelectNextElem={OnSelectNextElemAirport}
              departureCollector={flightDetailsCollector.departureL}
              setdepartureCollector={setflightDetailsCollector}
              isInValidMonth={isInvalid}
              reference={departureRef}
              tabIndex={returnTabId(props.SegmentIndex, 2)}
              setValidationTrigger={props.setValidationTrigger}
              ValidationTrigger={props.ValidationTrigger}
              setScrollingDown={props.setScrollingDown}
              language={props.language}
              isFareListHeader={props.isFareListHeader}
              isB2cAirline={props.isB2cAirline}
            />

            {/* {isInvalid &&
            <span style={{ color: "red", fontSize: "14px" }}>Please select valid Month. <strong>"DepArvDDMM"</strong></span>
          } */}

            {isInvalidDay && isInvalid && (
              <span style={{ color: "red", fontSize: "14px" }}>
                Please select valid Day. <strong>"DepArvDDMM"</strong>
              </span>
            )}

            {isInvalidMonth && isInvalid && (
              <span style={{ color: "red", fontSize: "14px" }}>
                Please select valid Month. <strong>"DepArvDDMM"</strong>
              </span>
            )}
          </div>
          <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-1 arrivalFlightField">
            <div className="d-flex">
              <div
                style={{
                  width: props.Input.Segments.length > 1 ? "90%" : "100%",
                }}
              >
                <AirportInput
                  SegmentIndex={index}
                  AirportType={AirportType.Arrival}
                  Segment={segment}
                  UpdateSegment={UpdateSegment}
                  OnSelectNextElem={OnSelectNextElemAirport}
                  arrivalCollector={flightDetailsCollector.arrivalL}
                  setdepartureCollector={setflightDetailsCollector}
                  isInValidMonth={isInvalid}
                  reference={arrivalRef}
                  tabIndex={returnTabId(props.SegmentIndex, 3)}
                  setValidationTrigger={props.setValidationTrigger}
                  ValidationTrigger={props.ValidationTrigger}
                  setScrollingDown={props.setScrollingDown}
                  language={props.language}
                  isFareListHeader={props.isFareListHeader}
                  isB2cAirline={props.isB2cAirline}
                />
              </div>
              <div>
                {props.Input.Segments.length > 1 && (
                  <div className="removeBtn">
                    <SegmentButtons
                      SegmentIndex={index}
                      Segment={segment}
                      NextSegment={props.NextSegment}
                      RemoveSegment={props.RemoveSegment}
                      AddReturnSegment={props.AddReturnSegment}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {props.Input.CabinClassPerLeg && (
            <CabinClassInput
              CabinClasses={segment.CabinClasses}
              UpdateCabinClasses={OnUpdateCabinClass}
              ClassName="col-12 mb-2"
              disabled={false}
              segmentIndex={props.Input.Segments.length}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Segment;
