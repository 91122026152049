import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faPizza,
  faHourglassStart,
} from "@fortawesome/pro-regular-svg-icons";
import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap";

interface FilterProps {
  isOpen: boolean;
  Toggle: () => void;
  result: any; // Replace 'any' with the appropriate type for your data
  setHotelResult: any;
}

interface Amenity {
  id: number;
  name: string;
}

const amenities = [
  { name: "24h reception", emoji: "🕛" },
  { name: "accessibility", emoji: "♿️" },
  { name: "air condition", emoji: "❄️" },
  { name: "beauty salon", emoji: "💇‍♀️" },
  { name: "bikes", emoji: "🚲" },
  { name: "concierge", emoji: "💼" },
  { name: "hair dryer", emoji: "💇" },
  { name: "internet", emoji: "🌐" },
  { name: "iron", emoji: "🔨" },
  { name: "no smoking", emoji: "🚭" },
  { name: "parking lot", emoji: "🅿️" },
  { name: "pets friendly", emoji: "🐾" },
  { name: "refrigerator", emoji: "🧊" },
  { name: "spa", emoji: "💆" },
  { name: "tv", emoji: "📺" },
  { name: "tv channels (satellite)", emoji: "📡" },
];

const meals = [
  { name: "Room only", code: "RO" },
  { name: "Bed and Breakfast", code: "BB" },
  { name: "Half Board", code: "HB" },
  { name: "Full Board", code: "FB" },
  { name: "All-Inclusive", code: "AI" },
];
const HotelFilter: React.FC<FilterProps> = (props) => {
  const amenitiesData: Amenity[] = props.result?.tripAdvisor?.facilities.list;
  const [selectedAmenities, setSelectedAmenities] = useState<string[]>([]);
  const [originalData, setOriginalData] = useState<any>(props.result);
  const [filteredHotels, setFilteredHotels] = useState<any>(props.result);
  const [selectedMealTypes, setSelectedMealTypes] = useState<string[]>([]);

  const allAmenities: string[] = Array.from(
    new Set(
      originalData.flatMap((hotel: any) => hotel.tripAdvisor.facilities.tags)
    )
  );

  const handleAmenityChange = (amenity: string) => {
    const matchingAmenity = amenities.find(
      (item) =>
        item.name === amenity ||
        alternativeAmenities.some((alternative) =>
          alternative.alternatives.includes(amenity)
        )
    );

    if (matchingAmenity) {
      if (selectedAmenities.includes(matchingAmenity.name)) {
        setSelectedAmenities(
          selectedAmenities.filter((item) => item !== matchingAmenity.name)
        );
      } else {
        setSelectedAmenities([...selectedAmenities, matchingAmenity.name]);
      }
      applyFiltersAmineties(); // Call applyFilters whenever checkbox changes
    }
  };

  const handleMealTypeChange = (mealType: string) => {
    if (selectedMealTypes.includes(mealType)) {
      setSelectedMealTypes(
        selectedMealTypes.filter((item) => item !== mealType)
      );
    } else {
      setSelectedMealTypes([...selectedMealTypes, mealType]);
    }
    applyFilters();
  };

  useEffect(() => {
    const newFilteredHotels = props.result.filter((hotel: any) => {
      const amenities = hotel.tripAdvisor.facilities.tags;
      return selectedAmenities.every((amenity) => amenities.includes(amenity));
    });
    setFilteredHotels(newFilteredHotels);
  }, [selectedAmenities, props.result]);

  useEffect(() => {
    applyFilters();
  }, [selectedMealTypes?.length]);

  useEffect(() => {
    applyFiltersAmineties();
  }, [selectedAmenities?.length]);

  const applyFiltersAmineties = () => {
    if (selectedAmenities.length === 0 && selectedMealTypes.length === 0) {
      props.setHotelResult(originalData);
      setFilteredHotels(originalData);
    } else {
      const filtered = originalData.filter((hotel: any) =>
        selectedAmenities.every((amenity) =>
          hotel.tripAdvisor?.facilities.tags.includes(amenity)
        )
      );
      props.setHotelResult(filtered);
      setFilteredHotels(filtered);
    }
  };

  const applyFilters = () => {
    console.log(selectedMealTypes);
    if (selectedMealTypes.length === 0 && selectedAmenities.length === 0) {
      // Restoring Original Data
      props.setHotelResult(originalData);
      setFilteredHotels(originalData);
    } else {
      let filtered: any = originalData; // Start with the original data

      if (selectedMealTypes.length > 0) {
        // Filter based on selected meal types
        filtered = filtered.filter((hotel: any) =>
          hotel.PriceData.some((priceItem: any) =>
            selectedMealTypes.includes(priceItem.items[0].board)
          )
        );
      }

      if (selectedAmenities.length > 0) {
        // Filter based on selected amenities
        filtered = filtered.filter((hotel: any) =>
          selectedAmenities.every((amenity) =>
            hotel.tripAdvisor?.facilities.tags.includes(amenity)
          )
        );
      }

      props.setHotelResult(filtered);
      setFilteredHotels(filtered);
    }
  };

  const alternativeAmenities = [
    {
      original: "tv channels (satellite)",
      alternatives: ["tv channels (x)", "tv channels (y)"],
    },
    // Add more alternative amenities as needed
  ];
  // * reset filter function
  const resetFilters = () => {
    setSelectedAmenities([]);
    applyFilters();
  };

  return (
    <Offcanvas
      backdrop={true}
      fade={true}
      scrollable={true}
      isOpen={props.isOpen}
      toggle={props.Toggle}
      size="xl"
    >
      <OffcanvasHeader toggle={props.Toggle}>Filter</OffcanvasHeader>
      <OffcanvasBody>
        <div className="text-end mb-2">
          <button className="btn btn-danger ml-2" onClick={resetFilters}>
            Reset
          </button>
        </div>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="filter-section mt-3">
                <FontAwesomeIcon icon={faHourglassStart} />
                &nbsp;Amenities Filter
              </div>
              <Form>
                {allAmenities
                  .sort((a, b) => a.localeCompare(b))
                  .map((selectedAmenity: string) => {
                    const matchingAmenity = amenities.find(
                      (item) =>
                        item.name === selectedAmenity ||
                        alternativeAmenities.some((alternative) =>
                          alternative.alternatives.includes(selectedAmenity)
                        )
                    );

                    if (matchingAmenity) {
                      return (
                        <FormGroup key={selectedAmenity} check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={selectedAmenities.includes(
                                matchingAmenity.name
                              )}
                              onChange={() =>
                                handleAmenityChange(matchingAmenity.name)
                              }
                            />
                            <span
                              style={{
                                cursor: "pointer",
                                fontSize: "18px",
                              }}
                              data-bs-toggle="tooltip"
                              title={matchingAmenity.name}
                            >
                              {matchingAmenity.emoji}
                            </span>{" "}
                            {matchingAmenity.name}
                          </Label>
                        </FormGroup>
                      );
                    }
                    return null;
                  })}
              </Form>
              <hr />
              <Form>
                <div className="filter-section mt-3">
                  <FontAwesomeIcon icon={faPizza} />
                  &nbsp; Meal Types Filter
                </div>
                {meals.map((meal) => (
                  <FormGroup key={meal.code} check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={selectedMealTypes.includes(meal.code)}
                        onChange={() => handleMealTypeChange(meal.code)}
                      />
                      {meal.name} ({meal.code})
                    </Label>
                  </FormGroup>
                ))}
              </Form>
            </div>
          </div>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default HotelFilter;