import React, { CSSProperties, useEffect, useRef, useState } from "react";
import {
  Airline,
  Airport,
  ApiCredentialsOptions,
  AvailableFareConnectionModel,
  AvailableFareModel,
  PublicServicesClient,
} from "WebApiClient";
import FareLegConnectionTableRow from "./Fare.Leg.ConTbl.Row";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import IncServices from "components/Shared/IncServices";
import { AgentApiURL } from "Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import { faLuggageCart } from "@fortawesome/pro-solid-svg-icons";
import Warning from "components/Shared/Warning";
interface ConnectionTableProps {
  Data: AvailableFareConnectionModel[];
  SelectedConnection: any;
  GetSelectedConnectionIndexByLegIndex: any;
  LegIndex: number;
  SelectConnection: (legIndex: number, connectionIndex: number) => void;
  IsWebFare: boolean;
  ApplicableConnections: number[] | undefined;
  Fare: AvailableFareModel;
  radio: boolean;
  showSelected: boolean;
}

interface AirportData {
  iata: string;
  countryCode: string;
  cityName: string;
}

const FareLegConnectionTable: React.FC<ConnectionTableProps> = (props) => {
  const { t } = useTranslation();
  function HasAllotment() {
    let result = false;
    props.Data.forEach((con) => {
      con.segments.forEach((seg) => {
        if (seg.allotmentInformation) {
          result = true;
        }
      });
    });
    return result;
  }

  // Function to check equality for all objects in the array
  const areAllIncludedServicesEqual = (
    dataArray: any,
    stringToSearch: string
  ) => {
    const firstObject = dataArray[0];
    if (dataArray.length < 2) {
      const segments1 = _.get(firstObject, "segments", []);
      if (segments1?.length < 2) {
        return true;
      }
      for (let j = 0; j < segments1.length; j++) {
        const singleCellDoubleBaggage = areIncludedServicesEqual(
          segments1[j],
          segments1[j + 1],
          stringToSearch
        );
        if (singleCellDoubleBaggage) {
          return true;
        }
      }
    }
    if (dataArray.length > 1) {
      for (let i = 1; i < dataArray.length; i++) {
        const currentObject = dataArray[i];

        // Get the segments array from each object
        const segments1 = _.get(firstObject, "segments", []);
        const segments2 = _.get(currentObject, "segments", []);

        // Check if the lengths of segments arrays are equal
        if (segments1.length !== segments2.length) {
          return false;
        }

        // Check the equality of includedServices for each segment
        for (let j = 0; j < segments1.length; j++) {
          const includedServicesEqual = areIncludedServicesEqual(
            segments1[j],
            segments2[j],
            stringToSearch
          );
          if (!includedServicesEqual) {
            return false;
          }
        }
      }
      return true;
    }
  };

  // Function to check if the arrays of includedServices are equal
  const areIncludedServicesEqual = (
    obj1: any,
    obj2: any,
    searchString: string
  ) => {
    const services1 = _.get(obj1, searchString, []);
    const services2 = _.get(obj2, searchString, []);
    if (searchString == "cabinClassName" && services1 !== services2) {
      return false;
    }
    if (
      (typeof services1 !== "string" || typeof services2 !== "string") &&
      (services1.length == 0 || services2.length == 0)
    ) {
      return true;
    }
    if (
      (typeof services1 === "string" || typeof services2 === "string") &&
      (services1 == "" || services2 == "")
    ) {
      return true;
    }
    return _.isEqual(services1, services2);
  };

  // Check if the includedServices are equal for all objects in the array
  const resultIsPresentAll = areAllIncludedServicesEqual(
    props.Data,
    "includedServices"
  );

  const baggageIsPresentAll = areAllIncludedServicesEqual(
    props.Data,
    "freeBaggageAllowance"
  );

  const cabinIsPresentAll = areAllIncludedServicesEqual(
    props.Data,
    "cabinClassName"
  );

  //If more than one class cabin present Checking here
  // Flatten the array and use uniqBy to check if "cabinClassName" values are different
  const areCabinClassNamesDifferent =
    _.uniqBy(
      _.flatMap(props.Fare.legs, "connections[0].segments"),
      "cabinClassName"
    ).length > 1;

  // Assuming your array of objects is named 'combinedSegments'
  const combinedSegments = _.flatMap(props.Fare.legs, "connections").map(
    (connection) => connection.segments
  );

  // Check if all includedServices arrays and freeBaggageAllowance properties are the same or all are empty
  const areIncludedServicesBaggageEqualsFinal = _.every(
    combinedSegments,
    (objArray, index) =>
      _.isEqualWith(
        objArray[0]?.includedServices,
        combinedSegments[0][0]?.includedServices,
        (a, b) => _.isEqual(a, b) || (_.isEmpty(a) && _.isEmpty(b))
      ) &&
      _.isEqual(
        combinedSegments[index][0]?.freeBaggageAllowance,
        combinedSegments[0][0]?.freeBaggageAllowance
      )
  );

  const filteredSegments = props.Data.sort((a, b) => {
    const legTravelTimeA = a.connectionHeader
      ? a.connectionHeader.legTravelTime.hours * 60 +
      a.connectionHeader.legTravelTime.minutes
      : Infinity;
    const legTravelTimeB = b.connectionHeader
      ? b.connectionHeader.legTravelTime.hours * 60 +
      b.connectionHeader.legTravelTime.minutes
      : Infinity;
    return legTravelTimeA - legTravelTimeB;
  });
  const [isWarning, setIsWarning] = useState(true);
  //check if codeShare is not null;
  // const doesCodeShareHaveValue = _.some(_.flatMap(props.Fare.legs, 'connections[0].segments'), 'codeShare');

  function hasNonEmptyMealCode(filteredSegment: any) {
    return filteredSegment.some((meal: any) => meal.mealCode !== "");
  }
  // console.log('checkkkk - ',props.Fare.legs[0].fareTypeInformation.originalFareType,props.Fare.legs[0].fareTypeInformation.salesPreferenceDisplayName)
  const emptyMealCode = hasNonEmptyMealCode(filteredSegments);
  const isSabreAmadeusPresent = props.Fare.legs[0].fareTypeInformation.originalFareType == "Sabre" ||
    props.Fare.legs[0].fareTypeInformation.salesPreferenceDisplayName == "Sabre" ||
    props.Fare.legs[0].fareTypeInformation.originalFareType == "Amadeus" ||
    props.Fare.legs[0].fareTypeInformation.salesPreferenceDisplayName == "Amadeus" ||
    props.Fare.legs[0].fareTypeInformation.salesPreferenceDisplayName == "1S" ||
    props.Fare.legs[0].fareTypeInformation.originalFareType == "1S";
  return (
    <React.Fragment>
      {props.LegIndex == 0 && areIncludedServicesBaggageEqualsFinal ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {baggageIsPresentAll && (
            <div className="">
              <span>
                <span>
                  {!props?.Data[props.SelectedConnection]?.segments[0]
                    .freeBaggageAllowance ? (
                    <span>{t("FareList.FareLegCntTbl.NoBaggage")}</span>
                  ) : (
                    <>
                      {props?.Data[props.SelectedConnection]?.segments[0]
                        .freeBaggageAllowance.length > 0
                        ? `Baggage - ${props?.Data[props.SelectedConnection]?.segments[0]
                          .freeBaggageAllowance
                        }`
                        : t("FareList.FareLegCntTbl.NoBaggage")}
                    </>
                  )}
                </span>
                <br />
              </span>
            </div>
          )}
          {(resultIsPresentAll && !isSabreAmadeusPresent) && (
            < >
              <div className="">
                <span className="">
                  {props?.Data[props.SelectedConnection]?.segments[0]
                    .includedServices.length ? (
                    <React.Fragment>
                      {t("FareList.FareLegCntTbl.Incl.Services")} &nbsp;
                    </React.Fragment>
                  ) : (
                    null
                  )}
                </span>
                <span className="ancillaries text-s">
                  <span>
                    <span>
                      {props?.Data[props.SelectedConnection]?.segments[0]
                        .includedServices.length ? (
                        <React.Fragment>
                          {props?.Data[
                            props.SelectedConnection
                          ]?.segments[0].includedServices.map(
                            (mainGroup: any, mainGroupIndex: any) => (
                              <IncServices
                                key={`mainGroup_${mainGroupIndex}`}
                                data={mainGroup}
                                incSerFull={
                                  props?.Data[props.SelectedConnection]
                                    ?.segments[0].includedServices
                                }
                                toShow={true}
                              />
                            )
                          )}
                        </React.Fragment>
                      ) : (
                        null
                      )}
                    </span>
                  </span>
                </span>
              </div>
              {isWarning ? (
                <div className={isWarning && props?.Data[props.SelectedConnection]?.segments[0]
                  .includedServices.length ? "mt-3 mb-1" :""}>
                  <span className={isWarning && props?.Data[props.SelectedConnection]?.segments[0]
                    .includedServices.length ? "isWarning pt-2 " : ""}>
                    {props?.Data[props.SelectedConnection]?.segments[0]
                      .includedServices.length ? (
                      <React.Fragment>
                        {props?.Data[
                          props.SelectedConnection
                        ]?.segments[0].includedServices.map(
                          (mainGroup: any, mainGroupIndex: any) => (
                            <Warning
                              key={`mainGroup_${mainGroupIndex}`}
                              data={mainGroup}
                              incSerFull={
                                props?.Data[props.SelectedConnection]
                                  ?.segments[0].includedServices
                              }
                              setIsWarning={setIsWarning}
                              toShow={true}
                            />
                          )
                        )}
                      </React.Fragment>
                    ) : null}
                  </span>
                </div>
              ) : null}
            </>
          )}
        </div>
      ) : (
        null
      )}

      {!areIncludedServicesBaggageEqualsFinal && (
        <div className="mb-2" style={{ display: "flex", flexDirection: 'column' }}>
          {resultIsPresentAll && (
            <div>
              <span>
                {props?.Data[props.SelectedConnection]?.segments[0]
                  .includedServices.length ? (
                  <React.Fragment>
                    {t("FareList.FareLegCntTbl.Incl.Services")} &nbsp;
                  </React.Fragment>
                ) : (
                  <></>
                )}
              </span>
              <span className="ancillaries text-s">
                <span>
                  <span>
                    {props?.Data[props.SelectedConnection]?.segments[0]
                      .includedServices.length ? (
                      <React.Fragment>
                        {props?.Data[
                          props.SelectedConnection
                        ]?.segments[0].includedServices.map(
                          (mainGroup: any, mainGroupIndex: any) => (
                            <IncServices
                              key={`mainGroup_${mainGroupIndex}`}
                              data={mainGroup}
                              incSerFull={
                                props?.Data[props.SelectedConnection]
                                  ?.segments[0].includedServices
                              }
                              toShow={true}
                            />
                          )
                        )}
                      </React.Fragment>
                    ) : null}
                  </span>
                  <br />
                </span>
              </span>
            </div>
          )}
          {baggageIsPresentAll && (
            <div className="mb-2">
              <span>
                <span>
                  {!props?.Data[props.SelectedConnection]?.segments[0]
                    .freeBaggageAllowance ? (
                    <span>{t("FareList.FareLegCntTbl.NoBaggage")}</span>
                  ) : (
                    <>
                      {props?.Data[props.SelectedConnection]?.segments[0]
                        .freeBaggageAllowance.length > 0
                        ? `Baggage - ${props?.Data[props.SelectedConnection]?.segments[0]
                          .freeBaggageAllowance
                        }`
                        : t("FareList.FareLegCntTbl.NoBaggage")}
                    </>
                  )}
                </span>
                <br />
              </span>
            </div>
          )}
        </div>
      )}
      <div
        style={{ overflow: "auto" }}
        className="multi-collapse segment__moreFlightTimes"
      >
        <table className="table table-sm table-bordered table-responsive-xl">
          <thead className="thead-light">
            <tr className="fareTableHeaderTR">
              {props.radio && !props.showSelected && (
                <th style={{ width: "1%" }}></th>
              )}
              <th className="fareTabletrtdRouting">
                {t("FareList.FareLegCntTbl.Routing")}{" "}
              </th>
              {(!cabinIsPresentAll || areCabinClassNamesDifferent) && (
                <th className="fareTabletrtdCabin">
                  {" "}
                  {t("FareList.FareLegCntTbl.Cabin")}{" "}
                </th>
              )}
              <th className="fareTabletrtdFlight">
                {t("FareList.FareLegCntTbl.Flight")}
              </th>

              {/* <th className="fareTabletrtdOperatedBy">
                                {t("FareList.FareLegCntTbl.OperatedBy")}
                            </th> */}

              <th className="fareTabletrtdDate">
                {t("FareList.FareLegCntTbl.Date")}
              </th>
              <th className="fareTabletrtdTime">
                {t("FareList.FareLegCntTbl.Time")}
              </th>
              <th className="fareTabletrtdType">
                {t("FareList.FareLegCntTbl.Type")}
              </th>
              {/* style={{ width: "12%" }} <th>RBD</th> */}
              {!baggageIsPresentAll && (
                <th className="fareTabletrtdBaggage">
                  {t("FareList.FareLegCntTbl.Baggage")}
                </th>
              )}
              {!resultIsPresentAll && (
                <th className="fareTabletrtdServices">
                  {t("FareList.FareLegCntTbl.Incl.Services")}
                </th>
              )}
              {
                emptyMealCode && (<th className="fareTabletrtdMeal">
                  Meal
                </th>)
              }
              {HasAllotment() && <th>A</th>}
              {/*
                            <th><span className="d-md-inline">Details</span></th>
                            <th></th> */}
            </tr>
          </thead>
          <tbody>
            {filteredSegments.map((connection, index) => (
              <FareLegConnectionTableRow
                radio={props.radio}
                Data={connection!}
                SelectedConnection={props.SelectedConnection}
                GetSelectedConnectionIndexByLegIndex={
                  props.GetSelectedConnectionIndexByLegIndex
                }
                connection={filteredSegments}
                LegIndex={props.LegIndex}
                ConnectionIndex={index}
                key={"FareLegConnectionTableRows1" + index}
                SelectConnection={props.SelectConnection}
                IsWebFare={props.IsWebFare}
                connectionAppliesToFilter={
                  props.ApplicableConnections
                    ? props.ApplicableConnections.includes(index)
                    : true
                }
                Fare={props.Fare}
                resultIsPresentAllIncl={resultIsPresentAll}
                baggageIsPresentAll={baggageIsPresentAll}
                cabinIsPresentAll={cabinIsPresentAll}
                isCabinVisibleInTable={areCabinClassNamesDifferent}
                showSelected={props.showSelected}
              // doesCodeShareHaveValue={doesCodeShareHaveValue}
              />
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default FareLegConnectionTable;
