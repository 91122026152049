import { faPassport } from "@fortawesome/pro-light-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountrySelect from "components/Shared/CountrySelect";
import DateInput from "components/Shared/DateInput";
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import * as moment from "moment";
import React, { Fragment } from "react";
import { AgentApisPassportType, FareBookingPassengerApisPassport } from "WebApiClient";
import { GetNullableApiRequestDate } from "../Helpers/DateConverters";
import { ApisPassportValidResult } from "./APISInput.Validate.Passport";
import { useTranslation } from "react-i18next";
export interface IBookingPassengerApisPassportProps {
    Data: FareBookingPassengerApisPassport | undefined;
    OnUpdatePassenger: (data: FareBookingPassengerApisPassport | undefined) => void
    IsValid: ApisPassportValidResult;
}

const BookingPassengerApisPassport: React.FC<IBookingPassengerApisPassportProps> = ({ Data, IsValid, OnUpdatePassenger }) => {
    const { t } = useTranslation();

    function OnAddPassport() {
        let d: FareBookingPassengerApisPassport = {
            country: "",
            expiryDate: undefined,
            holder: true,
            issueDate: undefined,
            nationality: "",
            number: "",
            placeOfBirth: "",
            type: AgentApisPassportType.PassportCard

        }
        OnUpdatePassenger(d);
    }
    function OnRemovePassport() {
        OnUpdatePassenger(undefined);
    }
    function OnPassportNumberUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.number = value;
            OnUpdatePassenger(data);

        }
    }
    function OnPlaceOfbirthUpdate(value: string) {
        if (Data) {

            let data = { ...Data };
            data.placeOfBirth = value;
            OnUpdatePassenger(data);
        }
    }

    function OnPassportTypeUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.type = parseInt(value);
            OnUpdatePassenger(data);
        }
    }


    function OnPassportCountryUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.country = value;
            OnUpdatePassenger(data);
        }
    }


    function OnPassportExpiryDateUpdate(value: Date | undefined) {
        if (Data) {
            let data = { ...Data };
            data.expiryDate = GetNullableApiRequestDate(value);
            OnUpdatePassenger(data);
        }
    }



    function OnPassportIssueDateUpdate(value: Date | undefined) {
        if (Data) {
            let data = { ...Data };
            data.issueDate = GetNullableApiRequestDate(value);
            OnUpdatePassenger(data);
        }
    }






    function OnNationalityUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.nationality = value;
            OnUpdatePassenger(data);
        }
    }


    function TogglePassportHolder() {
        if (Data) {
            let data = { ...Data };
            data.holder = !data.holder;
            OnUpdatePassenger(data);
        }
    }

    function GetValidClassName(required: boolean, isValid: boolean): string {
        let result = "";
        if (required) {
            result = isValid ? "is-valid" : "is-invalid";
        }
        return result;
    }

    return (
        <React.Fragment>
            <div className="card mb-2">
                <div className="card-body">
                    <h5 className="card-title"><ValidIndicatorIcon
                        IsValid={IsValid.Valid}
                        Icon={faPassport}
                        NoValueSet={Data === undefined}
                    />{t("FareBooking.APISPage.Passport.PassportTitle")} {Data &&
                        <button style={{marginLeft:'6px'}} className="btn btn-outline-danger btn-sm" onClick={OnRemovePassport}><FontAwesomeIcon icon={faTrash} /></button>

                        }</h5>
                    {Data &&
                        <Fragment>
                            <div className="row align-items-center mb-2">
                                <label className="col-sm-4 col-md-4 col-lg-4">{t("FareBooking.APISPage.Passport.Nationality")}{IsValid.NationalityRequired ? "*" : ""}</label>
                                <div className="col-sm-8 col-md-8 col-lg-8">
                                    <CountrySelect
                                        Value={Data.nationality}
                                        Onchange={OnNationalityUpdate}
                                        Valid={IsValid.Nationality}
                                        ID={`nation`}
                                        Required={IsValid.NationalityRequired}
                                        disabled={false}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-2">
                                <label className="col-sm-4 col-md-4 col-lg-4">{t("FareBooking.APISPage.Passport.Type.TypeTitle")}{IsValid.TypeRequired ? "*" : ""}</label>
                                <div className="col-sm-8 col-md-8 col-lg-8">
                                    <select value={Data.type} onChange={(e) => OnPassportTypeUpdate(e.target.value)} className={`form-select ${GetValidClassName(IsValid.TypeRequired, IsValid.Type)}`}>
                                        <option value={AgentApisPassportType.Unkown}>{t("FareBooking.APISPage.Passport.Type.TypeOption1")}</option>
                                        <option value={AgentApisPassportType.ApprovedID}>{t("FareBooking.APISPage.Passport.Type.TypeOption2")}</option>
                                        <option value={AgentApisPassportType.Crewmember}>{t("FareBooking.APISPage.Passport.Type.TypeOption3")}</option>
                                        <option value={AgentApisPassportType.IDCardA}>{t("FareBooking.APISPage.Passport.Type.TypeOption4")}</option>
                                        <option value={AgentApisPassportType.IDCardC}>{t("FareBooking.APISPage.Passport.Type.TypeOption5")}</option>
                                        <option value={AgentApisPassportType.IDCardI}>{t("FareBooking.APISPage.Passport.Type.TypeOption6")}</option>
                                        <option value={AgentApisPassportType.MilitaryID}>{t("FareBooking.APISPage.Passport.Type.TypeOption7")}</option>
                                        <option value={AgentApisPassportType.PassengerPassport}>{t("FareBooking.APISPage.Passport.Type.TypeOption8")}</option>
                                        <option value={AgentApisPassportType.PassportCard}>{t("FareBooking.APISPage.Passport.Type.TypeOption9")}</option>
                                    </select>
                                </div>
                            </div>
                            {Data.type !== AgentApisPassportType.Unkown &&
                                <React.Fragment>
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">{t("FareBooking.APISPage.Passport.Number")}{IsValid.NumberRequired ? "*" : ""}</label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <input value={Data.number} onChange={(e) => OnPassportNumberUpdate(e.target.value)} type="text" className={`form-control  ${GetValidClassName(IsValid.NumberRequired, IsValid.Number)}`} />
                                        </div>
                                    </div>

                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">{t("FareBooking.APISPage.Passport.Country")}{IsValid.CountryRequired ? "*" : ""}</label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <CountrySelect
                                                Value={Data.country}
                                                Onchange={OnPassportCountryUpdate}
                                                Valid={IsValid.Country}
                                                ID={`country`}
                                                Required={IsValid.CountryRequired}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">{t("FareBooking.APISPage.Passport.ExpiryDate")}{IsValid.ExpiryDateRequired ? "*" : ""}</label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <DateInput
                                                OnDateChange={(date: Date | undefined) => OnPassportExpiryDateUpdate(date)}
                                                Value={Data.expiryDate ? moment({ day: Data.expiryDate.day, month: Data.expiryDate.month - 1, year: Data.expiryDate.year }).toDate() : undefined}
                                                InputGroup={false}
                                                MaxDate={moment().add(30, "years").toDate()}
                                                IsDob={false}
                                                MinDate={moment().toDate()}
                                                InputClassNames={`form-control ${GetValidClassName(IsValid.ExpiryDateRequired, IsValid.ExpiryDate)}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">{t("FareBooking.APISPage.Passport.IssueDate")}{IsValid.IssueDateRequired ? "*" : ""}</label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <DateInput
                                                OnDateChange={(date: Date | undefined) => OnPassportIssueDateUpdate(date)}
                                                Value={Data.issueDate ? moment({ day: Data.issueDate.day, month: Data.issueDate.month - 1, year: Data.issueDate.year }).toDate() : undefined}
                                                InputGroup={false}
                                                IsDob={false}
                                                MaxDate={moment().add(5, "years").toDate()}
                                                MinDate={moment().subtract(50, "years").toDate()}
                                                InputClassNames={`form-control  ${GetValidClassName(IsValid.IssueDateRequired, IsValid.IssueDate)}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">{t("FareBooking.APISPage.Passport.BirthPlace")}{IsValid.PlaceOfBirthRequired ? "*" : ""}</label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <input value={Data.placeOfBirth ? Data.placeOfBirth : ""} onChange={(e) => OnPlaceOfbirthUpdate(e.target.value)} type="text" className={`form-control  ${GetValidClassName(IsValid.PlaceOfBirthRequired, IsValid.PlaceOfBirth)}`} />
                                        </div>
                                    </div>
                                    <div className="form-check mb-2" onClick={() => TogglePassportHolder()}>
                                        <input checked={Data.holder} onChange={() => { }} type="checkbox" className="form-check-input" />
                                        <label className="form-check-label">{t("FareBooking.APISPage.Passport.Holder")}</label>
                                    </div>
                                </React.Fragment>
                            }
                        </Fragment>
                    }
                    {!Data &&
                        <button className="btn btn-primary" onClick={OnAddPassport}>{t("FareBooking.APISPage.Passport.AddPassport")}</button>
                    }
                </div>
            </div>

        </React.Fragment>

    );
}

export default BookingPassengerApisPassport;

