import { faMedium } from "@fortawesome/free-brands-svg-icons";
import {
  faChalkboardTeacher,
  faCircle,
  faCoffee,
  faCookieBite,
  faCouch,
  faExchange,
  faFastForward,
  faGavel,
  faGlassMartini,
  faInfoCircle,
  faMapMarkerQuestion,
  faMapSigns,
  faMoneyCheckEditAlt,
  faPaw,
  faPlug,
  faRandom,
  faRedo,
  faShoppingBag,
  faSuitcase,
  faTachometerFastest,
  faTicketAlt,
  faTv,
  faUndoAlt,
  faUserCheck,
  faUtensils,
  faWifi,
  IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import _ from "lodash";
import {
  IncludedServiceData,
  IncludedServiceMainType,
  IncludedServiceSubType,
} from "WebApiClient";

interface IncludedServiceIconProps {
  data: any;
  incSerFull?: any;
  toShow?: boolean;
  setIsWarning: (isWarning: boolean) => void;
}
export const Warning: React.FC<IncludedServiceIconProps> = (props) => {
  var mainGroup: any = props.data;
  const uniqueServices = _.uniqBy(mainGroup.services, "hint");

  const resultObject = { ...mainGroup, services: uniqueServices };
  function toTitleCase(str: string) {
    props.setIsWarning(true);
    // Remove instances of "WARNING :"
    str = str.replace(/\bWARNING\s*:\s*/g, "");

    // Apply title case to the remaining string
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function warningOn(){
    props.setIsWarning(false);
    return null
  }

  return (
    <React.Fragment>
      <span>
        {props.toShow &&
          resultObject.services!.map((sub: any, Index: any) => (
            <span>
              {typeof sub.hint === "string" && sub.hint.includes("WARNING:")
                ? toTitleCase(sub.hint)
                : warningOn()}
            </span>
          ))}
      </span>
    </React.Fragment>
  );
};

function GetIcon(data: IncludedServiceData): IconDefinition {
  let iconClass: IconDefinition = faInfoCircle;

  switch (data.subType) {
    case IncludedServiceSubType.FreeBaggageAllowance:
      iconClass = faSuitcase;
      break;
    case IncludedServiceSubType.CarryOnBaggage:
      iconClass = faShoppingBag;
      break;
    case IncludedServiceSubType.Lunch:
      iconClass = faUtensils;
      break;
    case IncludedServiceSubType.Snack:
      iconClass = faCookieBite;
      break;
    case IncludedServiceSubType.SnacksAndBeverage:
      iconClass = faUtensils;
      break;
    case IncludedServiceSubType.NonAlcoholicDrink:
      iconClass = faCoffee;
      break;
    case IncludedServiceSubType.FreeAlcoholicDrink:
      iconClass = faGlassMartini;
      break;
    // INFLIGHT ENTERTAINMENT
    case IncludedServiceSubType.PersonalEntertainment:
      iconClass = faTv;
      break;
    case IncludedServiceSubType.InternetAccess:
      iconClass = faWifi;
      break;
    case IncludedServiceSubType.InSeatpower:
      iconClass = faPlug;

      break;
    case IncludedServiceSubType.Pet:
      iconClass = faPaw;

      break;
    case IncludedServiceSubType.Security:
      iconClass = faGavel;

      break;
    case IncludedServiceSubType.PriorityBoarding:
      iconClass = faFastForward;

      break;
    case IncludedServiceSubType.Ticket:
      iconClass = faTicketAlt;

      break;
    case IncludedServiceSubType.CheckIn:
      iconClass = faChalkboardTeacher;

      break;
    case IncludedServiceSubType.ReBooking:
      iconClass = faRedo;
      break;
    case IncludedServiceSubType.RefundAllowed:
      iconClass = faMoneyCheckEditAlt;
      break;
    case IncludedServiceSubType.PriorityCheckIn:
      iconClass = faUserCheck;
      break;
    case IncludedServiceSubType.FastTrack:
      iconClass = faTachometerFastest;
      break;
    case IncludedServiceSubType.ChangeAfterDeparture:
      iconClass = faUndoAlt;
      break;
    case IncludedServiceSubType.ChangeBeforeDeparture:
      iconClass = faUndoAlt;
      break;
    case IncludedServiceSubType.ChangeInCaseOfNoShow:
      iconClass = faMapMarkerQuestion;
      break;
    case IncludedServiceSubType.OpenReturnDate:
      iconClass = faExchange;
      break;
    case IncludedServiceSubType.Stopover:
      iconClass = faMapSigns;
      break;
    default:
      switch (data.type) {
        case IncludedServiceMainType.Baggage:
          iconClass = faSuitcase;
          break;
        case IncludedServiceMainType.Meal:
          iconClass = faUtensils;
          break;
        case IncludedServiceMainType.InFlightEntertainment:
          iconClass = faTv;
          break;
        case IncludedServiceMainType.Miles:
          iconClass = faMedium;
          break;
        case IncludedServiceMainType.Transfer:
          iconClass = faRandom;

          break;
        case IncludedServiceMainType.Seat:
          iconClass = faCouch;

          break;
        case IncludedServiceMainType.Other:
          iconClass = faCircle;
          break;
      }
  }
  return iconClass;
}
export default Warning;
