import React from "react";

interface DateFormatProps {
  dates: string;
}

const DateFormat: React.FC<DateFormatProps> = ({ dates }) => {
  const formatDate = (dates: string): string => {
    if (!dates) return ""; // Return empty string if dates is null or undefined
    const [day, month, year] = dates.split(".");
    const monthAbbreviation = new Date(
      `${year}-${month}-${day}`
    ).toLocaleString("default", { month: "short" });

    const formattedDate = `${day} ${monthAbbreviation} ${year}`;
    return formattedDate;
  };

  const formattedDate = formatDate(dates);
  return <span>{formattedDate}</span>;
};

export default DateFormat;
