import { faAddressCard } from "@fortawesome/pro-light-svg-icons";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountrySelect from "components/Shared/CountrySelect";
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import React, { Fragment } from "react";
import { AgentApisAdressType } from "WebApiClient";
import { FareBookingPassengerApisAddress } from "../../../WebApiClient";
import { ApisAddressValidResult } from "./APISInput.Validate.Adress";
import { useTranslation } from "react-i18next";
export interface BookingPassengerApisAddressProps {
    Data: FareBookingPassengerApisAddress | undefined;
    IsValid: ApisAddressValidResult;
    OnUpdatePassenger: (
        data: FareBookingPassengerApisAddress | undefined
    ) => void;
}

const BookingPassengerApisAddress: React.FC<
    BookingPassengerApisAddressProps
> = ({ Data, IsValid, OnUpdatePassenger }) => {
    const { t } = useTranslation();
    function OnAddAddress() {
        let d: FareBookingPassengerApisAddress = {
            city: "",
            country: "",
            municipality: "",
            state: "",
            street: "",
            type: AgentApisAdressType.Residence,
            zip: "",
        };
        OnUpdatePassenger(d);
    }
    function OnRemoveAddress() {
        OnUpdatePassenger(undefined);
    }

    function OnStreetUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.street = value;
            OnUpdatePassenger(data);
        }
    }

    function OnZIPUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.zip = value;
            OnUpdatePassenger(data);
        }
    }

    function OnCityUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.city = value;
            OnUpdatePassenger(data);
        }
    }
    function OnStateUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.state = value;
            OnUpdatePassenger(data);
        }
    }

    function OnMunicipalityUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.municipality = value;
            OnUpdatePassenger(data);
        }
    }
    function OnCountryUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.country = value;
            OnUpdatePassenger(data);
        }
    }
    function OnTypeUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.type = parseInt(value);
            OnUpdatePassenger(data);
        }
    }

    function GetValidClassName(required: boolean, isValid: boolean): string {
        let result = "";
        if (required) {
            result = isValid ? "is-valid" : "is-invalid";
        }
        return result;
    }

    return (
        <React.Fragment>
            <div className="card mb-2">
                <div className="card-body">
                    <h5 className="card-title">
                        <ValidIndicatorIcon
                            IsValid={IsValid.Valid}
                            Icon={faAddressCard}
                            NoValueSet={Data === undefined}
                        />{" "}
                        {t("FareBooking.APISPage.Address.AddressTitle")}
                        {Data && (
                            <button
                                className="btn btn-outline-danger btn-sm"
                                style={{marginLeft:'6px'}}
                                onClick={OnRemoveAddress}
                            >
                                <FontAwesomeIcon
                                    icon={faTrash}
                                ></FontAwesomeIcon>
                            </button>
                        )}
                    </h5>
                    {Data && (
                        <Fragment>
                            <div className="row align-items-center mb-2">
                                <label className="col-sm-4 col-md-4 col-lg-4">
                                    {t(
                                        "FareBooking.APISPage.Address.Type.TypeTitle"
                                    )}
                                    {IsValid.AddressRequired ? "*" : ""}
                                </label>
                                <div className="col-sm-8 col-md-8 col-lg-8">
                                    <select
                                        value={Data.type}
                                        className={`form-select ${GetValidClassName(
                                            IsValid.AddressRequired,
                                            IsValid.Type
                                        )}`}
                                        onChange={(e) =>
                                            OnTypeUpdate(e.target.value)
                                        }
                                    >
                                        <option
                                            value={AgentApisAdressType.Unknown}
                                        >
                                            {t(
                                                "FareBooking.APISPage.Address.Type.TypeOption1"
                                            )}
                                        </option>
                                        <option
                                            value={
                                                AgentApisAdressType.Residence
                                            }
                                        >
                                            {t(
                                                "FareBooking.APISPage.Address.Type.TypeOption2"
                                            )}
                                        </option>
                                        <option
                                            value={
                                                AgentApisAdressType.Destination
                                            }
                                        >
                                            {t(
                                                "FareBooking.APISPage.Address.Type.TypeOption3"
                                            )}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            {Data.type !== AgentApisAdressType.Unknown && (
                                <React.Fragment>
                                    {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - STREET - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">
                                            {t(
                                                "FareBooking.APISPage.Address.Street"
                                            )}
                                            {IsValid.AddressRequired ? "*" : ""}
                                        </label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <input
                                                value={
                                                    Data.street
                                                        ? Data.street
                                                        : ""
                                                }
                                                onChange={(e) =>
                                                    OnStreetUpdate(
                                                        e.target.value
                                                    )
                                                }
                                                type="text"
                                                className={`form-control   ${GetValidClassName(
                                                    IsValid.AddressRequired,
                                                    IsValid.Street
                                                )}`}
                                            />
                                        </div>
                                    </div>
                                    {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - // STREET - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

                                    {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - ZIP - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">
                                            {t(
                                                "FareBooking.APISPage.Address.ZipCode"
                                            )}
                                            {IsValid.AddressRequired ? "*" : ""}
                                        </label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <input
                                                value={Data.zip ? Data.zip : ""}
                                                onChange={(e) =>
                                                    OnZIPUpdate(e.target.value)
                                                }
                                                type="text"
                                                className={`form-control   ${GetValidClassName(
                                                    IsValid.AddressRequired,
                                                    IsValid.ZipCode
                                                )}`}
                                            />
                                        </div>
                                    </div>
                                    {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - // ZIP - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

                                    {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - CITY - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">
                                            {t(
                                                "FareBooking.APISPage.Address.City"
                                            )}
                                            {IsValid.AddressRequired ? "*" : ""}
                                        </label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <input
                                                value={
                                                    Data.city ? Data.city : ""
                                                }
                                                onChange={(e) =>
                                                    OnCityUpdate(e.target.value)
                                                }
                                                type="text"
                                                className={`form-control   ${GetValidClassName(
                                                    IsValid.AddressRequired,
                                                    IsValid.City
                                                )}`}
                                            />
                                        </div>
                                    </div>
                                    {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - // CITY - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

                                    {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - MUNICIPALITY- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">
                                            {t(
                                                "FareBooking.APISPage.Address.Municipality"
                                            )}
                                            {IsValid.MunicipalityRequired
                                                ? "*"
                                                : ""}
                                        </label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <input
                                                value={
                                                    Data.municipality
                                                        ? Data.municipality
                                                        : ""
                                                }
                                                onChange={(e) =>
                                                    OnMunicipalityUpdate(
                                                        e.target.value
                                                    )
                                                }
                                                type="text"
                                                className={`form-control  ${GetValidClassName(
                                                    IsValid.MunicipalityRequired,
                                                    IsValid.Municipality
                                                )}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">
                                            {t(
                                                "FareBooking.APISPage.Address.State"
                                            )}
                                            {IsValid.AddressRequired ? "*" : ""}
                                        </label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <input
                                                value={
                                                    Data.state ? Data.state : ""
                                                }
                                                onChange={(e) =>
                                                    OnStateUpdate(
                                                        e.target.value
                                                    )
                                                }
                                                type="text"
                                                className={`form-control  ${GetValidClassName(
                                                    IsValid.AddressRequired,
                                                    IsValid.State
                                                )}`}
                                            />
                                        </div>
                                    </div>
                                    {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - // MUNICIPALITY- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}

                                    {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - COUNTRY- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">
                                            {t(
                                                "FareBooking.APISPage.Address.Country"
                                            )}
                                            {IsValid.AddressRequired ? "*" : ""}
                                        </label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <CountrySelect
                                                Value={Data.country}
                                                Onchange={OnCountryUpdate}
                                                Valid={IsValid.Country}
                                                ID={`addressc`}
                                                Required={
                                                    IsValid.AddressRequired
                                                }
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </Fragment>
                    )}
                    {!Data && (
                        <button
                            className="btn btn-primary"
                            onClick={OnAddAddress}
                        >
                            {t("FareBooking.APISPage.Address.AddAddress")}
                        </button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default BookingPassengerApisAddress;
