import { createSlice } from '@reduxjs/toolkit'

const initialState = { value: false }
export const segmentBoolSlice = createSlice({
    name: 'segmentBool',
    initialState,
    reducers: {
        segmentBool_Reducer(state, action) {
            state.value = action.payload
        },
    },
})

export const { segmentBool_Reducer } = segmentBoolSlice.actions