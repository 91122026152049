import React, { useState, useRef, useEffect } from "react";
import AvailableHotel from "./AvailableHotel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HotelMap } from "components/BookingHotel/component/HotelsMap";
import {
  faDollar,
  faLocationDot,
  faStar,
} from "@fortawesome/pro-regular-svg-icons";
import { useAvailableHotelsClient } from "hooks/useHttpClient";

interface HotelData {
  hitchHiker: any;
  id: number;
  name: string;
  description: string;
}

interface InfiniteScroll {
  allHotelIds: any;
  hotelResult: any;
  validHotelRes: any;
  activeTab: any;
  setSelectedHotel: any;
  hotelValidIds: any;
  searchData: any;
  skipHotelCount: any;
  setSkipHotelCount: any;
  setIsSortOn: any;
  isSortOn: boolean;
}

interface FormData {
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  gender: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  numberOfGuests: string;
}

interface customerInfo {
  firstName: string;
  middleName: string;
  lastName: string;
  title: string;
  gender: string;
  dateOfBirth: string;
  email: string;
  phoneNumber: string;
  numberOfGuests: string;
}

const InfiniteScroll: React.FC<InfiniteScroll> = (props) => {
  const Hotel = useAvailableHotelsClient();
  const [hotels, setHotels] = useState<any>(props.hotelResult || []);
  const [allHotelIds, setAllHotelIds] = useState<any[]>([]);
  const [allValidHotelIds, setValidHotelIds] = useState<[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [ischeckBool, setischeckBool] = useState<boolean>(false);
  const [activeSort, setActiveSort] = useState("");
  const [isLastIndex, setIsLastIndex] = useState<boolean>(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [likeHotels, setLikeHotels] = useState<any>([]);
  const [isMapSectionOpen, setIsMapSectionOpen] = useState(false);

  const [currentIndex, setCurrentIndex] = useState<number>(
    props.hotelValidIds.length
  );
  const [customerInfo, setCustomerInfo] = useState<customerInfo>({
    firstName: "",
    middleName: "",
    lastName: "",
    title: "",
    gender: "Male",
    dateOfBirth: "",
    email: "",
    phoneNumber: "",
    numberOfGuests: "",
  });
  const loadMoreRef = useRef<HTMLDivElement>(null);
  // * Hotel Id convert in to number ----
  const hotel_ids: string[] = props.allHotelIds.map((item: any) =>
    Number(item.hotelId)
  );
  // * End------

  // * Toggle Hotel Details Hide Show----
  const handleItemClick = (index: any) => {
    setOpenIndex(index === openIndex ? null : index);
  };
  // * End -----
  // * Load next 50 Hotels -------
  const loadHotels = async (newFiftyIds: any) => {
    setIsLoading(true);
    try {
      if (currentIndex < hotel_ids.length) {
        await fetchHotelsData(newFiftyIds);
      } else {
        setIsLastIndex(true);
      }
    } catch (error) {
      console.error("Error fetching hotels:", error);
      props.setSkipHotelCount(props.skipHotelCount + 25);
    } finally {
      setIsLoading(false); // Make sure isLoading is set to false even in case of an error.
    }
  };
  // * End ----
  // * API's For Fetch Hotel Data
  async function fetchHotelsData(hotel_ids: any) {
    await Hotel.GetRatesAsync(
      props.searchData.roomGuests.adults,
      props.searchData.roomGuests.children,
      props.searchData.checkIn,
      props.searchData.roomGuests.numRooms,
      props.searchData.nights,
      hotel_ids
    ).then(async (validHotel: any) => {
      if (validHotel.results !== null && validHotel.results.length !== 0) {
        const hotelId = validHotel?.results.map(
          (data: any) => data?.items[0].hotelId
        );
        const uniqueArray = Array.from(new Set(hotelId));
        // const filteredTripAdvisorIds = uniqueArray.filter((item: any) => !hotel_ids.includes(item.toString()));
        const hotel_idsSet = new Set(hotel_ids);
        const filteredTripAdvisorIds = uniqueArray.filter(
          (item: any) => !hotel_idsSet.has(item)
        );
        props.setSkipHotelCount(
          props.skipHotelCount + filteredTripAdvisorIds.length
        );
        const hotelData = await Hotel.GetHotelsAsync(uniqueArray);
        const HotelData = hotelData.map((hotel: any) => {
          const matchingItem = validHotel?.results.filter(
            (item: any) => hotel.tripAdvisor.id === item.items[0].hotelId
          );
          return { ...hotel, PriceData: matchingItem };
        });
        setHotels((prevHotels: any) => [...prevHotels, ...HotelData]);
      } else {
        props.setSkipHotelCount(props.skipHotelCount + 25);
      }
    });
    setIsLoading(false);
  }
  // * End ---
  const handleObserver: IntersectionObserverCallback = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  // *get next fifty values in hotel ids -------
  const getNextFiftyValues = (currentIndex: number): number[] => {
    const nextFiftyValues = allHotelIds.slice(currentIndex, currentIndex + 25);
    return nextFiftyValues;
  };
  //* End-----

  // End Infinite scroll
  //* useEffects start
  useEffect(() => {
    setAllHotelIds(hotel_ids);
    setIsLastIndex(false);
  }, [hotel_ids.length]);

  useEffect(() => {
    setValidHotelIds(props.hotelValidIds);
    setHotels(props.hotelResult);
  }, [props.hotelResult?.length]);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(handleObserver, options);
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, []);

  // useEffect(() => {
  //   if (props.selectedOption == "price") {
  //     const sortedHotels = sortHotelsByMinimumPrice(hotels);
  //     console.log("Price", sortedHotels);
  //     setHotels(sortedHotels);
  //   } else {
  //     const sortedHotels = sortHotelsByMinimumRating(hotels);
  //     // console.log("rating", sortedHotels);
  //     setHotels(sortedHotels);
  //   }
  // }, [props.selectedOption]);

  useEffect(() => {
    if (pageNumber !== 1) {
      if (currentIndex < hotel_ids.length) {
        const newFiftyIds = getNextFiftyValues(currentIndex);
        loadHotels(newFiftyIds);
        setCurrentIndex(currentIndex + 25);
      } else {
        setIsLastIndex(true);
      }
    }
  }, [pageNumber]);
  useEffect(() => {
    setCurrentIndex(props.hotelValidIds.length);
  }, [props.hotelValidIds.length, props.hotelValidIds[1]]);
  //* end useEffect

  //* star rating
  const filledStar = (
    <span role="img" aria-label="star" className="star-icon star">
      ★
    </span>
  );
  const emptyStar = (
    <span role="img" aria-label="star" className="star-icon star">
      ☆
    </span>
  );
  const starIcons = [];
  for (let i = 1; i <= 5; i++) {
    starIcons.push(i <= 2 ? filledStar : emptyStar);
  }

  //remove duplicate obj
  function removeDuplicateObjects(arr: any) {
    const uniqueObjects = new Set();
    const resultArray = [];

    for (const obj of arr) {
      // Convert the object to a string for easy comparison
      const objString = JSON.stringify(obj);

      if (!uniqueObjects.has(objString)) {
        uniqueObjects.add(objString);
        resultArray.push(obj);
      }
    }
    return resultArray;
  }

  //*check present fields
  function areArraysPresent(set1: any, set2: any) {
    return set1.every((obj1: any) =>
      set2.some((obj2: any) => objectsEqual(obj1, obj2))
    );
  }

  function objectsEqual(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  //* Custom sorting function
  function customSort(item1: any, item2: any) {
    if (item1.liked && !item2.liked) {
      return -1; // item1 comes before item2
    } else if (!item1.liked && item2.liked) {
      return 1; // item2 comes before item1
    } else {
      return 0; // no change in order
    }
  }

  //*assign liked fields
  function assignLiked(data: any) {
    data.forEach((obj: any) => {
      if (obj.liked) {
        const guid = obj.hitchHiker.guid;

        // Step 2: Find the object with the same guid but without "liked"
        const otherObj = data.find(
          (other: any) => other.hitchHiker.guid === guid && !other.liked
        );
        if (otherObj) {
          otherObj.display = "off";
        }
      }
    });

    return data;
  }

  const handleClick = (sortType: string) => {
    //* Handle the click event based on the sortType
    setActiveSort(sortType);
    if (sortType == "Cheapest") {
      const sortedHotels = sortHotelsByMinimumPrice([...hotels]);
      if (areArraysPresent(likeHotels, sortedHotels)) {
        setHotels(
          assignLiked(removeDuplicateObjects(sortedHotels.sort(customSort)))
        );
      } else if (!areArraysPresent(likeHotels, sortedHotels)) {
        setHotels(
          removeDuplicateObjects(
            assignLiked([...likeHotels, ...sortedHotels].sort(customSort))
          )
        );
      }
      // setHotels(sortedHotels);
    } else if (sortType === "Rating") {
      const sortedRatingHotels = sortHotelsByMinimumRating([...hotels]);
      // setHotels(sortedRatingHotels);
      if (areArraysPresent(likeHotels, sortedRatingHotels)) {
        setHotels(
          removeDuplicateObjects(
            assignLiked(sortedRatingHotels.sort(customSort))
          )
        );
      }
      if (!areArraysPresent(likeHotels, sortedRatingHotels)) {
        setHotels(
          removeDuplicateObjects(
            assignLiked([...likeHotels, ...sortedRatingHotels].sort(customSort))
          )
        );
      }
    }
  };

  const OpenMap = () => {
    setIsMapSectionOpen(!isMapSectionOpen);
  };
  //* star rating end

  //* Remove Hotel Function ------
  const handleRemoveHotel = (indexToRemove: number) => {
    setHotels((prevHotels: any) =>
      prevHotels.filter((_: any, index: number) => index !== indexToRemove)
    );
  };
  //* Remove Hotel Function End -----

  // * Sort Hotel By Price --------
  const getMinimumPrice = (priceData: {
    price: { amount: number; currency: string };
  }) => {
    return priceData.price.amount;
  };
  const sortHotelsByMinimumPrice = (hotelList: any) => {
    props.setIsSortOn(false);
    return [...hotelList].sort((a: any, b: any) => {
      const minPriceA = getMinimumPrice(a.PriceData[0] || {});
      const minPriceB = getMinimumPrice(b.PriceData[0] || {});
      return minPriceA - minPriceB;
    });
  };
  // * Sort Hotel By Price End--------

  // * Sort Hotel By Rating --------
  const sortHotelsByMinimumRating = (hotelList: any) => {
    const sortedRatingHotels = [...hotelList].sort((a, b) => {
      const ratingA = a.tripAdvisor?.tripAdvisorData?.rating || 0; // Default to 0 if rating is missing
      const ratingB = b.tripAdvisor?.tripAdvisorData?.rating || 0; // Default to 0 if rating is missing
      if (ratingA !== ratingB) {
        // If ratings are not the same, sort by rating
        return ratingB - ratingA;
      } else {
        // If ratings are the same, sort by minimum price
        const minPriceA = getMinimumPrice(a.PriceData[0] || {});
        const minPriceB = getMinimumPrice(b.PriceData[0] || {});
        return minPriceA - minPriceB;
      }
    });
    props.setIsSortOn(false);
    return sortedRatingHotels;
  };
  // * Sort Hotel By Price End--------
  // * Add Like Hotels ----
  const addLikeHotel = (likedHotel: any) => {
    // Check if the new object is already present in the array
    let index = likeHotels.findIndex(
      (obj: any) => obj.hitchHiker.guid === likedHotel.hitchHiker.guid
    );
    // let letIndexHotel = hotels.findIndex((obj:any) => obj.hitchHiker.guid === likedHotel.hitchHiker.guid)
    if (index === -1) {
      // If the object is not present, add it to the array
      setLikeHotels(likeHotels.push({ ...likedHotel, liked: true }));
    } else {
      // If the object is present, remove it from the array
      likeHotels.splice(index, 1);

      if (hotels.find((obj: any) => obj.liked !== likedHotel.liked)) {
        // Find the index of the object with "liked: true"
        const likedIndex = hotels.findIndex(
          (obj: any) =>
            obj.liked == likedHotel.liked &&
            obj.hitchHiker.guid == likedHotel.hitchHiker.guid
        );
        if (likedIndex !== -1) {
          const likedGuid = hotels[likedIndex].hitchHiker.guid;

          hotels.splice(likedIndex, 1);

          // Find the index of the object with the same "guid" value
          const sameGuidIndex = hotels.findIndex(
            (item: any) => item.hitchHiker.guid === likedGuid
          );
          if (sameGuidIndex !== -1) {
            // Remove the "display" field from the object with the same "guid" value
            delete hotels[sameGuidIndex].display;
          }
        }
        //  (hotels.filter((obj:any) => !(obj.liked == likedHotel.liked && obj.hitchHiker.guid == likedHotel.hitchHiker.guid) ))
      }

      setHotels(hotels);
      setischeckBool((prev) => !prev);
    }
    setLikeHotels(likeHotels);
  };

  useEffect(() => {
    if (activeSort === "Cheapest") {
      const sortedHotels = sortHotelsByMinimumPrice([...hotels]);
      if (areArraysPresent(likeHotels, sortedHotels)) {
        setHotels(
          removeDuplicateObjects(assignLiked(sortedHotels.sort(customSort)))
        );
      } else if (!areArraysPresent(likeHotels, sortedHotels)) {
        setHotels(
          assignLiked(
            removeDuplicateObjects(
              [...likeHotels, ...sortedHotels].sort(customSort)
            )
          )
        );
      }
    } else if (activeSort === "Rating") {
      const sortedRatingHotels = sortHotelsByMinimumRating([...hotels]);
      // setHotels(sortedRatingHotels);
      if (areArraysPresent(likeHotels, sortedRatingHotels)) {
        setHotels(
          removeDuplicateObjects(
            assignLiked(sortedRatingHotels.sort(customSort))
          )
        );
      }
      if (!areArraysPresent(likeHotels, sortedRatingHotels)) {
        setHotels(
          removeDuplicateObjects(
            assignLiked([...likeHotels, ...sortedRatingHotels].sort(customSort))
          )
        );
      }
    } else if (props.isSortOn) {
      const sortedHotels = sortHotelsByMinimumPrice([...hotels]);
      if (areArraysPresent(likeHotels, sortedHotels)) {
        setHotels(
          removeDuplicateObjects(assignLiked(sortedHotels.sort(customSort)))
        );
      } else if (!areArraysPresent(likeHotels, sortedHotels)) {
        setHotels(
          removeDuplicateObjects(
            assignLiked([...likeHotels, ...sortedHotels].sort(customSort))
          )
        );
      }
      setActiveSort("Cheapest");
    }
  }, [props.isSortOn == true]);

  // *End---
  useEffect(() => {
    setHotels(hotels);
  }, [hotels.length, ischeckBool]);

  return (
    <div>
      {hotels?.length ? (
        <section className="d-flex w-100 HotelList">
          <div
            className={`sortBox d-flex justify-content-between ${
              activeSort === "Cheapest" ? "activeCard" : ""
            }`}
            onClick={() => handleClick("Cheapest")}
          >
            <div className="w-100">
              <h3>Cheapest</h3>
              <h5>
                {
                  sortHotelsByMinimumPrice(hotels)[0]?.PriceData[0]?.price
                    ?.currency
                }
                &nbsp;
                {
                  sortHotelsByMinimumPrice(hotels)[0]?.PriceData[0]?.price
                    ?.amount
                }
              </h5>
              {starIcons}
            </div>
            <div className="responsive-icon text-end">
              <FontAwesomeIcon
                style={{ height: "100%", opacity: "0.2" }}
                icon={faDollar}
              />
            </div>
          </div>
          <div
            className={`sortBox d-flex justify-content-between hotelMap`}
            onClick={() => OpenMap()}
          >
            <div className="w-100">
              <h3>Map</h3>
              {/* <h5>$ 300.45</h5>
              {starIcons} */}
            </div>
            {/* <div className="responsive-icon text-end">
              <FontAwesomeIcon
                style={{ height: "100%", opacity: "0.2" }}
                icon={faLocationDot}
              />
            </div> */}
          </div>
          <div
            className={`sortBox d-flex justify-content-between ${
              activeSort === "Rating" ? "activeCard" : ""
            }`}
            onClick={() => handleClick("Rating")}
          >
            <div className="responsive-icon">
              <FontAwesomeIcon
                style={{ height: "100%", opacity: "0.2" }}
                icon={faStar}
              />
            </div>
            <div className="w-100 text-end">
              <h3>Rating</h3>
              {/* <h5>$ 300.45</h5> */}
              <h5>
                {
                  sortHotelsByMinimumRating(hotels)[0]?.PriceData[0]?.price
                    ?.currency
                }{" "}
                &nbsp;
                {
                  sortHotelsByMinimumRating(hotels)[0]?.PriceData[0]?.price
                    ?.amount
                }
              </h5>
              {starIcons}
            </div>
          </div>
        </section>
      ) : null}
      {isMapSectionOpen && (
        <section>
          <HotelMap hotelData={props.allHotelIds} />
        </section>
      )}

      {hotels?.map((hotel: any, index: number) => (
        <AvailableHotel
          key={index + "iiii" + "-" + hotel.hitchHiker?.guid}
          handleItemClick={handleItemClick}
          openIndex={openIndex}
          setOpenIndex={setOpenIndex}
          indexH={index}
          result={hotel}
          activeTab={props.activeTab}
          setSelectedHotel={props.setSelectedHotel}
          HotelSearch={props.searchData}
          onRemove={() => handleRemoveHotel(index)}
          addLikeHotel={addLikeHotel}
          setCustomerInfo={setCustomerInfo}
          customerInfo={customerInfo}
        />
      ))}

      <div ref={loadMoreRef} className="topSpace">
        {/* {isLastIndex ? null : <div>
                </div>} */}
        <div
          style={{ cursor: `${isLastIndex || isLoading ? "" : "pointer"}` }}
          className="hotelStatusBar d-flex justify-content-between mb-2 py-3 px-3"
          onClick={() => {
            if (!isLastIndex || isLoading) {
              loadHotels(pageNumber);
            }
          }}
        >
          <div className="w-25">
            <span style={{ fontWeight: "bold" }}>{hotels.length}</span>
            <span style={{ fontWeight: "bold" }}>/</span>
            <span style={{ fontWeight: "bold" }}>{hotel_ids.length}</span>
            {props.skipHotelCount.length && (
              <span style={{ fontSize: "12px" }}>
                {" "}
                ( {props.skipHotelCount} Hotels unavailable)
              </span>
            )}
          </div>
          <div className="hotelStatus text-center w-50">
            {isLoading ? (
              <span> Loading... </span>
            ) : isLastIndex ? null : (
              <span>Scroll/ Click to load more</span>
            )}
          </div>
          <div className="w-25"></div>
        </div>
      </div>
    </div>
  );
};

export default InfiniteScroll;
