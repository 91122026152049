import React from "react";
import { BookingStatus } from "WebApiClient";
export function GetBookingStatusDisplay(Status: BookingStatus): string {
    let result = "";
    switch (Status) {
        case BookingStatus.All:
            result = "All";
            break;
        case BookingStatus.Booked:
            result = "Booked";
            break;
        case BookingStatus.BookingError:
            result = "Booking error";
            break;
        case BookingStatus.CancelError:
            result = "Cancel error";
            break;
        case BookingStatus.Canceled:
            result = "Cancelled";
            break;
        case BookingStatus.NotProcessed:
            result = "Not processed";
            break;
        case BookingStatus.Queued:
            result = "Queued";
            break;
        case BookingStatus.TicketIssued:
            result = "Ticket issued";
            break;
        case BookingStatus.TicketedError:
            result = "Ticketing error";
            break;
        case BookingStatus.TicketingInProgress:
            result = "Ticketing in progress";
            break;
        case BookingStatus.Unknown:
            result = "Unknown";
            break;
        case BookingStatus.TicketCancelError:
            result = "Ticket cancel error";
            break;
        case BookingStatus.TicketCanceled:
            result = "Ticket cancelled";
            break;
        case BookingStatus.OrderReshop:
            result = "Order reshop";
            break;
        case BookingStatus.OrderReshopFailed:
            result = "Order reshop (failed)";
            break;
    }
    return result;
}
export const BookingStatusLabel: React.FC<{ Status: BookingStatus }> = ({ Status }) => {
    return <span>{GetBookingStatusDisplay(Status)}</span>

}