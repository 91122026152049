import { useLocalizedPrice } from "hooks/useLocalization";
import React, { Fragment, useRef, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { SharedPriceModel, SurchargeDataModel } from "WebApiClient";
import { useTranslation } from "react-i18next";
import { currencyFormatter } from "./Helpers/CurrencyConverter";
import { LocalizationConverter } from "./Helpers/LocalizationConverter";
export const PriceDisplay: React.FC<{
  price: number;
  currency: any;
  multiplier?: number | undefined;
}> = (props) => {
  const { price, currency } = props;
  const localizedPrice = useLocalizedPrice(price, currency, props.multiplier);
  // return <span>{currency} {new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency, currencyDisplay: "none" }).format(price)}</span>
  return <span>{localizedPrice}</span>;
};

export const SharedPriceDisplay: React.FC<{
  price: SharedPriceModel | undefined;
  multiplier?: number;
}> = (props) => {
  const price = props.price;

  if (price && price.currencyCode.length > 0 && price.valueSpecified) {
    return (
      <SharedPriceValidDisplay price={price} multiplier={props.multiplier} />
    );
  } else {
    return <span></span>;
  }
};
export const SurchargeDisplay: React.FC<{
  price: SurchargeDataModel | undefined;
}> = (props) => {
  const price = props.price;
  if (price) {
    return <SurchargeValidDisplay price={price} />;
  } else {
    return <span></span>;
  }
};
const SharedPriceValidDisplay: React.FC<{
  price: SharedPriceModel;
  multiplier?: number;
}> = (props) => {
  const { t, i18n } = useTranslation();
  const { currencyCode, isIATA, isIATASpecified, value, valueSpecified, tax } =
    props.price;
  const localizedSinglePrice = useLocalizedPrice(
    value - (tax != undefined ? tax : 0),
    currencyCode,
    undefined
  );

  const localizedTotalPrice = useLocalizedPrice(
    value,
    currencyCode,
    props.multiplier
  );

  const elemRef = useRef<HTMLSpanElement>(null);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  // return <span>{currency} {new Intl.NumberFormat('de-DE', { style: 'currency', currency: currency, currencyDisplay: "none" }).format(price)}</span>

  return (
    <Fragment>
      <Popover target={elemRef} isOpen={open} toggle={toggle}>
        <PopoverBody>
          <b>Price details</b> <br />
          Fare:{localizedSinglePrice} <br />
          {props.multiplier !== undefined && (
            <Fragment>
              Amount: {props.multiplier.toString()} <br></br>
              Price (Total): {localizedTotalPrice}
            </Fragment>
          )}
          {tax && (
            <Fragment>
              Tax: {LocalizationConverter(tax, i18n.language)}{currencyFormatter(currencyCode)}
            </Fragment>
          )}
        </PopoverBody>
      </Popover>
      <span
        ref={elemRef}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        onTouchStart={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        onTouchEnd={() => setOpen(false)}
      >
        {localizedTotalPrice}
      </span>
    </Fragment>
  );
};
const SurchargeValidDisplay: React.FC<{ price: SurchargeDataModel }> = (
  props
) => {
  const {
    equivalentAmount,
    equivalentMinimumSurcharge,
    percentageSurchargeContains,
    percentageSurcharge,
    isForEachSegment,
    isForEachPassenger,
  } = props.price;
  return (
    <span>
      Amount: <SharedPriceDisplay price={equivalentAmount} />
      {equivalentMinimumSurcharge !== undefined && (
        <span>
          {" "}
          | Minimum <SharedPriceDisplay
            price={equivalentMinimumSurcharge}
          />{" "}
        </span>
      )}
      {percentageSurchargeContains.length > 0 && (
        <span> | Contains {percentageSurchargeContains}</span>
      )}
      {percentageSurcharge.length > 0 && (
        <span> | Percentage {percentageSurchargeContains}</span>
      )}
      {isForEachSegment !== undefined && (
        <span>
          {" "}
          | Applies to all segments: {isForEachSegment ? "Yes" : "No"}
        </span>
      )}
      {isForEachPassenger !== undefined && (
        <span>
          {" "}
          | Applies to all passengers: {isForEachPassenger ? "Yes" : "No"}
        </span>
      )}
    </span>
  );
};
