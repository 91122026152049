
import React from 'react'
import { useParams } from 'react-router-dom';
import {
    Navbar,
    NavItem,
    NavbarToggler,
    Collapse,
    NavLink,
    Nav,
    NavbarBrand
} from 'reactstrap';
import { AppRoutes } from 'routes';
import AvailableFaresPage from './AvailableFaresPage';

export interface ITestPageProps {
}

export default function TestPage(props: ITestPageProps) {
    const [isOpen, setIsOpen] = React.useState(false);
    let { logo, pagePath } = useParams();
    return (
        <div style={{
            display: 'block', padding: 0
        }}>
            <Navbar color="light" light expand="md">
                <NavbarBrand href="/">
                    <img src="https://mlo92xz0eu6i.i.optimole.com/afmPVyw-J_5jyiQl/w:auto/h:auto/q:mauto/f:avif/https://embarkingonvoyage.com/wp-content/uploads/2020/12/EOV_Logo-color.png" width="70px" alt="" />
                </NavbarBrand>
                <NavbarToggler onClick={() => { setIsOpen(!isOpen) }} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav className="mr-auto" navbar>
                        <NavItem>
                            <NavLink href="#">Home</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#">Login</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#">Signup</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
            <h3>logo:{logo}</h3>
            <h3>pagePath:{pagePath}</h3>
            <AvailableFaresPage isB2cAirline={false} />
        </div >
    );
}
