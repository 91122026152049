import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";

export const WaitingCard: React.FC<{ message?: string }> = (props) => {

  return (
    <div className="card">
      <div style={{ color: "black" }} className="card-body text-center">
        <FontAwesomeIcon icon={faSpinner} spin />{" "}
        {props.message ?? "Loading, please wait..."}
      </div>
    </div>
  );
};
