import { DateDisplay } from "components/Shared/DateDisplay";
import { GenderEnumDisplay } from "components/Shared/GenderEnumDisplay";
import React from "react";
import { BookingItemPassengerModel } from "WebApiClient";

interface BookingVersionItemDetailsBookedFlightProps {
    Data: BookingItemPassengerModel[]
    OnCancel?: (passengers: number[]) => void;
}

const BookingPassengersCard: React.FC<BookingVersionItemDetailsBookedFlightProps> = props => {
    return (
        <div className="card">
            <div className="card-header card-header-primary">Passengers <span className="badge badge-primary badge-pill align-top">{props.Data.length}</span></div>
            <div className="card-body">
                <table className="table table-sm table-striped table-hover table-bordered">
                    <thead>
                        <tr>
                            <th className="font-weight-bold">No.</th>
                            <th className="font-weight-bold">Last Name</th>
                            <th className="font-weight-bold">First Name</th>
                            <th className="font-weight-bold">DOB</th>
                            <th className="font-weight-bold">Type</th>
                            <th className="font-weight-bold">Gender</th>
                            <th className="font-weight-bold">Ticket No.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.Data.map((pax, paxIndex) =>
                            <PassengerItem key={"PaxIndex"+paxIndex} data={pax} />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );


}

export default BookingPassengersCard;

const PassengerItem: React.FC<{ data: BookingItemPassengerModel }> = ({ data }) => {
    const { birthDate, firstName, type, gender, lastName, passengerNumber, ticketNumbers } = data;

    return (
        <tr>
            <td>{passengerNumber}</td>
            <td>{lastName}</td>
            <td>{firstName}</td>
            <td><DateDisplay date={birthDate} /></td>
            <td>{type}</td>
            <td><GenderEnumDisplay gender={gender} /></td>
            <td>
                {ticketNumbers.length > 0 &&
                    <table className="table table-sm table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th className="font-weight-bold">No.</th>
                                <th className="font-weight-bold">Status</th>
                                <th className="font-weight-bold">Carrier</th>
                                <th className="font-weight-bold">Segments</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ticketNumbers.map((tkt, tktIndex) =>
                                <tr key={"tkt"+tktIndex}>
                                    <td>{tkt.ticketNo}</td>
                                    <td>{tkt.ticketStatus && <span>{tkt.ticketStatus}</span>}</td>
                                    <td>{tkt.ticketingCarrier}</td>
                                    <td>{tkt.segmentNumbers.join(",")}</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                }
            </td>
        </tr>
    );


}