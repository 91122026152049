import { faAlignJustify } from "@fortawesome/pro-regular-svg-icons";
import {
  faCogs,
  faConciergeBell,
  faHourglassStart,
  faLoveseat,
  faLuggageCart,
  faMapMarked,
  faPlane,
  faUsersClass,
  faWatch,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilterCriteria } from "components/AvailableFares/types/AvailableFaresTypes";
import IncludedServiceIcon from "components/Shared/IncludedServiceIcon";
import { TimeRangePicker } from "components/Shared/TimeRangeSlider";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  FaresFilterState,
  FreeBaggageFilterCriteria,
} from "../types/AvailableFaresTypes";
import { StopsData, b2cSettingsText } from "WebApiClient";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";

interface CheckboxState {
  freeBaggage: boolean;
  noBaggage: boolean;
}
interface OwnProps {
  Filter: FaresFilterState;
  TotalFaresAmount: number;
  FilteredFaresAmount: number;
  UpdateFilter: (filter: FaresFilterState) => void;
  InitialFilter: FaresFilterState;
  IsOpen: boolean;
  Toggle: () => void;

  AllFaresExpandActive: boolean;
  AllFaresExpandedToggle: () => void;

  AllFlightTimesExpandActive: boolean;
  AllFlightTimesExpandedToggle: () => void;

  AllCalculationExpandActive: boolean;
  AllCalculationExpandedToggle: () => void;

  cssActiveCheckerClean?: any;
  getStops: (stops: number) => StopsData | undefined;
  travelStops: any;
  makingCarouselValueEmpty: () => void;
  totalPassenger: number;
}

const FaresFilter: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const {
    Filter,
    FilteredFaresAmount,
    TotalFaresAmount,
    UpdateFilter,
    InitialFilter,
    cssActiveCheckerClean,
    totalPassenger
  } = props;

  const [stops, setStop] = useState<number[]>(
    props.travelStops().sort((a: number, b: number) => a - b)
  );

  useEffect(() => {
    setStop(props.travelStops());
  }, []);

  function SetFreeBaggage(value: FreeBaggageFilterCriteria) {
    let updatedFilter = { ...Filter };
    updatedFilter.FreeBaggage = value;
    UpdateFilter(updatedFilter);
  }

  function ToggleAllAirports() {
    let updatedFilter = { ...Filter };
    const inverted = !Filter.SelectAllAirportsActive;
    updatedFilter.ArrivalAirports.forEach((element) => {
      element.Checked = inverted;
    });
    updatedFilter.DepartureAirports.forEach((element) => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllAirportsActive = inverted;

    UpdateFilter(updatedFilter);
  }

  function ToggleAllBaggageTypes() {
    let updatedFilter = { ...Filter };
    const inverted = !Filter.SelectAllBaggageType;
    updatedFilter.BaggageType.forEach((element) => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllBaggageType = inverted;

    UpdateFilter(updatedFilter);
  }

  function ToggleArrivalAirport(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.ArrivalAirports[index].Checked =
      !updatedFilter.ArrivalAirports[index].Checked;
    UpdateFilter(updatedFilter);
  }
  function ToggleDepartureAirport(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.DepartureAirports[index].Checked =
      !updatedFilter.DepartureAirports[index].Checked;
    UpdateFilter(updatedFilter);
  }
  function ToggleAllAirlines() {
    let updatedFilter = { ...Filter };
    const inverted = !Filter.SelectAllAirlinesActive;

    updatedFilter.Airlines.forEach((element) => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllAirlinesActive = inverted;
    UpdateFilter(updatedFilter);
  }

  function ToggleAirline(index: number) {
    cssActiveCheckerClean();
    let updatedFilter = { ...Filter };
    updatedFilter.Airlines[index].Checked =
      !updatedFilter.Airlines[index].Checked;
    if (updatedFilter.Airlines.every((el: any) => el.Checked == true)) {
      updatedFilter.SelectAllAirlinesActive = true;
    } else {
      updatedFilter.SelectAllAirlinesActive = false;
    }
    UpdateFilter(updatedFilter);
  }

  function ToggleAllAlliances() {
    let updatedFilter = { ...Filter };
    const inverted = !Filter.SelectAllAlliancesActive;
    updatedFilter.Alliances.forEach((element) => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllAlliancesActive = inverted;
    UpdateFilter(updatedFilter);
  }

  function ToggleAlliance(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.Alliances[index].Checked =
      !updatedFilter.Alliances[index].Checked;
    UpdateFilter(updatedFilter);
  }

  function SetDepartureTimes(index: number, minHour: number, maxHour: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.DepartureTime[index].MinHour = minHour;
    updatedFilter.DepartureTime[index].MaxHour = maxHour;
    UpdateFilter(updatedFilter);

    // console.log("updatedFilter", getSegmentName());
  }

  function SetArrivalTimes(index: number, minHour: number, maxHour: number) {
    let updatedFilter = { ...Filter };

    updatedFilter.ArrivalTime[index].MinHour = minHour;
    updatedFilter.ArrivalTime[index].MaxHour = maxHour;

    UpdateFilter(updatedFilter);
  }

  function ToggleAllCabinClasses() {
    let updatedFilter = { ...Filter };

    const inverted = !Filter.SelectAllCabinClassesActive;
    updatedFilter.CabinClasses.forEach((element) => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllCabinClassesActive = inverted;

    UpdateFilter(updatedFilter);
  }

  function ToggleCabinClass(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.CabinClasses[index].Checked =
      !updatedFilter.CabinClasses[index].Checked;
    UpdateFilter(updatedFilter);
  }

  //baggageType
  function ToggleBaggageType(value: string) {
    let updatedFilter = { ...Filter };
    const index = updatedFilter.BaggageType.findIndex(
      (item) => item.Value === value
    );
    if (index !== -1) {
      updatedFilter.BaggageType[index].Checked =
        !updatedFilter.BaggageType[index].Checked;
      UpdateFilter(updatedFilter);
    }
  }

  function ToggleAllAllotmentTypes() {
    let updatedFilter = { ...Filter };
    const inverted = !Filter.SelectAllAllotmentTypesActive;
    updatedFilter.AllotmentTypes.forEach((element) => {
      element.Checked = inverted;
    });
    updatedFilter.SelectAllAllotmentTypesActive = inverted;
    UpdateFilter(updatedFilter);
  }

  function ToggleAllotmentType(index: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.AllotmentTypes[index].Checked =
      !updatedFilter.AllotmentTypes[index].Checked;
    UpdateFilter(updatedFilter);
  }

  function ToggleAllIncludedServices() {
    let updatedFilter = { ...Filter };
    const inverted = !Filter.SelectAllIncludedServicesActive;
    updatedFilter.IncludedService.forEach((mainGroup) => {
      mainGroup.Values.forEach((subGroup) => {
        subGroup.Checked = inverted;
      });
    });
    updatedFilter.SelectAllIncludedServicesActive = inverted;
    UpdateFilter(updatedFilter);
  }

  function ToggleIncludedService(mainTypeIndex: number, subTypeIndex: number) {
    let updatedFilter = { ...Filter };
    updatedFilter.IncludedService[mainTypeIndex].Values[subTypeIndex].Checked =
      !updatedFilter.IncludedService[mainTypeIndex].Values[subTypeIndex]
        .Checked;
    UpdateFilter(updatedFilter);
  }

  const sortByFilter = (dataObj: any, label: string) => {
    return dataObj?.sort(function (a: any, b: any) {
      let textA: any = a?.[label].toUpperCase();
      let textB: any = b?.[label].toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  };

  const InitialBaggageCheckBox = {
    freeBaggage: false,
    noBaggage: false,
  };

  const [checkboxes, setCheckboxes] = useState<CheckboxState>(
    InitialBaggageCheckBox
  );

  const handleCheckboxChange = (checkboxName: keyof CheckboxState) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [checkboxName]: !prevCheckboxes[checkboxName],
    }));
  };


  const b2cSettingsText = useSelector((state: State) => state.Session.FrontendSettings.b2cSettingsText);


  const B2CSettings: b2cSettingsText = (() => {
    try {
      return JSON.parse(b2cSettingsText || "{\"HotelTab\":\"None\",\"AirPortSearchType\":\"Most\",\"ShowLanguageDecimalTabB2C\":\"English\",\"ShowExtraAncillariesTabB2C\":\"On\",\"ShowDateInput\":\"Right\",\"ShowDefaultFilter\":\"On\",\"CabinClass\":{\"Economy\":{\"Checked\":true,\"preSelect\":true},\"Premium\":{\"Checked\":true,\"preSelect\":false},\"Business\":{\"Checked\":true,\"preSelect\":false},\"First\":{\"Checked\":false,\"preSelect\":false}},\"TermsConditionsLink\":\"https://dev.flight.agentplus.io\",\"ShowBackgroundModalColor\":\"Off\",\"showMarqueeText\":\"The flight will be delayed for 4 hours suraj\",\"showMarqueeFrom\":\"2024-05-31T10:55\",\"showMarqueeTo\":\"2024-05-30T10:58\",\"showBrandName\":\"On\",\"showAssistance\":\"On\",\"ConfirmationText\":\"<p>Thank you for booking with Everest Travel.\\n#Booking Reference#\\nYou will receive a confirmation email soon.</p>\",\"priceOption\":\"pricePerPerson\",\"Style\":\"body{\\ncolor:\\\"black\\\"\\n}\\n\"}");
    } catch {
      return { HotelTab: "All", AirPortSearchType: "All" };
    }
  })();


  useEffect(() => {
    if (checkboxes.freeBaggage && checkboxes.noBaggage) {
      SetFreeBaggage(FreeBaggageFilterCriteria.All);
    } else if (checkboxes.freeBaggage) {
      SetFreeBaggage(FreeBaggageFilterCriteria.Yes);
    } else if (checkboxes.noBaggage) {
      SetFreeBaggage(FreeBaggageFilterCriteria.No);
    } else {
      SetFreeBaggage(FreeBaggageFilterCriteria.All);
    }
  }, [checkboxes.freeBaggage, checkboxes.noBaggage]);

  function modifyAirportString(inputString: string) {
    return inputString
      .replace(/\([^)]+\)/, "")
      .replace(/\bAirport\b|\bArpt\b/g, "")
      .trim();
  }

  type ActiveButtonsState = { [key: number]: boolean };

  const initialActiveButtonsState: ActiveButtonsState =
    stops.reduce<ActiveButtonsState>((acc, stop) => {
      acc[stop] = false;
      return acc;
    }, {});

  const [activeButtons, setActiveButtons] = useState<ActiveButtonsState>(
    () => initialActiveButtonsState
  );

  const handleClick = (stopIndex: number, stop: number) => {
    // Make a copy of the activeButtons state
    const newActiveButtons = { ...activeButtons };
    // Toggle the state of the clicked button
    newActiveButtons[stop] = !newActiveButtons[stop];
    // Update the state with the new object
    setActiveButtons(newActiveButtons);
    // Update the filter based on the button state
    const updatedFilter = { ...Filter };
    updatedFilter.LegsMaxStopAmount.MaxStopSelection = newActiveButtons; // Set the default value
    // Update the filter
    UpdateFilter(updatedFilter);
  };

  return (
    <>
      <div>
        {Filter !== undefined && (
          <div className="row">
            <div className="">
              <div className="p-1">
                <div className="text-end">
                  <button
                    className="btn btn-danger ml-2"
                    onClick={() => {
                      UpdateFilter(InitialFilter);
                      setActiveButtons(initialActiveButtonsState);
                      setCheckboxes(InitialBaggageCheckBox);
                      props.makingCarouselValueEmpty()
                    }}
                  >
                    {t("FareList.Filter.Reset")}
                  </button>
                </div>
                <div className="">
                  <div className="pb-2">
                    <div className="filter-section">
                      <FontAwesomeIcon icon={faWatch} />{" "}
                      {t("FareList.Filter.DepartureTime")}
                    </div>

                    <div className="filter-section-content mb-3">
                      {Filter.DepartureTime.map(
                        (departureTime, index: number) => (
                          <div
                            className="form-group"
                            key={`afq_filter_depTime1${index}`}
                          >
                            <div className="mt-3">
                              <TimeRangePicker
                                key={"time2" + index}
                                Update={(values) =>
                                  SetDepartureTimes(index, values[0], values[1])
                                }
                                values={[
                                  departureTime.MinHour,
                                  departureTime.MaxHour,
                                ]}
                                Label={Filter.DepartureAirports[index]?.Value}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  <div className=" pb-2">
                    <div className="filter-section">
                      <FontAwesomeIcon icon={faWatch} />{" "}
                      {t("FareList.Filter.ArrivalTime")}
                    </div>
                    <div className="filter-section-content mb-3">
                      {Filter.ArrivalTime.map((arrivalTime, index: number) => (
                        <div
                          className="form-group"
                          key={`afq_filter_arrTime2${index}`}
                        >
                          <div className="mt-3">
                            <TimeRangePicker
                              key={"time1" + index}
                              Update={(values) =>
                                SetArrivalTimes(index, values[0], values[1])
                              }
                              values={[
                                arrivalTime.MinHour,
                                arrivalTime.MaxHour,
                              ]}
                              Label={Filter.ArrivalAirports[index]?.Value}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="pb-2">
                    <div className="filter-section">
                      <FontAwesomeIcon icon={faHourglassStart} />{" "}
                      {t("FareList.Filter.Stops")}
                    </div>
                    <div className="filter-section-content">
                      <div className="mb-3" key={`afg_filter_legMaxStop_`}>
                        <div className="text-black">
                          {stops.map((stop: number, stopIndex: number) => (
                            <div
                              className="text-black my-2 d-flex justify-content-between align-items-center"
                              key={`stop_${stopIndex}`}
                            >
                              <input
                                type="checkbox"
                                onChange={() => handleClick(stopIndex, stop)}
                                checked={activeButtons[stop]}
                                className="btn-check w-100 px-5"
                              />
                              <div className="w-25 " style={{ whiteSpace: 'nowrap' }}>
                                {props.getStops(stop)?.totalTravelTime.hours +
                                  "h " +
                                  props.getStops(stop)?.totalTravelTime
                                    .minutes +
                                  "m"}
                              </div>

                              <label
                                onClick={() => handleClick(stopIndex, stop)}
                                className={`btn btn-outline-primary buttonWidth ${activeButtons[stop] ? "active" : ""
                                  }`}
                              >
                                {stop === 0
                                  ? t("FareList.Filter.Direct")
                                  : `${stop}  ${t("FareList.Filter.Stop")}`}
                              </label>

                              <div className="w-25 text-end" style={{ whiteSpace: 'nowrap' }}>
                                {
                                  B2CSettings.priceOption === "pricePerPerson"
                                    ? ((props.getStops(stop)?.minPrice ?? 0) / totalPassenger)?.toFixed(2) + "€"
                                    : (props.getStops(stop)?.minPrice ?? 0)?.toFixed(2) + "€"
                                }
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pb-2">
                    <div className="filter-section">
                      <FontAwesomeIcon icon={faLuggageCart} />{" "}
                      {t("FareList.Filter.BaggageIncluded")}
                    </div>
                    <div className="filter-section-content d-flex w-75 justify-content-start">
                      <div className="bg-white rounded me-1">
                        <input
                          type="radio"
                          onChange={() => handleCheckboxChange("freeBaggage")}
                          checked={checkboxes.freeBaggage}
                          className="btn-check px-5"
                        />
                        <label
                          onClick={() => handleCheckboxChange("freeBaggage")}
                          className="btn btn-outline-primary buttonWidth"
                        >
                          {t("FareList.Filter.Yes")}
                        </label>
                      </div>

                      <div className="bg-white rounded">
                        <input
                          type="radio"
                          onChange={() => handleCheckboxChange("noBaggage")}
                          checked={checkboxes.noBaggage}
                          className="btn-check w-100 px-5"
                        />
                        <label
                          onClick={() => handleCheckboxChange("noBaggage")}
                          className="btn btn-outline-primary buttonWidth"
                        >
                          {t("FareList.Filter.No")}
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* BaggageType */}
                  <div className="mt-2 pb-2">
                    <div className="filter-section">
                      <FontAwesomeIcon icon={faLuggageCart} />{" "}
                      {t("FareList.Filter.BaggageType")}
                    </div>
                    <div className="filter-section-content">
                      <div
                        onClick={() => ToggleAllBaggageTypes()}
                        className="form-check sub-filter-section sub-filter-section-bold border-bottom pb-2 mb-2"
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={() => { }}
                          checked={Filter.SelectAllBaggageType}
                        ></input>
                        <label className="form-check-label font-weight-bold">
                          {t("FareList.Filter.SelectUnselect")}
                        </label>
                      </div>
                      <div className="form-group mb-3">
                        {Filter.BaggageType.slice()
                          .sort((a, b) => a.Value.localeCompare(b.Value))
                          .map((baggage: FilterCriteria, index: number) => (
                            <div
                              className="form-check sub-filter-section sub-filter-section-bold"
                              key={`afq_filter_BagT${index}`}
                              onClick={() => ToggleBaggageType(baggage.Value)}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={baggage.Checked}
                                readOnly
                              />
                              <label className="form-check-label">
                                {baggage.Label == ""
                                  ? "No free baggage"
                                  : baggage.Label}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  {Filter?.DepartureAirports?.length +
                    Filter?.ArrivalAirports?.length >
                    2 && (
                      <div className="mt-2 pb-2">
                        <div className="filter-section">
                          <FontAwesomeIcon icon={faMapMarked} />{" "}
                          {t("FareList.Filter.Airports")}
                        </div>
                        <div className="filter-section-content sub-filter-section sub-filter-section-bold">
                          <div
                            onClick={() => ToggleAllAirports()}
                            className="form-check border-bottom pb-2 mb-2"
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              onChange={() => { }}
                              checked={Filter.SelectAllAirportsActive}
                            ></input>
                            <label className="form-check-label">
                              {t("FareList.Filter.SelectUnselect")}
                            </label>
                          </div>
                          <div className="font-weight-bold sub-filter-section pb-1">
                            {t("FareList.Filter.Departure")}
                          </div>
                          <div className="form-group mb-3">
                            <div className="custom-controls-stacked">
                              {Filter.DepartureAirports.map(
                                (airport: FilterCriteria, index: number) => (
                                  <div
                                    className="form-check sub-filter-section sub-filter-section-bold"
                                    key={`afq_filter_depAr${index}`}
                                    onClick={() => ToggleDepartureAirport(index)}
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={airport.Checked}
                                      readOnly
                                    />
                                    <label className="form-check-label">
                                      {modifyAirportString(airport.Label)}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                          </div>

                          <div className="font-weight-bold sub-filter-section pb-1">
                            {t("FareList.Filter.Arrival")}
                          </div>

                          <div className="form-group mb-3">
                            <div className="custom-controls-stacked">
                              {Filter.ArrivalAirports.map(
                                (airport: FilterCriteria, index: number) => (
                                  <div
                                    className="form-check sub-filter-section sub-filter-section-bold"
                                    key={`afq_filter_arAr${index}`}
                                    onClick={() => ToggleArrivalAirport(index)}
                                  >
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={airport.Checked}
                                      readOnly
                                    />
                                    <label className="form-check-label">
                                      {modifyAirportString(airport.Label)}
                                    </label>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {Filter.CabinClasses.length > 1 && (
                    <div className="mt-2 pb-2">
                      <div className="filter-section">
                        <FontAwesomeIcon icon={faLoveseat} />{" "}
                        {t("FareList.Filter.CabinClass")}
                      </div>
                      <div className="filter-section-content">
                        <div
                          onClick={() => ToggleAllCabinClasses()}
                          className="form-check sub-filter-section sub-filter-section-bold border-bottom pb-2 mb-2"
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={() => { }}
                            checked={Filter.SelectAllCabinClassesActive}
                          ></input>
                          <label className="form-check-label font-weight-bold">
                            {t("FareList.Filter.SelectUnselect")}
                          </label>
                        </div>
                        <div className="form-group mb-3">
                          {Filter.CabinClasses.map(
                            (fareType: FilterCriteria, index: number) => (
                              <div
                                className="form-check sub-filter-section sub-filter-section-bold"
                                key={`afq_filter_cabclass${index}`}
                                onClick={() => ToggleCabinClass(index)}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={fareType.Checked}
                                  readOnly
                                />
                                <label className="form-check-label">
                                  {fareType.Label == "PremiumEconomy"
                                    ? "Premium"
                                    : fareType.Label}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {Filter.AllotmentTypes.length > 0 && (
                    <div className="mt-2 pb-2">
                      <div className="filter-section">
                        <FontAwesomeIcon icon={faAlignJustify} />{" "}
                        {t("FareList.Filter.Allotments")}
                      </div>
                      <div className="filter-section-content">
                        <div
                          onClick={() => ToggleAllAllotmentTypes()}
                          className="form-check sub-filter-section sub-filter-section-bold border-bottom pb-2 mb-2"
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={() => { }}
                            checked={Filter.SelectAllAllotmentTypesActive}
                          ></input>
                          <label className="form-check-label font-weight-bold">
                            {t("FareList.Filter.SelectUnselect")}
                          </label>
                        </div>
                        <div className="form-group mb-3">
                          <div className="custom-controls-stacked">
                            {Filter.AllotmentTypes.map(
                              (source: FilterCriteria, index: number) => (
                                <div
                                  className="form-check sub-filter-section sub-filter-section-bold"
                                  key={`afq_filter_allotment${index}`}
                                  onClick={() => ToggleAllotmentType(index)}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={source.Checked}
                                    readOnly
                                  />
                                  <label className="form-check-label">
                                    {source.Label}
                                  </label>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Included Services */}
                  {Filter?.IncludedService.length > 0 && (
                    <div className="mt-3 pb-2">
                      <div className="filter-section">
                        <FontAwesomeIcon icon={faConciergeBell} />{" "}
                        {t("FareList.Filter.IncludedServices")}
                      </div>
                      <div className="filter-section-content">
                        <div
                          onClick={() => ToggleAllIncludedServices()}
                          className="form-check sub-filter-section sub-filter-section-bold border-bottom pb-2 mb-2"
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={() => { }}
                            checked={Filter.SelectAllIncludedServicesActive}
                          ></input>
                          <label className="form-check-label font-weight-bold">
                            {t("FareList.Filter.SelectUnselect")}
                          </label>
                        </div>
                        <div className="form-group mb-3">
                          {Filter.IncludedService!.map(
                            (mainGroup: any, mainIndex: number) => (
                              <div key={`afq_filter_mainincluded${mainIndex}`}>
                                <div className="font-weight-bold sub-filter-section pb-1">
                                  {t(`FareList.Filter.${mainGroup.MainLabel}`)}
                                </div>
                                {mainGroup.Values.map(
                                  (subType: any, subIndex: number) => (
                                    <div
                                      className="form-check sub-filter-section sub-filter-section-bold"
                                      key={`afq_filter_mainincluded${mainIndex}_sub${subIndex}`}
                                      onClick={() =>
                                        ToggleIncludedService(
                                          mainIndex,
                                          subIndex
                                        )
                                      }
                                    >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={subType.Checked}
                                        readOnly
                                      />
                                      <label className="form-check-label">
                                        <IncludedServiceIcon
                                          data={subType.Icon}
                                        />{" "}
                                        {subType.Label}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="mt-2 pb-2">
                    <div className="filter-section">
                      <FontAwesomeIcon icon={faUsersClass} />{" "}
                      {t("FareList.Filter.Alliances")}
                    </div>
                    <div className="filter-section-content">
                      <div
                        onClick={() => ToggleAllAlliances()}
                        className="form-check sub-filter-section sub-filter-section-bold border-bottom pb-2 mb-2"
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={() => { }}
                          checked={Filter.SelectAllAlliancesActive}
                        ></input>
                        <label className="form-check-label font-weight-bold">
                          {t("FareList.Filter.SelectUnselect")}
                        </label>
                      </div>
                      <div className="form-group mb-3">
                        <div className="custom-controls-stacked">
                          {Filter.Alliances.map(
                            (airline: FilterCriteria, index: number) => (
                              <div
                                className="form-check sub-filter-section sub-filter-section-bold"
                                key={`afq_filter_all${index}`}
                                onClick={() => ToggleAlliance(index)}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={airline.Checked}
                                  readOnly
                                />
                                <label className="form-check-label">
                                  {airline.Label}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2 pb-2 h-100">
                    <div className="filter-section">
                      <FontAwesomeIcon icon={faPlane} />{" "}
                      {t("FareList.Filter.Airlines")}
                    </div>
                    <div className="filter-section-content">
                      <div
                        onClick={() => {
                          ToggleAllAirlines();
                        }}
                        className="form-check sub-filter-section sub-filter-section-bold border-bottom pb-2 mb-2"
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          onChange={() => { }}
                          checked={Filter.SelectAllAirlinesActive}
                        ></input>
                        <label className="form-check-label font-weight-bold">
                          {t("FareList.Filter.SelectUnselect")}
                        </label>
                      </div>
                      <div className="form-group mb-3">
                        <div className="custom-controls-stacked">
                          {sortByFilter(Filter.Airlines, "Label").map(
                            (airline: FilterCriteria, index: number) => (
                              <div
                                className="form-check sub-filter-section sub-filter-section-bold"
                                key={`afq_filter_arAr${index}`}
                                onClick={() => ToggleAirline(index)}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={airline.Checked}
                                  readOnly
                                />
                                {/* <img
                                src={flighImg}
                                alt="flight_images"
                                className="mx-2"
                                style={{
                                  width: "18px",
                                }}
                              /> */}
                                <label className="form-check-label">
                                  {modifyAirportString(airline.Label)}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FaresFilter;
