import {
  AgentGenderEnum,
  AvailableFareModel,
  FareBookingPassengerView,
} from "WebApiClient";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";

export interface BookPassengerValidationGeneralTabResult {
  Valid: boolean;
  IsChild: boolean;
  IsInfant: boolean;
  IsAdult: boolean;
  FirstName: boolean;
  LastName: boolean;
  Title: boolean;
  ShowTitle: boolean;
  ShowGender: boolean;
  Gender: boolean;
  DateOfBirth: boolean;
  Email: boolean;
  Mobile: boolean;
}
export interface passengerConfig {
  PassengerDOB: string;
  PassengerGender: string;
}

export function PassengerGeneralValid(
  passenger: FareBookingPassengerView,
  fare: AvailableFareModel,
  dontContact:boolean
): BookPassengerValidationGeneralTabResult {
  var otherSettings: string = useSelector((state: State) =>
    state.Session.FrontendSettings.otherSettingsAsKeyValueText == null
      ? state.Session.FrontendSettings.otherSettingsAsKeyValueText ==
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#232323"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None'
      : state.Session.FrontendSettings.otherSettingsAsKeyValueText
  );

  let settingArr = otherSettings.split("\n");
  var passengerConfig: passengerConfig =
    settingArr[5] == undefined
      ? { PassengerDOB: "No", PassengerGender: "Unselected" }
      : JSON.parse(settingArr[5]);
  let generalTabValid = true;
  const IsChild = passenger.isChild;
  const isInfant = passenger.isInfant;
  const isAdult = passenger.isAdult;

  const data = passenger.bookingData;

  const firstNameValid: boolean =
    data.firstName !== null && data.firstName !== "";
  if (!firstNameValid) {
    generalTabValid = false;
  }

  const titleRequired = false;
  const titleValid: boolean = titleRequired
    ? data.title !== null && data.title !== ""
    : true;
  if (!titleValid) {
    generalTabValid = false;
  }

  const genderRequired = true;
  const genderValid: boolean = genderRequired
    ? data.gender !== AgentGenderEnum.Unknown
    : true;
  if (!genderValid) {
    generalTabValid = false;
  }

  const lastNameValid: boolean = data.lastName !== null && data.lastName !== "";
  if (!lastNameValid) {
    generalTabValid = false;
  }

  let dateOfBirth = passengerConfig.PassengerDOB == "No" ? false : true;
  const dateOfBirthValid: boolean = dateOfBirth
    ? data.dateOfBirth !== undefined && data.dateOfBirth !== null
    : true;
  if (!dateOfBirthValid) {
    generalTabValid = false;
  }

  if (data.frequentFlyerCards) {
    data.frequentFlyerCards.forEach((ff) => {
      if (ff.carrierCodes.length === 0 || ff.programNumber.length === 0) {
        generalTabValid = false;
      }
    });
  }

  const emailValid: boolean =
    data.email !== null && /\S+@\S+\.\S+/.test(data.email);
  if (!emailValid ) {
    generalTabValid =  false;
  }

  const mobileValid: boolean =
  data.mobile !=null;
  if (!mobileValid ) {
    generalTabValid =  false;
  }

  const general: BookPassengerValidationGeneralTabResult = {
    Title: titleValid,
    IsAdult: isAdult,
    IsChild: IsChild,
    IsInfant: isInfant,
    ShowTitle: titleRequired,
    Gender: genderValid,
    ShowGender: genderRequired,
    Valid: generalTabValid,
    FirstName: firstNameValid,
    LastName: lastNameValid,
    DateOfBirth: dateOfBirthValid,
    Email: emailValid,
    Mobile: mobileValid,
  };
  return general;
}
