import { useState } from "react";
import { GroupedPassenger } from "./Ancillary";

export const AddServiceSelector: React.FC<{ groups: GroupedPassenger[] }> = ({ groups }) => {
    const [typeSelection, setTypeSelection] = useState<GroupedPassenger | undefined>(undefined);
    return <div className="row">
        <div className="col">
            <div className="input-group">
                <label className="input-group-text">Type</label>

                <select className="form-select" onChange={(e) => { setTypeSelection(groups[parseInt(e.target.value)]) }}>
                    <option>Select</option>
                    {groups.map((group, groupIndex) =>
                        <option value={groupIndex.toString()} key={"GroupIndex" + groupIndex}>{group.Passenger.typeLabel}</option>
                    )}
                </select>

            </div>
        </div>
        {typeSelection &&
            <div className="col">
                <PassengerSelector group={typeSelection} />
            </div>
        }

    </div>
}
const PassengerSelector: React.FC<{ group: GroupedPassenger }> = ({ group }) => {
    const [typeSelection, setTypeSelection] = useState<GroupedPassenger | undefined>(undefined);
    return <div className="row">
        <div className="col">
            <div className="input-group">
                <label className="input-group-text">Pax</label>

                <select className="form-select">
                    <option>Select</option>
                    {group.PassengerOptions.map((pax, groupIndex) =>
                        <option key={"grpIndex"+groupIndex}>{pax.Label}</option>
                    )}
                </select>

            </div>
        </div>


    </div>
}