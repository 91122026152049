import "bootstrap/dist/css/bootstrap.css";
import { store } from "configureStore";
import { Provider } from "react-redux";
import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next"; // Import I18nextProvider
import "react-toastify/dist/ReactToastify.css";
import "react-widgets/styles.css";
import "Styles/HitchHiker.Styles.css";
import App from "./App";
import i18n from "./i18n"; // Import the i18n configuration

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <div dir={i18n.dir()}>
            <App />
          </div>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);
