import CountrySelect from "components/Shared/CountrySelect";
import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import {
  Adress,
  BookingAddressBehavior,
  FareBookingPassengerView,
} from "WebApiClient";
import { defaultAdress } from "../types/FareBookingTypes";
import { BookAddressValidationResult } from "./FareBooking.Validate.Address";
import { faServicestack } from "@fortawesome/free-brands-svg-icons";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export interface FareBookingAddressProps {
  Address: Adress | undefined;
  Invoice: Adress;
  AddressBehavior: BookingAddressBehavior;
  OnUpdateAddress: (address: Adress | undefined) => void;
  OnUpdateInvoice: (address: Adress) => void;
  AdressValid: BookAddressValidationResult;
  InvoiceValid: BookAddressValidationResult;
  Icon: JSX.Element;
  IsOpen: boolean;
  Passengers?: any;
}

const InputAddress: React.FC<{
  Address: Adress;
  OnUpdateAddress: (address: Adress) => void;
  IsValid: BookAddressValidationResult;
  isDisabled: boolean;
  Title: string;
  OnRemove?: () => void;
  Passengers?: any;
}> = ({
  Address,
  OnUpdateAddress,
  IsValid,
  isDisabled,
  Title,
  OnRemove,
  Passengers,
}) => {
    const { t } = useTranslation();

    function OnTitleUpdate(value: string) {
      let data = { ...Address };
      data.title = value;
      OnUpdateAddress(data);
    }

    function OnFirstNameUpdate(value: string) {
      let data = { ...Address };
      data.firstName = value;
      OnUpdateAddress(data);
    }

    function OnLastNameUpdate(value: string) {
      let data = { ...Address };
      data.lastName = value;
      OnUpdateAddress(data);
    }

    function OnStreetUpdate(value: string) {
      let data = { ...Address };
      data.street = value;
      OnUpdateAddress(data);
    }

    function OnZIPUpdate(value: string) {
      let data = { ...Address };
      data.zip = value;
      OnUpdateAddress(data);
    }

    function OnCityUpdate(value: string) {
      let data = { ...Address };
      data.city = value;
      OnUpdateAddress(data);
    }

    function OnCountryUpdate(value: string) {
      let data = { ...Address };
      data.country = value;
      OnUpdateAddress(data);
    }

    function OnLastFirstNameUpdate(firstName: string, lastName: string) {
      let data = { ...Address };
      data.firstName = firstName;
      data.lastName = lastName;
      OnUpdateAddress(data);
    }

    useEffect(() => {
      if (Passengers[0]?.bookingData?.firstName.length > 0 && Passengers[0]?.bookingData?.lastName > 0) {
        OnLastFirstNameUpdate(
          Passengers[0]?.bookingData?.firstName,
          Passengers[0]?.bookingData?.lastName
        );
      }
    }, [
      Passengers[0]?.bookingData?.lastName,
      Passengers[0]?.bookingData?.firstName,
    ]);

    useEffect(() => {
      if (Passengers[0]?.bookingData?.firstName.length > 0 && Passengers[0]?.bookingData?.lastName > 0) {
        OnLastFirstNameUpdate(
          Address.firstName ? Address.firstName : "",
          Address.lastName ? Address.lastName : ""
        );
      }
    }, []);

    return (
      <div className="card">
        <div className="card-header card-header-primary">
          {Title}{" "}
          {/* {OnRemove && (
            <span className="float-end">
              <button onClick={OnRemove} className="btn btn-danger btn-sm">
                {t("FareBooking.PassengerPage.Remove")}
              </button>
            </span>
          )} */}
        </div>
        <div className="card-body card-body-primary">
          {isDisabled && (
            <div className="row">
              <div className="col-12">
                <Alert color="info">
                  {t("FareBooking.DeliveryInvoice.InvoiceAlert")}
                </Alert>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>{t("FareBooking.PassengerPage.Title")}{Title == "Delivery" ? "" : "*"}</label>
                <input
                  disabled={isDisabled}
                  value={Address.title ? Address.title : ""}
                  onChange={(e) => OnTitleUpdate(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="form-group mt-3">
                <label>{t("FareBooking.DeliveryInvoice.FullName")}{Title == "Delivery" ? "" : "*"}</label>
                <input
                  value={Address.firstName ? Address.firstName : ""}
                  onChange={(e) => OnFirstNameUpdate(e.target.value)}
                  type="text"
                  className="form-control"
                  disabled={isDisabled}
                  placeholder={t("FareBooking.PassengerPage.FirstName")}
                />
              </div>
              <div className="form-group mt-3">
                <input
                  value={Address.lastName ? Address.lastName : ""}
                  onChange={(e) => OnLastNameUpdate(e.target.value)}
                  type="text"
                  className="form-control"
                  disabled={isDisabled}
                  placeholder={t("FareBooking.PassengerPage.LastName")}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group mt-sm-3 mt-md-0">
                <label>{t("FareBooking.APISPage.Address.Street")}{Title == 'Delivery' ? null : "*"}</label>
                <input
                  value={Address.street}
                  onChange={(e) => OnStreetUpdate(e.target.value)}
                  type="text"
                  disabled={isDisabled}
                  className="form-control"
                />
              </div>
              <div className="form-group mt-3">
                <label>{t("FareBooking.APISPage.Address.ZipCode")}{Title == 'Delivery' ? null : "*"}</label>
                <input
                  value={Address.zip}
                  onChange={(e) => OnZIPUpdate(e.target.value)}
                  type="text"
                  disabled={isDisabled}
                  className="form-control"
                />
              </div>
              <div className="form-group mt-3">
                <label>{t("FareBooking.APISPage.Address.City")}{Title == 'Delivery' ? null : "*"}</label>
                <input
                  value={Address.city}
                  onChange={(e) => OnCityUpdate(e.target.value)}
                  type="text"
                  disabled={isDisabled}
                  className="form-control"
                />
              </div>
              <div className="form-group mt-3">
                <label>{t("FareBooking.APISPage.Address.Country")}{Title == 'Delivery' ? null : "*"}</label>
                <CountrySelect
                  Value={Address.country!}
                  Onchange={OnCountryUpdate}
                  Valid={IsValid.Country}
                  ID={`bacountry`}
                  Required={true}
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

const FareBookingAddress: React.FC<FareBookingAddressProps> = ({
  Address,
  OnUpdateAddress,
  AdressValid,
  AddressBehavior,
  Icon,
  Invoice,
  OnUpdateInvoice,
  InvoiceValid,
  IsOpen,
  Passengers,
}) => {
  //toggle state

  const [toggleDelivery, setToggleDelivery] = useState(false)

  const isDisabled = AddressBehavior === BookingAddressBehavior.UseBranchAdress;

  function OnAddAddress() {
    OnUpdateAddress(Invoice);
    sendPageHeight1();
    sendPageHeight();
  }

  function OnRemoveAddress() {
    OnUpdateAddress(undefined);
    sendPageHeight1();
    sendPageHeight();
  }
  const { t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = useState(IsOpen);

  const sendPageHeight1 = () => {
    const height = 800;
    window.parent.postMessage(["setHeight", height + "px"], "*");
  };

  const sendPageHeight = () => {
    setTimeout(() => {
      const height = document.body.scrollHeight;
      window.parent.postMessage(["setHeight", height + "px"], "*");
    }, 100);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    sendPageHeight1();
    sendPageHeight();
  };

  useEffect(() => {
    OnUpdateAddress(Address == undefined ? Invoice : Address)
  }, [])

  const toggleDeliveryVisibility = () => {
    setToggleDelivery(!toggleDelivery)
  }

  useEffect(() => {
    if (toggleDelivery == true) {
      OnAddAddress()
    }
    if (toggleDelivery == false) {
      OnRemoveAddress()
    }
  }, [toggleDelivery])

  return (
    <div className="card mx-2">
      <div
        className="d-flex blueCard cursor-pointer align-items-center px-3 py-2 clickme"
        onClick={toggleCollapse}
      >
        <div className="card-header-primary d-flex align-items-center w-100">
          {/* <span>{t("FareBooking.AncillariesPage.AncillaryTitle")}</span> */}
          <span>
            {Icon}
            {t("FareBooking.DeliveryInvoice.DeliveryInvoiceTitle")}
          </span>
        </div>
        <div>
          <FontAwesomeIcon icon={!isCollapsed ? faChevronUp : faChevronDown} />
        </div>
      </div>
      <div
        className={`collapse ${isCollapsed ? "show" : ""}`}
        id="collapseExample"
      >
        <div className="card-body">
          <div className="row">
            <div className="col-12 mb-2">
              <InputAddress
                Address={Invoice}
                isDisabled={false}
                IsValid={InvoiceValid}
                OnUpdateAddress={OnUpdateInvoice}
                Title={t("FareBooking.DeliveryInvoice.Invoice")}
                Passengers={Passengers}
              />
            </div>
            <div className="col-12" style={{ padding: '0px 18px' }}>
              <div className="d-grid">
                <button className="btn btn-primary" onClick={toggleDeliveryVisibility}>
                  <span className="d-flex justify-content-center align-items-center" style={{ gap: '8px' }}>
                    <input
                      className="ml-2 custom-checkbox"
                      type="checkbox"
                      checked={toggleDelivery}
                      style={{ scale: "1.5" }}
                      
                    />
                    <span>{t("FareBooking.DeliveryInvoice.AddInvoice")}</span>
                  </span>
                </button>
              </div>
            </div>

            <div className="col-12">
              {Address && (
                <InputAddress
                  Address={Address}
                  isDisabled={isDisabled}
                  IsValid={AdressValid}
                  OnUpdateAddress={OnUpdateAddress}
                  Title={t("FareBooking.DeliveryInvoice.Delivery")}
                  OnRemove={OnRemoveAddress}
                  Passengers={Passengers}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FareBookingAddress;
