import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GuessDate, {
  GuessDateResult,
} from "components/FareSearch/Business/GuessDate";
import { useDateFormat, useFormatDate } from "hooks/useLocalization";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ActiveModifiers, DayPicker, Matcher } from "react-day-picker";
import "react-day-picker/dist/style.css";
import {
  ArrowContainer,
  Popover,
  PopoverPosition,
  PopoverState,
} from "react-tiny-popover";
import { Card } from "reactstrap";
import { InvariantDateModel, b2cSettingsText } from "WebApiClient";
import { GetNullableApiRequestDate } from "./Helpers/DateConverters";
import { Calendar } from "@contentful/f36-components";
import nextElementFocus from "components/FareSearch/component/FlightDetails/nextElementFocus";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { ISegmentState } from "components/FareSearch/types/FareSearchTypes";
interface InvariantDateInputProps extends BaseDateInputProps {
  Value: InvariantDateModel | undefined;
  OnDateChange(value: InvariantDateModel | undefined): void;
}
interface DateInputProps extends BaseDateInputProps {
  Value: Date | undefined;
  OnDateChange(value: Date | undefined): void;
}
interface BaseDateInputProps {
  SelectNextInput?(): void;
  InputID?: string;
  PopoverPosition?: PopoverPosition[];
  MinDate?: Date;
  MaxDate?: Date;
  InputGroup: boolean;
  InputClassNames?: string;
  PlaceHolder?: string;
  IsDob: boolean;
  booked1?: boolean;
  booked2?: boolean;
  bookedDays1?: any;
  bookedDays2?: any;
  bookedDays3?: any;
  bookedStyle1?: any;
  bookedStyle2?: any;
  bookedStyle3?: any;
  btnRef?: any;
  reference?: any;
  tabIndex?: any;
  required?: string;
  setdepartureCollector?: any;
  autoFocus?: boolean;
  setScrollingDown?: any;
  setIsChangeActive?: any;
  isDateInputInOrder?: boolean;
  segmentArr?: ISegmentState[] | any;
  scrollingDown?: boolean;
}
export const InvariantDateInput: React.FC<InvariantDateInputProps> = (
  props
) => {
  function OnDateChange(date: Date | undefined) {
    const update = GetNullableApiRequestDate(date);
    props.OnDateChange(update);
  }
  const date: Date | undefined = props.Value
    ? moment({
      day: props.Value.day,
      month: props.Value.month - 1,
      year: props.Value.year,
    }).toDate()
    : undefined;
  return <DateInput {...props} OnDateChange={OnDateChange} Value={date} />;
};

export const DateInput: React.FC<DateInputProps> = (props) => {
  const sessionInfo = useSelector((state: State) => state.Session);
  const { t } = useTranslation();
  const [inputStringValue, setInputStringValue] = useState("");
  const debug = false;
  const [inputDateValue, setInputDateValue] = useState<Date | undefined>(
    undefined
  );
  const [guessedDate, setGuessedDate] = useState<GuessDateResult | undefined>(
    undefined
  );
  const [inputActive, setInputActive] = useState<boolean>(false);
  const dateDisplay = useFormatDate(props.Value);
  const dateFormat = useDateFormat();

  function emptyDateValidation() {
    if (inputStringValue?.length == 0 || !props.isDateInputInOrder) {
      return "is-invalid";
    }
    if (inputStringValue?.length != 0) {
      return "is-valid";
    }
  }

  useEffect(() => {
    if (props.Value) {
      // console.log("dateDisplay", dateDisplay, props.Value);
      setInputStringValue(dateDisplay);
      setInputDateValue(props.Value);
      // props.btnRef?.current.focus();
    } else {
      setInputStringValue("");
      setInputDateValue(props.Value);
    }
  }, [props.Value]);

  const showDateInputSide: b2cSettingsText = (() => {
    try {
      return JSON.parse(
        sessionInfo?.FrontendSettings?.b2cSettingsText ||
        '{"HotelTab":"None","AirPortSearchType":"All","ShowLanguageDecimalTabB2C":"English","ShowExtraAncillariesTabB2C":"On","ShowDateInput":"Left","ShowDefaultFilter":"Off"}'
      );
    } catch {
      return {
        HotelTab: "All",
        AirPortSearchType: "All",
        ShowLanguageDecimalTabB2C: "English",
        ShowExtraAncillariesTabB2C: "On",
        ShowDateInput: "Left",
        ShowDefaultFilter: "Off",
      };
    }
  })();

  // is-invalid
  function stringSeparator(val: any) {
    const str = `${val}`;
    const { ShowDateInput } = showDateInputSide || {}; // Destructure showDateInputSide

    let depString: string;
    let arrString: string;
    let dayString: string;
    let monthString: string;

    if (ShowDateInput === "Right") {
      depString = str.slice(0, 3);
      arrString = str.slice(3, 6);
      dayString = str.slice(6, 8);
      monthString = str.slice(8, 10);
    } else {
      depString = str.slice(4, 7);
      arrString = str.slice(7, 10);
      dayString = str.slice(0, 2);
      monthString = str.slice(2, 4);
    }

    const departureL = depString.length > 2 ? depString : undefined;
    const arrivalL = arrString.length > 2 ? arrString : undefined;
    const dayL = dayString ? Number(dayString) : undefined;
    const monthL = monthString ? Number(monthString) : undefined;

    // Update props using object spread syntax
    props.setdepartureCollector((prev: any) => ({
      ...prev,
      departureL,
      arrivalL,
      DayL: dayL,
      MonthL: monthL,
    }));
  }

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    // console.log("onInputChange fired");
    const value: string = event.target.value;
    if (showDateInputSide?.ShowDateInput == "Right") {
      if (/^(?=.*\d.*\d.*\d.*\d)[A-Za-z\d]{6,}$/.test(value)) {
        //more 3 char more than 3 number
        stringSeparator(value);
      }
    } else {
      if (/^\d{4}[A-Za-z]{6}$/.test(value)) {
        //more 3 char more than 3 number
        stringSeparator(value);
      }
    }
    setGuessedDate(
      GuessDate(value, dateFormat, props.MinDate, props.MaxDate, props.IsDob)
    );
    setInputStringValue(value);
    setInputActive(false);
  }

  function onInputFocus(event: React.FocusEvent<HTMLInputElement>): void {
    if (props?.scrollingDown == false || props?.scrollingDown == undefined) {
      event.target.select();
    }
    if (props?.scrollingDown == true) {
      setInputActive(false);
    }
    sendPageHeight()
  }

  function onInputSelect(event: React.SyntheticEvent<HTMLInputElement, Event>) {
    if (props?.scrollingDown == false || props?.scrollingDown == undefined) {
      setInputActive(true);
    }
  }
  function onInputBlur(event: any) {
    // Check if the related target of the blur event is null or not the calendar

    if (event.relatedTarget === null || event.relatedTarget?.classList.contains('css-sun3y7') || event.relatedTarget?.classList.contains('css-195lt0x')) {
      // Do not set inputActive to false if the focus shifted to the calendar
      return;
    }
    OnInputLeave();
    setInputActive(false);
  }
  useEffect(() => {
    setInputActive(false);
  }, []);

  function OnDayPickerSelection(
    day: Date | undefined,
    selectedDay: Date,
    activeModifiers: ActiveModifiers,
    e: React.MouseEvent
  ) {
    if (debug) {
      console.log("OnDayPickerSelection fired");
    }
    OnSelectDate(selectedDay);
    sendPageHeightDefault()
  }

  function OnSelectDate(date: Date | undefined) {
    if (debug) {
      console.log("OnSelectDate fired");
    }
    setInputActive(false);
    props.OnDateChange(date);
    if (props.SelectNextInput) {
      // console.log("Datepicker selectnext fired");
      props.SelectNextInput();
    }
    setInputActive(false);
  }

  function handleYearMonthChange(date: Date) {
    if (debug) {
      console.log("handleYearMonthChange fired");
    }
    setInputDateValue(date);
  }

  function OnPopoverClickOutside(event: MouseEvent) {
    if (debug) {
      console.log("OnPopoverClickOutside fired");
    }
    setInputActive(false);
    OnInputLeave();
    sendPageHeightDefault()
  }

  function OnTakeGuessedDateButtonclick() {
    if (guessedDate) {
      OnSelectDate(guessedDate.Result);
    }
  }

  function OnClear() {
    props.OnDateChange(undefined);
  }

  function OnClosePicker() {
    if (debug) {
      console.log("OnClosePicker");
    }
    if (guessedDate) {
      OnSelectDate(guessedDate.Result);
    }
    setInputActive(false);
  }

  //today Date and current segment
  const indexSeg: number = Number(props.InputID?.match(/segment(\d+)_date/)![1])
  const currentDate = new Date();

  //From-To month cal
  const before: any = props.MinDate
    ? { before: currentDate }
    : undefined;
  const after: any = props.MaxDate ? {
    after: new Date(
      currentDate.getFullYear() + 2,
      currentDate.getMonth(),
      currentDate.getDate()
    ),
  } : undefined;

  const match: Matcher[] = [before ? before : [], after ? after : []];

  useEffect(() => {
    setInputActive(false);
  }, [props?.segmentArr?.length]);

  useEffect(() => {
    if (props?.scrollingDown == true || props?.scrollingDown == undefined) {
      setInputActive(false);
    }
  }, [props?.scrollingDown]);

  //height controller
  const sendPageHeight = () => {
    setTimeout(() => {
      const height = document.body.scrollHeight + 299;
      window.parent.postMessage(["setHeight", height + "px"], "*");
    }, 0);
  };
  const sendPageHeightDefault = () => {
    setTimeout(() => {
      const element = document.querySelector('.avialFarePageContainer'); // Replace with your CSS class
      if (element) {
        const height = element.scrollHeight;
        
        window.parent.postMessage(["setHeight", height + "px"], "*");
      }
    }, 0);
  
  };

  function ToolTipContent(popoverState: PopoverState): JSX.Element {
    // const res = this.GetDateGuess();
    return (
      <ArrowContainer
        position={popoverState.position}
        childRect={popoverState.childRect}
        popoverRect={popoverState.popoverRect}
        arrowColor="black"
        arrowSize={2}
        className="calenderShadow"
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Card>
            {/* <button
              type="button"
              className="btn btn-outline btn-sm me-2"
              onClick={OnClosePicker}
            >
              <FontAwesomeIcon icon={faTimes} /> close
            </button> */}
            <Calendar
              className="smartCalenderDiv"
              mode="single"
              onSelect={OnDayPickerSelection}
              selected={inputDateValue}
              modifiers={{
                booked1: props.bookedDays1,
                booked2: props.bookedDays2,
                booked3: props.bookedDays3,
              }}
              modifiersStyles={{
                booked1: props.bookedStyle1,
                booked2: props.bookedStyle2,
                booked3: props.bookedStyle3,
              }}
              // numberOfMonths={1}
              disabled={props.IsDob ? false : match}
              showOutsideDays={false}
              // month={inputDateValue}
              // fromMonth={props.IsDob ? props.MinDate : before?.before}
              toMonth={props.IsDob ? props.MaxDate : after?.after}
              fromYear={props.IsDob ? props.MinDate?.getFullYear() : before?.before?.getFullYear()}
              toYear={props.IsDob ? props.MaxDate?.getFullYear() : after?.after?.getFullYear()}
              defaultMonth={!props.IsDob ? props?.segmentArr[indexSeg - 1]?.DateInput : inputDateValue}
            />
            <div className="btn-group">
              {/* <button
                type="button"
                className="btn btn-sm btn-danger"
                style={{ color: "white" }}
                onClick={OnClear}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button> */}
              {/* {guessedDate && (
                <button
                  type="button"
                  className="btn ms-2 btn-sm btn-primary"
                  onClick={OnTakeGuessedDateButtonclick}
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} />{" "}
                  {guessedDate
                    ? guessedDate.ResultString
                    : "type to see autocomplete.."}
                </button>
              )} */}
            </div>
          </Card>
        </div>
      </ArrowContainer>
    );
  }

  function OnInputLeave() {
    if (debug) {
      console.log("Onleave fired");
    }

    if (inputStringValue !== "") {
      if (guessedDate) {
        OnSelectDate(guessedDate.Result);
      }
    } else {
      OnSelectDate(undefined);
    }
  }

  function OnInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.which === 13 || e.which === 9 || e.which === 27) {
      // TAB / ENTER / ESC
      setInputActive(false);
      // props.btnRef?.current.focus();
      if (!(e.which === 13 && e.ctrlKey)) {
        if (e.which !== 9) {
          // TAB
          e.preventDefault();
          e.stopPropagation();
        }
        OnInputLeave();
      }
    }
  }

  return (
    <Popover
      positions={
        props.PopoverPosition !== undefined
          ? props.PopoverPosition
          : ["bottom", "left", "right", "top"]
      }
      isOpen={inputActive}
      containerStyle={{ zIndex: "999999" }}
      onClickOutside={OnPopoverClickOutside}
      content={(popoverState: PopoverState) => ToolTipContent(popoverState)}
    >
      <div className={`${props.InputGroup ? "input-group" : ""}`}>
        {props.InputGroup && (
          <span className="input-group-text" title="Flight Date">
            <FontAwesomeIcon icon={faCalendarAlt} />
          </span>
        )}
        <input
          value={inputStringValue}
          tabIndex={props.tabIndex}
          onChange={onInputChange}
          // autoFocus={props.autoFocus ? true : false}
          autoFocus={false}
          type="text"
          className={`${props.InputClassNames ? props.InputClassNames : "form-control"
            } ${props.segmentArr && emptyDateValidation()}`}
          autoComplete={"off"}
          onKeyDown={(e) => {
            OnInputKeyDown(e);
            nextElementFocus({
              tabIndex: props.tabIndex,
              reference: { InputDate: props.reference },
            });
          }}
          onFocus={onInputFocus}
          onSelect={onInputSelect}
          onBlur={onInputBlur}
          id={props.InputID}
          placeholder={
            props.PlaceHolder !== undefined
              ? props.PlaceHolder
              : t("FareBooking.PassengerPage.Dob")
          }
          ref={props.reference}
          required
          onClick={(e) => {
            e.stopPropagation();
            props.setScrollingDown(false);
          }}
        />
      </div>
    </Popover>
  );
};

export default DateInput;
