import { ServiceAddObj } from "components/Fare/AncillariesModal";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import React, { Fragment, useState } from "react";
import { ServicePriceModel, ServiceSegmentModel } from "WebApiClient";
import { GroupedPassenger } from "./Ancillary";
import _ from "lodash";

interface AncillaryRelationProps {
  GroupedPassenger: GroupedPassenger;
  OnAddService(obj: ServiceAddObj): void;
  AddedServices: ServiceAddObj[];
  AllSegments: ServiceSegmentModel[];
  isServiceAdded: any;
  remove: any;
  removeValuesInBrackets: any;
}

export const AncillaryRelation: React.FC<AncillaryRelationProps> = ({
  GroupedPassenger,
  AddedServices,
  OnAddService,
  AllSegments,
  isServiceAdded,
  remove,
  removeValuesInBrackets,
}) => {
  const { Prices, Service, PassengerOptions } = GroupedPassenger;
  const uniquePriceArr = _.uniqWith(Prices, _.isEqual);

  function _OnAddService(price: ServicePriceModel, segments: ServiceSegmentModel[], passenger: number) {
    const addObj: ServiceAddObj = {
      Price: price,
      PassengerIndex: passenger,
      Segments: segments,
      Service: Service,
    };
    OnAddService(addObj);
    isServiceAdded(true);
  }

  function ServiceAlreadyAdded(
    price: ServicePriceModel,
    segments: ServiceSegmentModel[],
    passenger: number
  ): boolean {
    let result = false;
    let filtered = AddedServices.filter((e) =>
      e.PassengerIndex === passenger &&
      e.Price.priceID === price.priceID &&
      e.Service.bookingCode === Service.bookingCode &&
      e.Service.code === Service.code
    );

    if (filtered.length > 0) {
      filtered.forEach((element) => {
        element.Segments.forEach((s) => {
          segments.forEach((ss) => {
            if (
              ss.arrival.toLowerCase() === s.arrival.toLowerCase() &&
              ss.departure.toLowerCase() == s.departure.toLowerCase() &&
              ss.id == s.id
            ) {
              result = true;
            }
          });
        });
      });
      return result;
    }
    return false;
  }

  return (
    <div className="mt-2">
      {uniquePriceArr.map((price, priceIndex) => (
        <div className="d-flex align-items-center m-1"
          key={"price" + priceIndex}>
          <span className="w-25">
            {" "}
            {priceIndex == 0
              ? "Outbound"
              : uniquePriceArr.length == priceIndex + 1
                ? "Inbound"
                : "Midbound"}{" "}
          </span>
          <div className="card-body">
            <div className="">
              <div className="d-inline">
                {price.Segments.map((s, i) => (
                  <span className="d-block" key={"segI" + i}>
                    <span>{s?.departure}</span> - <span>{s?.arrival}</span>
                  </span>
                ))}
              </div>
            </div>
          </div>
          <div className="d-flex">
            {PassengerOptions.map((o: any, i: number) => (
              <div className="" key={"pass" + i}>
                {price.Price !== undefined &&
                  price.Price.priceID.length > 0 && (
                    <div className="">
                      {!ServiceAlreadyAdded(
                        price.Price!,
                        price.Segments,
                        o.Index
                      ) ? (
                        <button
                          disabled={ServiceAlreadyAdded(
                            price.Price!,
                            price.Segments,
                            o.Index
                          )}
                          className="btn btn-secondary m-2"
                          onClick={() => {
                            _OnAddService(
                              price.Price!,
                              price.Segments,
                              o.Index
                            );
                          }}
                        >
                          {o.Label.includes("(")
                            ? removeValuesInBrackets(o.Label)
                            : `Passenger ${i + 1} (${o.PassengerType})`}
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary m-2"
                          onClick={() => {
                            remove(price.Price!, price.Segments, o.Index);
                          }}
                        >
                          {o.Label.includes("(")
                            ? removeValuesInBrackets(o.Label)
                            : `Passenger ${i + 1}(${o.PassengerType})`}
                        </button>
                      )}
                    </div>
                  )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
