import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { faPaste } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
interface ErrorCardProps {
    ErrorOccured: boolean;
    ErrorMessages: string[];
    TransactionID: string | undefined
}
export const ErrorMessage: React.FC<ErrorCardProps> = ({ ErrorMessages, ErrorOccured, TransactionID }) => {
    function RenderErrorMessages(): JSX.Element {
        return <ul>
            {ErrorMessages.map((e, index) =>
                <li key={`bkError${index}`}>{e}</li>
            )}
        </ul>
    }
    return (
        <React.Fragment>
            {ErrorOccured &&
                <Row className="mb-2">
                    <Col colSpan={12}>
                        <div className="alert alert-danger" role="alert">
                            <h4 className="alert-heading">An error has occured.</h4>
                            {RenderErrorMessages()}
                            <hr />
                            <p>Try to to redo your last action.
                                {TransactionID &&
                                    <span>
                                        If this error persists, contact support with the Transaction ID <Button size="sm" color="info" outline onClick={() => { navigator.clipboard.writeText(TransactionID).then(() => { toast.success("TransactionID copied to clipboard.") }); }} className="font-weight-bold clickme"> <FontAwesomeIcon icon={faPaste} /> {TransactionID} </Button>
                                    </span>
                                }
                            </p>
                        </div>

                    </Col>
                </Row>
            }
        </React.Fragment>
    );
};

export const ValidationErrorMessage: React.FC<{ error: boolean; messages: string[] | undefined }> = ({ error, messages }) => {
    function RenderErrorMessages(): JSX.Element {
        if (messages && messages.length > 0) {
            if (messages.length > 1) {
                return <ul>
                    {messages.map((e, index) =>
                        <li key={`bkError1${index}`}>{e}</li>
                    )}
                </ul>

            }
            else {
                return <div>{messages[0]}</div>
            }

        }
        else {
            return <div>An unknown validation error occurred. Please review your input.</div>
        }
    }
    return (
        <React.Fragment>
            {error &&
                <Row className="mb-2">
                    <Col colSpan={12}>
                        <div className="alert alert-danger" role="alert">
                            <h4><FontAwesomeIcon icon={faExclamationTriangle} /> Something went wrong.</h4>
                            {RenderErrorMessages()}
                        </div>

                    </Col>
                </Row>
            }
        </React.Fragment>
    );
};

export const BackendNotReacheableError: React.FC<{}> = ({ }) => {


    return (
        <React.Fragment>

            <Row className="mb-2">
                <Col colSpan={12}>
                    <div className="alert alert-danger" role="alert">
                        <h4 className="alert-heading">We could not connect to our backend services.</h4>

                        <hr />

                    </div>

                </Col>
            </Row>

        </React.Fragment>
    );
};