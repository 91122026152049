import { faAddressBook, faChair, faFileInvoiceDollar, faInfoCircle, faPlane, faQuestionCircle, faServer, faStar, faStarExclamation, faStickyNote, faText, faUsers } from "@fortawesome/pro-regular-svg-icons";
import { PnrContactsCard } from "components/PNR/Pnr.ContactsCard";
import { PnrEnhancedRemarksCard } from "components/PNR/Pnr.EnhancedRemarksCard";
import { PnrOsisCard } from "components/PNR/Pnr.OsisCard";
import { PnrSksCard } from "components/PNR/Pnr.SksCard";
import { PnrSSRsCard } from "components/PNR/Pnr.SSRsCard";
import { TabbedContent, TabbedContentProps, TabProps } from "components/Shared/TabbedContent";
import React from "react";
import { BookingVersionItemModel, PnrResponseSegmentModel, SingleBookingContact } from '../../../WebApiClient';
import BookingAdditionalsCard from "./Booking.AdditionalsCard";
import { BookingGeneralCard } from "./Booking.GeneralCard";
import BookingPassengersCard from "./Booking.PassengersCard";
import BookingQuotesCard from "./Booking.QuotesCard";
import { BookingManagerSeatsCard } from "./Booking.SeatsCard";
import BookedFlightSegmentsCard from "./Booking.SegmentsCard";
import { BookingManagerServicesCard } from "./Booking.ServicesCard";
import BookingVariousInfoCard from "./Booking.VariousInfosCard";

interface BookingVersionItemProps {
  Data: BookingVersionItemModel;
  IsLatest: boolean;
}
const BookingVersionItem: React.FC<BookingVersionItemProps> = ({ IsLatest, Data }) => {
  const flightDetails = Data.flightDetailsInfo;
  const contacts: SingleBookingContact[] = [...flightDetails?.delivery ? [{ ...flightDetails.delivery }] : [], ...flightDetails?.invoice ? [{ ...flightDetails.invoice }] : []]



  function GetSegments(): PnrResponseSegmentModel[] {
    let result: PnrResponseSegmentModel[] = [];
    if (flightDetails) {
      flightDetails.legs.forEach(e => {
        result = result.concat(e.segments);
      })
    }
    return result;
  }
  const BookingSegments = GetSegments();
  const DetailsTabContent: TabbedContentProps = {
    EnableJump: true,

    Tabs: [
      {
        Component: <BookingGeneralCard Data={Data.generalInfo} CalculationResult={flightDetails?.calculationResult} />,
        Title: "General",
        Icon: faInfoCircle,
        AmountIndicator: undefined,

      },


      ...flightDetails ? [
        ...flightDetails.legs.length > 0 ? [{
          Component: <BookedFlightSegmentsCard Data={flightDetails.legs} />,
          Title: "Flight",
          Icon: faPlane,
          AmountIndicator: flightDetails.legs.length,
          RenderOnlyIfActive: true
        } as TabProps,] : [],
        ...flightDetails.passengers.length > 0 ? [{
          Component: <BookingPassengersCard Data={flightDetails.passengers} />,
          Title: "Passengers",
          Icon: faUsers,
          AmountIndicator: flightDetails.passengers.length,
          RenderOnlyIfActive: true
        } as TabProps,] : [],
        ...contacts.length > 0 ? [{
          Component: <PnrContactsCard data={contacts} />,
          Title: "Contacts",
          Icon: faAddressBook,
          AmountIndicator: contacts.length,
          RenderOnlyIfActive: true
        } as TabProps,] : [],
        ...flightDetails.passengerQuotes.length > 0 ? [{
          Component: <BookingQuotesCard Data={flightDetails.passengerQuotes} />,
          Title: "Quotes",
          Icon: faFileInvoiceDollar,
          AmountIndicator: flightDetails.passengerQuotes.length,
          RenderOnlyIfActive: true

        } as TabProps,] : [],


        ...flightDetails.ssrElements.length > 0 ? [{
          Component: <PnrSSRsCard data={flightDetails.ssrElements} bookingPassengers={flightDetails.passengers} segments={BookingSegments} />,
          Title: "SSR",
          Icon: faStarExclamation,
          AmountIndicator: flightDetails.ssrElements.length,
          RenderOnlyIfActive: true
        } as TabProps,] : [],
        ...flightDetails.osiElements.length > 0 ? [{
          Component: <PnrOsisCard data={flightDetails.osiElements} />,
          Title: "OSI",
          Icon: faStickyNote,
          AmountIndicator: flightDetails.osiElements.length,
          RenderOnlyIfActive: true
        } as TabProps,] : [],
        ...flightDetails.skElements.length > 0 ? [{
          Component: <PnrSksCard data={flightDetails.skElements} />,
          Title: "SK",
          Icon: faQuestionCircle,
          AmountIndicator: flightDetails.skElements.length,
          RenderOnlyIfActive: true
        } as TabProps,] : [],
        ...flightDetails.seats.length > 0 ? [{
          Component: <BookingManagerSeatsCard data={flightDetails.seats} bookingPassengers={flightDetails.passengers} segments={BookingSegments} />,
          Title: "Seats",
          Icon: faChair,
          AmountIndicator: flightDetails.seats.length,
          RenderOnlyIfActive: true
        } as TabProps,] : [],
        ...flightDetails.enhancedRemarks.length > 0 ? [{
          Component: <PnrEnhancedRemarksCard data={flightDetails.enhancedRemarks} />,
          Title: "Remarks",
          Icon: faText,
          AmountIndicator: flightDetails.enhancedRemarks.length,
          RenderOnlyIfActive: true
        } as TabProps,] : [],
        ...flightDetails.services.length > 0 ? [{
          Component: <BookingManagerServicesCard data={flightDetails.services} bookingPassengers={flightDetails.passengers} segments={BookingSegments} />,
          Title: "Services",
          Icon: faStar,
          AmountIndicator: flightDetails.services.length,
          RenderOnlyIfActive: true
        } as TabProps,] : [],
        ...flightDetails.variousElements.length > 0 ? [{
          Component: <BookingAdditionalsCard Data={flightDetails.variousElements} />,
          Title: "Various elements",
          Icon: faQuestionCircle,
          AmountIndicator: flightDetails.variousElements.length,
          RenderOnlyIfActive: true
        } as TabProps,] : [],
      ] : [],

      {
        Component: <BookingVariousInfoCard Data={Data.generalInfo} />,
        Title: "Various",
        Icon: faServer,
        AmountIndicator: undefined,
        RenderOnlyIfActive: true
      },
    ]
  }

  return (
    <React.Fragment>
      <div className="card mb-2">
        <div className="card-header card-header-primary">Version {Data.version} {IsLatest ? " (latest)" : ""}</div>
        <div className="card-body card-body-secondary">
          <TabbedContent {...DetailsTabContent} />
        </div>
      </div>
    </React.Fragment>
  );


}

export default BookingVersionItem;