import React, { useEffect, useState } from "react";
import Logo from "Styles/images/logo.png";
import Flag from "Styles/images/US.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faSearch } from "@fortawesome/pro-regular-svg-icons";
import FareSearch from "components/FareSearch/component/FareSearch";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { faBed, faPlane, faPlaneUp } from "@fortawesome/pro-regular-svg-icons";
import {
  AgentFrontendFareSearchOptions,
  AvailableFaresSearchRequest,
  FareSearchSubmitApiResponse,
} from "WebApiClient";
import { IFareSearchInputState } from "components/FareSearch/types/FareSearchTypes";
import LanguageSelector from "components/General/LanguageSelector";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { AgentApiURL } from "Constants";
import { State } from "rootExports/rootReducer";
import { useSelector } from "react-redux";

interface LandingPageInterface {
  IsModifySearch: boolean;
  OnSearchSubmitted: (response: FareSearchSubmitApiResponse) => void;
  LastSubmitted: AvailableFaresSearchRequest | undefined;
  OnReset: () => void;
  Config: AgentFrontendFareSearchOptions | any;
  Init: IFareSearchInputState | any;
  submitResult: FareSearchSubmitApiResponse | undefined;
  GoToResults?: () => void;
  showSearchForm: boolean;
  OnModify: any;
  boxRef?: any;
  setActiveTabHF?: any;
  activeTabHF?: any;
  scrollingDown?: any;
  setScrollingDown?: any;
  language: string;
}
// React.FC<LandingPageInterface>
const FareHeaderList: React.FC<LandingPageInterface> = (props) => {
  const { t } = useTranslation();
  const handleClickAway = () => {
    if (props.scrollingDown !== true) {
      props.setScrollingDown(true);
    }
  };
  const sessionInfo = useSelector((state: State) => state.Session);
  const [imageColor, setImageColor] = useState<string | null>(null);
  const hotelCheck = (() => {
    try {
      return JSON.parse(
        sessionInfo?.FrontendSettings?.b2cSettingsText ||
        '{"HotelTab":"All","AirPortSearchType":"All"}'
      );
    } catch {
      return { HotelTab: "All", AirPortSearchType: "All" };
    }
  })();

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ): void => {
    const img = event.currentTarget;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    const pixelData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

    // Define the target color and acceptable color range
    const targetColor = [33, 150, 243]; // RGB values for #2196F3
    const colorRange = 30; // Adjust this value to define the range of acceptable colors

    // Check if the image contains the target color or similar colors
    let hasColor = false;
    for (let i = 0; i < pixelData.length; i += 4) {
      const r = pixelData[i];
      const g = pixelData[i + 1];
      const b = pixelData[i + 2];

      // Check if the pixel color is within the acceptable range of the target color

      if (
        Math.abs(r - targetColor[0]) <= colorRange &&
        Math.abs(g - targetColor[1]) <= colorRange &&
        Math.abs(b - targetColor[2]) <= colorRange
      ) {
        hasColor = true;
        break;
      }
    }
    setImageColor(hasColor ? "color-2196F3" : "not-color-2196F3");
  };

  const toggleCollapse = () => {
    props.setScrollingDown(!props.scrollingDown);
  };

  // const sendPageHeight = () => {
  //   const height = document.body.scrollHeight;
  //   window.parent.postMessage(["setHeight", height + "px"], "*");
  // };

  // useEffect(() => {
  //   sendPageHeight();
  //   window.addEventListener("load", sendPageHeight);
  //   document.addEventListener("click", sendPageHeight, true);
  //   window.addEventListener("message", sendPageHeight);
  //   return () => {
  //     document.removeEventListener("click", sendPageHeight, true);
  //     window.removeEventListener("message", sendPageHeight);
  //   };
  // }, []);

  return (
    <>
      <div
        className={
          props.scrollingDown
            ? "p-3 text-white text-center fareHeader heightHeaderScroll"
            : "p-3 text-white text-center fareHeader"
        }
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={
                sessionInfo.isLoggedIn &&
                  sessionInfo.FrontendSettings.logoID !== ""
                  ? `${AgentApiURL}/publicservices/images/logo/${sessionInfo.FrontendSettings.logoID}`
                  : "images/logo.png"
              }
              onLoad={handleImageLoad}
              height="35"
              width="78"
              className={`img-fluid mb-2 companyLogo ${imageColor === "similar-color" ? "similarColorBackground" : ""
                }`}
              alt="company logo"
              style={{ filter: "drop-shadow(1px 1px 1px white)" }}
              onClick={props.OnModify}
            ></img>
          </div>
        </div>
        {props.showSearchForm && (
          <>
            <Card
              style={{ margin: "0px 10%" }}
              className={
                props.scrollingDown
                  ? "mt-3 cardForm cardPositionChangeScroll"
                  : "mt-3 cardForm zIndexBg"
              }
              onClick={toggleCollapse}
            >
              <CardBody
                className={props.scrollingDown ? "scrollHide" : "scrollHideOff"}
              >
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div>
                    {!props.scrollingDown && (
                      <>
                        <Nav tabs className="searchCard">
                          <div className="d-flex cardTop cardTopExtra">
                            <NavItem>
                              <NavLink
                                className={
                                  props.activeTabHF === "1" ? "active" : ""
                                }
                                onClick={() => props.setActiveTabHF("1")}
                              >
                                <FontAwesomeIcon icon={faPlaneUp} />{" "}
                                {t("LandingPage.SearchForm.Airline")}
                              </NavLink>
                            </NavItem>
                            {hotelCheck.HotelTab !== "None" && (
                              <NavItem>
                                <NavLink
                                  className={
                                    props.activeTabHF === "2" ? "active" : ""
                                  }
                                  onClick={() => props.setActiveTabHF("2")}
                                >
                                  <FontAwesomeIcon icon={faBed} />{" "}
                                  {t("LandingPage.SearchForm.Hotels")}
                                </NavLink>
                              </NavItem>
                            )}
                          </div>
                        </Nav>
                      </>
                    )}
                    <TabContent activeTab={props.activeTabHF}>
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <FareSearch
                              OnReset={props.OnReset}
                              IsModifySearch={props.IsModifySearch}
                              OnSearchSubmitted={props.OnSearchSubmitted}
                              LastSubmitted={props.LastSubmitted}
                              submitResult={props.submitResult}
                              GoToResults={props.GoToResults}
                              boxRef={props.boxRef}
                              setScrollingDown={props.setScrollingDown}
                              language={props.language}
                              isNext={false}
                              scrollingDown={props.scrollingDown}
                              isFareListHeader={true}
                              isB2cAirline={false}
                            />
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="6">
                            <Card body>
                              <CardTitle>Special Title Treatment</CardTitle>
                              <CardText>
                                With supporting text below as a natural lead-in
                                to additional content.
                              </CardText>
                              <Button>Go somewhere</Button>
                            </Card>
                          </Col>
                          <Col sm="6">
                            <Card body>
                              <CardTitle>Special Title Treatment</CardTitle>
                              <CardText>
                                With supporting text below as a natural lead-in
                                to additional content.
                              </CardText>
                              <Button>Go somewhere</Button>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                    {props.scrollingDown && (
                      <div
                        className={"scroll-to-top-buttonHIdeO"}
                        onClick={() => props.setScrollingDown(false)}
                      >
                        <FontAwesomeIcon icon={faArrowDown} />
                      </div>
                    )}
                  </div>
                </ClickAwayListener>
              </CardBody>
            </Card>
            <div
              className={`${props.scrollingDown ? "" : "modalEffectBg"}`}
            ></div>
          </>
        )}
      </div>
    </>
  );
};

export default FareHeaderList;
