import {
  faLocationDot,
  faAngleDown,
  faAngleUp,
  faThumbTack,
  faThumbsUp,
  faThumbsDown,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Tooltip } from "reactstrap";
import HotelDetails from "./HotelDetails";
import SubRatingPopup from "components/HotelSearch/component/SubRatingPopup";
import { useAvailableHotelsClient } from "hooks/useHttpClient";
interface AvailableHotels {
  result: any;
  activeTab: any;
  setSelectedHotel?: any;
  indexH: number;
  setOpenIndex: any;
  openIndex: any;
  handleItemClick: any;
  HotelSearch: any;
  onRemove: any;
  addLikeHotel: any;
  setCustomerInfo: any;
  customerInfo: any;
}

const AvailableHotels: React.FC<AvailableHotels> = (props) => {
  const [hotelRes, setHotelRes] = useState<any>(props?.result);
  const [hotelResLast, setHotelResLast] = useState<any>([]);
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [activeTab, setActiveTab] = React.useState<string>("tab2");
  const [isOpen, setIsOpen] = useState(false);
  const [code, setCode] = useState<string>("");
  const Hotel = useAvailableHotelsClient();
  const [isBookChecker, setisBookChecker] = useState(0);

  // const toggleSectionVisibility = (tabId: any) => {
  //     setActiveTab((prevTabId) => {
  //         if (prevTabId === tabId) {
  //             // Toggle the visibility of the section if the clicked tab is already active
  //             setIsSectionVisible((prevState) => !prevState);
  //         } else {
  //             // Set the new tabId and make the section visible
  //             setIsSectionVisible(true);
  //             return tabId;
  //         }
  //     });
  // };

  const toggleSectionVisibility = (tabId: any) => {
    setIsSectionVisible((prevState) => (prevState ? false : true));
    setActiveTab(tabId);
  };

  var isBook: any;
  useEffect(() => {
    setHotelRes(props.result);
  }, [props.result?.hitchHiker?.guid]);

  const bookHotel = async (e: any) => {
    if (
      isBookChecker !== 0 &&
      selectedCell.rowIndex >= 0 &&
      selectedCell.columnIndex >= 0
    ) {
      toggleSectionVisibility("tab6"); // Toggle the section visibility

      props.handleItemClick(props.indexH);
      props.setSelectedHotel(props.result);
      const bookHotel=await Hotel.GetPreBookHotel(
        props.result?.tripAdvisor?.id,
        code,
        props.HotelSearch.roomGuests.adults,
        props.HotelSearch.checkIn,
        props.HotelSearch.nights
      );
      console.log(bookHotel);
      
    }
    // setHotelRes({})
  };

  const [selectedCell, setSelectedCell] = useState<{
    rowIndex: number;
    columnIndex: number;
  }>({ rowIndex: -1, columnIndex: -1 });
  const handleCellClick = (
    price: any,
    rowIndex: number,
    columnIndex: number,
    code: string
  ) => {
    // setActiveTab("tab4")
    // setIsSectionVisible(true);
    setSelectedCell({ rowIndex, columnIndex });
    setCode(code);
  };
  function BookChecker(e: any) {
    const value = Number(e.target.textContent);
    setisBookChecker(value);
  }

  const filledStar = (
    <span role="img" aria-label="star" className="star-icon star">
      ★
    </span>
  );
  const emptyStar = (
    <span role="img" aria-label="star" className="star-icon star">
      ☆
    </span>
  );

  const starIcons = [];
  for (let i = 1; i <= 5; i++) {
    starIcons.push(i <= hotelRes?.tripAdvisor?.stars ? filledStar : emptyStar);
  }

  const amenities = [
    { name: "24h reception", emoji: "🕛" },
    { name: "accessibility", emoji: "♿️" },
    { name: "air condition", emoji: "❄️" },
    { name: "beauty salon", emoji: "💇‍♀️" },
    { name: "bikes", emoji: "🚲" },
    { name: "concierge", emoji: "💼" },
    { name: "hair dryer", emoji: "💇" },
    { name: "internet", emoji: "🌐" },
    { name: "iron", emoji: "🔨" },
    { name: "no smoking", emoji: "🚭" },
    { name: "parking lot", emoji: "🅿️" },
    { name: "pets friendly", emoji: "🐾" },
    { name: "refrigerator", emoji: "🧊" },
    { name: "spa", emoji: "💆" },
    { name: "tv", emoji: "📺" },
    { name: "tv channels (satellite)", emoji: "📡" },
  ];

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const changePriceFormat = (price: number | undefined): string => {
    if (typeof price === "number") {
      const priceString = price.toFixed(2);
      return priceString.replace(".", ",");
    }
    return ""; // or any other default value if needed
  };

  useEffect(() => {
    let sortedArr = { ...props.result }; // Initialize sortedArr with props.result
    if (props.result?.hitchHiker?.guid) {
      const sortedPObject = props.result?.PriceData?.sort(
        (a: any, b: any) => a.price?.amount - b.price?.amount
      );
      sortedArr = { ...props.result, PriceData: sortedPObject };
    }
    const priceData = sortedArr.PriceData;
    const uniqueNames = new Set(
      priceData.map((item: any) => item.items[0].name)
    );
    const mergedData: any = [];
    uniqueNames.forEach((name: any) => {
      const itemsWithSameName = priceData.filter(
        (item: any) => item.items[0].name === name
      );
      const combinedBoards = itemsWithSameName.map((item: any) => ({
        code: item.code,
        boardRO: item.items[0].board == "RO" && item.items[0].board,
        boardBB: item.items[0].board == "BB" && item.items[0].board,
        boardHB: item.items[0].board == "HB" && item.items[0].board,
        boardAI: item.items[0].board == "AI" && item.items[0].board,
        boardFB:
          (item.items[0].board == "FB" || item.items[0].board === "VP") &&
          item.items[0].board,
        priceFB:
          (item.items[0].board == "FB" || item.items[0].board === "VP") &&
          item.price.amount,
        priceRO: item.items[0].board == "RO" && item.price.amount,
        priceBB: item.items[0].board == "BB" && item.price.amount,
        priceHB: item.items[0].board == "HB" && item.price.amount,
        priceAI: item.items[0].board == "AI" && item.price.amount,
        currency: item.price.currency,
      }));

      const combinedObject = combinedBoards.reduce((acc: any, current: any) => {
        Object.keys(current).forEach((key) => {
          if (current[key] !== false) {
            acc[key] = current[key];
          }
        });
        return acc;
      }, {});
      let mergedItem = { name, item: {}, boardsWithPrice: combinedObject };
      // Create new fields boardRO, boardAI, etc. in item.items[0]
      const newItem = {
        ...props.result?.hitchHiker,
        boardRO: null,
        boardBB: null,
        boardVP: null,
        boardHP: null,
        boardAI: null,
        boardFB: null,
      };
      // Set the values for boardRO and boardAI based on conditions
      combinedBoards.forEach((boardObj: any) => {
        if (boardObj.board === "RO") {
          newItem.boardRO = boardObj.price.toFixed(2);
        } else if (boardObj.board === "BB") {
          newItem.boardBB = boardObj.price.toFixed(2);
        } else if (boardObj.board === "HP") {
          newItem.boardHP = boardObj.price.toFixed(2);
        } else if (boardObj.board === "VP") {
          newItem.boardVP = boardObj.price.toFixed(2);
        } else if (boardObj.board === "AI") {
          newItem.boardAI = boardObj.price.toFixed(2);
        } else if (boardObj.board === "FB") {
          newItem.boardFB = boardObj.price.toFixed(2);
        }
        // You can add more conditions for other board types if needed
      });

      mergedItem.item = newItem;
      // Use newItem directly as the item for mergedItem
      mergedData.push(mergedItem);
    });
    setHotelRes({ ...sortedArr }); // Update the state with updated sortedArr
    setHotelResLast(mergedData);
  }, [props.result?.hitchHiker?.guid]);

  const getPriceClassName = (
    board: string,
    amount: number,
    rowIndex: number,
    columnIndex: number,
    targetBoard: string
  ) => {
    const isSelected =
      board === targetBoard &&
      amount !== 0 &&
      selectedCell.rowIndex === rowIndex &&
      selectedCell.columnIndex === columnIndex;
    isBook = isSelected;
    return board === targetBoard &&
      amount !== 0 &&
      selectedCell.rowIndex === rowIndex &&
      selectedCell.columnIndex === columnIndex
      ? "selected"
      : "";
  };

  const openReview = (e: any) => {
    // toggleSectionVisibility("tab2");
    // setActiveTab("tab3")
    // setIsSectionVisible(true);
    toggleSectionVisibility("tab3"); // Toggle the section visibility
    props.handleItemClick(props.indexH);
  };

  const getBackgroundColor = (ratingValue: number) => {
    if (ratingValue < 3) {
      return "red";
    } else if (ratingValue >= 3 && ratingValue < 4) {
      return "orange";
    } else if (ratingValue >= 4 && ratingValue < 4.5) {
      return "yellow";
    } else if (ratingValue >= 4.5) {
      return "green";
    }
  };

  const getTextColor = (ratingValue: number) => {
    if (ratingValue < 3) {
      return "white"; // Set a readable text color for rating less than 3
    } else if (ratingValue >= 3 && ratingValue < 4.5) {
      return "black"; // Set a readable text color for other ranges
    } else if (ratingValue >= 4.5) {
      return "white"; // Set a readable text color for the Excellent range
    }
  };
  const getRatingMessage = (ratingValue: number) => {
    if (ratingValue < 3) {
      return "Poor";
    } else if (ratingValue >= 3 && ratingValue < 4) {
      return "Average";
    } else if (ratingValue >= 4 && ratingValue < 4.5) {
      return "Good";
    } else if (ratingValue >= 4.5) {
      return "Excellent";
    }
  };

  //   console.log("result",props.result);

  const [likeActive, setLikeActive] = useState(false);

  const handleLikeClick = (e: any, likedHotelObj: any) => {
    e.stopPropagation();
    setLikeActive(!likeActive);
    props.addLikeHotel(likedHotelObj);
  };

  return (
    <section key={"availableHotel" + props.result.hitchHiker.guid}>
      {props.result?.hitchHiker?.guid ? (
        <section
          className={
            props.result.display == "off" ? "HotelList dNone" : "HotelList"
          }
        >
          <Card
            className="my-2"
            onClick={(e) => {
              e.stopPropagation();
              toggleSectionVisibility("tab1");
              props.handleItemClick(props.indexH);
            }}
          >
            <CardBody>
              <section className="d-flex">
                <div className="w-25" style={{ paddingRight: "20px" }}>
                  <img
                    className="mr-5 img-fluid"
                    width={400}
                    src={hotelRes?.hitchHiker?.galleryPicture}
                    alt=""
                  />
                </div>
                <div className="w-75">
                  <div className="d-flex justify-content-between mb-3">
                    <div style={{ width: "65%" }}>
                      <h1>
                        {hotelRes?.tripAdvisor?.name} <span>{starIcons}</span>
                      </h1>
                      <p className="mb-1">
                        <FontAwesomeIcon icon={faLocationDot} />{" "}
                        {hotelRes?.tripAdvisor?.address}
                      </p>
                      {hotelRes?.tripAdvisor?.facilities?.list.map(
                        (amenity: any, index: any) => {
                          const matchingAmenity = amenities.find(
                            (item) => item.name === amenity
                          );
                          return (
                            <span
                              key={index + "aminity" + "-" + amenity}
                              style={{ cursor: "pointer", fontSize: "18px" }}
                              data-bs-toggle="tooltip"
                              title={amenity}
                            >
                              {matchingAmenity ? matchingAmenity.emoji : ""}
                            </span>
                          );
                        }
                      )}
                    </div>
                    <div style={{ width: "35%" }} className="text-end">
                      {/* <strong>Starting from</strong> */}
                      <h1>
                        {props.result?.PriceData[0]?.price?.currency}{" "}
                        {changePriceFormat(
                          props.result?.PriceData[0]?.price?.amount
                        )}
                      </h1>
                      <div
                        style={{
                          // backgroundColor:
                          padding: "10px",
                          borderRadius: "5px",
                        }}
                      >
                        {props.result?.tripAdvisor?.tripAdvisorData?.rating !==
                          undefined && (
                          <p
                            style={{
                              fontWeight: "700",
                              cursor: "pointer",
                              color: getTextColor(
                                props.result?.tripAdvisor?.tripAdvisorData
                                  ?.rating
                              ),
                              backgroundColor: getBackgroundColor(
                                props.result?.tripAdvisor?.tripAdvisorData
                                  ?.rating
                              ),
                            }}
                            className="ratingText"
                            onMouseEnter={toggleModal}
                            onMouseLeave={toggleModal}
                            onClick={(e) => {
                              e.stopPropagation();
                              openReview(e);
                            }}
                          >
                            {getRatingMessage(
                              props.result?.tripAdvisor?.tripAdvisorData?.rating
                            )}{" "}
                            <FontAwesomeIcon
                              icon={!isSectionVisible ? faAngleUp : faAngleDown}
                            />
                          </p>
                        )}
                      </div>
                      {isOpen && (
                        <section>
                          <SubRatingPopup
                            ratingData={
                              props.result?.tripAdvisor?.tripAdvisorData
                                ?.subRating
                            }
                            isOpen={isOpen}
                            hotelData={hotelRes}
                          />
                        </section>
                      )}
                    </div>
                  </div>
                  <div className="d-flex align-items-start">
                    <div className="w-75">
                      <table className="table table-sm priceTable bdr">
                        <thead className="table-dark">
                          <tr>
                            <th scope="col">Room Type</th>
                            <th scope="col">RO</th>
                            <th scope="col">BB</th>
                            <th scope="col">HB</th>
                            <th scope="col">FB</th>
                            <th scope="col">AI</th>
                          </tr>
                        </thead>
                        {hotelResLast?.map((data: any, index1: number) => (
                          <tbody key={index1 + "id" + "_" + data.name}>
                            <tr>
                              <td>{data.name}</td>
                              <td
                                className={getPriceClassName(
                                  data.boardsWithPrice.boardRO,
                                  data.boardsWithPrice.priceRO,
                                  index1,
                                  1,
                                  "RO"
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCellClick(
                                    data.boardsWithPrice.priceRO,
                                    index1,
                                    1,
                                    data.boardsWithPrice.code
                                  );
                                  BookChecker(e);
                                }}
                              >
                                {data.boardsWithPrice.priceRO
                                  ? data.boardsWithPrice.priceRO.toFixed(2)
                                  : null}
                              </td>
                              <td
                                className={getPriceClassName(
                                  data.boardsWithPrice.boardBB,
                                  data.boardsWithPrice.priceBB,
                                  index1 + 2,
                                  2,
                                  "BB"
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCellClick(
                                    data.boardsWithPrice.priceBB,
                                    index1 + 2,
                                    2,
                                    data.boardsWithPrice.code
                                  );
                                  BookChecker(e);
                                }}
                              >
                                {data.boardsWithPrice.priceBB
                                  ? data.boardsWithPrice.priceBB.toFixed(2)
                                  : null}
                              </td>
                              <td
                                className={getPriceClassName(
                                  data.boardsWithPrice.boardHB,
                                  data.boardsWithPrice.priceHB,
                                  index1 + 3,
                                  3,
                                  "HB"
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCellClick(
                                    data.boardsWithPrice.priceHB,
                                    index1 + 3,
                                    3,
                                    data.boardsWithPrice.code
                                  );
                                  BookChecker(e);
                                }}
                              >
                                {data.boardsWithPrice.priceHB
                                  ? data.boardsWithPrice.priceHB.toFixed(2)
                                  : null}
                              </td>
                              <td
                                className={getPriceClassName(
                                  data.boardsWithPrice.boardFB,
                                  data.boardsWithPrice.priceFB,
                                  index1 + 4,
                                  4,
                                  "FB"
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCellClick(
                                    data.boardsWithPrice.priceFB,
                                    index1 + 4,
                                    4,
                                    data.boardsWithPrice.code
                                  );
                                  BookChecker(e);
                                }}
                              >
                                {data.boardsWithPrice.priceFB
                                  ? data.boardsWithPrice.priceFB.toFixed(2)
                                  : null}
                              </td>
                              {/* <td
                    className={getPriceClassName(data.boardsWithPrice.boardVP, data.boardsWithPrice.priceVP, index1 + 4, 4, 'VP')}
                    onClick={(e) => { handleCellClick(data.boardsWithPrice.priceVP, index1 + 4, 4); BookChecker(e) }}
                >
                    {data.boardsWithPrice.priceVP ? data.boardsWithPrice.priceVP.toFixed(2) : null}
                </td> */}
                              <td
                                className={getPriceClassName(
                                  data.boardsWithPrice.boardAI,
                                  data.boardsWithPrice.priceAI,
                                  index1 + 5,
                                  5,
                                  "AI"
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCellClick(
                                    data.boardsWithPrice.priceAI,
                                    index1 + 5,
                                    5,
                                    data.boardsWithPrice.code
                                  );
                                  BookChecker(e);
                                }}
                              >
                                {data.boardsWithPrice.priceAI
                                  ? data.boardsWithPrice.priceAI.toFixed(2)
                                  : null}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                    <div className="w-25">
                      <div className="text-end">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary  btnWidth mb-2"
                            onClick={(e) => {
                              e.stopPropagation();
                              bookHotel(e);
                            }}
                          >
                            {isBookChecker !== 0 &&
                            selectedCell.rowIndex >= 0 &&
                            selectedCell.columnIndex >= 0
                              ? "Book"
                              : "Select Price"}
                          </button>
                          <br />
                          {/* <button
                            className="btn btn-primary btnWidth  mt-3"
                            onClick={() => {
                              props.onRemove(props.result);
                            }}
                          >
                            Remove
                          </button> */}
                        </div>
                        <div className="buttonContainer mt-3">
                          <div
                            itemType="button"
                            className="ellipticalButton btnWidth text-center ml-5"
                          >
                            <div
                              className={`likeSection ${
                                props.result.liked || likeActive ? "active" : ""
                              }`}
                              onClick={(e) => handleLikeClick(e, props.result)}
                            >
                              <span className="likeIcon">
                                <FontAwesomeIcon icon={faThumbsUp} />
                              </span>
                            </div>
                            <div
                              className="dislikeSection"
                              onClick={() => {
                                props.onRemove(props.result);
                              }}
                            >
                              <span className="dislikeIcon">
                                <FontAwesomeIcon icon={faThumbsDown} />
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* <div className="likeDislikeContainer">
                          <div
                            className={`likeButton ${
                              likeClicked ? "clicked" : ""
                            }`}
                            onClick={handleLikeClick}
                          >
                            <FontAwesomeIcon icon={faThumbsUp} />
                          </div>
                          <div
                            className={`dislikeButton ${
                              dislikeClicked ? "clicked" : ""
                            }`}
                            onClick={handleDislikeClick}
                          >
                            <FontAwesomeIcon icon={faThumbsDown} />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </CardBody>
          </Card>

          {isSectionVisible && (
            <HotelDetails
              handleItemClick={props.handleItemClick}
              openIndex={props.openIndex}
              setOpenIndex={props.setOpenIndex}
              indexH={props.indexH}
              tabId={activeTab}
              hotelData={hotelRes}
              isSectionVisible={isSectionVisible}
              HotelSearch={props.HotelSearch}
              setCustomerInfo={props.setCustomerInfo}
              customerInfo={props.customerInfo}
            />
          )}
        </section>
      ) : null}
    </section>
  );
};

export default AvailableHotels;
