import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
} from "reactstrap";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { useBookingClient } from "hooks/useHttpClient";
import {
  BookingIdentifier,
  BookingIdentifierwithEmail,
  BookingItemModel,
} from "WebApiClient";
import { toast } from "react-toastify";
//mui
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCancel,
  faCross,
  faTimesCircle,
  faUndo,
} from "@fortawesome/pro-regular-svg-icons";

import TextareaAutosize from "@mui/material/TextareaAutosize";
import { State } from "rootExports/rootReducer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface ImportBookingModalProps {
  Booking: BookingItemModel | any;
  Toggle: () => void;
  IsOpen: boolean;
  IsPopUp: boolean;
  // data: BaseApiResponseMetaData | undefined
}

const ConfirmDetailSendModal: React.FC<ImportBookingModalProps> = (props) => {
  const bookingClient = useBookingClient();
  const data = props.Booking.latestVersion!;
  const [multiEmail, setMultiEmail] = useState<any>([]);
  const [checkedItems, setCheckedItems] = useState<any>({});
  const [checkedItems2, setCheckedItems2] = useState<any>({});
  const [checkedItems3, setCheckedItems3] = useState<any>({});
  const [selectedEmails, setSelectedEmails] = useState<string>("");
  const [selectedEmails2, setSelectedEmails2] = useState<string>("");
  const [selectedEmails3, setSelectedEmails3] = useState<string>("");
  const [EmailTemplateName, setEmailTemplateName] = useState<string>("");
  const [textAreaDesc, setTextAreaDesc] = useState<string>("");
  const { t } = useTranslation();
  useEffect(() => {
    getEmailAddress(
      props.Booking?.latestVersion?.flightDetailsInfo?.enhancedRemarks ?? []
    );
  }, [props.IsOpen]);

  useEffect(() => {
    setCheckedItems({});
    setCheckedItems2({});
    setCheckedItems3({});
    setSelectedEmails2("");
    setSelectedEmails("");
    setSelectedEmails3("");
  }, []);

  function getEmailAddress(array: any) {
    const emailRegex = /\S+@\S+\.\S+/; // A simple email regex pattern
    let foundEmails: any[] = [];
    for (const item of array) {
      const words = item.remark.split(" ");
      if (words.includes("EM")) {
        foundEmails.push({ EM: words[1] });
      }
      if (words.includes("HE")) {
        foundEmails.push({ HE: words[1] });
      }
    }

    // Add the emergency contact email if available
    if (
      props.Booking?.latestVersion?.flightDetailsInfo?.invoice?.phoneNumbers[1]
        ?.number
    ) {
      const emergencyContactEmail =
        props.Booking?.latestVersion?.flightDetailsInfo?.invoice
          ?.phoneNumbers[1]?.number;
      if (emailRegex.test(String(emergencyContactEmail))) {
        foundEmails.push({ emergencyContact: emergencyContactEmail });
      }
    }

    // Create a Set to store seen values
    const uniqueValues: { [key: string]: boolean } = {};

    // Filter the array to remove duplicate email addresses
    const uniqueArray = foundEmails.filter((obj: any) => {
      const value: any = Object.values(obj)[0]; // Get the value of the object
      if (!uniqueValues[value]) {
        uniqueValues[value] = true; // Mark the value as encountered
        return true; // Include the object in the result
      }
      return false; // Exclude the object from the result
    });

    // Set the multi-email array
    setMultiEmail(uniqueArray);

    // Return the filtered emails (if any)
    return foundEmails.length > 0 ? foundEmails : null;
  }

  function ConvertXMLToJSON() {
    return bookingClient
      .downloadBackOfficeFile(data.bookingIdentifier!)
      .then((e: any) => {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = function () {
            const xmlString: any = reader.result;
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlString, "text/xml");
            const jsonObj: any = {};

            function xmlElementToJSON(element: any) {
              if (element.hasChildNodes()) {
                const obj: any = {};
                for (let i = 0; i < element.childNodes.length; i++) {
                  const item = element.childNodes[i];
                  if (item.nodeType === 1) {
                    if (
                      item.childNodes.length === 1 &&
                      item.firstChild.nodeType === 3
                    ) {
                      obj[item.nodeName] = item.firstChild.nodeValue;
                    } else {
                      if (!obj[item.nodeName]) {
                        obj[item.nodeName] = xmlElementToJSON(item);
                      } else if (Array.isArray(obj[item.nodeName])) {
                        obj[item.nodeName].push(xmlElementToJSON(item));
                      } else {
                        obj[item.nodeName] = [
                          obj[item.nodeName],
                          xmlElementToJSON(item),
                        ];
                      }
                    }
                  }
                }
                return obj;
              } else {
                return ""; // Return empty string for elements with no child nodes
              }
            }
            jsonObj[xmlDoc.documentElement.nodeName] = xmlElementToJSON(
              xmlDoc.documentElement
            );
            const jsonString = JSON.stringify(jsonObj, null, 2);
            resolve(jsonString);
          };
          reader.readAsText(e.data);
        });
      })
      .catch(() => {
        toast.error("An error occurred while converting the XML to JSON.");
        throw new Error("XML to JSON conversion failed.");
      });
  }

  function removeKeyFromObject(obj: any, keyToRemove: any) {
    if (obj.hasOwnProperty(keyToRemove)) {
      delete obj[keyToRemove];
    }
    return obj;
  }

  //TO email
  useEffect(() => {
    // When checkedItems change, update the selectedEmails string
    const selectedEmailArray = Object.keys(checkedItems).filter(
      (email) => checkedItems[email]
    );
    setSelectedEmails(selectedEmailArray.join(";"));
  }, [checkedItems]);

  const handleCheckboxChange = (email: any) => {
    // setCheckedItems2(removeKeyFromObject(checkedItems2, email));
    // setCheckedItems3(removeKeyFromObject(checkedItems3, email));
    setCheckedItems({
      ...checkedItems,
      [email]: !checkedItems[email],
    });
  };
  //cc email
  useEffect(() => {
    // When checkedItems change, update the selectedEmails string
    const selectedEmailArray2 = Object.keys(checkedItems2).filter(
      (email) => checkedItems2[email]
    );
    setSelectedEmails2(selectedEmailArray2.join(";"));
  }, [checkedItems2]);

  const handleCheckboxChange2 = (email: any) => {
    setCheckedItems2((prevCheckedItems2:any) => {
      // If the email is already present, remove it; otherwise, add it
      if (prevCheckedItems2[email]) {
        const { [email]: _, ...rest } = prevCheckedItems2;
        return rest;
      } else {
        return {
          ...prevCheckedItems2,
          [email]: true,
        };
      }
    });
  };
  //Bcc email
  useEffect(() => {
    // When checkedItems change, update the selectedEmails string
    const selectedEmailArray3 = Object.keys(checkedItems3).filter(
      (email) => checkedItems3[email]
    );
    setSelectedEmails3(selectedEmailArray3.join(";"));
  }, [checkedItems3]);

  const handleCheckboxChange3 = (email: any) => {
    setCheckedItems3({
      ...checkedItems3,
      [email]: !checkedItems3[email],
    });
  };
  
  var otherSettings: string = useSelector((state: State) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None\n[]\nfalse\nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#232323"}showFareHeader=OffshowSmartCalender=None\n[]\nfalse\nfalse\n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None';
    }
    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });

  let settingArr = otherSettings.split("\n");
  let fieldContainer: string[];

  if (settingArr[7]) {
    try {
      fieldContainer = JSON.parse(settingArr[7])
        .Container.split("#")
        .filter((item: any) => item !== "");
    } catch (error) {
      // Provide a default value or error indicator
      fieldContainer = [];
    }
  } else {
    // Provide a default value or error indicator
    fieldContainer = [];
  }

  useEffect(() => {
    try {
      // const isBranch = JSON.parse( settingArr[7] !== undefined ? settingArr[7] : "");
      const isMail = fieldContainer.find((item) => item.startsWith("MAIL="));
      let mailValue = "";
      if (isMail) {
        mailValue = isMail.split("=")[1];
        setEmailTemplateName(mailValue);
      }
    } catch (error) {
      setEmailTemplateName(""); // Handle parsing error
    }
  }, [fieldContainer]);

  async function sendConfirmTicket() {
    let ToMail = Object.entries(checkedItems)
      .filter(([email, isSelected]) => isSelected)
      .map(([email]) => email);
    let CcMail = Object.entries(checkedItems2)
      .filter(([email, isSelected]) => isSelected)
      .map(([email]) => email);
    let BccMail = Object.entries(checkedItems3)
      .filter(([email, isSelected]) => isSelected)
      .map(([email]) => email);
    let finalMail = {
      To: ToMail.length > 0 ? ToMail : null,
      Cc: CcMail.length > 0 ? CcMail : null,
      Bcc: BccMail.length > 0 ? BccMail : null,
    };

    let Description = textAreaDesc.length > 0 ? textAreaDesc : null;
    if (finalMail.Bcc == null && finalMail.To == null && finalMail.Cc == null) {
      toast.error("please atleast add one mail");
    } else {
      try {
        // const jsonString: any = await ConvertXMLToJSON();
        // console.log("json", jsonString);
        var Ids: any;
        if (data?.bookingIdentifier != undefined) {
          Ids = data.bookingIdentifier;
        } else {
          Ids = {
            cartId: props.Booking.shoppingCartID,
            bookingId: props.Booking.bookings[0].bookingResponse.bookingItemID,
          };
        }

        const Obj: BookingIdentifierwithEmail = {
          cartId: Ids.cartId,
          bookingId: Ids.bookingId,
          emailIds: finalMail,
          Description: Description,
          TemplateName: EmailTemplateName,
        };

        await bookingClient.SendConfirmTicket(Obj);
        toast.success(t("FareBooking.Check&BookPage.EmailNotification"));
        setCheckedItems({});
        setCheckedItems2({});
        setCheckedItems3({});
        setSelectedEmails2("");
        setSelectedEmails("");
        setSelectedEmails3("");
        props.Toggle();
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while sending the confirmation email.");
        props.Toggle();
      }
    }
  }
  //if no email found then u can add one
  const [customEmail, setCustomEmail] = useState<string>("");
  const [checkDuplicate, setCheckDuplicate] = useState<string>("0");

  const handleCustomEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.value);
    setCustomEmail(e.target.value);
    // Reset duplicate check when the input changes
    setCheckDuplicate("0");
  };

  const addNewMail = () => {
    let newMailObj: any = {};
    if (customEmail === "") {
      setCheckDuplicate("2");
    } else {
      newMailObj = {
        [`NewMail${multiEmail?.length + 1}`]: customEmail,
      };
      // Check if the searchString is present in any of the object properties
      const isStringInArray = multiEmail.some((obj: any) => {
        for (const key in obj) {
          if (obj[key] === customEmail) {
            return true; // If the string is found, exit the loop and return true
          }
        }
        return false;
      });
      if (!isStringInArray) {
        setMultiEmail([...multiEmail, newMailObj]);
        setCustomEmail("");
      } else {
        setCheckDuplicate("1");
      }
    }
  };

  //remove mail from list
  function removeMailFromList(email: string) {
    const filteredArray = multiEmail.filter((obj: any) => {
      let keepObject = true;

      for (const key in obj) {
        if (obj[key] == email) {
          keepObject = false;
          break;
        }
      }

      return keepObject;
    });
    setMultiEmail(filteredArray);
  }

  function findValueWithHE(arr: any) {
    for (let obj of arr) {
      for (let key in obj) {
        if (key.includes("HE")) {
          return obj[key];
        }
      }
    }
    return null; // Return null if "HE" is not found in any keys
  }

  // useEffect(() => {
  //   const HEMail = findValueWithHE(multiEmail);
  //   if (HEMail) {
  //     handleCheckboxChange2(HEMail);
  //   }
  // }, [multiEmail?.length]);

  //textArea
  const handleTextAreaChange = (e: any) => {
    setTextAreaDesc(e.target.value);
  };


  return (
    <>
      {!props.IsPopUp ? (
        <Modal size="lg" centered toggle={props.Toggle} isOpen={props.IsOpen}>
          <ModalHeader toggle={props.Toggle}>Send Confirm Details</ModalHeader>
          <ModalBody>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex" }}>
                <div>
                  <input
                    value={customEmail}
                    type="text"
                    name="email"
                    placeholder="Enter a valid email address"
                    onChange={(e) => handleCustomEmailChange(e)}
                    autoComplete="off"
                    style={{
                      width: "250px",
                      marginRight: "8px",
                      height: "32px",
                    }}
                  />
                  {checkDuplicate == "1" && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      Mail is already present
                    </p>
                  )}
                  {checkDuplicate == "2" && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      Please enter the valid mail
                    </p>
                  )}
                </div>
                <Button
                  color="primary"
                  onClick={addNewMail}
                  style={{
                    height: "34px",
                    paddingBottom: "2px",
                    paddingTop: "2px",
                  }}
                >
                  +
                </Button>
              </div>
              <div style={{ marginLeft: "60px", width: "40%" }}>
                <TextareaAutosize
                  onChange={(e) => handleTextAreaChange(e)}
                  style={{ width: "100%" }}
                  aria-label="empty textarea"
                  minRows={3}
                  maxRows={4}
                  placeholder="Enter text here"
                />
              </div>
            </div>
            <div className="row my-4 p-4">
              <table>
                <thead>
                  <tr>
                    <th>Mail</th>
                    <th>To</th>
                    <th>Cc</th>
                    <th>Bcc</th>
                  </tr>
                </thead>
                <tbody>
                  {multiEmail?.map((data: any, index: any) => {
                    const email: any = Object.values(data)[0]; // Get the email address

                    const HE = Object.keys(data)[0];
                    return (
                      <tr key={index} style={{ height: "40px" }}>
                        <td>{email as string}</td>
                        <td>
                          <label>
                            <input
                              type="checkbox"
                              checked={checkedItems[email]}
                              onChange={() => handleCheckboxChange(email)}
                              name={email}
                            />
                          </label>
                        </td>
                        <td>
                          <label>
                            <input
                              type="checkbox"
                              checked={checkedItems2[email]}
                              onChange={() => handleCheckboxChange2(email)}
                              name={email}
                            />
                          </label>
                        </td>
                        <td>
                          <label>
                            <input
                              type="checkbox"
                              checked={checkedItems3[email]}
                              onChange={() => handleCheckboxChange3(email)}
                              name={email}
                            />
                          </label>
                        </td>
                        {Object.keys(data)[0].startsWith("NewMail") && (
                          <td>
                            <Button
                              onClick={() => removeMailFromList(email)}
                              style={{
                                padding: "0",
                                borderRadius: "50%",
                                backgroundColor: "white",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderColor: "#fff",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                size="sm"
                                style={{
                                  color: "red",
                                  height: "24px",
                                }}
                              />
                            </Button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div
              style={{ paddingRight: "60px" }}
              className="d-flex justify-content-end"
            >
              <button
                className="btn btn-primary"
                type="button"
                onClick={sendConfirmTicket}
              >
                Send
              </button>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={props.Toggle}>
              close
            </Button>
          </ModalFooter>
        </Modal>
      ) : (
        <div>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              <div>
                <input
                  value={customEmail}
                  type="text"
                  name="email"
                  placeholder="Enter a valid email address"
                  onChange={(e) => handleCustomEmailChange(e)}
                  autoComplete="off"
                  style={{
                    width: "250px",
                    marginRight: "8px",
                    height: "32px",
                  }}
                />
                {checkDuplicate == "1" && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    Mail is already present
                  </p>
                )}
                {checkDuplicate == "2" && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    Please enter the valid mail
                  </p>
                )}
              </div>
              <Button
                color="primary"
                onClick={addNewMail}
                style={{
                  height: "34px",
                  paddingBottom: "2px",
                  paddingTop: "2px",
                }}
              >
                +
              </Button>
            </div>
            <div style={{ marginLeft: "60px", width: "40%" }}>
              <TextareaAutosize
                onChange={(e) => handleTextAreaChange(e)}
                style={{ width: "100%" }}
                aria-label="empty textarea"
                minRows={3}
                maxRows={4}
                placeholder="Enter text here"
              />
            </div>
          </div>
          <div className="row my-4 p-4">
            <table>
              <thead>
                <tr>
                  <th>Mail</th>
                  <th>To</th>
                  <th>Cc</th>
                  <th>Bcc</th>
                </tr>
              </thead>
              <tbody>
                {multiEmail?.map((data: any, index: any) => {
                  const email: any = Object.values(data)[0]; // Get the email address

                  const HE = Object.keys(data)[0];
                  return (
                    <tr key={index} style={{ height: "40px" }}>
                      <td>{email as string}</td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={checkedItems[email]}
                            onChange={() => handleCheckboxChange(email)}
                            name={email}
                          />
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={checkedItems2[email]}
                            onChange={() => handleCheckboxChange2(email)}
                            name={email}
                          />
                        </label>
                      </td>
                      <td>
                        <label>
                          <input
                            type="checkbox"
                            checked={checkedItems3[email]}
                            onChange={() => handleCheckboxChange3(email)}
                            name={email}
                          />
                        </label>
                      </td>
                      {Object.keys(data)[0].startsWith("NewMail") && (
                        <td>
                          <Button
                            onClick={() => removeMailFromList(email)}
                            style={{
                              padding: "0",
                              borderRadius: "50%",
                              backgroundColor: "white",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderColor: "#fff",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              size="sm"
                              style={{
                                color: "red",
                                height: "24px",
                              }}
                            />
                          </Button>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div
            style={{ paddingRight: "60px" }}
            className="d-flex justify-content-end"
          >
            <button
              className="btn btn-primary"
              type="button"
              onClick={sendConfirmTicket}
            >
              Send
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmDetailSendModal;
