
import React, { useEffect, useState } from "react";
import _ from 'lodash';
import {
  BookResponsePassengerModel,
  BookingIdentifierwithEmail,
  FareBookingPassengerView,
  SmartBookResponseDataModel,
} from "WebApiClient";

import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { useBookingClient } from "hooks/useHttpClient";
import { toast } from "react-toastify";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { RaisedExceptionMockData1 } from "./Mocks";
import { useTranslation } from "react-i18next";

const FareBookingConfirmation: React.FC<{
  Result: SmartBookResponseDataModel;
  OnNewQuery: () => void;
  ResultStatus: boolean | undefined;
  passengers: FareBookingPassengerView[] | any;
  ErrorData?: any;
  errorOccured?: any;
}> = ({ Result, OnNewQuery, ResultStatus, passengers, ErrorData, errorOccured }) => {
  const { bookings, shoppingCartID } = Result;
  const BookingId = bookings[0].bookingResponse?.bookingItemID;
  const [EmailTemplateName, setEmailTemplateName] = useState<string>("");
  const bookingClient = useBookingClient();
  const { t } = useTranslation();
  var otherSettings: string = useSelector((state: State) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : 'menuPosition=L/{"textColor":"#ffffff","bgColor":"#232323"}showFareHeader=OffshowSmartCalender=None/n[]/nfalse/nfalse/n{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}0{"PassengerGender":"Unselected","PassengerDOB":"No"}showHotelTab=All{"Container":"#BRANCHCHANGE#"}showCabinContainer=None';
    }
    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });

  let settingArr = otherSettings.split("\n");
  const fieldContainer =
    settingArr[7] != undefined ? settingArr[7].split("#") : [];

  //showConfirmationText redux 
  const sessionInfo = useSelector((state: any) => state.Session);
  const showConfirmationText = JSON.parse(
    sessionInfo?.FrontendSettings?.b2cSettingsText
  );

  const labelCol = "col-xs-6 col-md-4 col-lg-2 mb-1";
  const valueCol = "col-auto mb-2";
  const [
    elementsConfirmEmailSendModalOpen,
    setElementsConfirmEmailSendModalOpen,
  ] = useState(true);

  function ToggleConfirmEmailSendModal() {
    setElementsConfirmEmailSendModalOpen(!elementsConfirmEmailSendModalOpen);
  }

  useEffect(() => {
    if (!ResultStatus && elementsConfirmEmailSendModalOpen) {
      // Delay execution of sendConfirmTicket function by 2000 milliseconds (2 seconds)
      const timeoutId = setTimeout(() => {
        sendConfirmTicket();
        ToggleConfirmEmailSendModal();
      }, 2000);

      // Clean up the timeout to avoid memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [ResultStatus, elementsConfirmEmailSendModalOpen]);

  const extractEmails = () => {
    const adultPassengers = passengers.filter(
      (passenger: any) => passenger.isAdult
    );
    const emails = adultPassengers.map(
      (passenger: any) => passenger.bookingData.email
    );
    return emails;
  };

  const getTemplate = () => {
    try {
      const isMail = fieldContainer.find((item) => item.endsWith("email.html"));
      console.log(isMail);
      var mailValue = "";
      // Moved the declaration to below the isMail assignment
      if (isMail) {
        if (isMail.includes("=")) {
          let mail = isMail.split("=");
          mailValue = mail[1];
          setEmailTemplateName(mailValue);
        } else {
          mailValue = isMail; // Assign isMail to mailValue if isMail is truthy
          setEmailTemplateName(mailValue);
        }
      }
      return mailValue; // Return mailValue
    } catch (error) {
      console.error("Error in getTemplate:", error); // Log the error for debugging
      return ""; // Return empty string in case of error
    }
  };

  async function sendConfirmTicket() {
    try {
      const Obj: BookingIdentifierwithEmail = {
        cartId: shoppingCartID,
        bookingId: BookingId?.length ? BookingId : "",
        emailIds: {
          To: extractEmails(),
          Bcc: null,
          Cc: null,
        },
        Description: "",
        TemplateName: getTemplate(),
      };
      await bookingClient.SendConfirmTicket(Obj);

      toast.success(t("FareBooking.Check&BookPage.EmailNotification"));
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while sending the confirmation email.");
    }
  }

  const sendPageHeight = () => {
    const height = document.body.scrollHeight;
    window.parent.postMessage(["setHeight", height + "px"], "*");
  };

  useEffect(() => {
    sendPageHeight();
  }, []);

  let originalString = "";
  let replacementString = "";

  // Tweaking confirmation text
  const bookingResponse = bookings[0]?.bookingResponse;
  const raisedException = bookings[0]?.raisedException;

  if (bookingResponse !== undefined && bookingResponse !== null) {
    const bookingReference = bookingResponse.recordLocator!;
    originalString = showConfirmationText?.ConfirmationText ?? "<p>Thank you for booking with Everest Travel. #Filekey# You will receive a confirmation email soon.</p>";
    replacementString = `<strong>${bookingReference}</strong>`;
  } else if (raisedException !== undefined && raisedException !== null) {
    originalString = raisedException.exceptionText;
    replacementString = "";
  } else {
    <ErrorDisplay
      data={ErrorData}
      isHide={true}
    />
  }

  const replaceText = (str: string, replacement: string) => {
    // Replace #...# placeholders
    str = str.replace(/#(.*?)#/g, replacement);
    // Replace {...} placeholders
    str = str.replace(/\{(.*?)\}/g, replacement);
    return str;
  };

  const updatedString = replaceText(originalString, replacementString);
  return (
    <div className="card">
      {
        errorOccured ? (
          <ErrorDisplay
            data={ErrorData}
            isHide={true}
          />
        ) :
          <>
            <div className="card-body">
              <div className="row">
                <div className="col-12" mb-2>
                  {bookingResponse !== undefined && bookingResponse !== null ?
                    <div dangerouslySetInnerHTML={{ __html: updatedString }} /> :
                    <ErrorDisplay
                      data={ErrorData}
                      isHide={true}
                    />
                  }
                </div>
              </div>
            </div>
          </>
      }

    </div>
  );
};

export default FareBookingConfirmation;
