import { faCheckCircle, faExclamationTriangle, faSync, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorDisplay, ValidationErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import { useSessionHandler } from "hooks/useSessionHandler";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { BookingItemModel, CancelTicketApiResponse, GetCancelSurchargesApiResponse } from "WebApiClient";
import { BookingMangerTableHeader } from "./BookingManager.Table";
import { BookingMangerTableRowData } from "./BookingManager.Table.Row";
// import { BookingManagerSingleBookingState } from "../types/BookingManagerTypes";

interface ConfirmCancelTicket {
  Booking: BookingItemModel,
  OnUpdate: (data: BookingItemModel) => void
  Toggle: () => void
  IsOpen: boolean

}
const BookingMangerTableRowConfirmCancelTicketModal: React.FC<ConfirmCancelTicket> = props => {
  const booking = props.Booking;
  const bookClient = useBookingClient();
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const [response, setResponse] = useState<CancelTicketApiResponse | undefined>(undefined);
  const [errorOccured, setErrorOccured] = useState(false);
  const result = response?.result;
  const sessionHandler = useSessionHandler();

  function SubmitCancel() {
    const identifier = booking.latestVersion?.bookingIdentifier;
    if (identifier) {
      setFetching(true);
      bookClient.cancelTicket(identifier)
        .then(response => {
          setResponse(response);
          dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
          if (response.result) {
            setErrorOccured(false);
            if (response.result.updatedBooking) {
              props.OnUpdate(response.result.updatedBooking)
            }
            else {
              if (response.result!.validationResult.isValid) {
                setErrorOccured(true);
              }
            }
          }
          else {
            setErrorOccured(true);
          }
        })
        .catch(() => {
          setErrorOccured(true);
        })
        .finally(() => {
          setFetching(false);
          sessionHandler.RefreshSession();
        })
    }

  }

  const warnings = result?.warnings ? result.warnings : [];
  return (
    <React.Fragment>

      <Modal size="xl" centered toggle={props.Toggle} isOpen={(props.IsOpen || fetching)}>
        <ModalHeader toggle={props.Toggle}>Cancel ticket</ModalHeader>
        <ModalBody>
          <div className="row">

            {fetching &&
              <div className="col-12">
                <WaitingCard />
              </div>
            }
            {!fetching &&
              <React.Fragment>
                {errorOccured &&
                  <ErrorDisplay data={response?.responseMetaData} />
                }
                {result ?
                  <Fragment>
                    {!result.validationResult.isValid &&
                      <div className="col-12 mb-2">
                        <ValidationErrorDisplay data={result.validationResult} />
                      </div>
                    }
                    {warnings.length > 0 &&
                      <div className="col-12 mb-2">
                        <Alert color="warning">

                          <h4 className="alert-heading mb-2">
                            <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION
                          </h4>
                          <ul>
                            {warnings.map((e, i) =>
                              <li key={"warnings1"+i}>{e}</li>
                            )}
                          </ul>
                        </Alert>
                      </div>
                    }
                    <div className="col-12 mb-2">
                      {result.tickets.length > 0 &&
                        <Alert color="success">
                          <div className="row">
                            <div className="col-12">
                              Ticket(s) successfully cancelled:
                            </div>
                            <div className="col-12">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>EMD number</th>
                                    <th>Filekey</th>
                                    <th>Status code</th>
                                    <th>Ticket number (first)</th>
                                    <th>Ticket number (last)</th>
                                    <th>Error text</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {result.tickets.map((ticket, index) =>
                                    <tr key={"resultTickets"+index}>
                                      <td>{ticket.emdNumber}</td>
                                      <td>{ticket.recordLocator}</td>
                                      <td>{ticket.statusCode}</td>
                                      <td>{ticket.firstTicketNumber}</td>
                                      <td>{ticket.lastTicketNumber}</td>
                                      <td>{ticket.errorText}</td>
                                    </tr>
                                  )}

                                </tbody>
                              </table>
                            </div>
                          </div>

                        </Alert>
                      }
                    </div>
                  </Fragment>
                  :
                  <div className="col-12 mb-2">
                    <div className="row">
                      <div className="col-12 mb-2">
                        <h4>Confirm to cancel ALL tickets in this booking.</h4>
                      </div>

                    </div>
                  </div>
                }

                <div className="col-12 mb-2">
                  <div className="table-responsive">

                    <table className={`table table-sm table-bordered w-100 table-hover bg-white`}>
                      <BookingMangerTableHeader GetArrowIcon={() => <span></span>} SetOrdering={() => { }} ClassName="" ShowActions={false} />
                      <tbody>
                        <tr>
                          <BookingMangerTableRowData Booking={props.Booking} />
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
                {/* {props.Booking.latestVersion.generalInfo.crs?.gds.toLowerCase() === "+w" &&
                  <div className="col-12 mb-2">
                    <QuerysurchargesComponent {...props} />
                  </div>
                } */}
                {result?.updatedBooking ? null :
                  < div className="col-12">
                    <Button color="danger" className="text-white" block onClick={SubmitCancel}><FontAwesomeIcon style={{ "color": "white" }} icon={faTrash} /> YES, Cancel tickets</Button>{' '}
                  </div>
                }
              </React.Fragment>
            }
          </div>



        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={props.Toggle}>close</Button>
        </ModalFooter>


      </Modal>

    </React.Fragment >
  );


}

const QuerysurchargesComponent: React.FC<ConfirmCancelTicket> = props => {
  const booking = props.Booking;
  const bookClient = useBookingClient();
  const [fetching, setFetching] = useState(false);
  const [errorCollapseOpen, setErrorCollapseOpen] = useState(false);
  const dispatch = useDispatch();
  const [response, setResponse] = useState<GetCancelSurchargesApiResponse | undefined>(undefined);
  const [errorOccured, setErrorOccured] = useState(false);
  const result = response?.result;
  const sessionHandler = useSessionHandler();

  function Get() {
    const identifier = booking.latestVersion?.bookingIdentifier;
    if (identifier) {
      setFetching(true);
      bookClient.getCancelSurcharges(identifier)
        .then(response => {
          setResponse(response);
          dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
          if (response.result) {
            if (!response.result.surcharge && response.result.notApplicable === false) {
              setErrorOccured(true);
            }
            else {
              setErrorOccured(false);
            }
          }
          else {
            setErrorOccured(true);
          }
        })
        .catch(() => {
          setErrorOccured(true);
        })
        .finally(() => {
          setFetching(false);
          // sessionHandler.RefreshSession();
        })
    }

  }
  useEffect(() => {
    Get();
  }, [])

  return (
    <React.Fragment>

      <div className="row">

        {fetching &&
          <div className="col-12">
            <WaitingCard message="Checking cancellation surcharges..." />
          </div>
        }
        {!fetching &&
          <React.Fragment>
            {result &&
              <Fragment>

                <div className="col-12 mb-2">
                  <Alert color="warning">
                    <Fragment>
                      {result.surcharge &&
                        <Fragment>
                          {result.surcharge.equivalentAmount?.value === 0 ?

                            <span><FontAwesomeIcon icon={faCheckCircle} /> No cancellation surcharges</span> :
                            <span>
                              <FontAwesomeIcon icon={faExclamationTriangle} /> Cancellation surcharges: <SharedPriceDisplay price={result.surcharge.amount} />
                            </span>
                          }
                        </Fragment>
                      }
                      {result.notApplicable &&
                        <span>Cancellation fees cannot be determined for this type of booking.</span>
                      }
                    </Fragment>
                    <span > <FontAwesomeIcon icon={faSync} className="clickme" onClick={Get} /></span>
                  </Alert>
                </div>
              </Fragment>
            }
            {errorOccured &&
              <div className="col-12">
                <Alert color="warning">
                  <div className="alert-heading">Cancellation Surcharges could not be retrieved. <button className="btn btn-primary" onClick={Get}><FontAwesomeIcon icon={faSync} /> Refresh</button></div>
                  <button className="btn btn-secondary" onClick={() => { setErrorCollapseOpen(!errorCollapseOpen) }}>{errorCollapseOpen ? "Hide" : "Show"} error</button>
                  <Collapse isOpen={errorCollapseOpen}>
                    <ErrorDisplay data={response?.responseMetaData} />
                  </Collapse>
                </Alert>
              </div>
            }


          </React.Fragment>
        }
      </div>


    </React.Fragment>
  );


}




{/* {!props.Booking.DidCancelBooking && !isFetching && !props.Booking.CancelBookingError &&
            <React.Fragment>

              <div className="row">
                <div className="col">
                  <Alert color={!props.Booking.DidCancelBooking ? "info" : "success"}>
                    <h4 className="alert-heading">Are you SURE to cancel this booking?</h4>
                  </Alert>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Filekey</label>
                    <div className="col-sm-10">
                      <label className="form-control-plaintext">{props.Booking.Data.latestVersion!.generalInfo!.filekey}</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-2 col-form-label">Passenger</label>
                    <div className="col-sm-10">
                      <label className="form-control-plaintext">{props.Booking.Data.latestVersion!.generalInfo!.mainPassengerLastName}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Button color="danger" className="text-white" block onClick={() => props.CancelBooking(props.Booking)}><FontAwesomeIcon style={{ "color": "white" }} icon={faTrash} /> YES, Cancel booking</Button>{' '}
                </div>
              </div>
            </React.Fragment>

          }
          <div className="row">
            {isFetching &&
              <div className="col"><FontAwesomeIcon icon={faSpinner} spin /> Cancelling booking...</div>
            }
            {props.Booking.CancelBookingError && !isFetching &&
              <React.Fragment>

                <ErrorMessage
                  ErrorOccured={props.Booking.CancelBookingError}
                  ErrorMessages={[props.Booking.CancelBookingErrorMessage]}
                  TransactionID={props.Booking.CancelBookingTransactionID}
                />
                <Button color="danger" className="text-white" block onClick={() => props.CancelBooking(props.Booking)}><FontAwesomeIcon style={{ "color": "white" }} icon={faTrash} /> RETRY to Cancel booking</Button>{' '}
              </React.Fragment>
            }
            {props.Booking.DidCancelBooking &&
              <React.Fragment>
                <Alert color={"success"}>
                  <h4 className="alert-heading">Booking successfully cancelled.</h4>
                </Alert>
              </React.Fragment>

            }
          </div>
 */}













export default BookingMangerTableRowConfirmCancelTicketModal



