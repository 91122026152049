import React from "react";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";


const style = {
    display: 'block'
}

const GeneralWaitScreen: React.FC<{ show?: boolean }> = ({ show }) => {
    const session = useSelector((state: State) => state.Session);

    
    //termsCondition redux
    const sessionInfoB2c = useSelector((state: State) => state.Session);
    const showBackModalColor = JSON.parse(
        sessionInfoB2c?.FrontendSettings?.b2cSettingsText
        );
    return (
        <React.Fragment>
            {(session.ShowGeneralWaitScreen || show) &&
                <div>
                    <div id={showBackModalColor?.ShowBackgroundModalColor=="On"? "generalWaitscreen":"generalWaitscreenOn"} style={style}></div>
                  
                    <div id={showBackModalColor?.ShowBackgroundModalColor=="On"? "epic-overlay":""}></div>
                </div>
            }
        </React.Fragment>
    );
};


export default GeneralWaitScreen