import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faUser,
  faHouseNight,
  faSort,
} from "@fortawesome/pro-regular-svg-icons";
import { Card, CardBody } from "reactstrap";
import { ClickAwayListener } from "@mui/material";
import Rooms from "./Rooms";
import { useAvailableHotelsClient } from "hooks/useHttpClient";
import DateInput from "components/Shared/DateInput";
// import validHotel from 'HotelValidId.json'

interface search {
  resultStatus: any;
  setHotelResult: any;
  isPending: any;
  setValidHotels: any;
  setHotelValidIds: any;
  setAllHotelIds: any;
  setSearchData: any;
  setIsValidIds: any;
  settriggerB: any;
  triggerB: any;
  setSkipHotelCount: any;
  setIsSortOn: any;
  setIsError:any
  // setSelectedOption: any;
  // selectedOption: any;
}

interface HotelId {
  hotelId: string;
  longitude: number;
  latitude: number;
}

const HotelSearch: React.FC<search> = (props) => {
  const [hotelIds, setHotelIds] = useState<any>([]);
  const [hotelRes, setHotelRes] = useState<any>();
  const [HotelValidIds, setHotelValidIds] = useState<any>();
  const [fetchingHotelData, setFeatchingHotelData] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState("Sort by");
  var currentIndex = 25;
  var ValidHotelData: any = null;
  const Hotel = useAvailableHotelsClient();
  var rooms = {
    numRooms: 1,
    adults: 1,
    children: 0,
    infants: 0,
    mealType: ["RO"],
  };

  const [searchData, setSearchData] = useState({
    searchKeyword: "",
    checkIn: new Date(),
    nights: 1,
    roomGuests: rooms,
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleInputClick = () => {
    setDropdownOpen(true);
  };

  const handleInputClose = () => {
    setDropdownOpen(false);
  };

  async function handleError(e:any) {
    const errorMessage = e instanceof Error ? e.message : 'An error occurred';
    errorMessage && props.setIsError(true)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    if (event.target) {
      const { name, value } = event.target;
      setSearchData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      const keys = Object.keys(event);
      setSearchData((prevState: any) => {
        const newState = { ...prevState };
        keys.forEach((key: string) => {
          newState[key] = event[key];
        });
        return newState;
      });
    }
  };

  function SelectNext() {}

  const getHotelIds = async (searchData: any) => {
    props.setIsError(false)
    if (!searchData || !searchData.searchKeyword) {
      // Handle validation for searchData or searchData.searchKeyword being empty or undefined
      console.error(
        "Invalid search data. searchData or searchData.searchKeyword is missing."
      );
      return;
    }

    try {
      setFeatchingHotelData(true);
      let allHotelIds: HotelId[] = await Hotel.GetHotelIdsAsync(
        searchData.searchKeyword
      );
      props.setAllHotelIds(allHotelIds);
      const hotel_ids: string[] = allHotelIds.map((item) => item.hotelId);
      const firstFiftyValues = hotel_ids.slice(0, currentIndex);
      Hotel.GetRatesAsync(
        searchData.roomGuests.adults,
        searchData.roomGuests.children,
        searchData.checkIn,
        searchData.roomGuests.numRooms,
        searchData.nights,
        firstFiftyValues
      ).then(async (validHotels: any) => {
        setHotelValidIds(validHotels);
        props.setValidHotels(validHotels);
        if (!validHotels.results || validHotels.results.length === 0) {
          props.setIsValidIds(true);
          props.isPending(false);
          // const extFiftyValues = hotel_ids.slice(0, currentIndex + 25);
        } else {
          props.setIsValidIds(false);
          props.isPending(false);
          ValidHotelData = validHotels;
          const hotelId = ValidHotelData?.results.map(
            (data: any) => data?.items[0]?.hotelId
          );
          const uniqueArray = Array.from(new Set(hotelId));
          const filteredTripAdvisorIds = uniqueArray.filter(
            (item: any) => !firstFiftyValues.includes(item.toString())
          );
          props.setSkipHotelCount(filteredTripAdvisorIds.length);
          fetchHotelData(uniqueArray).then((res: any) => {
            props.setHotelValidIds(uniqueArray);
            setHotelIds(uniqueArray);
          });
        }
      }).catch((e) => { handleError(e)});
      // Additional code if needed...
    } catch (error) {
      console.error("Error occurred while fetching hotel data:", error);
      // Handle the error or show an error message to the user
    } finally {
      setFeatchingHotelData(false);
    }
  };

  async function fetchHotelData(hotel_ids: any) {
    let hotelData = await Hotel.GetHotelsAsync(hotel_ids);
    // console.log("hotelData",hotelData);

    const Hotels = hotelData.map((hotel: any) => {
      const matchingItem = ValidHotelData?.results.filter(
        (item: any) => hotel.tripAdvisor.id === item.items[0].hotelId
      );
      return { ...hotel, PriceData: matchingItem };
    });

    props.setHotelResult(Hotels);
    props.setHotelValidIds(hotel_ids);
    setHotelRes(Hotels);
    props.resultStatus(true);

    // Calculate the endIndex to avoid going beyond the total number of IDs
    // let endIndex = startIndex + 10;
    // if (endIndex > totalIds) {
    //     endIndex = totalIds;
    // }

    // for (let i = startIndex; i < endIndex; i++) {
    //     let PriceArr: any[] = []
    //     const hotelId = hotel_ids[i];
    //     let hotelsRes = await Hotel.GetHotelsAsync(hotelId);
    //     ValidHotelData?.results.map((itr: any) => {
    //         itr.items.map((itr2: any) => {
    //             if (itr2.hotelId == hotelsRes.tripAdvisor.id) {
    //                 PriceArr.push({ ...itr })
    //             }
    //         })
    //     });
    //     hotelsRes = { ...hotelsRes, PriceData: PriceArr }
    //     // Assuming props.setHotelResult and setHotelRes are valid and used for state management.
    //     arrayRes.push(hotelsRes);
    //     props.setHotelResult(arrayRes);
    //     props.setHotelValidIds(hotel_ids);
    //     setHotelRes(arrayRes);
    //     props.resultStatus(true);
    // }
    // Update the startIndex for the next call
    // startIndex += 0;
    // The arrayRes now contains the results of the next 50 async operations.
    props.settriggerB(!props.triggerB);
    return;
  }

  const handleSubmit = (event: React.FormEvent) => {
    props.isPending(true);
    event.preventDefault();
    props.setSearchData(searchData);
    getHotelIds(searchData);
    props.setAllHotelIds([]);
    props.setHotelResult([]);
    props.setValidHotels([]);
    props.setIsValidIds(false);
  };

  function OnChangeNew(value: Date | undefined) {
    let date: any = { checkIn: value };
    handleInputChange(date);
    // let newSegment: ISegmentState = ;
    // props.UpdateSegment(props.SegmentIndex, newSegment);
  }

  useEffect(() => {
    props.setHotelResult(hotelRes);
  }, [hotelRes?.length, props.triggerB]);

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Check if the event key is 'f' or 'F'
    if (e.key === "f" || e.key === "F") {
      // Prevent the event from propagating to parent elements
      e.stopPropagation();
    }
  };

  const callSortFun = () => {
    props.setIsSortOn(true);
  };

  return (
    <section className="sticky-container">
      <Card className="sticky-card ">
        <CardBody style={{ backgroundColor: "#C2DDF8" }}>
          <div className="container row fieldContainer">
            <div className="col-lg-4 col-md-6 search-div prS-0">
              {/* <div className="input-group input-group-lg">
                                <span className="input-group-text">
                                    <FontAwesomeIcon icon={faBed} />
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search for"
                                    aria-describedby="inputGroup-sizing-lg"
                                    name="searchKeyword"
                                    value={searchData.searchKeyword}
                                    onChange={handleInputChange}
                                />
                            </div> */}
              <div className="input-group input-group-lg">
                <span className="input-group-text">
                  <FontAwesomeIcon icon={faBed} />
                </span>
                <input
                  type="text"
                  className="form-control"
                  name="searchKeyword"
                  value={searchData.searchKeyword}
                  onChange={handleInputChange}
                  placeholder="Search for"
                  onKeyDown={handleInputKeyDown}
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-6 dateDiv prS-0">
              <div
                style={{ fontSize: "20px" }}
                id="dateFont"
                className="input-group input-group-lg h-100 dateText"
              >
                <span className="date-icon"></span>
                <DateInput
                  Value={searchData.checkIn}
                  OnDateChange={OnChangeNew}
                  SelectNextInput={SelectNext}
                  InputGroup={true}
                  IsDob={false}
                  PlaceHolder={"Date"}
                />
              </div>
            </div>
            <div className="col-lg-2 col-md-6 nightsDiv prS-0">
              <div className="input-group input-group-lg">
                <span className="input-group-text">
                  <FontAwesomeIcon icon={faHouseNight} />
                </span>
                <input
                  type="number"
                  className="form-control"
                  name="nights"
                  min={0}
                  placeholder=""
                  value={searchData.nights}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 roomDiv prS-0">
              <ClickAwayListener onClickAway={handleInputClose}>
                <div className="input-group input-group-lg">
                  <span className="input-group-text">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                  <input
                    type="text"
                    className="form-control room"
                    placeholder="Room & Guests"
                    aria-describedby="basic-addon1"
                    onClick={handleInputClick}
                    name="roomGuests"
                    value={`${searchData.roomGuests.numRooms} | ${searchData.roomGuests.mealType}`}
                    onChange={handleInputChange}
                    readOnly
                  />
                  {dropdownOpen ? (
                    <section className="w-100">
                      <Rooms
                        setRoomData={setSearchData}
                        searchData={searchData}
                        isOpen={setDropdownOpen}
                      />
                    </section>
                  ) : null}
                </div>
              </ClickAwayListener>
            </div>
            <div className="d-flex col-lg-auto col-md-12 text-end btnDiv">
              <button
                type="submit"
                className="btn btn-primary btn-lg"
                onClick={handleSubmit}
              >
                Search
              </button>
              <div className="dropdown ml-3">
                <div className="container">
                  <button
                    className="btn btn-light btn-lg"
                    onClick={callSortFun}
                    aria-expanded={isDropdownOpen}>
                    <FontAwesomeIcon icon={faSort} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </section>
  );
};

export default HotelSearch;