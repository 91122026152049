
import BookingManager from "components/BookingManager/component/BookingManager";
import { DefaultBookingManagerFilter } from "components/BookingManager/types/BookingManagerTypes";
import { useSessionHandler } from "hooks/useSessionHandler";
import React, { useEffect, useState } from "react";
import { Route } from "react-router";
import { Routes } from "react-router-dom";
import NoMatch from "routes/NoMatch";
import { BookingMangerFilterRequestData } from "WebApiClient";
import BookingdetailsPage from "./BookingDetailsPage";

const BookingManagerPage: React.FC<{}> = props => {
    const [CurrentFilter, SetCurrentFilter] = useState<BookingMangerFilterRequestData>(DefaultBookingManagerFilter);

    const DEBUG = false;
    const sessionHandler = useSessionHandler();
    useEffect(() => {
        document.title = `Bookingmanager`;
        if (DEBUG) {
            console.log(`Booking manager Page initialized.`)
        }
        sessionHandler.RefreshSession();

    }, [])

    return <Routes>
        <Route path="/" element={<BookingManager CurrentFilter={CurrentFilter} OnFilterUpdate={(filter) => { SetCurrentFilter(filter) }} />}>

        </Route>
        <Route path="/details/:cartid/:bookingid" element={<BookingdetailsPage />}>

        </Route>
        <Route path="*" element={<NoMatch />} />
    </Routes>
}

export default BookingManagerPage;

// class BookingManagerPage extends Component<AllProps> {
//     // constructor(props: AllProps) {
//     //     super(props);
//     // }
//     componentDidMount() {

//         this.props.SetPage(ActivePageEnum.BookingManagerActivePage);
//         document.title = `Bookingmanager`;
//     }
//     render() {
//         return (
//             <React.Fragment>
//                 <BookingManager />
//             </React.Fragment>
//         );

//     }

// };

// type AllProps = PropsFromState & DispatchProps;

// interface PropsFromState {


// }
// const mapStateToProps = () => ({

// });

// interface DispatchProps {
//     SetPage: typeof Session_Reducer_SetActivePage;

//     RefreshToken: typeof Session_Saga_RefreshToken;

// }
// const mapDispatchToProps = (dispatch: Dispatch) => ({
//     SetPage: (activePage: ActivePageEnum) =>
//         dispatch(Session_Reducer_SetActivePage(activePage)),

//     RefreshToken: () => dispatch(Session_Saga_RefreshToken()),
// });
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(BookingManagerPage);
