import { faElementor } from "@fortawesome/free-brands-svg-icons";
import { faInfoCircle, faStarExclamation, faStickyNote, faText } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BookingMangerManageAPISElements } from "components/ManageBookingElements/ManageBookingAPIS";
import { BookingMangerManageOSIElements } from "components/ManageBookingElements/ManageBookingOSI";
import { BookingMangerManageRemarkElements } from "components/ManageBookingElements/ManageBookingRemarks";
import { BookingMangerManageSSRElements } from "components/ManageBookingElements/ManageBookingSSR";
import { ErrorDisplay } from "components/Shared/APIMetaDataDisplay";
import { TabbedContent, TabbedContentProps } from "components/Shared/TabbedContent";
import { WaitingCard } from "components/Shared/WaitingCard";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { BookingElementsManagementApiResponse, BookingItemModel } from "WebApiClient";
// import { BookingManagerSingleBookingState } from "../types/BookingManagerTypes";

interface BookingMangerManageElementsModalProps {
  Booking: BookingItemModel,
  Toggle: () => void
  IsOpen: boolean

}
const BookingMangerManageElementsModal: React.FC<BookingMangerManageElementsModalProps> = props => {
  const booking = props.Booking;
  const bookClient = useBookingClient();
  const [fetching, setFetching] = useState(false);
  const dispatch = useDispatch();
  const [response, setResponse] = useState<BookingElementsManagementApiResponse | undefined>(undefined);
  const [errorOccured, setErrorOccured] = useState(false);
  const result = response?.result;
  const bookingidentifier = booking.latestVersion.bookingIdentifier;
  function OnFetch() {


    setFetching(true);
    bookClient.getElementsManagement(bookingidentifier)
      .then(response => {
        setResponse(response);
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        if (!response.result) {
          setErrorOccured(true)
        }
        else {
          setErrorOccured(false);
        }

      })
      .catch(() => {
        setErrorOccured(true);
      })
      .finally(() => {
        setFetching(false);
      })


  }





  useEffect(() => {
    if (props.IsOpen) {
      OnFetch();
    }
  }, [props.IsOpen])

  const DetailsTabContent: TabbedContentProps = {
    EnableJump: true,
    Tabs: result ? [
      {
        Component: <BookingMangerManageSSRElements model={result} OnRefresh={OnFetch} BookingIdentifier={bookingidentifier} />,
        Title: "SSR",
        Icon: faStarExclamation,
        AmountIndicator: undefined

      },
      {
        Component: <BookingMangerManageOSIElements model={result} OnRefresh={OnFetch} BookingIdentifier={bookingidentifier} />,
        Title: "OSI",
        Icon: faStickyNote,
        AmountIndicator: undefined,
        applyCss:true,
      },
      {
        Component: <BookingMangerManageRemarkElements model={result} OnRefresh={OnFetch} BookingIdentifier={bookingidentifier} />,
        Title: "Remarks",
        Icon: faText,
        AmountIndicator: undefined
      },
      {
        Component: <BookingMangerManageAPISElements model={result} OnRefresh={OnFetch} BookingIdentifier={bookingidentifier} />,
        Title: "APIS",
        Icon: faInfoCircle,
        AmountIndicator: undefined
      },


      // {
      //   Component: <BookingActionsCard Data={Data} />,
      //   Title: "Actions",
      //   Icon: faEdit,
      //   RenderOnlyIfActive: true
      // }
    ] : []
  }

  return (
    <React.Fragment>

      <Modal size="xl" centered toggle={props.Toggle} isOpen={(props.IsOpen || fetching)}>
        <ModalHeader toggle={props.Toggle}><FontAwesomeIcon icon={faElementor} /> Manage elements for &nbsp;<strong>{props.Booking.latestVersion.generalInfo.filekey}</strong></ModalHeader>
        <ModalBody>
          <div className="row">
            {fetching &&
              <div className="col-12">
                <WaitingCard />
              </div>
            }
            {!fetching &&
              <React.Fragment>
                {errorOccured &&
                  <ErrorDisplay data={response?.responseMetaData} />
                }

                {result &&

                  <Fragment>
                    <div className="col-12 mb-2">


                      <TabbedContent {...DetailsTabContent} />


                    </div>
                  </Fragment>


                }

              </React.Fragment>
            }
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={props.Toggle}>close</Button>
          <Button color="primary" onClick={OnFetch}>Refresh</Button>
        </ModalFooter>


      </Modal>

    </React.Fragment>
  );
}
export default BookingMangerManageElementsModal;