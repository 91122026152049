import { faShield, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountrySelect from "components/Shared/CountrySelect";
import DateInput from "components/Shared/DateInput";
import ValidIndicatorIcon from "components/Shared/ValidIndicatorIcon";
import * as moment from "moment";
import React, { Fragment } from "react";
import { AgentApisVisaType, FareBookingPassengerApisVisa } from "WebApiClient";
import { GetNullableApiRequestDate } from "../Helpers/DateConverters";
import { ApisVisaValidResult } from "./APISInput.Validate.Visa";
import { useTranslation } from "react-i18next";
export interface ApisVisaInputProps {
    Data: FareBookingPassengerApisVisa | undefined;
    OnUpdatePassenger: (data: FareBookingPassengerApisVisa | undefined) => void;
    IsValid: ApisVisaValidResult
}

const ApisVisaInput: React.FC<ApisVisaInputProps> = ({ Data, OnUpdatePassenger, IsValid }) => {
    const { t } = useTranslation();
    function OnAddVisa() {
        let d: FareBookingPassengerApisVisa = {
            appliedCountry: "",
            dateOfIssue: undefined,
            number: "",
            placeOfIssue: "",
            type: AgentApisVisaType.Visa

        }
        OnUpdatePassenger(d);
    }
    function OnRemoveVisa() {
        OnUpdatePassenger(undefined);
    }
    function OnAppliedCountryUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.appliedCountry = value;
            OnUpdatePassenger(data);
        }

    }
    function OnNumberUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.number = value;
            OnUpdatePassenger(data);
        }

    }
    function OnPlaceOfIssueUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.placeOfIssue = value;
            OnUpdatePassenger(data);
        }

    }
    function OnDateOfIssueUpdate(value: Date | undefined) {
        if (Data) {
            let data = { ...Data };
            data.dateOfIssue = GetNullableApiRequestDate(value);
            OnUpdatePassenger(data);
        }

    }
    function OnPassportTypeUpdate(value: string) {
        if (Data) {
            let data = { ...Data };
            data.type = parseInt(value);
            OnUpdatePassenger(data);
        }

    }

    function GetValidClassName(required: boolean, isValid: boolean): string {
        let result = "";
        if (required) {
            result = isValid ? "is-valid" : "is-invalid";
        }
        return result;
    }



    return (
        <React.Fragment>
            <div className="card mb-2">

                <div className="card-body">
                    <h5 className="card-title"><ValidIndicatorIcon
                        IsValid={true}
                        Icon={faShield}
                        NoValueSet={Data === undefined}
                    /> {t("FareBooking.APISPage.Visa.VisaTitle")}
                        {Data &&
                            <button style={{marginLeft:'6px'}} className="btn btn-outline-danger btn-sm" onClick={OnRemoveVisa}><FontAwesomeIcon icon={faTrash} /></button>

                        }
                    </h5>
                    {Data &&
                        <Fragment>

                            <div className="row align-items-center mb-2">
                                <label className="col-sm-4 col-md-4 col-lg-4">{t("FareBooking.APISPage.Visa.Type.TypeTitle")}</label>
                                <div className="col-sm-8 col-md-8 col-lg-8">
                                    <select value={Data.type} onChange={(e) => OnPassportTypeUpdate(e.target.value)} className={`form-select`}>
                                        <option value={AgentApisVisaType.Unknown}>{t("FareBooking.APISPage.Visa.Type.TypeOption1")}</option>
                                        <option value={AgentApisVisaType.Visa}>{t("FareBooking.APISPage.Visa.Type.TypeOption2")}</option>
                                        <option value={AgentApisVisaType.Redress}>{t("FareBooking.APISPage.Visa.Type.TypeOption3")}</option>
                                        <option value={AgentApisVisaType.KnownTraveller}>{t("FareBooking.APISPage.Visa.Type.TypeOption4")}</option>
                                    </select>
                                </div>
                            </div>
                            {Data.type !== AgentApisVisaType.Unknown &&
                                <React.Fragment>
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">{t("FareBooking.APISPage.Visa.Country")}</label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <CountrySelect
                                                Value={Data.appliedCountry!}
                                                Onchange={OnAppliedCountryUpdate}
                                                Valid={IsValid.AppliedCountry}
                                                ID={`appliedc`}
                                                Required={IsValid.VisaInfoRequired}
                                                disabled={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">{t("FareBooking.APISPage.Visa.IssueDate")}</label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">

                                            <DateInput
                                                OnDateChange={(date: Date | undefined) => OnDateOfIssueUpdate(date)}
                                                Value={Data.dateOfIssue ? moment({ day: Data.dateOfIssue.day, month: Data.dateOfIssue.month - 1, year: Data.dateOfIssue.year }).toDate() : undefined}
                                                InputGroup={false}
                                                IsDob={false}
                                                MaxDate={moment().add(5, "years").toDate()}
                                                MinDate={moment().subtract(50, "years").toDate()}
                                                InputClassNames={`form-control  ${GetValidClassName(IsValid.VisaInfoRequired, IsValid.DateOfIssue)}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">{t("FareBooking.APISPage.Visa.Number")}</label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <input
                                                value={Data.number ? Data.number : ""}
                                                onChange={(e) => OnNumberUpdate(e.target.value)}
                                                type="text"
                                                className={`form-control  ${GetValidClassName(IsValid.VisaInfoRequired, IsValid.Number)}`}
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-2">
                                        <label className="col-sm-4 col-md-4 col-lg-4">{t("FareBooking.APISPage.Visa.IssuePlace")}</label>
                                        <div className="col-sm-8 col-md-8 col-lg-8">
                                            <input
                                                value={Data.placeOfIssue ? Data.placeOfIssue : ""}
                                                onChange={(e) => OnPlaceOfIssueUpdate(e.target.value)}
                                                type="text"
                                                className={`form-control  ${GetValidClassName(IsValid.VisaInfoRequired, IsValid.PlaceOfIssue)}`}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </Fragment>
                    }
                    {!Data &&
                        <button className="btn btn-primary" onClick={OnAddVisa}>{t("FareBooking.APISPage.Visa.AddVisa")}</button>
                    }
                </div>
            </div>

        </React.Fragment>

    );
}

export default ApisVisaInput;

