import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorDisplay } from 'components/Shared/APIMetaDataDisplay';
import { WaitingCard } from 'components/Shared/WaitingCard';
import { useBookingClient } from 'hooks/useHttpClient';
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Session_Reducer_PushTransaction } from 'rootExports/SessionReducer';
import { BookingIdentifier, GetPNRApiResponse } from '../../../WebApiClient';
import { BookingPnrCardData } from '../../PNR/Pnr';
interface BookingVersionItemDetailsGeneralProps {
  Identifier: BookingIdentifier;
}
export const BookingPnrCard: React.FC<BookingVersionItemDetailsGeneralProps> = ({ Identifier }) => {
  const [response, setResponse] = useState<GetPNRApiResponse | undefined>(undefined);
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const client = useBookingClient();
  const dispatch = useDispatch();
  const result = response?.result;
  function FetchPNRText() {
    setFetching(true);
    setResponse(undefined);
    client.getPNR(Identifier)
      .then(response => {
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        setResponse(response);
        if (response.result) {
          setError(false);
        }
        else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setFetching(false);
      });
  }

  useEffect(() => {
    FetchPNRText();
  }, [])
  return (
    <div className="card">
      <div className="card-header card-header-primary">PNR <span className="float-end"><button className="btn btn-primary btn-sm" onClick={FetchPNRText}><FontAwesomeIcon icon={faSync} /> Refresh</button></span></div>
      <div className="card-body card-body-secondary">

        <div className="row">
          <div className="col-12"></div>

          {error &&
            <ErrorDisplay data={response?.responseMetaData} />
          }

          {fetching &&
            <div className="col-12">
              <WaitingCard />
            </div>
          }
          {!fetching &&
            <React.Fragment>

              {result &&
                <div className="col-12">
                  <BookingPnrCardData data={result} />
                </div>
              }

            </React.Fragment>
          }
        </div>

      </div>
    </div>
  );


}
