import Line from '../../Styles/images/signup/line.svg'
import facebookLogo from '../../Styles/images/signup/facebookLogo.svg'
import googleLogo from '../../Styles/images/signup/googleLogo.svg'
import twitterLogo from '../../Styles/images/signup/twitterLogo.svg'
import appleLogo from '../../Styles/images/signup/appleLogo.svg'
import closeIcon from '../../Styles/images/signup/closeIcon.svg'

import './SignUp.css'

interface SignInInterface{
    setLoginToReg?:any
}

const SignIn: React.FC<SignInInterface> = (props) =>  {
    return <main>
        <section className='container'>
            <div style={{minHeight: '635px', maxWidth: '501px'}} className="p-0 m-0 bg-light">
            {/* <div className='flex justify-end h-0'>
                <a href='/'><img src={closeIcon} className='me-2 mt-2'/></a>
            </div> */}
            <div style={{minHeight: '75vh'}} className='flex flex-col justify-center'>
            <div className="flex justify-center my-2">
                <h1 className="w-75 font-bold text-xl headingClass">Sign In / Register</h1>
            </div>
            <div className="flex justify-center my-1">
                <p className="w-75 text-xs text-gray-600 simpleTextClass">Manage your booking with ease and enjoy member-only benefits</p>
            </div>
            <div className="flex justify-center my-2">
            <div className="form-group w-75">
                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Please enter an email address" />
            </div>
            </div>
            <div className="flex justify-center my-1 ml-2" onClick={props.setLoginToReg}>
                <div className='w-75'>
                <button type="button" style={{backgroundColor: '#2196F3'}} className="btn text-light w-100">Submit</button>
                </div>
            </div>
            <div className="flex justify-center">
                <img src={Line} className='w-1/6'/>
                <p className="w-1/2 text-xs text-gray-600 text-center mt-3 mx-1 simpleTextClass">More sign in methods</p>
                <img src={Line} className='w-1/6' />
            </div>
            <div className='flex justify-content-center'>
            <div className="flex justify-content-around my-2 w-75">
                <div className='flex justify-center'>
                    <div className="flex flex-col justify-center align-items-center gap-1">
                        <img src={facebookLogo} width={32} height={32}/>
                        <p className='text-xs text-gray-600 simpleTextClass'>Facebook</p>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <div className="flex flex-col justify-center align-items-center gap-1">
                        <img src={googleLogo}  width={32} height={32}/>
                        <p className='text-xs text-gray-600 simpleTextClass'>Google</p>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <div className="flex flex-col justify-center align-items-center gap-1">
                        <img src={twitterLogo} width={32} height={32}/>
                        <p className='text-xs text-gray-600 simpleTextClass'>Twitter</p>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <div className="flex flex-col justify-center align-items-center gap-1">
                        <img src={appleLogo} width={32} height={32}/>
                        <p className='text-xs text-gray-600 simpleTextClass'>Apple</p>
                    </div>
                </div>
            </div>
            </div>
            <div className="flex justify-center">
                <p className='text-xs w-75 text-gray-600 minimalTextClass'>By signing in or registering, you are deemed to have agreed to the HitcHiker.com Terms and Condition and Privacy Statement</p>
            </div>
            </div>
            </div>
        </section>
    </main>
}

export default SignIn;