import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BookingItemPassengerModel, PnrResponsePassengerModel } from "WebApiClient";


interface NormalizedPassenger {
    Firstname: string,
    LastName: string,
    PassengerNumber: string
}

function GetPNRPassengerByID(id: number, passengers: PnrResponsePassengerModel[]): NormalizedPassenger | undefined {
    let filter = passengers.filter(e => e.passengerNumber === id)[0];
    let result: NormalizedPassenger | undefined = undefined;
    if (filter) {
        result = { Firstname: filter.firstName, LastName: filter.name, PassengerNumber: id.toString() };
    }

    return result;
}
function GetItemPassengerByID(id: number, passengers: BookingItemPassengerModel[]): NormalizedPassenger | undefined {
    let filter = passengers.filter(e => e.passengerNumber === id)[0];
    let result: any;
    if (filter) {
        result = { Firstname: filter.firstName, LastName: filter.lastName, PassengerNumber: id.toString() };
    }

    return result;
}
export const BookingMangerPassengersTableCell: React.FC<{ active?: any, numbers: number[], pnrPassengers?: PnrResponsePassengerModel[], bookingItemPassengers?: BookingItemPassengerModel[] }> = ({ active, numbers, pnrPassengers, bookingItemPassengers }) => {
    return <td className={active ? "activeTR" : ""} >
        {numbers?.length === 0 ?
            <span>All passengers</span> :
            <span>{numbers?.map((pN, i) => {
                let passenger: NormalizedPassenger | undefined = undefined;
                if (pnrPassengers) {
                    passenger = GetPNRPassengerByID(pN, pnrPassengers)
                }
                if (bookingItemPassengers) {
                    passenger = GetItemPassengerByID(pN, bookingItemPassengers)
                }
                return <span key={"fontA_" + i}>
                    <FontAwesomeIcon icon={faUser} />{" "}
                    {passenger ?
                        <span>{passenger.LastName},{passenger.Firstname}</span> : <span>Passenger "{pN}"</span>
                    }
                    <br /></span>
            }

            )}

            </span>
        }
    </td>
}



export const BookingMangerPassengersTableData = (numbers: any, bookingItemPassengers: any, pnrPassengers: any,) => {
    return numbers.map((pN: any, i: any) => {
        let passenger: any;
        passenger = GetPNRPassengerByID(pN, pnrPassengers)
        if (bookingItemPassengers) {
            passenger = GetItemPassengerByID(pN, bookingItemPassengers)
        }
        if (passenger) {
            return {
                fName: passenger.Firstname,
                lName: passenger.LastName
            }
        } else {
            return pN
        }
    })
}

