import { BookingAddressBehavior } from "WebApiClient";
import { AvailableFareModel } from "./../../../WebApiClient";
import { useEffect, useState } from "react";

export interface BookOtherValidationResult {
  Valid: boolean;
  WebfarePasswordRequired: boolean;
  WebfarePassword: boolean;
  EmergencyPhone: boolean;
  paymentBOFDetails: any;
}

export const DefaultBookOtherValidationResult: BookOtherValidationResult = {
  Valid: false,
  WebfarePasswordRequired: false,
  WebfarePassword: false,
  EmergencyPhone: false,
  paymentBOFDetails: [],
};

export function OtherValid(
  fare: AvailableFareModel,
  webfarePassword: string,
  emergencyPhone: string,
  webfarePasswordbehavior: BookingAddressBehavior,
  emergencyPhoneBehavior: BookingAddressBehavior,
  paymentBOFDetails: any,
  paymentDataBackend: any
): BookOtherValidationResult {
  let otherValid = true;
  const [paymentCheckValidation, setPaymentCheckValidation] = useState(false);
  const apisRequirenments = fare.apisRequirenments!;
  const webfarePasswordRequired =
    webfarePasswordbehavior !== BookingAddressBehavior.UseBranchAdress &&
    apisRequirenments.password;
  const webFarePasswordValid: boolean = webfarePasswordRequired
    ? webfarePassword !== null && webfarePassword !== ""
    : true;

  if (!webFarePasswordValid) {
    otherValid = false;
  }

  const emergencyPhoneRequired =
    emergencyPhoneBehavior !== BookingAddressBehavior.UseBranchAdress;
  const emergencyPhoneValid: boolean = emergencyPhoneRequired
    ? emergencyPhone !== null && emergencyPhone !== ""
    : true;
  if (!emergencyPhoneValid) {
    otherValid = false;
  }

  useEffect(() => {
    if (paymentDataBackend?.length) {
      setPaymentCheckValidation(
        validateInputValues(paymentDataBackend, paymentBOFDetails).includes(false)
      );
    }
  }, [paymentBOFDetails]);

  function validateInputValues(rules: any, values: any) {
    let finalBoolean = []; // Corrected: Initialize to true, assuming validation will pass


    for (let i = 0; i < 20; i++) {
      for (const rule of rules) {
        const code = rule[`Code${i}`];
        if(code !== undefined){
          const value = values[code];
          if (value) {
            if (value?.length >= rule[`Minlen${i}`] && value?.length <= rule[`MaxLen${i}`]) {
              finalBoolean.push(true);
            } else {
              finalBoolean.push(false);
              break;
            }
          } else {
            finalBoolean.push(false); // Corrected: Set to false when a value is missing
          }
        }
      }
    }

    return finalBoolean;
  }

  
  const result: BookOtherValidationResult = {
    Valid:  true,  //otherValid,
    WebfarePassword: webFarePasswordValid,
    EmergencyPhone: emergencyPhoneValid,
    WebfarePasswordRequired: webfarePasswordRequired,
    // paymentBOFDetails:  true
    paymentBOFDetails:  !paymentCheckValidation
  };
  return result;
}
