import React, { useState } from "react";
import FareSearch from "components/FareSearch/component/FareSearch";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  CardText,
  CardTitle,
  Col,
  ModalHeader,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Modal, ModalBody } from "reactstrap";
import SignIn from "components/AuthLoginReg/SignIn";
import SignUp from "components/AuthLoginReg/SignUp";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";

interface LandingPageInterface {
  OnReset: any;
  IsModifySearch: any;
  OnSearchSubmitted: any;
  LastSubmitted: any;
  submitResult: any;
  GoToResults: any;
  setHeightHolder?: any;
  setActiveTabHF?: any;
  activeTabHF?: any;
  language: string;
  isB2cAirline: boolean;
}

const LandingPage: React.FC<LandingPageInterface> = (props) => {
  //modal Login Reg
  const [modal, setModal] = useState(false);
  const [LoginToReg, setLoginToReg] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleLR = () => setLoginToReg(!LoginToReg);
  // form hide open when scroll
  const [scrollingDown, setScrollingDown] = useState(true);
  //termsCondition redux
  const sessionInfoB2c = useSelector((state: State) => state.Session);
  const showBackModalColor = JSON.parse(sessionInfoB2c?.FrontendSettings?.b2cSettingsText);

  return (
    <div className=" ">
      <div className=" p-3 text-white text-center">
        <div className="d-flex justify-content-between pb-3"></div>
        <div className="d-flex flex-column-reverse flex-lg-column">
          <div className="px-lg-5 searchCard mt-5">
            {/* <Nav tabs className="searchCard">
              <div className="d-flex cardTop">
                <NavItem>
                  <NavLink
                    className={props.activeTabHF === "1" ? "active" : ""}
                    onClick={() => toggleTab("1")}>
                    <FontAwesomeIcon icon={faPlaneUp} />{" "}
                    {t("LandingPage.SearchForm.Airline")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={props.activeTabHF === "2" ? "active" : ""}
                    onClick={() => toggleTab("2")}
                  >
                    <FontAwesomeIcon icon={faBed} />{" "}
                    {t("LandingPage.SearchForm.Hotels")}
                  </NavLink>
                </NavItem>
              </div>
            </Nav> */}
            <TabContent activeTab={props.activeTabHF} className="shadow">
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <FareSearch
                      OnReset={props.OnReset}
                      IsModifySearch={props.IsModifySearch}
                      OnSearchSubmitted={props.OnSearchSubmitted}
                      LastSubmitted={props.LastSubmitted}
                      submitResult={props.submitResult}
                      GoToResults={props.GoToResults}
                      boxRef={props.setHeightHolder}
                      language={props.language}
                      isNext={true}
                      isFareListHeader={false}
                      setScrollingDown={setScrollingDown}
                      isB2cAirline={props.isB2cAirline}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="6">
                    <Card body>
                      <CardTitle>Special Title Treatment</CardTitle>
                      <CardText>
                        With supporting text below as a natural lead-in to
                        additional content.
                      </CardText>
                      <Button>Go somewhere</Button>
                    </Card>
                  </Col>
                  <Col sm="6">
                    <Card body>
                      <CardTitle>Special Title Treatment</CardTitle>
                      <CardText>
                        With supporting text below as a natural lead-in to
                        additional content.
                      </CardText>
                      <Button>Go somewhere</Button>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>

            <div style={{ position: "fixed", top: "20vh", right: "0px" }}>
              <div className="btn-group btn-group-vertical">
                {/* <button className="btn  btn-cancel mb-2" onClick={props.OnReset}>
    <FontAwesomeIcon icon={faRedo} /> Reset
  </button> */}
                {props.GoToResults && (
                  <button
                    className="btn btn-primary btn-lg mb-2 py-4 PrevBtn"
                    onClick={props.GoToResults}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} backdropClassName={showBackModalColor?.ShowBackgroundModalColor == "On" ? "loaderColorHalf" : "loaderColorZero"}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          {LoginToReg ? (
            <SignUp setLoginToReg={toggleLR} />
          ) : (
            <SignIn setLoginToReg={toggleLR} />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default LandingPage;
