import GeneralWaitScreen from "components/General/WaitScreens/GeneralWaitScreen";
import { Playground } from "components/playground";
import { useAppSelector } from "configureStore";
import { useLoginClient } from "hooks/useHttpClient";
import AvailableFaresPage from "Pages/AvailableFaresPage";
import AvailableFaresPageCopy from "Pages/AvailableFaresPageCopy";
import FareDisplayPage from "Pages/FareDisplayPage";
import BookingDetailsPage from "Pages/BookingDetailsPage";
import BookingManagerPage from "Pages/BookingManagerPage";
import DeepLoginPage from "Pages/DeepLoginPage";
import { EmailSignupConfirmationPage } from "Pages/EmailSignupConfirmationPage";
import { EmailUnsubscribeConfirmationPage } from "Pages/EmailUnsubscribeConfirmationPage ";
import LoginPage from "Pages/LoginPage";
import TestPage from "Pages/TestPage";
import UnauthorizedPage from "Pages/Unauthorized";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route } from "react-router";
import { Navigate, Routes, To, useNavigate, useParams } from "react-router-dom";
import {
  Session_Reducer_isAdmin,
  Session_Reducer_SetB2cSettings,
  Session_Reducer_SetFrontendSettings,
  Session_Reducer_SetToken,
} from "rootExports/SessionReducer";
import NoMatch from "routes/NoMatch";
import "./pageTransition.css";
import HotelsPage from "Pages/HotelsPage";
import { loginBool_Reducer } from "rootExports/LoginReducer";
import { useUserInformation } from "hooks/various";
export const AppRoutes: React.FC<{}> = (Allprops) => {

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/playground" element={<Playground />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/weblogin/:routeWebname" element={<LoginPage />} />
      <Route path="/account/login/:routeWebname" element={<LoginPage />} />
      <Route path="/deep/:webid/:deeplinkid" element={<DeepLoginPage />} />
      <Route
        path="/signup/confirm/:code"
        element={<EmailSignupConfirmationPage />}
      />
      <Route
        path="/signup/unsubscribe/:code"
        element={<EmailUnsubscribeConfirmationPage />}
      />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="/auth/:webid/:deeplinkid/*" element={<AuthorizedRoutes />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export const RequireAuth: React.FC<{
  isAuthenticated: boolean;
  children: JSX.Element;
  redirectTo: To;
}> = ({ children, redirectTo, isAuthenticated }) => {
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

export const AuthorizedRoutes: React.FC<{}> = (Allprops) => {
  const isLoggedIn = useAppSelector((state) => state.Session.isLoggedIn);
  let { webid, deeplinkid } = useParams();
  const loginClient = useLoginClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const otherSettings: string = useAppSelector((state: any) => {
    if (
      state.Session.FrontendSettings.otherSettingsAsKeyValueText == null ||
      state.Session.FrontendSettings.otherSettingsAsKeyValueText.trim() === ""
    ) {
      return state.Session.FrontendSettings.otherSettingsAsKeyValueText ===
        "menuPosition=L\nshowFareHeader=Off"
        ? "menuPosition=L\nshowFareHeader=Off\nshowSmartCalender=None/n[]/nfalse/nfalse"
        : `menuPosition=L/{"textColor":"#ffffff","bgColor":"#242424"}
        showFareHeader=Off
        showSmartCalender=None/n[]/nfalse/nfalse{"Economy":{"Checked":true,"preSelect":true},"Premium":{"Checked":true,"preSelect":false},"Business":{"Checked":true,"preSelect":false},"First":{"Checked":true,"preSelect":false}}\n0\nshowHotelTab=None\nshowCabinContainer=All`;
    }

    return state.Session.FrontendSettings.otherSettingsAsKeyValueText;
  });
  const settingArr = otherSettings.split("\n");
  const fieldContainer = settingArr[7] != undefined ? settingArr[7].split("#") : [];
  const mustLoginBool: boolean = fieldContainer?.includes('MUSTLOGIN');
  const LoginReduxBool: any = useAppSelector((state: any) => state.LoginBool);
  const userInfo = useUserInformation();
  function OnLogout() {
    // dispatch(Session_Reducer_Reset());
    window.location.href = `/weblogin/${userInfo?.WebName}`;
  }
  const loginBoolStr = sessionStorage.getItem("loginBool");
  const isLogin = loginBoolStr ? JSON.parse(loginBoolStr) : false;
  useEffect(() => {
    if (!isLogin) {
      if (mustLoginBool && !LoginReduxBool.value) {
        dispatch(loginBool_Reducer(true));
        OnLogout();
      }
    }
  })
  useEffect(() => {
    if (isLoggedIn) {
      // console.log(`AUTHORIZED Route render: ${deeplinkid ? `| Deeplink: ${deeplinkid}` : ""}`);
    } else {
      loginClient
        .loginByDeeplink(webid!, deeplinkid!, undefined)
        .then((e) => {
          if (e.success) {
            dispatch(Session_Reducer_isAdmin(e.isAdmin!));
            dispatch(Session_Reducer_SetToken(e.token!));
            dispatch(
              Session_Reducer_SetFrontendSettings(e.agentFrontendSettings!));
            dispatch(Session_Reducer_SetB2cSettings(e.agentFrontendSettings?.b2cSettingsText));
          } else {
            navigate("/unauthorized");
          }
        })
        .catch(() => {
          navigate("/unauthorized");
        });
    }
  });
  if (isLoggedIn) {
    return (
      <Routes>
        <Route path="/faresearch" element={<AvailableFaresPage isB2cAirline={false} />} />
        <Route path="/availfares" element={<AvailableFaresPage isB2cAirline={false} />} />
        <Route path="/airline" element={<AvailableFaresPage isB2cAirline={true} />} />
        <Route path="/fareSearchFormAirline" element={<AvailableFaresPageCopy isB2cAirline={true} />} />
        <Route path="/fareSearchForm" element={<AvailableFaresPageCopy isB2cAirline={false} />} />
        <Route path="/hotels" element={<HotelsPage />} />
        <Route path="/faredisplay" element={<FareDisplayPage />} />
        <Route path="/bookingmanager/*" element={<BookingManagerPage />} />
        <Route
          path="/bookingdetails/:cartid/:bookingid"
          element={<BookingDetailsPage />}
        />
        <Route
          path="/bookingdetails/:filekey"
          element={<BookingDetailsPage />}
        />
        <Route path="/test/:logo/:pagePath" element={<TestPage />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    );
  } else {
    return <GeneralWaitScreen show={true}></GeneralWaitScreen>;
  }
};