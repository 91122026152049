import React, { useEffect, useState } from "react";
import HotelSearch from "components/HotelSearch/component/HotelSearch";
import InfiniteScroll from "components/AvailableHotels/component/InfiniteScroll";
import HotelFilter from "components/AvailableHotels/component/AvailableHotel.Filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faKeyboard } from "@fortawesome/pro-regular-svg-icons";
import {
  KeyBoardModal,
  KeyBoardShortcutDescription,
} from "components/Shared/KeyBoardModal";
import LoadingSpinner from "components/AvailableHotels/component/LoadingSpinner";

import HotelBooking from "components/AvailableHotels/component/HotelBooking";
import { SessionHandler, useSessionHandler } from "hooks/useSessionHandler";
import ErrorMessage from "components/AvailableHotels/component/ErrorMessage";

interface APIResponse {
  results: []; // Here you can replace 'any' with the appropriate type for the results data.
  sessionId: string;
  status: "success" | "error";
  timestamp: number;
  requestTime: string;
  completed: 0 | 1; // Here, 0 represents false (not completed) and 1 represents true (completed).
  processTime: number;
}

const HotelsPage: React.FC<any> = () => {
  const [hotelResult, setHotelResult] = useState<any[]>();
  const [filteredHotelResult, setFilteredHotelResult] = useState<any[]>();
  const [allHotelIds, setAllHotelIds] = useState<any>();
  const [hotelValidIds, setHotelValidIds] = useState<any>();
  const [selectedHotel, setSelectedHotel] = useState<any>();
  const [validHotelPrice, setValidHotelPrice] = useState<APIResponse>();
  const [resultStatus, setResultStatus] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [keyBoardModalOpen, setKeyboardModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [isValidId, setIsValidIds] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<any>();
  const [triggerB, settriggerB] = useState(true);
  const [skipHotelCount, setSkipHotelCount] = useState<number>(0);
  const [isSortOn, setIsSortOn] = useState<boolean>(false);
  const DEBUG = false;
  const sessionHandler = useSessionHandler();
  useEffect(() => {
    document.title = `Hotels`;
    if (DEBUG) {
      console.log(`Hotel Page initialized.`);
    }
    sessionHandler.RefreshSession();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if the event target is an input element
      if (event.target instanceof HTMLInputElement) {
        return;
      }

      if (event.key === "f" || event.key === "F") {
        setIsFilterOpen(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  function ToggleFilter() {
    setIsFilterOpen(!isFilterOpen);
  }

  function OnKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === "f") {
      ToggleFilter();
    }
  }
  const ToggleKeyBoardModal = () => {
    setKeyboardModalOpen(!keyBoardModalOpen);
  };

  useEffect(() => {
  }, [filteredHotelResult?.length]);

  return (
    <>
      <section onKeyDown={OnKeyDown}>
        <div className="w-100">
          <HotelSearch
            setIsError={setIsError}
            isPending={setIsPending}
            resultStatus={setResultStatus}
            setHotelResult={setHotelResult}
            setHotelValidIds={setHotelValidIds}
            setAllHotelIds={setAllHotelIds}
            setValidHotels={setValidHotelPrice}
            setSearchData={setSearchData}
            setIsValidIds={setIsValidIds}
            settriggerB={settriggerB}
            triggerB={triggerB}
            setSkipHotelCount={setSkipHotelCount}
            setIsSortOn={setIsSortOn}
          />
          <div className={`${activeTab !== "1" ? "d-none" : ""}`}>
            {resultStatus && (
              <section>
                <InfiniteScroll
                  allHotelIds={allHotelIds}
                  hotelResult={hotelResult}
                  validHotelRes={validHotelPrice}
                  activeTab={setActiveTab}
                  setSelectedHotel={setSelectedHotel}
                  hotelValidIds={hotelValidIds}
                  searchData={searchData}
                  setSkipHotelCount={setSkipHotelCount}
                  skipHotelCount={skipHotelCount}
                  setIsSortOn={setIsSortOn}
                  isSortOn={isSortOn}
                />
                <HotelFilter
                  isOpen={isFilterOpen}
                  Toggle={ToggleFilter}
                  result={hotelResult}
                  setHotelResult={setHotelResult}
                  // setFilteredHotelResult={setFilteredHotelResult}
                />
                <button
                  style={{ right: "20px" }}
                  onClick={() => ToggleFilter()}
                  className="position-fixed top-50 btn btn-primary filterBtn"
                >
                  {" "}
                  <FontAwesomeIcon icon={faFilter} /> Filter
                </button>
              </section>
            )}
            {isPending ? (
              isError || isValidId ? (
                <ErrorMessage />
              ) : (
                <LoadingSpinner />
              )
            ) : null}
            <div>{isValidId && <span>Please try again.</span>}</div>
          </div>
        </div>
      </section>
      <KeyBoardModal
        open={keyBoardModalOpen}
        toggle={ToggleKeyBoardModal}
        Shortcuts={shortcuts}
      />
    </>
  );
};

const shortcuts: KeyBoardShortcutDescription[] = [
  // {
  //     Description: "Open Filter",
  //     Key: "F"
  // },
  // {
  //     Description: "Expand all fares",
  //     Key: "A"
  // },
  // {
  //     Description: "Expand all flight times",
  //     Key: "T"
  // },
  // {
  //     Description: "Expand all calculation",
  //     Key: "C"
  // },
  // {
  //     Description: "Modified Search",
  //     Key: "M"
  // },
  // {
  //     Description: "Reset Filter",
  //     Key: "R"
  // },
];

export default HotelsPage;
