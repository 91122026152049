import {
  IconDefinition,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faCircle,
  faConciergeBell,
  faCouch,
  faEuroSign,
  faPlaneArrival,
  faPlaneDeparture,
  faRandom,
  faSuitcase,
  faTrash,
  faUtensils,
} from "@fortawesome/pro-regular-svg-icons";
import bag from "Styles/images/V21.svg";
import { faStar as solidStar } from "@fortawesome/pro-solid-svg-icons";
import { faStar as lightStar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import { DateDisplay } from "components/Shared/DateDisplay";
import React, { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Collapse } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  ServiceBookingTypeEnumModel,
  ServiceGroupTypeEnumModel,
  ServiceModel,
  ServicePassengerModel,
  ServicePriceModel,
  ServiceReferenceIDModel,
  ServiceSegmentModel,
} from "WebApiClient";
import { AncillaryRelation } from "./AncillaryRelation";
import { AssignablePassengerOption } from "./AncillarySelection";
import _ from "lodash";
import carryOnBag from "Styles/images/carry-on-bag.svg";
import cabinBag from "Styles/images/check-on-bag.svg";
import personalBag from "Styles/images/Persnol-bag.svg";
import personaldisableBag from "Styles/images/Persnol-bag.svg";
import { AddServiceSelector } from "./AddServiceQuickSelect";
import { ServiceBookingTypeEnumDisplay } from "components/Shared/ServiceBookingTypeEnumDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { log } from "console";
import { LocalizationConverter } from "components/Shared/Helpers/LocalizationConverter";

interface AncillaryProps {
  Service: ServiceModel;
  Passengers: ServicePassengerModel[];
  Prices: ServicePriceModel[];
  AllSegments: ServiceSegmentModel[];
  ToggleAll: boolean;
  OnAddService(obj: ServiceAddObj): void;
  AddedServices: ServiceAddObj[];
  Type?: number;
  PassengerOptions: AssignablePassengerOption[];
  textStored?: any;
  setrenderOnce?: any;
  renderOnce?: any;
  index?: any;
  OnRemoveService?: any;
  setRemoveServiceHolder?: any;
  iconBlueTrue?: boolean;
  // OnRemoveService(obj: ServiceAddObj): void;
}
export interface GroupedPassenger {
  PassengerOptions: AssignablePassengerOption[];
  Passenger: ServicePassengerModel;
  Prices: GroupedPassengerPrice[];
  Service: ServiceModel;
}
export interface GroupedPassengerPrice {
  Segments: ServiceSegmentModel[];
  Price: ServicePriceModel | undefined;
}

export const Ancillary: React.FC<AncillaryProps> = ({
  Service,
  Passengers,
  Prices,
  AllSegments,
  ToggleAll,
  OnAddService,
  AddedServices,
  PassengerOptions,
  Type,
  textStored,
  index,
  OnRemoveService,
  setRemoveServiceHolder,
  iconBlueTrue,
}) => {
  const [open, setOpen] = useState(false);
  const [isServiceAdded, setIsServiceAdded] = useState<boolean>(false);
  const {
    bookingCode,
    bookingType,
    code,
    emdRequired,
    extensions,
    freeTextFormat,
    freeTextMode,
    id,
    lastEMDDate,
    referenceIDs,
    refundable,
    serviceCarrier,
    serviceDescription,
    serviceLocation,
    serviceType,
    text,
    type,
  } = Service;
  const { t } = useTranslation();
  const isRefundable = refundable !== undefined && refundable === true;

  const [countText, setCountText] = useState<any>({
    free: [0, []],
    hand: [0, []],
    kg: [0, []],
  });

  function Toggle() {
    setOpen(!open);
  }
  useEffect(() => {
    setOpen(ToggleAll);
  }, [ToggleAll]);

  useEffect(() => {
    const wordCount: any = { free: [0, []], hand: [0, []], kg: [0, []] };
    textStored.forEach((text: any) => {
      if (text.toLowerCase().includes("free")) {
        wordCount.free[0]++;
        wordCount.free[1].push(text);
      }
      if (text.toLowerCase().includes("hand")) {
        wordCount.hand[0]++;
        wordCount.hand[1].push(text);
      }
      if (text.toLowerCase().includes("kg")) {
        wordCount.kg[0]++;
        wordCount.kg[1].push(text);
      }
    });
    setCountText(wordCount);
  }, [textStored]);

  function GetPriceReferences(): ServicePriceModel[] {
    let result: ServicePriceModel[] = [];
    referenceIDs.forEach((e) => {
      if (e.priceID.length > 0) {
        result = result.concat(Prices.filter((y) => y.priceID === e.priceID));
      }
    });
    return result;
  }

  const PriceReferences: ServicePriceModel[] = GetPriceReferences();
  const grouped: GroupedPassenger[] = GetGrouped();

  function GetGrouped(): GroupedPassenger[] {
    let result: GroupedPassenger[] = [];

    referenceIDs.forEach((reference) => {
      const price = GetPrice(reference);
      const existingGroup = result.find(
        (e) => e.Passenger.id === reference.passengerID
      );

      if (!existingGroup) {
        const passengerType: ServicePassengerModel | undefined =
          Passengers.find((e) => e.id === reference.passengerID);
        if (passengerType) {
          const availPassengers = PassengerOptions.filter(
            (e) => e.PassengerType === passengerType.typeAbbr
          );
          const newGroup: GroupedPassenger = {
            Passenger: passengerType,
            Prices: [price],
            Service: Service, // You might want to replace Service with the correct reference
            PassengerOptions: availPassengers,
          };
          result.push(newGroup);
        }
      } else {
        existingGroup.Prices.push(price);
      }
    });
    return result;
  }

  function GetPrice(ref: ServiceReferenceIDModel): GroupedPassengerPrice {
    const price: ServicePriceModel = Prices.find(
      (e) => e.priceID === ref.priceID
    )!;
    let segments: ServiceSegmentModel[] = [];
    ref.segmentIDs.forEach((sID) => {
      const s = AllSegments.find((e) => e.id === sID)!;
      segments.push(s);
    });
    const result: GroupedPassengerPrice = {
      Price: price,
      Segments: segments,
    };
    return result;
  }

  function removeValuesInBrackets(inputString: string) {
    const regex1 = /^\s*(\S+)/;
    const match = inputString.match(regex1);
    const firstName = match ? match[1] : inputString.trim();
    return firstName;
  }

  function remove(price: any, segments: any, passenger: number, code: string) {
    const addObj: any = {
      Price: price,
      PassengerIndex: passenger,
      Segments: segments,
      Service: Service,
      Code: code,
    };

    setRemoveServiceHolder([
      {
        Type: addObj.Service.type,
        Service: addObj.Service,
        Code: addObj.Code,
      },
    ]);
    OnRemoveService(addObj);
  }

  return (
    <>
      {(serviceType !== 4 && code != "0B0" && code != "0BZ" && code != "0BH" && code != "0NJ") && (
        <div className="">
          <AncillaryHeader
            OnClick={Toggle}
            Open={open}
            Prices={PriceReferences}
            Service={Service}
            grouped={grouped}
            AddedServices={AddedServices}
            OnAddService={OnAddService}
            isServiceAdded={setIsServiceAdded}
            ServiceAddedBool={isServiceAdded}
            OnRemoveService={OnRemoveService}
            serviceIndex={index}
            AllSegments={AllSegments}
            setRemoveServiceHolder={setRemoveServiceHolder}
            iconBlueTrue={iconBlueTrue}
            removeValuesInBrackets={removeValuesInBrackets}
          />
          <Collapse isOpen={false}>
            <div className="card-body">
              <div className="row">
                <div className="col-12 mb-2">
                  <div className="card">
                    <div className="card-header card-header-primary">
                      Description
                    </div>
                    <div className="card-body description">
                      <p>
                        {serviceDescription.length > 0
                          ? serviceDescription
                          : text}
                      </p>
                      {refundable !== undefined && (
                        <p>Refundable: {refundable ? "Yes" : "No "}</p>
                      )}
                      {emdRequired !== undefined && (
                        <p>EMD required: {emdRequired ? "Yes" : "No "}</p>
                      )}
                      {lastEMDDate && (
                        <p>
                          Last EMD date: <DateDisplay date={lastEMDDate} />
                        </p>
                      )}
                      {serviceCarrier.length > 0 && (
                        <p>Service carrier: {serviceCarrier}</p>
                      )}
                    </div>
                  </div>
                </div>
                {bookingCode.length > 0 && (
                  <div className="col-12 mb-2">
                    <div className="card">
                      <div className="card-header card-header-primary">
                        Add Service
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12 mb-4">
                            <AddServiceSelector groups={grouped} />
                          </div>
                          {grouped.map((group, index) => (
                            <div className="col-12 mb-2" key={"grup" + index}>
                              <div className="row">
                                <AncillaryRelation
                                  GroupedPassenger={group}
                                  OnAddService={OnAddService}
                                  AddedServices={AddedServices}
                                  AllSegments={AllSegments}
                                  isServiceAdded={setIsServiceAdded}
                                  removeValuesInBrackets={
                                    removeValuesInBrackets
                                  }
                                  remove={remove}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Collapse>
        </div>
      )}
    </>
  );
};

interface AncillaryHeaderProps {
  OnClick: () => void;
  Open: boolean;
  Service: ServiceModel;
  Prices: ServicePriceModel[];
  grouped: GroupedPassenger[];
  AddedServices: ServiceAddObj[];
  OnAddService(obj: ServiceAddObj): void;
  isServiceAdded?: any;
  ServiceAddedBool?: any;
  // OnRemoveService(obj: ServiceAddObj): void;
  OnRemoveService: any;
  serviceIndex: number;
  AllSegments: ServiceSegmentModel[];
  setRemoveServiceHolder?: any;
  iconBlueTrue?: boolean;
  removeValuesInBrackets: any;
}

interface ButtonCounts {
  [key: number]: number;
}
interface Segment {
  arrival: string;
  departure: string;
}
const AncillaryHeader: React.FC<AncillaryHeaderProps> = ({
  OnClick,
  Open,
  Service,
  Prices,
  grouped,
  AddedServices,
  OnAddService,
  isServiceAdded,
  ServiceAddedBool,
  OnRemoveService,
  serviceIndex,
  AllSegments,
  setRemoveServiceHolder,
  iconBlueTrue,
  removeValuesInBrackets,
}) => {
  const {
    bookingCode,
    code,
    text,
    bookingType,
    serviceDescription,
    refundable,
    serviceCarrier,
  } = Service;

  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // const renderSeg = (): JSX.Element[] | any => {
  //     // Your logic to generate JSX elements
  //     let segments: JSX.Element[] = [];
  //     Service.referenceIDs.map((Seg, id) => {
  //         Seg.segmentIDs.map((ids) => {
  //             console.log("all",AllSegments);

  //             AllSegments.map((Segs, id) => {
  //                 if (ids == Segs.id) {
  //                     const { arrival, departure } = Segs;
  //                     const key = `segment_${id}_${Segs.id}_${Segs.arrival}`;

  //                     segments.push(
  //                         <div key={key}>
  //                             <FontAwesomeIcon icon={faPlaneDeparture} />{" "}
  //                             &nbsp;
  //                             <span className="segment-label">
  //                                 {departure}
  //                             </span>
  //                             &nbsp;
  //                             {"- "}
  //                             <FontAwesomeIcon icon={faPlaneArrival} />
  //                             &nbsp;
  //                             <span className="segment-label">{arrival}</span>
  //                         </div>
  //                     );
  //                 }
  //             });
  //         });
  //     });
  //     segments = _.uniqBy(segments, "key");
  //     return segments;
  // };

  const renderSeg = (): JSX.Element[] | any => {
    // Your logic to generate JSX elements
    let segments: JSX.Element[] = [];
    // Flatten the array of segmentIDs from Service.referenceIDs
    const allSegmentIDs = Service.referenceIDs.flatMap(
      (refId) => refId.segmentIDs
    );
    // Iterate through allSegments
    AllSegments.forEach((seg) => {
      // Check if the segment ID is included in allSegmentIDs
      if (allSegmentIDs.includes(seg.id)) {
        const { arrival, departure } = seg;
        const key = `segment_${seg.id}_${arrival}_${departure}`;

        segments.push(
          <div key={key}>
            <FontAwesomeIcon icon={faPlaneDeparture} /> &nbsp;
            <span className="segment-label">{departure}</span>
            &nbsp;
            {"- "}
            <FontAwesomeIcon icon={faPlaneArrival} />
            &nbsp;
            <span className="segment-label">{arrival}</span>
          </div>
        );
      }
    });

    return segments;
  };

  const renderSegText = (): Segment[] | any => {
    const Arr: ServiceSegmentModel[] = [];
    const allSegmentIDs = Service.referenceIDs.flatMap(
      (refId) => refId.segmentIDs
    );

    AllSegments.forEach((seg) => {
      if (allSegmentIDs.includes(seg.id)) {
        Arr.push(seg);
      }
    });
    return Arr;
  };

  function _OnAddService(
    price: any,
    segments: any,
    passenger: number,
    code: string
  ) {
    const addObj: any = {
      Price: price,
      PassengerIndex: passenger,
      Segments: segments,
      Service: Service,
    };
    OnAddService(addObj);
    isServiceAdded(true);
    setGreyScaler(true);
  }

  function remove(price: any, segments: any, passenger: number, code: string) {
    const addObj: any = {
      Price: price,
      PassengerIndex: passenger,
      Segments: segments,
      Service: Service,
      Code: code,
    };

    setRemoveServiceHolder([
      {
        Type: addObj.Service.type,
        Service: addObj.Service,
        Code: addObj.Code,
      },
    ]);
    OnRemoveService(addObj);
    setGreyScaler(false);
  }

  const [GreyScaler, setGreyScaler] = useState(false);
  const [priceOccurrences, setPriceOccurrences] = useState<any[]>([]);

  useEffect(() => {
    priceTimes();
  }, [AddedServices?.length]);

  function priceTimes() {
    // Create an array to store the result objects
    const resultArray: any[] = [];

    // Iterate through the array
    AddedServices.forEach((item: ServiceAddObj) => {
      const priceID: string = item.Price.priceID.toString(); // Ensure priceID is converted to string
      const equivalentPriceValue: number | undefined =
        item?.Price?.equivalentPrice?.value; // Use optional chaining for safety
      // Create a new result object for each item
      const resultObject: any = {
        code: item.Service.code,
        [priceID]: equivalentPriceValue || 0, // Initialize with 0 if equivalentPriceValue is undefined
        segments: item.Segments,
      };

      // Check if a result object with the same code and priceID already exists
      const existingObjectIndex = resultArray.findIndex(
        (obj) => obj.code === resultObject.code && obj[priceID] !== undefined
      );

      if (existingObjectIndex !== -1) {
        // If an existing object is found, update its equivalentPriceValue
        resultArray[existingObjectIndex][priceID] += equivalentPriceValue || 0;
      } else {
        // If no existing object is found, add the resultObject to the resultArray
        resultArray.push(resultObject);
      }
    });
    // Set the resultArray
    setPriceOccurrences(resultArray);
  }

  function ServiceAlreadyAdded(
    price: ServicePriceModel,
    segments: ServiceSegmentModel[],
    passenger: number,
    code: string
  ): boolean {
    let result = false;
    let filtered = AddedServices.filter((e) => {
      return (
        e.PassengerIndex === passenger &&
        e.Price.priceID === price.priceID &&
        e.Service.bookingCode === Service.bookingCode &&
        e.Service.code === code
      );
    });

    if (filtered.length > 0) {
      filtered.forEach((element) => {
        element.Segments.forEach((s) => {
          segments.forEach((ss) => {
            if (
              ss.arrival.toLowerCase() === s.arrival.toLowerCase() &&
              ss.departure.toLowerCase() == s.departure.toLowerCase()
            ) {
              result = true;
            }
          });
        });
      });
      return result;
    }

    return false;
  }

  // function removeValuesInBrackets(inputString: string) {
  //   console.log(inputString);
  //   const regex1 = /^\s*(\S+)/;
  //   const match = inputString.match(regex1);
  //   const firstName = match ? match[1] : inputString.trim();
  //   return firstName;
  // }

  //mobile breakpoint check
  const matchesMobileBP = useMediaQuery("(max-width:600px)");
  return (
    <div className="border border-1 p-2 bg-light">
      <div className="p-2">
        <div
          className="d-flex justify-content-between"
          onClick={() => setIsOpen(!isOpen)}
        >
          {matchesMobileBP ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
              }}
            >
              <div className="ancillaryTitleW">
                <div
                  className="text-center"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <img
                    src={bag}
                    height="25"
                    className={`rounded-0 ${bookingType == 0 || GreyScaler || iconBlueTrue
                      ? ""
                      : "greyScalerCss"
                      }  `}
                    alt=""
                  />
                  <span className="font-15">{code}</span>
                  {code !== bookingCode && (
                    <span className="font-15">{bookingCode}</span>
                  )}
                </div>
                <p className="ms-3 mb-0">
                  {text} <br />
                  {text !== serviceDescription && (
                    <>
                      <span className="font-15">
                        {serviceDescription
                          .toLowerCase()
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </span>
                      <br />
                    </>
                  )}
                  {bookingType !== 0 && (
                    <span className="font-15">
                      {refundable
                        ? t("FareBooking.AncillariesPage.Non-refundable")
                        : t("FareBooking.AncillariesPage.Refundable")}
                    </span>
                  )}
                </p>
              </div>
              <div style={{ display: "flex" }}>
                <div className="w-50 align-content-center text-center">
                  <div className="renderSegment">
                    {renderSeg().length === 0
                      ? "All flights"
                      : renderSeg()?.length == AllSegments?.length
                        ? "All flights"
                        : renderSeg()}
                  </div>
                  {/* <span>Service carrier = {serviceCarrier}</span> */}
                </div>
                {bookingType !== 0 ? (
                  <div className="priceWidthAnc">
                    <p
                      style={{
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                      className="my-0"
                    >
                      {priceOccurrences?.length &&
                        priceOccurrences.find(
                          (occurrence) => {
                            const allSegmentsPresent = occurrence.segments.every((occSegment: any) =>
                              renderSegText().some((renderSegment: any) => _.isEqual(occSegment, renderSegment))
                            );

                            return (
                              occurrence.code === code &&
                              occurrence[Prices[0]?.priceID.toString()] &&
                              Prices[0]?.equivalentPrice?.value &&
                              allSegmentsPresent
                            );
                          }) ? (
                        <>
                          {priceOccurrences.find(
                            (occurrence) =>
                              occurrence.code === code &&
                              occurrence[Prices[0]?.priceID.toString()] &&
                              Prices[0]?.equivalentPrice?.value
                          )[Prices[0]?.priceID.toString()] /
                            Prices[0]?.equivalentPrice?.value!}
                          x
                          {LocalizationConverter(
                            Prices[0]?.equivalentPrice?.value.toFixed(2),
                            i18n.language
                          )}
                          {Prices[0]?.equivalentPrice?.value !== undefined &&
                            (Prices[0]?.equivalentPrice?.currencyCode !==
                              "EUR" ? (
                              Prices[0]?.equivalentPrice?.currencyCode
                            ) : (
                              <FontAwesomeIcon icon={faEuroSign} />
                            ))}
                        </>
                      ) : (
                        <>
                          {Prices[0]?.equivalentPrice?.value !== undefined
                            ? LocalizationConverter(
                              Prices[0]?.equivalentPrice?.value.toFixed(2),
                              i18n.language
                            )
                            : "0.00"}

                          {Prices[0]?.equivalentPrice?.value !== undefined &&
                            (Prices[0]?.equivalentPrice?.currencyCode !==
                              "EUR" ? (
                              Prices[0]?.equivalentPrice?.currencyCode
                            ) : (
                              <FontAwesomeIcon icon={faEuroSign} />
                            ))}
                        </>
                      )}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="ancillaryTitleW">
                <div
                  className="text-center"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <img
                    src={bag}
                    height="25"
                    className={`rounded-0 ${bookingType == 0 || GreyScaler || iconBlueTrue
                      ? ""
                      : "greyScalerCss"
                      }  `}
                    alt=""
                  />
                  <span className="font-15">{code}</span>
                  {code !== bookingCode && (
                    <span className="font-15">{bookingCode}</span>
                  )}
                </div>
                <p className="ms-3 mb-0">
                  {text} <br />
                  {text !== serviceDescription && (
                    <>
                      <span className="font-15">
                        {serviceDescription
                          .toLowerCase()
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </span>
                      <br />
                    </>
                  )}
                  {bookingType !== 0 && (
                    <span className="font-15">
                      {refundable
                        ? t("FareBooking.AncillariesPage.Non-refundable")
                        : t("FareBooking.AncillariesPage.Refundable")}
                    </span>
                  )}
                </p>
              </div>
              <div className="w-50 align-content-center text-center">
                <div className="renderSegment">
                  {renderSeg().length === 0
                    ? "All flights"
                    : renderSeg()?.length == AllSegments?.length
                      ? "All flights"
                      : renderSeg()}
                </div>
                {/* <span>Service carrier = {serviceCarrier}</span> */}
              </div>
              {bookingType !== 0 || bookingCode! == "" ? (
                <div className="priceWidthAnc">
                  <p
                    style={{
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="my-0"
                  >
                    {priceOccurrences?.length &&
                      priceOccurrences.find(
                        (occurrence) => {
                          const allSegmentsPresent = occurrence.segments.every((occSegment: any) =>
                            renderSegText().some((renderSegment: any) => _.isEqual(occSegment, renderSegment))
                          );
                          return (
                            occurrence.code === code &&
                            occurrence[Prices[0]?.priceID.toString()] &&
                            Prices[0]?.equivalentPrice?.value &&
                            allSegmentsPresent
                          );
                        }) ? (
                      <>
                        {priceOccurrences.find(
                          (occurrence) =>
                            occurrence.code === code &&
                            occurrence[Prices[0]?.priceID.toString()] &&
                            Prices[0]?.equivalentPrice?.value
                        )[Prices[0]?.priceID.toString()] /
                          Prices[0]?.equivalentPrice?.value!}
                        x
                        {LocalizationConverter(
                          Prices[0]?.equivalentPrice?.value.toFixed(2),
                          i18n.language
                        )}
                        {Prices[0]?.equivalentPrice?.value !== undefined &&
                          (Prices[0]?.equivalentPrice?.currencyCode !==
                            "EUR" ? (
                            Prices[0]?.equivalentPrice?.currencyCode
                          ) : (
                            <FontAwesomeIcon icon={faEuroSign} />
                          ))}
                      </>
                    ) : (
                      <>
                        {Prices[0]?.equivalentPrice?.value !== undefined
                          ? LocalizationConverter(
                            Prices[0]?.equivalentPrice?.value.toFixed(2),
                            i18n.language
                          )
                          : "Included"}

                        {Prices[0]?.equivalentPrice?.value !== undefined &&
                          (Prices[0]?.equivalentPrice?.currencyCode !==
                            "EUR" ? (
                            Prices[0]?.equivalentPrice?.currencyCode
                          ) : (
                            <FontAwesomeIcon icon={faEuroSign} />
                          ))}
                      </>
                    )}
                  </p>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
      <Collapse
        isOpen={isOpen && bookingType !== 0 && bookingCode !== ""}
        className="border-2 border-top border-primary"
      >
        {grouped.map((group, index) => (
          <div className="col-12" key={"grup" + index}>
            <div className="row">
              <AncillaryRelation
                GroupedPassenger={group}
                OnAddService={OnAddService}
                AddedServices={AddedServices}
                AllSegments={AllSegments}
                isServiceAdded={ServiceAddedBool}
                remove={remove}
                removeValuesInBrackets={removeValuesInBrackets}
              />
            </div>
          </div>
        ))}
      </Collapse>
    </div>
  );
};

interface SelectedServiceReference {
  LegIndex: number;
  ServiceIndex: number;
}

interface ServiceDetailsProps {
  serviceAdd: ServiceAddObj;
  OnRemoveService(serviceIndex: number): void;
  ServiceIndex: number;
  OnSelectService(service: SelectedServiceReference | undefined): void;
}
