import { GetApiRequestDate, GetNullableApiRequestDate } from "components/Shared/Helpers/DateConverters";
import { AvailableFaresPassengerInput, AvailableFaresPassengerTypeInput, AvailableFaresPassengerTypeInputDetails, AvailableFaresSearchRequest, CabinClassInput, FlightDetailSelection } from "WebApiClient";
import { ICabinClassState, IFareSearchInputState } from "../types/FareSearchTypes";

export function GetRequestData(input: IFareSearchInputState): AvailableFaresSearchRequest {
    const inputPassengers = input.AvilableFaresPassengers;
    let requestSegments: FlightDetailSelection[] = [];

    input.Segments.forEach(element => {
        const segmentCabinClasses = ConvertCabinClasses(element.CabinClasses);
        let seg: FlightDetailSelection = { from: element.DepartureInput, to: element.ArrivalInput, radius: element.Radius, date: undefined, cabinClasses: segmentCabinClasses, departureDate: GetApiRequestDate(element.DateInput!) };
        requestSegments.push(seg)
    });


    // let faredisplayPassengers: number[] = [];
    // if (input.FareDisplayPassengers) {
    //     input.FareDisplayPassengers.forEach((e) => {
    //         if (e.Checked) {
    //             faredisplayPassengers.push(e.Value)
    //         }
    //     })
    // }

    // let passengers: AvailableFaresPassengerInput = {
    //     adultPassengers: input.AvailableFares ? inputPassengers.MainPassengers.map(e => { const p: AvailableFaresPassengerInput = { dateOfBirth: e.BirthDate, passengerType: "ADT" }; return p }) : [],
    //     // mainPassengerType: 0,
    //     // childPassengerType:0,
    //     // infantPassengerType:0,
    //     childPassengers: input.AvailableFares ? inputPassengers.ChildPassengers.map(e => { const p: AvailableFaresPassengerInput = { dateOfBirth: e.BirthDate, passengerType: "ADT" }; return p }) : [],
    //     infantPassengers: input.AvailableFares ? inputPassengers.InfantPassengers.map(e => { const p: AvailableFaresPassengerInput = { dateOfBirth: e.BirthDate, passengerType: "ADT" }; return p }) : [],
    //     // fareDisplayPassengers: faredisplayPassengers
    // };

    const cabinClasses = ConvertCabinClasses(input.CabinClasses);
    let includeAirlines: string[] = [];
    let excludeAirlines: string[] = [];
    input.IncludeAirlines.forEach(e => {
        includeAirlines.push(e.value);
    })
    input.ExcludeAirlines.forEach(e => {
        excludeAirlines.push(e.value);
    })

    let requestPassengers: AvailableFaresPassengerInput = {
        adultPassengers: ConvertInputPassengers(inputPassengers.adultPassengers),
        childPassengers: ConvertInputPassengers(inputPassengers.childPassengers),
        infantPassengers: ConvertInputPassengers(inputPassengers.infantPassengers),
    }

    let request: AvailableFaresSearchRequest = {
        segments: requestSegments,
        passengers: requestPassengers,
        cabinClasses: cabinClasses,
        directFlightsOnly: input.DirectFlightsOnly,
        cabinClassPerLeg: input.CabinClassPerLeg,
        includeAirlines: includeAirlines,
        excludeAirlines: excludeAirlines,
        overrideMultiChannelBranch: input.overrideMultiChannelBranch,
        overrideMultiChannelBranchgroup: input.overrideMultiChannelBranchgroup
    };
    return request;
}
function ConvertInputPassengers(input: AvailableFaresPassengerTypeInput | undefined): AvailableFaresPassengerTypeInput | undefined {
    if (input) {
        let result: AvailableFaresPassengerTypeInput = {
            passengerType: input.passengerType,
            passengers: input.passengers.map(e => ConvertSinglePassenger(e))
        }
        return result;
    }
    else return undefined;
}
function ConvertSinglePassenger(input: AvailableFaresPassengerTypeInputDetails): AvailableFaresPassengerTypeInputDetails {
    const pp = { ...input, dateOfBirth: undefined, optionalDateOfBirth: GetNullableApiRequestDate(input?.dateOfBirth) };
    return pp;
}

function ConvertCabinClasses(input: ICabinClassState): CabinClassInput {
    const cabinClasses: CabinClassInput = {
        economy: input.Economy,
        premiumEconomy: input.PremiumEconomy,
        business: input.Business,
        first: input.First
    }
    return cabinClasses;
}