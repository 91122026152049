// Import the image
import image25Kg from '../../../Styles/images/IncludedServIcons/25K.png';
import image02P from '../../../Styles/images/IncludedServIcons/02P.png';
import baggage from '../../../Styles/images/IncludedServIcons/image.gif';
import bagGrey from '../../../Styles/images/IncludedServIcons/bag.gif';



export const SabreIconsEmoji: any = {
    "AP": { "icon": "🇺🇸", "hint": "AMERICAN" },
    "B": { "icon": "🍳", "hint": "BREAKFAST" },
    "BP": { "icon": "🏝️", "hint": "BERMUDA" },
    "C": { "icon": "🍹", "hint": "ALCOHOL BEV/COMP" },
    "CB": { "icon": "🏖️", "hint": "CARIBBEAN PLAN" },
    "CBA": { "icon": "🍹", "hint": "COMP DRINKS" },
    "CP": { "icon": "🥐", "hint": "CONTINENTAL BFST" },
    "D": { "icon": "🍽️", "hint": "DINNER" },
    "EDL": { "icon": "🥣", "hint": "COLD BREAKFAST" },
    "EP": { "icon": "🇪🇺", "hint": "EUROPEAN" },
    "F": { "icon": "🛒", "hint": "FOOD FOR PURCHASE" },
    "FDL": { "icon": "🥞", "hint": "COLD BRUNCH" },
    "FP": { "icon": "👨‍👩‍👧‍👦", "hint": "FAMILY PLAN" },
    "G": { "icon": "🛒", "hint": "FOOD FOR PURCHASE" },
    "H": { "icon": "🍲", "hint": "HOT MEAL" },
    "K": { "icon": "🥐", "hint": "CONTINENTAL BFAST" },
    "L": { "icon": "🍔", "hint": "LUNCH" },
    "M": { "icon": "🍴", "hint": "MEALS" },
    "BM": { "icon": "🍳", "hint": "Breakfast Meal" },
    "BD": { "icon": "🍽️", "hint": "Business  Dinner" },
    "MA": { "icon": "🇺🇸", "hint": "MOD AMERICAN" },
    "MD": { "icon": "🍽️🍲", "hint": "MAIN AND DINNER" },
    "N": { "icon": "", "hint": "" },
    "BL": { "icon": "🥣", "hint": "Breakfast Light" },
    "O": { "icon": "🥪", "hint": "COLD MEAL" },
    "P": { "icon": "🍻", "hint": "ALCOHOL BEV/PUR" },
    "R": { "icon": "🥤", "hint": "REFRESHMENT" },
    "S": { "icon": "🍿", "hint": "SNACK" },
    "RG": { "icon": "🍜", "hint": "Regular Gourmet meal" },
    "LD": { "icon": "🍽️", "hint": "Light Dinner" },
    "V": { "icon": "🛒", "hint": "REFRSHMNT/PUR" },
    "Alcoholic drinks": { "icon": "🍷", "hint": "" },
    // "01P": {"icon": " 🧳", "hint": ""},
    "01P": { "icon": bagGrey, "hint": "" },
    "02P": { "icon": image02P, "hint": "" },
    "25K": { "icon": image25Kg, "hint": "" },
    "Seat": { "icon": "💺", "hint": "" },
    "MileageAccural": { "icon": "📏", "hint": "MILES ACCRUAL" },
    "Change": { "icon": "🔄", "hint": "" },
    "Baggage": { "icon": "👝", "hint": "Baggage" },
    "InFlightEntertainment": { "icon": "📺", "hint": "" }
};



//  "RG": {
//     "hint": "Regular Gourmet meal",
//     "icon": "🍜" // Steaming bowl to represent a more substantial meal
//   },
//   "LD": {
//     "hint": "Light Dinner",
//     "icon": "🍽️"
//   }
//   "BL": {
//     "hint": "Light Dinner",
//     "icon": "🍽️"
//   }
// "BM": {
//     "hint": "Light Dinner",
//     "icon": "🍽️"
//   }
// "BM": {
//     "hint": "Light Dinner",
//     "icon": "🍽️"
//   }