import { DateTimeDisplay } from "components/Shared/DateDisplay";
import { TabbedContent, TabbedContentProps, TabProps } from "components/Shared/TabbedContent";
import React, { Fragment } from "react";
import { PnrResponseSegmentModel } from "WebApiClient";

export const PnrSegmentsCard: React.FC<{ data: PnrResponseSegmentModel[] }> = ({ data }) => {

    function GetTabs(): TabProps[] {
        let result: TabProps[] = [];

        result = data.map((segment, index) => {
            const tabTitle = `${segment.departure.iata} - ${segment.arrival.iata}`
            const tab: TabProps = {
                Component: <SegmentItem data={segment} index={index} />, Title: tabTitle,
                AmountIndicator: undefined
            }
            return tab;
        })

        return result;
    }

    const DetailsTabContent: TabbedContentProps = {
        EnableJump: true,
        Tabs: GetTabs()
    }

    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Segments <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <TabbedContent {...DetailsTabContent} />
                    </div>
                    {/* {data.map((passenger, index) =>
                        <SegmentItem key={index} data={passenger} index={index} />

                    )} */}
                </div>
            </div>
        </div>
    );


}

export const SegmentItem: React.FC<{ data: PnrResponseSegmentModel, index: number }> = ({ data, index }) => {

    const { airline, airlineRecordLocator, allotment, arrival,
        arrivalDate, bookStatus, bookingClass, brandCode, brandName,
        cabinClass, checkInTime, departure, departureDate, equipmentName,
        fareTimeLimit, fareType, flightNumber, includedServiceIDs,
        isAllotment, isElectronicTicketingPermitted, mealCode,
        mileages, operatingCarrier, operatingCarrierCode,
        originalSegmentNumber, segmentNumber, segmentStatus, allotmentCategory,
        allotmentID, bookingItemID, cartID, codeShare, corporateCode, extensions,
        fareCalculation, fareSelectionCurrency, farebase, freeBaggage, isRoundTrip,
        numberOfStops, requestedCurrency, secureDataNeeded, subFareType, travelTime } = data;

    return (


        <div className="card">
            <div className="card-header card-header-primary">Segment #{index + 1} {departure.name} ({departure.iata}) - {arrival.name} ({arrival.iata}) </div>
            <div className="card-body card-body-secondary">
                <div className="row">
                    <div className="col-12 mt-2">
                        <div className="row">
                            <div className="col-12">
                                <table className="table table-sm">
                                    <tbody>
                                        <tr>
                                            <td className="font-weight-bold">Departure Airport </td>
                                            <td>{departure.name} ({departure.iata})</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Departure Time </td>
                                            <td><DateTimeDisplay date={departureDate}></DateTimeDisplay></td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Arrival Airport </td>
                                            <td>{arrival.name} ({arrival.iata})</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Arrival Time </td>
                                            <td><DateTimeDisplay date={arrivalDate}></DateTimeDisplay></td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Airline </td>
                                            <td>{airline.hint} ({airline.code})</td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Travel Time </td>
                                            <td>{travelTime}</td>
                                        </tr>
                                        {segmentStatus.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Segment status: </td>
                                                <td>{segmentStatus}</td>
                                            </tr>
                                        }
                                        {airlineRecordLocator.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Airline Filekey: </td>
                                                <td>{airlineRecordLocator}</td>
                                            </tr>
                                        }
                                        {bookStatus.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Booking Status: </td>
                                                <td>{bookStatus}</td>
                                            </tr>
                                        }
                                        {bookingClass.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Booking Class: </td>
                                                <td>{bookingClass}</td>
                                            </tr>
                                        }
                                        {brandCode.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Brand: </td>
                                                <td>{brandCode}</td>
                                            </tr>
                                        }

                                        {brandName.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Brand name: </td>
                                                <td>{brandName}</td>
                                            </tr>
                                        }
                                        {checkInTime &&
                                            <tr>
                                                <td className="font-weight-bold">Check-in:</td>
                                                <td><DateTimeDisplay date={checkInTime}></DateTimeDisplay></td>
                                            </tr>
                                        }
                                        {cabinClass.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Cabin class: </td>
                                                <td>{cabinClass}</td>
                                            </tr>
                                        }
                                        {fareTimeLimit.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Fare time limit: </td>
                                                <td>{fareTimeLimit}</td>
                                            </tr>
                                        }
                                        {fareType.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Fare type: </td>
                                                <td>{fareType}</td>
                                            </tr>
                                        }
                                        {flightNumber.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Flight number: </td>
                                                <td>{flightNumber}</td>
                                            </tr>
                                        }
                                        {equipmentName.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Equipment: </td>
                                                <td>{equipmentName}</td>
                                            </tr>
                                        }
                                        {mileages.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Mileages: </td>
                                                <td>{mileages}</td>
                                            </tr>
                                        }


                                        <tr>
                                            <td className="font-weight-bold">Eletr. Ticketing: </td>
                                            <td>{isElectronicTicketingPermitted ? "Yes" : "No"}</td>
                                        </tr>
                                        {mealCode.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Meal: </td>
                                                <td>{mealCode}</td>
                                            </tr>
                                        }
                                        {codeShare.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Codeshare: </td>
                                                <td>{codeShare}</td>
                                            </tr>
                                        }
                                        {cartID.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Cart ID </td>
                                                <td>{cartID}</td>
                                            </tr>
                                        }
                                        {bookingItemID.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Booking ID </td>
                                                <td>{bookingItemID}</td>
                                            </tr>
                                        }
                                        {corporateCode.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Corporate code </td>
                                                <td>{corporateCode}</td>
                                            </tr>
                                        }
                                        {extensions.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Extensions </td>
                                                <td>{extensions}</td>
                                            </tr>
                                        }
                                        {fareCalculation.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Fare calc. </td>
                                                <td>{fareCalculation}</td>
                                            </tr>
                                        }
                                        {fareSelectionCurrency.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Selection currency </td>
                                                <td>{fareSelectionCurrency}</td>
                                            </tr>
                                        }
                                        {requestedCurrency.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Requested currency </td>
                                                <td>{requestedCurrency}</td>
                                            </tr>
                                        }
                                        {farebase.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Fare base </td>
                                                <td>{farebase}</td>
                                            </tr>
                                        }
                                        {freeBaggage.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Free baggage </td>
                                                <td>{freeBaggage}</td>
                                            </tr>
                                        }

                                        <tr>
                                            <td className="font-weight-bold">Round trip </td>
                                            <td>{isRoundTrip ? "Yes" : "No"}</td>
                                        </tr>
                                        {(numberOfStops !== undefined && numberOfStops !== null) &&
                                            <tr>
                                                <td className="font-weight-bold">Number of stops </td>
                                                <td>{(numberOfStops + 100).toString()}</td>
                                            </tr>
                                        }
                                        {(secureDataNeeded !== undefined) &&
                                            <tr>
                                                <td className="font-weight-bold">Secure data needed </td>
                                                <td>{secureDataNeeded}</td>
                                            </tr>
                                        }
                                        {(subFareType !== undefined && subFareType !== null) &&
                                            <tr>
                                                <td className="font-weight-bold">Sub fare type </td>
                                                <td>{(subFareType).toString()}</td>
                                            </tr>
                                        }

                                        {(originalSegmentNumber !== undefined && originalSegmentNumber !== null) &&
                                            <tr>
                                                <td className="font-weight-bold">Orig. segment number </td>
                                                <td>{(originalSegmentNumber).toString()}</td>
                                            </tr>
                                        }
                                        <tr>
                                            <td className="font-weight-bold">Number </td>
                                            <td>{(segmentNumber).toString()}</td>
                                        </tr>

                                        <tr>
                                            <td className="font-weight-bold">Operating carrier: </td>
                                            <td>{operatingCarrier} {operatingCarrierCode}</td>
                                        </tr>

                                        {includedServiceIDs.length > 0 &&
                                            <tr>
                                                <td className="font-weight-bold">Included services </td>
                                                <td>{includedServiceIDs.join(",")}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {isAllotment &&
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12">
                                            Allotment
                                        </div>
                                        <div className="col-12">
                                            <table className="table table-sm">
                                                <tbody>

                                                    <tr>
                                                        <td className="font-weight-bold">Allotment: </td>
                                                        <td>{isAllotment ? "Yes" : "No"}</td>
                                                    </tr>
                                                    {allotmentID.length > 0 &&
                                                        <tr>
                                                            <td className="font-weight-bold">ID</td>
                                                            <td>{allotmentID}</td>
                                                        </tr>
                                                    }
                                                    {allotmentCategory &&
                                                        <tr>
                                                            <td className="font-weight-bold">Category</td>
                                                            <td>{allotmentCategory}</td>
                                                        </tr>
                                                    }

                                                    {allotment &&
                                                        <Fragment>
                                                            <tr>
                                                                <td className="font-weight-bold">ID</td>
                                                                <td>{allotment.id}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Affiliate code</td>
                                                                <td>{allotment.affiliateCode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Mandatory programs</td>
                                                                <td>{allotment.manatoryPrograms ? "Yes" : "No"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Match code</td>
                                                                <td>{allotment.matchCode ? allotment.matchCode : ""}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Programs</td>
                                                                <td>{allotment.programs.join(",")}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Remark</td>
                                                                <td>{allotment.remark}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Supplier</td>
                                                                <td>{allotment.supplierField}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="font-weight-bold">Types</td>
                                                                <td>{allotment.types.join(",")}</td>
                                                            </tr>
                                                        </Fragment>
                                                    }
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>


                    </div>



                </div>
            </div>

        </div>

    );


}


