import { faServicestack } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AssignablePassengerOption } from "components/AncillarySelection/AncillarySelection";
import {
  AncillaryComponent,
  ServiceAddObj,
} from "components/Fare/AncillariesModal";
import { t } from "i18next";
import React, { useState } from "react";
import { AvailableFareModel } from "WebApiClient";
import { Collapse } from "reactstrap";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
export interface FareBookingAncillariesProps {
  SelectedConnections: number[];
  SetSelectedConnection: (legindex: number, connectionIndex: number) => void;
  Fare: AvailableFareModel;
  AddedServices: ServiceAddObj[];
  SetAddedServices(services: ServiceAddObj[]): void;
  Passengers: AssignablePassengerOption[];
  fetching?: any;
  error?: any;
  response?: any;
  setExtraAncCheck?: (newValue: boolean) => void;
}

const FareBookingAncillaries: React.FC<FareBookingAncillariesProps> = (
  props
) => {
  //collapse
  const [collapseOpen, setCollapseOpen] = useState(false);

  function ToggleCollapse() {
    setCollapseOpen(!collapseOpen);
  }


  return (
    <div className="card mx-2 mb-3">
      <div
        className="d-flex blueCard cursor-pointer align-items-center px-3 py-2 clickme"
        onClick={ToggleCollapse}
      >
        <div className="card-header-primary d-flex align-items-center w-100">
          <FontAwesomeIcon icon={faServicestack} /> &nbsp;
          <span>{t("FareBooking.AncillariesPage.AncillaryTitle")}</span>
        </div>
        <div>
          <FontAwesomeIcon icon={collapseOpen ? faChevronUp : faChevronDown} />
        </div>
      </div>

      <Collapse isOpen={collapseOpen}>
        <div className="card-body card-body-primary">
          <AncillaryComponent
            Fare={props.Fare}
            SelectedConnections={props.SelectedConnections}
            SetSelectedConnection={props.SetSelectedConnection}
            AddedServices={props.AddedServices}
            SetAddedServices={props.SetAddedServices}
            Passengers={props.Passengers}
            fetching={props.fetching}
            error={props.error}
            response={props.response}
            setExtraAncCheck={props.setExtraAncCheck}
          />
        </div>
      </Collapse>
    </div>
  );
};

export default FareBookingAncillaries;
