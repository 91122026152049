import {
  faRotateExclamation,
  faSpinner,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateDisplay } from "components/Shared/DateDisplay";
import { useBookingClient } from "hooks/useHttpClient";
import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Session_Reducer_PushTransaction } from "rootExports/SessionReducer";
import {
  AgentPlusBookingManagerFeature,
  BaseApiResponse,
  BookingItemModel,
  ENotificationActionRequiredEnumModel,
} from "WebApiClient";
import BookingDetailsModal from "./BookingManager.BookingDetailsModal";
import { BookingItemCard } from "./BookingManager.ItemCard";
import BookingMangerTableRowActions from "./BookingManager.Table.Row.Actions";
import { BookingStatusLabel } from "./BookingStatusLabel";
import BookingManagerErrorModal from "./SyncBookingModal";
import { GetPNRApiResponse } from "WebApiClient";

import BookingMangerSeatmapModal from "./SeatmapModal";
import BookingMangerTableRowConfirmCancelModal from "./BookingManager.Table.Row.Actions.ConfirmCancel";
import BookingMangerTableRowConfirmCancelTicketModal from "./BookingManager.Table.Row.Actions.ConfirmCancelTicket";
import ConfirmTicketModal from "./ConfirmTicket";
import BookingMangerTableRowReshopModal from "./BookingManager.Table.Row.Actions.ReshopModal";
import BookingMangerManageServicesModal from "./BookingManager.ManageServicesModal";
import BookingMangerManageElementsModal from "./BookingManager.ManageElementsModal";
import BookingManagerManageNotificationModal from "./BookingManager.ManageNotificationsModal";
import ConfirmDetailsendModal from "./ConfirmDetailsendModal";

interface BookingMangerTableRowProps {
  Booking: BookingItemModel;
  UpdateBooking: (data: BookingItemModel) => void;
  Fetching: boolean;
  CardView: boolean;
  IndexRow?: any;
  showBool?: any;
  bool?: boolean;
}

const BookingMangerTableRow: React.FC<BookingMangerTableRowProps> = (props) => {
  const booking = props.Booking;

  const [rowFetching, setRowIsFetching] = useState(false);
  const [notificationsModalOpen, setNotificationsModalOpen] = useState(false);
  function ToggleNotificationsModal() {
    setNotificationsModalOpen(!notificationsModalOpen);
  }
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  // ------------
  const [cancelModalOpen, setcancelModalOpen] = useState(false);
  const [cancelTicketModalOpen, setcancelTicketModalOpen] = useState(false);
  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const [reshopModalOpen, setReshopModalOpen] = useState(false);
  const [modifyModalOpen, setModifyModalOpen] = useState(false);
  const [seatmapModalOpen, setSeatmapModalOpen] = useState(false);
  const [serviceModalOpen, setServiceModalOpen] = useState(false);
  const [elementsModalOpen, setElementsModalOpen] = useState(false);
  const [
    elementsConfirmEmailSendModalOpen,
    setElementsConfirmEmailSendModalOpen,
  ] = useState(false);
  const [ActionsDropDownOpen, setActionsDropDownOpen] = useState(false);
  // ------------
  function ToggleDetailsModal() {
    setDetailsModalOpen(!detailsModalOpen);
  }
  function ToggleSeatmapModal() {
    setSeatmapModalOpen(!seatmapModalOpen);
  }
  function ToggleCancelModal() {
    setcancelModalOpen(!cancelModalOpen);
  }
  function ToggleCancelTicketModal() {
    setcancelTicketModalOpen(!cancelTicketModalOpen);
  }
  function ToggleTicketModal() {
    setTicketModalOpen(!ticketModalOpen);
  }
  function ToggleReshopModal() {
    setReshopModalOpen(!reshopModalOpen);
  }
  function ToggleModifyModal() {
    setModifyModalOpen(!modifyModalOpen);
  }
  function ToggleServiceModal() {
    setServiceModalOpen(!serviceModalOpen);
  }
  function ToggleElementsModal() {
    setElementsModalOpen(!elementsModalOpen);
  }
  function ToggleConfirmEmailSendModal() {
    setElementsConfirmEmailSendModalOpen(!elementsConfirmEmailSendModalOpen);
  }

  const bookClient = useBookingClient();
  const [syncResponse, setSyncResponse] = useState<BaseApiResponse | undefined>(
    undefined
  );
  const dispatch = useDispatch();
  const [syncErrorOccured, setSyncErrorOccured] = useState(false);
  const isFetching = props.Fetching || rowFetching;

  const OnSync = () => {
    setRowIsFetching(true);
    bookClient
      .syncBooking(booking.latestVersion.bookingIdentifier)
      .then((response) => {
        setSyncResponse(response);
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        if (response.responseMetaData.errorOccured) {
          setSyncErrorOccured(true);
        } else {
          setSyncErrorOccured(false);
          props.UpdateBooking(response.updatedBooking!);
        }
      })
      .catch(() => {
        setSyncErrorOccured(true);
      })
      .finally(() => {
        setRowIsFetching(false);
      });
  };

  const remarks =
    props.Booking.latestVersion.flightDetailsInfo?.enhancedRemarks;

  return (
    <React.Fragment>
      {props.CardView ? (
        <Fragment>
          <BookingItemCard
            UpdateBooking={props.UpdateBooking}
            item={props.Booking}
            NotificationsModalOpen={notificationsModalOpen}
            ToggleNotificationModal={ToggleNotificationsModal}
            ToggleDetailsModal={ToggleDetailsModal}
            OnSync={OnSync}
            IsFetching={isFetching}
            setMapToggle={ToggleSeatmapModal}
            seatmapModalOpen={seatmapModalOpen}
            serviceModalOpen={serviceModalOpen}
            serviceToggle={ToggleServiceModal}
            cancelModalOpen={cancelModalOpen}
            cancelTicketModal={ToggleCancelTicketModal}
            cancelModal={ToggleCancelModal}
            ElementsModal={ToggleElementsModal}
            ConfirmEmailSend={ToggleConfirmEmailSendModal}
            EmailSendModalOpen={elementsConfirmEmailSendModalOpen}
            ticketModal={ToggleTicketModal}
          />
        </Fragment>
      ) : (
        <Fragment>
          {isFetching ? (
            <Fragment>
              <EmptyBookingMangerTableRow></EmptyBookingMangerTableRow>
            </Fragment>
          ) : (
            <Fragment>
              <BookingMangerTableRowData
                Booking={props.Booking}
                ToggleNotificationsModal={ToggleNotificationsModal}
                ToggleDetailsModal={ToggleDetailsModal}
                OnSync={OnSync}
                IndexRow={props.IndexRow}
                showBool={props.showBool}
                bool={props.bool}
              />
              <td>
                <BookingMangerTableRowActions
                  Booking={props.Booking}
                  OnUpdateBooking={props.UpdateBooking}
                  NotificationsModalOpen={notificationsModalOpen}
                  ToggleNotificationModal={ToggleNotificationsModal}
                  ToggleDetailsModal={ToggleDetailsModal}
                  OnSync={OnSync}
                  IsFetching={isFetching}
                  setMapToggle={ToggleSeatmapModal}
                  seatmapModalOpen={seatmapModalOpen}
                  serviceModalOpen={serviceModalOpen}
                  serviceToggle={ToggleServiceModal}
                  cancelModalOpen={cancelModalOpen}
                  cancelTicketModal={ToggleCancelTicketModal}
                  cancelModal={ToggleCancelModal}
                  ElementsModal={ToggleElementsModal}
                  ticketModal={ToggleTicketModal}
                  ConfirmEmailSend={ToggleConfirmEmailSendModal}
                  EmailSendModalOpen={elementsConfirmEmailSendModalOpen}
                />
              </td>
              {/* ACTIONS */}
            </Fragment>
          )}
        </Fragment>
      )}

      <BookingMangerTableRowConfirmCancelModal
        Booking={props.Booking}
        OnUpdate={props.UpdateBooking}
        Toggle={ToggleCancelModal}
        IsOpen={cancelModalOpen}
      />
      <BookingMangerTableRowConfirmCancelTicketModal
        Booking={props.Booking}
        OnUpdate={props.UpdateBooking}
        Toggle={ToggleCancelTicketModal}
        IsOpen={cancelTicketModalOpen}
      />
      <ConfirmTicketModal
        Booking={props.Booking}
        OnUpdate={props.UpdateBooking}
        Toggle={ToggleTicketModal}
        IsOpen={ticketModalOpen}
      />
      <BookingMangerTableRowReshopModal
        Booking={props.Booking}
        Toggle={ToggleReshopModal}
        IsOpen={reshopModalOpen}
      />
      <BookingMangerManageServicesModal
        Booking={props.Booking}
        Toggle={ToggleServiceModal}
        IsOpen={serviceModalOpen}
      />
      <BookingMangerSeatmapModal
        Booking={props.Booking}
        Toggle={ToggleSeatmapModal}
        IsOpen={seatmapModalOpen}
      />
      <BookingMangerManageElementsModal
        Booking={props.Booking}
        Toggle={ToggleElementsModal}
        IsOpen={elementsModalOpen}
      />
      <BookingManagerManageNotificationModal
        Booking={props.Booking}
        OnUpdate={props.UpdateBooking}
        Toggle={ToggleNotificationsModal}
        IsOpen={notificationsModalOpen}
      />
      <BookingDetailsModal
        Booking={props.Booking}
        OnUpdate={props.UpdateBooking}
        Toggle={ToggleDetailsModal}
        IsOpen={detailsModalOpen}
      />
      <BookingManagerErrorModal
        Close={() => {
          setSyncResponse(undefined);
          setSyncErrorOccured(false);
        }}
        IsOpen={syncErrorOccured}
        data={syncResponse?.responseMetaData}
      />

      <ConfirmDetailsendModal
        Booking={props.Booking}
        Toggle={ToggleConfirmEmailSendModal}
        IsOpen={elementsConfirmEmailSendModalOpen}
        IsPopUp={false}
      />
    </React.Fragment>
  );
};

interface BookingTableRowData {
  Booking: BookingItemModel;
  ToggleNotificationsModal?: () => void;
  ToggleDetailsModal?: () => void;
  OnSync?: () => void;
  IndexRow?: any;
  bool?: boolean;
  showBool?: any;
}

export const BookingMangerTableRowData: React.FC<BookingTableRowData> = (
  props
) => {
  const booking = props.Booking;
  const indexNo = props.IndexRow;
  const latestVersion = booking.latestVersion;
  const generalInfo = booking.latestVersion.generalInfo;
  const services = booking.latestVersion.flightDetailsInfo?.services;
  const notifications = latestVersion.notifications;
  const noPrioNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.None
  ).length;
  const urgentNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.Urgent
  ).length;
  const highPrioNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.High
  ).length;
  const mediumPrioNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.Medium
  ).length;
  const lowPrioNotificationsAmount = notifications.filter(
    (e) => e.actionRequired === ENotificationActionRequiredEnumModel.Low
  ).length;
  const headerClassName = props.ToggleDetailsModal ? "clickme" : "";
  const notiClassName = props.ToggleNotificationsModal ? "clickme" : "";
  const actions = latestVersion.allowedActions;
  const bookClient = useBookingClient();
  const dispatch = useDispatch();
  const [responsePNR, setResponse] = useState<GetPNRApiResponse | undefined>(
    undefined
  );
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [autoRefresh, setautoRefresh] = React.useState("0");
  const Indentifyers = {
    cartId: generalInfo.cartId,
    bookingId: generalInfo.bookingId,
  };

  useEffect(() => {
    if (
      generalInfo.platingCarrier.code === "" &&
      indexNo === 0 &&
      !props.bool
    ) {
      // props.OnSync;
      props.showBool(true);
    }
    // GetBookingDetailsByFileKey(Indentifyers);
  }, []);

  function GetBookingDetailsByFileKey(Indentifyers: any) {
    setFetching(true);
    bookClient
      .getPNR(Indentifyers)
      .then((response) => {
        dispatch(Session_Reducer_PushTransaction(response.responseMetaData));
        if (response.result) {
          setError(false);
          setResponse(response);
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setFetching(false);
      });
  }
  function yourFunction() {
    // props.OnSync
  }

  // useEffect(() => {
  //   if (Number(autoRefresh) !== 0) {
  //     // GetBookings(CurrentFilter);
  //     var times = Number(autoRefresh) * 60000;
  //     const interval = setInterval(yourFunction, times);
  //     return () => clearTimeout(interval);
  //   }
  // }, [Number(autoRefresh)])

  return (
    <React.Fragment>
      {props.OnSync && (
        <Fragment>
          {actions.includes(AgentPlusBookingManagerFeature.RefreshPnr) ? (
            <td className={headerClassName} onClick={props.OnSync}>
              <FontAwesomeIcon title="Refresh PNR" icon={faRotateExclamation} />
            </td>
          ) : (
            <td className={headerClassName}></td>
          )}
        </Fragment>
      )}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        {generalInfo.mainPassengerLastName}
      </td>
      {/* LAST NAME */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        {generalInfo.filekey}
      </td>
      {/* FILEKEY */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        {generalInfo.platingCarrier.code}
      </td>
      {/* AIRLINE */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        {generalInfo.crs?.gds}
      </td>
      {/* SOURCEGDS */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        {generalInfo.crs?.terminalPCC}
      </td>
      {/* SOURCETERMINALPCC */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        <BookingStatusLabel Status={generalInfo.bookingStatus} />
      </td>
      {/* STATUS BOOKING */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        <DateDisplay date={generalInfo.bookingDate} />
      </td>
      {/* DATE BOOKING */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        <DateDisplay date={generalInfo.firstDepartureDate} />
      </td>
      {/* FIRDSTDEPARTURE */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        <DateDisplay date={generalInfo.ticketTimeLimit} />
      </td>
      {/* TTL */}
      <td className={headerClassName} onClick={props.ToggleDetailsModal}>
        <DateDisplay date={generalInfo.ticketingDate} />
      </td>
      {/* TICKETING DATE */}
      <td
        className={`text-center ${notiClassName} ${
          urgentNotificationsAmount > 0 ? "dangerBg" : ""
        }`}
        onClick={props.ToggleNotificationsModal}
      >
        {urgentNotificationsAmount > 0 ? (
          <span className="text-white">{urgentNotificationsAmount}</span>
        ) : (
          <Fragment>
            {highPrioNotificationsAmount > 0 && (
              <span className="badge rounded-pill bg-danger font-weight-bold text-white">
                {highPrioNotificationsAmount}
                {` `}
              </span>
            )}
            {mediumPrioNotificationsAmount > 0 && (
              <span className="badge rounded-pill bg-warning font-weight-bold">
                {mediumPrioNotificationsAmount}
                {` `}
              </span>
            )}
            {lowPrioNotificationsAmount > 0 && (
              <span className="badge rounded-pill bg-success font-weight-bold text-white">
                {lowPrioNotificationsAmount}
              </span>
            )}
            {noPrioNotificationsAmount > 0 && (
              <span
                style={{ backgroundColor: "gray" }}
                className="badge rounded-pill font-weight-bold"
              >
                {noPrioNotificationsAmount}
              </span>
            )}
          </Fragment>
        )}
      </td>
    </React.Fragment>
  );
};

export const EmptyBookingMangerTableRow: React.FC = (props) => {
  return (
    <React.Fragment>
      <td align="center" colSpan={13}>
        <span className="btn btn-sm">
          <FontAwesomeIcon icon={faSpinner} spin /> Loading...
        </span>
      </td>
    </React.Fragment>
  );
};

export default BookingMangerTableRow;
