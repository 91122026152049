import {
  faCircle,
  faCouch,
  faEuroSign,
  faFrown,
  faRandom,
  faSuitcase,
  faUtensils,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ServiceAddObj } from "components/Fare/AncillariesModal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert } from "reactstrap";
import {
  AncillaryModel,
  ServiceGroupTypeEnumModel,
  ServiceModel,
  b2cSettingsText,
} from "WebApiClient";
import _ from "lodash";
import { AncillaryGroup } from "./AncillaryGroup";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useSelector } from "react-redux";
import { State } from "rootExports/rootReducer";
import { LocalizationConverter } from "components/Shared/Helpers/LocalizationConverter";
export interface AssignableSegmentOption {
  From: string;
  To: string;
}
export interface AssignablePassengerOption {
  PassengerType: string;
  Label: string;
  Index: number;
}
interface AncillarySelectionProps {
  PassengerOptions: AssignablePassengerOption[];
  SegmentOptions: AssignableSegmentOption[];
  Model: AncillaryModel;
  OnAddService(obj: ServiceAddObj): void;
  OnEditService(index: number, amount: number): void;
  AddedServices: ServiceAddObj[];
  IncludedServices: ServiceModel | any;
  OnRemoveService?: any;
  setExtraAncCheck: ((newValue: boolean) => void | undefined) | undefined;
}
export interface GroupedService {
  Type: ServiceGroupTypeEnumModel;
  Services: ServiceModel[];
}
export const AncillarySelection: React.FC<AncillarySelectionProps> = ({
  Model,
  AddedServices,
  OnAddService,
  PassengerOptions,
  IncludedServices,
  OnRemoveService,
  setExtraAncCheck,
}) => {
  const { passengers, prices, segments, services } = Model;
  const { t, i18n } = useTranslation();
  const [toggleAllGroups, setToggleAllGroups] = useState<boolean>(false);
  const [TotalServicePrice, setTotalServicePrice] = useState<number>(0);
  const [showIncludedServices, setShowIncludedServices] =
    useState<boolean>(false);
  const [showOptionalServices, setShowOptionalServices] =
    useState<boolean>(false);
  const [showIncludedServices2, setShowIncludedServices2] =
    useState<boolean>(false);
  const [serviceType, setServiceType] = useState("all");
  const [textStored, setTextStored] = useState<any>([]);
  const [includedStateLast, setIncludedStateLast] = useState<any>([]);
  const [notIncludedStateLast, setNotIncludedStateLast] = useState<any>([]);
  const grouped: GroupedService[] = GetGrouped(services);
  const [removeServiceHolder, setRemoveServiceHolder] = useState([]);

  // console.log("grouped",grouped)
  const sessionInfo = useSelector((state: State) => state.Session);

  const showExtraAncillary: b2cSettingsText = (() => {
    try {
      return JSON.parse(
        sessionInfo?.FrontendSettings?.b2cSettingsText ||
        '{"HotelTab":"None","AirPortSearchType":"All","ShowLanguageDecimalTabB2C":"English","ShowExtraAncillariesTabB2C":"On","ShowDateInput":"Left","ShowDefaultFilter":"Off"}'
      );
    } catch {
      return {
        HotelTab: "All",
        AirPortSearchType: "All",
        ShowLanguageDecimalTabB2C: "English",
        ShowExtraAncillariesTabB2C: "On",
        ShowDateInput: "Left",
        ShowDefaultFilter: "Off",
      };
    }
  })();
  // const [extraAncCheck, setExtraAncCheck] = useState(true);
  const countIncluded = services.filter(
    (e) => e.bookingCode.length === 0
  ).length;

  function GetGrouped(services: ServiceModel[]): GroupedService[] {
    let result: GroupedService[] = [];
    services.forEach((service) => {
      if (result.filter((e) => e.Type === service.type).length === 0) {
        const newGroup: GroupedService = {
          Type: service.type,
          Services: [service],
        };
        result.push(newGroup);
      } else {
        result.filter((e) => e.Type === service.type)[0].Services.push(service);
      }
    });
    return result;
  }

  function servicesDisplay(type: string = "") {
    if (type === "inclServ") {
      setShowOptionalServices(false);
      setShowIncludedServices2(true);
    }
    if (type === "oplServ") {
      setShowOptionalServices(true);
      setShowIncludedServices2(false);
    }
  }

  function showServices(services: any) {
    setServiceType(services);
    if (services === "all") {
      setShowIncludedServices(true);
    }
    if (services === "inclServ") {
      setShowIncludedServices(true);
    }
    if (services === "oplServ") {
      setShowIncludedServices(false);
    }
  }

  function textExtractor() {
    // Filter objects with 'Type == 0'
    const filteredObjects = grouped.filter((obj: any) => obj.Type === 0);

    // Extract 'Services' array from filtered objects
    const servicesArray = filteredObjects.map((obj: any) => obj.Services);

    // Flatten the array of arrays into a single array of service objects
    const allServices = [].concat(...servicesArray);
    // Filter service objects with bookingType == 0 and matching text conditions
    const filteredServices = allServices.filter((service: any) => {
      const bookingTypeCondition = service?.bookingType == 0;
      const textCondition =
        service?.text.toLowerCase().includes("free") ||
        service?.text.toLowerCase().includes("hand") ||
        service?.text.toLowerCase().includes("kg");
      return bookingTypeCondition && textCondition;
    });
    const filteredTextArray = filteredServices.map(
      (service: any) => service.text
    );

    setTextStored(filteredTextArray);
  }

  useEffect(() => {
    const totalServicePrice = AddedServices.reduce((total, service) => {
      // Check if 'service.Price' and 'service.Price.equivalentPrice' are defined
      if (service && service.Price && service.Price.equivalentPrice) {
        const price = service.Price.equivalentPrice.value;

        // Check if the 'value' property is defined
        if (price !== undefined) {
          return total + price;
        }
      }

      return total;
    }, 0);
    setTotalServicePrice(totalServicePrice);
  }, [AddedServices.length]);

  useEffect(() => {
    const modifiedObj = grouped.map((group) => ({
      Type: group.Type,
      Services: group.Services.filter((service) => service.bookingType === 0 && service.referenceIDs.some(ref => ref.priceID === "")),
    }));
    const modifiedObj2 = grouped.map((group) => ({
      Type: group.Type,
      Services: group.Services.filter((service) => service.bookingType !== 0 && service.referenceIDs.some(ref => ref.priceID !== "")),
    }));

    const mergedServices: ServiceModel[] = modifiedObj.flatMap(
      (Service, id) => {
        if (Service.Type === 0) {
          return mergeObjects(Service.Services) || []; // Return an empty array if mergeObjects returns undefined
        }
        return []; // Return an empty array if Service.Type is not equal to 0
      }
    );

    setIncludedStateLast(mergedServices);
    setNotIncludedStateLast(modifiedObj2);
    showServices("inclServ");
    textExtractor();
  }, []);

  const modifiedObj = grouped.map((group) => ({
    Type: group.Type,
    Services: mergeObjects(
      group.Services.filter((service) => service.bookingType === 0 || service.referenceIDs.some(ref => ref.priceID === ""))
    ),
  }));

  const modifiedObj2 = grouped.map((group) => ({
    Type: group.Type,
    Services: group.Services.filter((service) => service.bookingType !== 0 && service.referenceIDs.some(ref => ref.priceID !== "")),
  }));


  // const mergedServices: ServiceModel[] = modifiedObj.flatMap((Service, id) => {
  //   if (Service.Type === 0) {
  //     return mergeObjects(Service.Services) || []; // Return an empty array if mergeObjects returns undefined
  //   }
  //   return []; // Return an empty array if Service.Type is not equal to 0
  // });

  function mergeObjects(array: ServiceModel[]): ServiceModel[] {
    const mergedObjects: { [code: string]: ServiceModel } = {};

    array.forEach((obj) => {
      const { code, referenceIDs, ...rest } = obj;
      if (!mergedObjects[code]) {
        mergedObjects[code] = { ...rest, code, referenceIDs: [] };
      }
      mergedObjects[code].referenceIDs.push(...referenceIDs);
    });

    return Object.values(mergedObjects);
  }

  function mergeServiceIntoTarget(sourceObject: any, targetObject: any) {
    const { Type, Service } = sourceObject;
    // Find the index of the target object with matching "Type"
    const targetIndex = targetObject.findIndex((obj: any) => obj.Type === Type);
    // If a matching index is found, add the "Service" object to the "Services" array
    if (targetIndex !== -1) {
      targetObject[targetIndex].Services.push(Service);
      setRemoveServiceHolder([]);
    }
    return targetObject;
  }

  // console.log(modifiedObj2, AddedServices);
  function removeObjectByBookingCode(bookingCodeToRemove: any) {
    if (removeServiceHolder.length > 0) {
      // setNotIncludedStateLast(
      //     mergeServiceIntoTarget(removeServiceHolder, [
      //         ...notIncludedStateLast,
      //     ])
      // );
    }
    if (removeServiceHolder.length == 0) {
      let modified2Holder: any = [];
      if (modified2Holder.length > 0) {
        modified2Holder = modified2Holder.map((group: any) => ({
          Type: group.Type,
          Services: group.Services.filter(
            (service: any) =>
              service.code !== bookingCodeToRemove && service.bookingType !== 0
          ),
        }));
      } else {
        modified2Holder = grouped.map((group) => ({
          Type: group.Type,
          Services: group.Services.filter(
            (service) =>
              service.code !== bookingCodeToRemove && service.bookingType !== 0
          ),
        }));
      }
      // setNotIncludedStateLast(modified2Holder);
    }
  }

  function addRemoveService() {
    let arrayHolder1: any = [];
    const addedServiceGet: any = AddedServices.map((AddedService) => ({
      Type: AddedService.Service.bookingType,
      Services: [AddedService.Service],
    }));
    const uniqueData = _.uniqBy(addedServiceGet, (item: any) =>
      JSON.stringify(item.Services)
    );

    // arrayHolder1 = [...mergedServices, ...addedServiceGet];
    arrayHolder1 = [...modifiedObj, ...uniqueData];

    setIncludedStateLast(arrayHolder1);

    AddedServices.map((Serv: any) => {
      removeObjectByBookingCode(Serv.Service.code);
    });
  }

  useEffect(() => {
    addRemoveService();
  }, [AddedServices?.length]);

  const iconBlueTrue: boolean = true;

  // Sort "Services" array within each object by "serviceDescription"
  const sortedDataArrayIncluded = _.map(
    _.cloneDeep(includedStateLast),
    (item) => {
      if (item.Services && item.Services.length > 0) {
        item.Services = _.sortBy(item.Services, "text");
      }
      return item;
    }
  );

  // Sort the entire array based on the first "serviceDescription" in each "Services" array
  const finalSortedArrayIncluded = _.sortBy(sortedDataArrayIncluded, [
    "Services[0].text",
  ]);

  // Sort "Services" array within each object by "serviceDescription"
  const sortedDataArrayNotIncluded = _.map(
    _.cloneDeep(notIncludedStateLast),
    (item) => {
      if (item.Services && item.Services.length > 0) {
        item.Services = _.sortBy(item.Services, "text");
      }
      return item;
    }
  );

  // Sort the entire array based on the first "serviceDescription" in each "Services" array
  const finalSortedArrayNotIncluded = _.sortBy(sortedDataArrayNotIncluded, [
    "Services[0].text",
  ]);

  useEffect(() => {
    if (
      finalSortedArrayNotIncluded[0]?.Services?.length > 0 ||
      finalSortedArrayIncluded[0]?.Services?.length > 0
    ) {
      if (setExtraAncCheck) {
        setExtraAncCheck(true);
      }
    }
    if (
      (finalSortedArrayNotIncluded[0]?.Services?.length == 0 &&
        finalSortedArrayIncluded[0]?.Services?.length == 0) ||
      (showExtraAncillary?.ShowExtraAncillariesTabB2C != "On" &&
        finalSortedArrayIncluded[0]?.Services?.length == 0)
    ) {
      if (setExtraAncCheck) {
        setExtraAncCheck(false);
      }
    }
    if (!services.length) {
      if (setExtraAncCheck) {
        setExtraAncCheck(false);
      }
    }
  }, [
    finalSortedArrayIncluded[0]?.Services?.length,
    finalSortedArrayNotIncluded[0]?.Services?.length,
  ]);

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "8px",
          flexDirection: "row",
        }}
        className="ancillaryFlexContainer"
      >
        {showExtraAncillary?.ShowExtraAncillariesTabB2C == "On" && (
          <div
            style={{
              backgroundColor: "#E7F4FD",
              height: "100%",
            }}
            className={
              finalSortedArrayNotIncluded[0]?.Services?.length > 0
                ? "ancillaryBlockWidth p-4 mx-auto"
                : "ancillaryBlockWidth ancillaryBlockheightOff p-4 mx-auto"
            }
          >
            <div className="" key={"grps"}>
              <div className="mx-auto">
                <div style={{ textAlign: "center" }}>
                  <strong className="">
                    {t("FareBooking.AncillariesPage.ExtraServices")}
                  </strong>
                </div>
                {finalSortedArrayNotIncluded.map((group: any, index: any) => (
                  <div key={index}>
                    <div>
                      <div className="mx-auto notIncluded">
                        <AncillaryGroup
                          textStored={textStored}
                          Group={group}
                          ToggleAll={toggleAllGroups}
                          Passengers={passengers}
                          Prices={prices}
                          Segments={segments}
                          ShowIncluded={showIncludedServices}
                          OnAddService={OnAddService}
                          AddedServices={AddedServices}
                          PassengerOptions={PassengerOptions}
                          serviceType={serviceType}
                          IncludedService={IncludedServices}
                          OnRemoveService={OnRemoveService}
                          setRemoveServiceHolder={setRemoveServiceHolder}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {grouped.length === 0 && (
              <div className="col-12">
                <Alert color="info">
                  <h4
                    className="alert-heading"
                    style={{
                      fontSize: "16px",
                      color: "#232323",
                    }}
                  >
                    <FontAwesomeIcon icon={faFrown} />{" "}
                    {t("FareBooking.AncillariesPage.NoAncillaries")}
                  </h4>
                </Alert>
              </div>
            )}
          </div>
        )}

        <div
          style={{ backgroundColor: "#E7F4FD", height: "100%" }}
          className={
            finalSortedArrayIncluded[0]?.Services?.length > 0
              ? "ancillaryBlockWidth p-4 mx-auto"
              : "ancillaryBlockWidth ancillaryBlockheightOff p-4 mx-auto"
          }
        >
          <div className="" key={"grps"}>
            <div className="mx-auto">
              <div style={{ textAlign: "center" }}>
                <strong className="">
                  {t("FareBooking.AncillariesPage.IncludedServices")}
                </strong>
              </div>
              {/* {grouped.length === 0 && (
                  <div className="col-12">
                    <Alert color="info">
                      <h4 className="alert-heading">
                        <FontAwesomeIcon icon={faFrown} />{" "}
                        {t("FareBooking.AncillariesPage.NoAncillaries")}
                      </h4>
                    </Alert>
                  </div>
                )} */}
              {finalSortedArrayIncluded.map((group: any, index: any) => {
                return (
                  <div key={index}>
                    <div>
                      <div className="mx-auto Included">
                        <AncillaryGroup
                          textStored={textStored}
                          Group={group}
                          ToggleAll={toggleAllGroups}
                          Passengers={passengers}
                          Prices={prices}
                          Segments={segments}
                          ShowIncluded={showIncludedServices}
                          OnAddService={OnAddService}
                          AddedServices={AddedServices}
                          PassengerOptions={PassengerOptions}
                          serviceType={serviceType}
                          IncludedService={IncludedServices}
                          OnRemoveService={OnRemoveService}
                          setRemoveServiceHolder={setRemoveServiceHolder}
                          iconBlueTrue={iconBlueTrue}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              <Alert color="info">
                <strong>
                  {t("FareBooking.AncillariesPage.PriceText")}:{" "}
                  {LocalizationConverter(
                    TotalServicePrice.toFixed(2),
                    i18n.language
                  )}
                  <FontAwesomeIcon icon={faEuroSign} />
                </strong>
              </Alert>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

interface AncillaryGroupHeaderComponentProps {
  Type: ServiceGroupTypeEnumModel;
  OnClick: () => void;
  Open: boolean;
}

const AncillaryGroupHeaderComponent: React.FC<
  AncillaryGroupHeaderComponentProps
> = (props) => {
  function GetIconAndLabel(): AncillaryHeaderInfo {
    let icon = faCircle;
    let label = "Other";
    switch (props.Type) {
      case ServiceGroupTypeEnumModel.Baggage:
        label = "Baggage";
        icon = faSuitcase;
        break;
      case ServiceGroupTypeEnumModel.Meal:
        label = "Meal";
        icon = faUtensils;
        break;
      case ServiceGroupTypeEnumModel.Seat:
        label = "Seat";
        icon = faCouch;
        break;
      case ServiceGroupTypeEnumModel.Transfer:
        label = "Transfer";
        icon = faRandom;
        break;
    }
    return { Icon: icon, Label: label };
  }

  const Data = GetIconAndLabel();

  return (
    <div className="clickme">
      <FontAwesomeIcon icon={Data.Icon} /> {Data.Label}
    </div>
  );
};

interface AncillaryHeaderInfo {
  Icon: IconProp;
  Label: string;
}
