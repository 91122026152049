import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { DateTimeDisplay } from "components/Shared/DateDisplay";
import { SharedPriceDisplay } from "components/Shared/PriceDisplay";
import { ServiceBookingTypeEnumDisplay } from "components/Shared/ServiceBookingTypeEnumDisplay";
import { TabbedContent, TabbedContentProps } from "components/Shared/TabbedContent";
import React from "react";
import { PnrServiceFareDataModel } from "WebApiClient";

export const PnrStoredServicesCard: React.FC<{ data: PnrServiceFareDataModel[] }> = ({ data }) => {

    const TabContent: TabbedContentProps = {
        EnableJump: true,
        Tabs: data.map((e, i) => {
            return {
                Component: <ServiceItem key={"ServiceI_"+i} data={e} />
                ,
                Title: `#${i + 1} ${e.serviceCode}`,
                Icon: faInfoCircle,
                AmountIndicator: undefined
            }
        })

    }

    return (
        <div className="card card-primary">
            <div className="card-header card-header-primary">Stored services <span className="badge badge-primary badge-pill align-top">{data.length}</span></div>
            <div className="card-body">
                <TabbedContent {...TabContent} />
                {/* <div className="row">
                    {data.map((service, index) =>
                        <div className="col-12 mb-2" key={index}>

                            <ServiceItem data={service} />
                        </div>
                    )}
                </div> */}
            </div>
        </div>
    );


}

const ServiceItem: React.FC<{ data: PnrServiceFareDataModel }> = ({ data }) => {
    const { coupons, documentType, emdRequired,
        equivalentServicePrice, extensions,
        fareCalculation, lastEMDDate,
        passengerNumber,
        platingCarrier, serviceCode, serviceCreationDate,
        serviceID, servicePrice } = data;

    return (
        <div className="card">
            {/* <div className="card-header">Service</div> */}
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-2">
                        <table className="table table-sm table-striped table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <td className="font-weight-bold">Code</td>
                                    <td>{serviceCode}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">ID</td>
                                    <td>{serviceID}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Carrier</td>
                                    <td>{platingCarrier}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Document type</td>
                                    <td>{documentType}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Calculation</td>
                                    <td>{fareCalculation}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Extensions</td>
                                    <td>{extensions}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Passenger number</td>
                                    <td>{passengerNumber ? passengerNumber.toString() : ""}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">EMD required</td>
                                    <td>{emdRequired ? emdRequired ? "Yes" : "No" : ""}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Equiv. price</td>
                                    <td><SharedPriceDisplay price={equivalentServicePrice} /></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Service price</td>
                                    <td><SharedPriceDisplay price={servicePrice} /></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Last EMD date</td>
                                    <td><DateTimeDisplay date={lastEMDDate} /></td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">Creation date</td>
                                    <td><DateTimeDisplay date={serviceCreationDate} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {coupons.length > 0 &&
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header card-header-primary">Coupons</div>
                                <div className="card-body">

                                    <table className="table table-sm table-striped table-hover table-bordered">
                                        <thead>
                                            <tr>
                                                <th className="font-weight-bold">Type</th>
                                                <th className="font-weight-bold">Type Code</th>
                                                <th className="font-weight-bold">Type ID</th>
                                                <th className="font-weight-bold">Type Text</th>
                                                <th className="font-weight-bold">Carrier</th>
                                                <th className="font-weight-bold">Exchangeable</th>
                                                <th className="font-weight-bold">Extensions</th>
                                                <th className="font-weight-bold">Last EMD date</th>
                                                <th className="font-weight-bold">NVB</th>
                                                <th className="font-weight-bold">NVA</th>
                                                <th className="font-weight-bold">Refundable</th>
                                                <th className="font-weight-bold">Segment</th>
                                                <th className="font-weight-bold">Service code</th>
                                                <th className="font-weight-bold">Service text</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {coupons.map((coupon, index) =>
                                                <tr key={"coupon_"+index}>
                                                    <td><ServiceBookingTypeEnumDisplay type={coupon.bookingType} /></td>
                                                    <td>{coupon.bookingTypeCode}</td>
                                                    <td>{coupon.bookingTypeID}</td>
                                                    <td>{coupon.bookingTypeText}</td>
                                                    <td>{coupon.carrier}</td>
                                                    <td>{coupon.exchangeable !== undefined ? coupon.exchangeable ? "Yes" : "No" : ""}</td>
                                                    <td>{coupon.extensions}</td>
                                                    <td>{<DateTimeDisplay date={coupon.lastEMDDate} />}</td>
                                                    <td>{<DateTimeDisplay date={coupon.notValidAfter} />}</td>
                                                    <td>{<DateTimeDisplay date={coupon.notValidBefore} />}</td>
                                                    <td>{coupon.refundable !== undefined ? coupon.refundable ? "Yes" : "No" : ""}</td>
                                                    <td>{coupon.segmentNumber ? coupon.segmentNumber.toString() : ""}</td>
                                                    <td>{coupon.serviceCode}</td>
                                                    <td>{coupon.serviceText}</td>

                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    }

                </div>
            </div>
        </div>

    );


}


