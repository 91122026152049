import React, { Fragment, useEffect, useState } from "react";
import {
  FareAPISRequirements,
  FareBookingPassengerApis,
  FareBookingPassengerApisAddress,
  FareBookingPassengerApisPassport,
  FareBookingPassengerApisVisa,
} from "WebApiClient";
import BookingPassengerApisAddress from "./APISInput.Adress";
import BookingPassengerApisPassport from "./APISInput.Passport";
import { ApisValidResult } from "./APISInput.Validate.Main";
import ApisVisaInput from "./APISInput.Visa";
import { useTranslation } from "react-i18next";
export interface APISMainInputProps {
  Data: FareBookingPassengerApis | undefined;
  OnUpdate: (data: FareBookingPassengerApis | undefined) => void;
  Valid: ApisValidResult;
  Apis: FareAPISRequirements;
  ShowRemoveButton: boolean;
  passengerIndex?: number;
}

const APISMainInput: React.FC<APISMainInputProps> = (props) => {
  const {
    Data: apisData,
    OnUpdate,
    Valid,
    ShowRemoveButton,
    passengerIndex,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    OnAddApis();
  }, []);

  function OnUpdatePassport(
    passport: FareBookingPassengerApisPassport | undefined
  ) {
    if (apisData) {
      let data = { ...apisData, passport };
      OnUpdate(data);
    }
  }

  function OnUpdateAddress(
    address: FareBookingPassengerApisAddress | undefined
  ) {
    if (apisData) {
      let data = { ...apisData, address };
      OnUpdate(data);
    }
  }

  function OnUpdateVisa(visa: FareBookingPassengerApisVisa | undefined) {
    if (apisData) {
      let data = { ...apisData, visa };
      OnUpdate(data);
    }
  }

  function OnAddApis() {
    let data: FareBookingPassengerApis = {
      address: undefined,
      passport: undefined,
      visa: undefined,
    };
    OnUpdate(data);
  }

  function OnRemoveApis() {
    OnUpdate(undefined);
  }

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-12 mb-2">
            <div className="alert alert-info">
              <p>
                {t("FareBooking.APISPage.APISDescription")}
              </p>
            </div>
          </div>

          {!apisData && ShowRemoveButton && (
            <div className="col-12 mb-2">
              <div className="d-grid">
                <button className="btn btn-primary" onClick={OnAddApis}>
                {t("FareBooking.APISPage.APISAdd")}
                </button>
              </div>
            </div>
          )}

          {apisData && (
            <Fragment>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
                <BookingPassengerApisPassport
                  Data={apisData.passport}
                  IsValid={Valid.Passport}
                  OnUpdatePassenger={OnUpdatePassport}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
                <ApisVisaInput
                  Data={apisData.visa}
                  IsValid={Valid.Visa}
                  OnUpdatePassenger={OnUpdateVisa}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-2">
                <BookingPassengerApisAddress
                  Data={apisData.address}
                  IsValid={Valid.Address}
                  OnUpdatePassenger={OnUpdateAddress}
                />
              </div>
              {/* {ShowRemoveButton && (
                <div className="col-12 mb-2">
                  <div className="d-grid">
                    <button className="btn btn-danger" onClick={OnRemoveApis}>
                      Remove APIS
                    </button>
                  </div>
                </div>
              )} */}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default APISMainInput;
