import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

export interface TabProps {
    Component: JSX.Element;
    Title: JSX.Element | string;
    Icon?: IconDefinition;
    RenderOnlyIfActive?: boolean,
    AmountIndicator: number | undefined,
    applyCss?:boolean
}
export interface TabbedContentProps {
    EnableJump: boolean
    Tabs: TabProps[]
}
export const TabbedContent: React.FC<TabbedContentProps> = ({ EnableJump, Tabs }) => {
    const [activeTab, setActiveTab] = useState(0);

    const toggle = (tab: number) => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    return <Fragment>

        <Nav tabs pills>
            {Tabs.map((tab, index) =>

                <NavItem key={"nav_" + index} >
                    <NavLink
                        className={`${activeTab === index ? "active" : ""} clickme`} style={{}}
                        onClick={() => { toggle(index); }}
                    >
                        {tab.Icon &&
                            <Fragment>
                                <FontAwesomeIcon icon={tab.Icon} />
                                {/* {tab.AmountIndicator && <span className="fa-layers-counter">{tab.AmountIndicator}</span>} */}
                                {` `}
                            </Fragment>
                        }
                        <span className="p-1">
                            {tab.Title}{``}
                        </span>
                         
                        <span>
                            {tab.AmountIndicator && <span style={{ fontSize: "0.7em" }} className="align-top badge rounded-pill text-bg-dark">{tab.AmountIndicator}</span>}
                        </span>
                    </NavLink>
                </NavItem>



            )}

        </Nav>
        <TabContent activeTab={activeTab} >
            {Tabs.map((tab, index) =>

                <TabPane tabId={index} key={"tabpen_" + index} className={tab.applyCss== true?"manageElementCancelActive":''} >
                    {((tab.RenderOnlyIfActive !== undefined && tab.RenderOnlyIfActive === true && activeTab === index)
                        || (tab.RenderOnlyIfActive === undefined) || (tab.RenderOnlyIfActive !== undefined && tab.RenderOnlyIfActive === false)) &&
                        <div className="mt-2">
                            {tab.Component}
                        </div>
                    }



                </TabPane>
            )}

        </TabContent>
    </Fragment>
}