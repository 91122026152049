import closeIcon from '../../Styles/images/signup/closeIcon.svg'

import './SignUp.css'

interface SignUpInterface{
    setLoginToReg?:any
}

const SignUp: React.FC<SignUpInterface> = (props) =>  {
    return <main>
    <section className='container'>
        <div style={{minHeight: '635px', maxWidth: '501px'}} className="p-0 m-0 bg-light">
        {/* <div className='flex justify-end h-0'>
            <a href='/'><img src={closeIcon} className='me-2 mt-2'/></a>
        </div> */}
        <div style={{minHeight: '75vh'}} className='flex flex-col justify-center'>
        <div className="flex justify-center my-2">
            <h1 className="w-75 font-bold text-xl headingClass">Create an Account</h1>
        </div>
        <div className="flex justify-center my-1">
            <p className="w-75 text-xs text-gray-600 simpleTextClass">Set a password for your new account: dummy@gmail.com. The password must be 8–20 characters and include letters, numbers, and symbols.</p>
        </div>
        <div className="flex justify-center my-2">
            <div className="form-group w-75">
                <input type="password" className="form-control" id="inputPassword" aria-describedby="emailHelp" placeholder="New Password" />
            </div>
        </div>
        <div className="flex justify-center my-2">
            <div className="form-group w-75">
                <input type="password" className="form-control" id="inputPassword2" aria-describedby="emailHelp" placeholder="Confirm New Password" />
            </div>
        </div>
        <div className="flex justify-center my-1 ml-2" onClick={props.setLoginToReg}>
        <div className='w-75'>
            <button type="button" style={{backgroundColor: '#2196F3'}} className="btn text-light w-100">Register</button>
        </div>
        </div>
        <div className="flex justify-center mt-5">
            <p className='text-xs w-75 text-gray-600 minimalTextClass'>By signing in or registering, you are deemed to have agreed to the HitcHiker.com Terms and Condition and Privacy Statement</p>
        </div>
        </div>
        </div>
    </section>
</main>
}

export default SignUp;