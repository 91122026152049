import _ from "lodash";

const normalizeName = (name: string): string => {
    const replacements: { [key: string]: string } = {
        Ä: "AE",
        ä: "ae",
        Æ: "AE",
        æ: "ae",
        Ö: "OE",
        ö: "oe",
        Ü: "UE",
        ü: "ue",
        ß: "ss",
        Å: "A",
        å: "a",
        À: "A",
        à: "a",
        Â: "A",
        â: "a",
        Ç: "C",
        ç: "c",
        É: "E",
        é: "e",
        È: "E",
        è: "e",
        Ê: "E",
        ê: "e",
        Ğ: "G",
        ğ: "g",
        İ: "I",
        ı: "i",
        Ñ: "N",
        ñ: "n",
        Ø: "O",
        ø: "o",
        Ô: "O",
        ô: "o",
        Ş: "S",
        ş: "s",
        Û: "U",
        û: "u",
    };

    // Apply replacements first
    let normalized = name;
    for (const [key, value] of Object.entries(replacements)) {
        const regex = new RegExp(`[${key}]`, "g");
        normalized = normalized.replace(regex, value);
    }

    // Normalize the string to NFD form and remove non-spacing marks
    const formD = normalized.normalize("NFD");
    let result = "";

    for (const ch of formD) {
        if (!/[\u0300-\u036f]/.test(ch)) {
            result += ch;
        }
    }

    // Remove any remaining non-ASCII characters except spaces and convert to uppercase
    result = result.replace(/[^a-zA-Z0-9-\s]/g, "").toUpperCase();

    return result;
};

export default normalizeName;
